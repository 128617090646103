<template>
  <div>
    <div v-show="this.loading" class="loader_page">
      <img src="../../src/assets/images/loading.gif" />
    </div>
    <div v-show="!this.loading">
      <Header class="header-login" />
      <div class="content block block-login" v-bind:data-dir="getDir">
        <div class="wrap text-center">
          <a href="/" class="logo">
            <img src="../assets/images/myGMT_once.gif" alt="GMT">
          </a>
          <div class="login">
            <div class="form-login" v-show="!jwtAuth && biometricLoaded">
              <form @submit.prevent="loginSms">
                <lottie-player id="homepage" autoplay loop mode="normal" />
                <h2  class="login-form-header">{{ $ml.with('VueJS').get('welcome')
                  }}
                  {{ this.authData.userFirstName || '' }} <br>
                  <span
                    v-html="($route.query && $route.query.creditorName) ? `You have a payment request waiting from <br> ${$route.query.creditorName}`: '' "></span>
                </h2>
                <div v-show="!this.$store.getters.getBiometricData.hasSecret || this.showForm"
                  :class="{ 'slide-in': biometricLoaded }">
                  <div class="form-group">
                    <label for="identification" v-text="$ml.with('VueJS').get('loginLabel1')" class="floating-label"
                      id="identification-floating-label"></label>
                    <input v-model.trim="authData.identification" v-validate="'required'" type="text"
                      name="identification" id="identification" class="form-control">
                    <span class="error">{{ errors.first('identification') }}</span>
                  </div>
                  <div class="form-group">
                    <label for="mobileNumber" v-text="$ml.with('VueJS').get('loginLabel2')" class="floating-label"
                      id="mobileNumber-floating-label"></label>
                    <input v-model.trim="authData.mobileNumber"
                      v-validate="{ required: 'required', numeric: 'numeric', regex: [/^05\d([-]{0,1})\d{7}$/] }"
                      type="tel" id="mobileNumber" name="mobileNumber" class="form-control" autocomplete="mobile">
                    <span class="error">{{ errors.first('mobileNumber') }}</span>
                  </div>

                  <div class="form-group group-btn btn-link">
                    <button type="submit" class="btn btn-white" v-text="$ml.with('VueJS').get('btnSignIn')"></button>
                    <!-- <button style="margin: 2px 5px; width: 100px;" type="submit"
                    @click="loginWith('yakov')">yakov</button>
                    <button style="margin: 2px 5px; width: 100px;" type="submit" @click="loginWith('eli')">eli</button>
                    <button style="margin: 2px 5px; width: 100px;" type="submit" @click="loginWith('roi')">roi</button> -->
                  </div>

                  <div class="form-group register-link">
                    <router-link v-bind:to="'/registration'" class="link"
                      v-text="$ml.with('VueJS').get('btnRegistration')"></router-link>
                  </div>

                </div>

                <div class="form-group btn-link biometric-section-div"
                  v-if="this.$store.getters.getBiometricData.hasTouchId" :class="{ 'slide-in': biometricLoaded }">
                  <button class="btn btn-border" type="button" @click="attemptBiometricSign()">
                    <div class="biometric-img-line">
                      <img src="../assets/images/Face_ID.svg" alt="face-id" v-if="this.$store.getters.isFaceId">
                      <i class="fas fa-fingerprint" v-else></i>
                      {{
                        this.$store.getters.isFaceId ? $ml.with('VueJS').get('btnBiometricSignInFaceId') :
                      $ml.with('VueJS').get('btnBiometricSignInTouchId')
                      }}
                    </div>
                  </button>
                </div>

                <div class="form-group captcha-div">
                  <p class="login-consent">
                    {{ $ml.with('VueJS').get('logInConsent') }}<a
                      v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('protocolLink')"
                      style="display:inline" target="_blank">{{ $ml.with('VueJS').get('termsUse') }}</a>
                    {{ $ml.with('VueJS').get('and') }}<a
                      v-bind:href="'https://gmtonline.co.il/' + $ml.with('VueJS').get('privacyLink')"
                      style="display:inline" target="_blank">{{ $ml.with('VueJS').get('privacyPolicy') }}</a>
                  </p>
                  <p v-if="!this.isApp">
                    This site is protected by reCAPTCHA and the Google
                    <a href="https://policies.google.com/privacy" style="display:inline" target="_blank">Privacy
                      Policy</a> and
                    <a href="https://policies.google.com/terms" style="display:inline" target="_blank">Terms of
                      Service</a> apply.
                  </p>
                </div>
                <vue-recaptcha ref="recaptcha" @verify="onVerify" @expired="onExpired" size="invisible"
                  :sitekey="sitekey"></vue-recaptcha>
              </form>
            </div>
          </div>
        </div>
      </div>

      <!--Modal Language-->
      <select-lang ref="selectLangModal" @post-upadte="postUpdate"></select-lang>
      <!--End Modal Language-->

      <!--Modal Message with sms code-->
      <div class="modal-mask modal-mask-message" @click="jwtAuth ? null : closeModal">
        <div class="modal-container modal-message" id="modal-message" @click.stop>
          <div>
            <span @click="closeModal" class="close-modal" :class="{ 'close-modal-rtl': getDir === 'rtl' }">✕</span>
            <div class="modal-body">
              <div>
                <img src="../assets/images/sms_once.gif" alt="">
                <div class="title" v-text="$ml.with('VueJS').get('messageCode')"></div>
                <input type="text" autocomplete="one-time-code" inputmode="numeric" maxlength="6"
                  class="form-control sms-code" id="smsCode" name="smsCode" v-model="authData.smsCode"
                  :class="{ 'form-error': errorCode }" @keyup.enter="login()">
                <span class="error">{{ error }}</span>
                <div class="form-group group-btn">
                  <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.with('VueJS').get('btnOk')"
                    @click="login()"></button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="isAndroidMobile" class="footer">
            <span v-text="$ml.get('messageNotSent')"></span>
          </div>
        </div>
      </div>
      <!--End Modal Message with sms code-->


      <!-- Modal Biometric Failure-->
      <div class="modal-mask" @click="closeBiometricFailureModal" id="biometric-failure-modal">
        <div class="modal-container modal-message" @click.stop>
          <div>
            <div class="modal-body biometric-failure">
              <img v-if="this.$store.getters.isFaceId" src="../assets/images/face-id-yellow.svg" alt="">
              <img v-else src="../assets/images/touch-id-yellow.svg" alt="">
              <div class="title">{{ $ml.with('VueJS').get('biometric_public_key_expired') }} </div>
              <button class="btn btn-small btn-border" v-text="$ml.with('VueJS').get('btnClose')"
                id="biometric-failure-close-btn" @click="closeBiometricFailureModal()"></button>
            </div>
          </div>
        </div>
      </div>
      <!--End Modal Biometric Failure-->
    </div>
  </div>
</template>

<script>
import { jwtLoginSMS, loginSMS } from "../services/api.js";
import { login } from "../services/auth.js";
import MainNav from "../components/MainNav";
import { Validator } from 'vee-validate';
import store from '../store';
import VueRecaptcha from 'vue-recaptcha';
import { EventBus } from "../event-bus";
import {
  addFloatingLabel,
  getUserFirstName,
  detectUserOs,
  isApp,
  loadLottieJson,
  localesDictionary,
  makeRand,
  modalHandler
} from "@/services/utils"
import SelectLang from '../components/SelectLang.vue'
import Header from "@/components/Header.vue";

export default {
  name: 'Login',
  components: { MainNav, VueRecaptcha, SelectLang, Header },
  data() {
    return {
      authData: {
        userFirstName: getUserFirstName(),
        identification: '',
        mobileNumber: '',
        country: 'ISR',
        recaptchaToken: '',
        signature: '',
        timestamp: '',
        // smsCode: '',
        jwt: '',
        biometricSign: false,
        smsCode: '123456',
      },
      // mygmt2 todo
      error: '',
      errorCode: false,
      user: {
        error: ''
      },
      currentLang: '',
      isActive: false,
      jwtAuth: false,
      firstVisited: false,
      sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      isApp: isApp(),
      isSubmitted: false,
      showForm: false,
      loading: false
    }
  },
  created() {

    EventBus.$on('changeLang', lang => {
      this.$validator.localize(this.dictionary());
      this.$validator.localize(lang);
    });
    if (this.$route.query.jwt) {
      this.jwtAuth = true
      this.authData.jwt = this.$route.query.jwt
    } else {
      this.jwtAuth = false
      this.authData.jwt = ''
    }
    if (this.$route.query.creditorName && this.$route.query.id) {

      localStorage.setItem('paymentRequestData', JSON.stringify({
        paymentRequestName: this.$route.query.creditorName,
        id: this.$route.query.id
      }))
    }

    if (this.isApp) {
      screen.orientation.lock();
    }
  },
  computed: {
    getLang() {
      return this.$store.getters.getLang;
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    biometricLoaded() {
      return isApp() ? this.$store.getters.getBiometricData.loaded : true
    },
    isAndroidMobile() {
      // return detectUserOs() == 'Android';
      return true
    }
  },
  methods: {
    loginSms() {
      this.authData.biometricSign = false
      this.$refs.recaptcha.execute();
    },
    loginWith(name) {

const logins = {

  roi: {

    identification: '036185254',

    mobileNumber: '0528912121',

    smsCode: '123456'

  },

  eli: {

    identification: '066650821',

    mobileNumber: '0525132768',

    smsCode: '123456',

  },

  yakov: {

    identification: '320701345',

    mobileNumber: '0525132768',

    smsCode: '123456',

  }

}

this.authData = {

  ...this.authData, ...logins[name],

}

// setTimeout(() => {

//   $(".btn-yellow").click()

// }, 500)

this.loginSms()

},

    attemptBiometricSign() {
      this.authData.biometricSign = true
      this.$refs.recaptcha.execute();
      if (this.$store.getters.getBiometricData.hasSecret) {
        this.showForm = true;
        gonative.auth.get({
          'callbackFunction': (res) => {
            if (res.success) {
              let biometricSignData = {}
              let resObj = JSON.parse(res.secret)
              let key = rsa.pki.privateKeyFromPem(resObj[0])
              biometricSignData.userIdentifier = resObj[1]
              let md = rsa.md.sha256.create();
              biometricSignData.salt = makeRand(8).repeat(32)
              biometricSignData.biometricTimeStamp = new Date().getTime()
              md.update(`${biometricSignData.salt}${biometricSignData.userIdentifier}${biometricSignData.biometricTimeStamp}${biometricSignData.salt}`, 'utf8')
              biometricSignData.biometricSignature = rsa.util.binary.base64.encode(rsa.util.binary.raw.decode(key.sign(md)))
              this.login(biometricSignData)
            } else {
              this.authData.biometricSign = false
              this.openBiometricFailureModal()
            }
          }
        });
      } else {
        this.$router.push('/biometricRegistration')
      }
    },
    closeModal() {
      this.authData.smsCode = '';
      this.error = '';
      modalHandler('.modal-mask', 'hide', 'ffffff', 'dark')
    },
    onVerify: function (recaptchaToken) {
      let self = this;
      this.authData.recaptchaToken = recaptchaToken;
      if (this.authData.biometricSign) {
        this.authData.biometricSign = false
        return
      }
      self.loading = true;
      if (this.jwtAuth) {
        jwtLoginSMS(this.authData, function (response) {
          self.loading = false
          if (!response.data.errorCode) {
            modalHandler('.modal-mask-message', 'show', '092086', 'light')
            self.authData.signature = response.data.result.signature;
            self.authData.timestamp = response.data.result.timestamp;
          }
        });
      } else {
        this.$validator.validate().then(result => {
          if (result) {
            loginSMS(this.authData, function (response) {
              self.loading = false
              if (!response.data.errorCode) {
                self.authData.signature = response.data.result.signature;
                self.authData.timestamp = response.data.result.timestamp;
                modalHandler('.modal-mask-message', 'show', '092086', 'light');
                // setTimeout(() => { $('#smsCode').focus() }, 250);
              }
            });
          } else {
            self.loading = false
          }
        })
      }
    },
    onExpired: function () {
      console.log('Expired');
      this.$refs.recaptcha.reset();
    },
    login(biometricSignData) {
      let self = this;
      if (!self.isSubmitted) {
        self.loading = true;
        self.isSubmitted = true;
        login({ ...this.authData, ...biometricSignData }, function (response) {
          self.loading = false;
          self.isSubmitted = false;
          if (response.data.errorCode === 'SMS_WRONG' || response.data.errorCode === 'SMS_EXPIRED') {
            self.error = self.$ml.with('VueJS').get(response.data.errorCode);
          }

          if (response.data.errorCode === 'BIOMETRIC_LOGIN_FAILED' || response.data.errorCode === 'BIOMETRIC_PUBLIC_KEY_EXPIRED') {
            self.closeModal();
            self.openBiometricFailureModal();
            gonative.auth.delete({
              'callbackFunction': (res) => {
                $("#biometric-failure-close-btn").click(() => {
                  window.location.replace('/#/login');
                  window.location.reload();
                });
              }
            });
          }
        });
      }
    },

    postUpdate() {
      Validator.localize(this.dictionary());
      Validator.localize(this.getLang);
    },
    dictionary() {
      const custom = {
        mobileNumber: {
          regex: this.$ml.with('VueJS').get("E_REGEX_mobileNumber")
        },
      }
      const messages = {
        required: this.$ml.with('VueJS').get("E_REQUIRED"),
        numeric: this.$ml.with('VueJS').get("E_NUMERIC")
      }
      return localesDictionary({ custom: custom, messages: messages });
    },
    closeBiometricFailureModal() {
      this.authData.biometricSign = false
      modalHandler('#biometric-failure-modal', 'hide', 'ffffff', 'dark');
    },
    openBiometricFailureModal() {
      modalHandler('#biometric-failure-modal', 'show', '092086', 'light');
    }
  },
  mounted() {
    store.commit('resetRegistration');
    Validator.localize(this.dictionary());
    Validator.localize(this.getLang);
    document.body.setAttribute('dir', this.getDir);
    this.$refs.selectLangModal.checkLangs()
    addFloatingLabel('mobileNumber')
    addFloatingLabel('identification')
    loadLottieJson("homepage", "homepage_v5")
    // mygmt2 todo
    // setTimeout(() => {
    //   $(".btn-white")[4].click()
    // }, 1500)
    // setTimeout(() => {
    //   $(".btn-yellow").click()
    // }, 3000)
    // mygmt2 todo
  },
}

window.onload = function () {
  if (location.href.includes('jwt')) {
    document.querySelector('button[type="submit"]').click()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.header-login {
  min-height: 71px;
  z-index: 1000;
  position: relative;

  .mygmt-header {
    min-height: 71px;
  }
}

.block.block-login {
  position: relative;
  padding-top: 70px;
  padding-bottom: 0;
  height: calc(100vh - 71px);

  .wrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 0;
  }

  .logo {
    position: relative;
    z-index: 1;

    img {
      max-width: 260px;
    }
  }

  .error {
    display: block;
    max-width: 256px;
    text-align: right;
    position: static;
    margin-right: 0;
    margin-bottom: -10px;
    font-size: 16px;
    color: #e8d400;
  }

  .login {
    max-width: 1500px;
    width: 100%;
    height: 470px;
    margin: 0 auto;
    position: relative;
    margin-top: 202px;

    .form-login {
      width: 100%;
      height: 100%;
      position: relative;
      //overflow: hidden;
      padding-top: 35px;

      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 1070px;
        /*  -webkit-border-radius: 50%;
          -moz-border-radius: 50%;
          border-radius: 50%;*/
        //background-color: $mainColor;
        background-image: url(../assets/images/bg-login-desctop.png);
        background-position: center;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }

      .login-form-header {
        color: white;
        text-align: center;
        margin-bottom: 10px;
        position: relative;
        z-index: 5;
      }
    }

    &:before {
      /*  content: '';
        display: block;
        width: 635px;
        height: 421px;
        background-image: url(../assets/images/img-login.png);
        position: absolute;
        left: 66px;
        right: 0;
        top: -373px;
        margin: auto;*/
      z-index: 0;
      background-color: #fff;
    }
  }

  form {
    max-width: 284px;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }

  .form-group {
    text-align: right;

    &+.form-group {
      margin-top: 20px;
    }

    &+.captcha-p {
      margin-top: 5px;
    }

    label {
      color: #fdffff;
      text-align: right;
    }

    .link {
      color: #fdffff;
      text-align: center;
    }

    .form-control {
      max-width: 98%;
      width: 100%;
      border: none;
      border-bottom: 2px solid rgb(242, 242, 242);
      background-color: transparent;
      font-size: 18px;
      color: #fdffff;
      padding: 7px 9px;
      margin-top: 5px;

      /*   &[aria-invalid=true]{
             border-color: #f75151;
         }*/
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 18px;
        color: #fdffff;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 18px;
        color: #fdffff;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 18px;
        color: #fdffff;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 18px;
        color: #fdffff;
      }
    }
  }

  .biometric-section-div {
    margin-top: 20px !important;

    .btn {
      display: inline-block;
      vertical-align: middle;
      width: 95%;
      font-size: 17px;
      height: 40px;
      margin: auto;
    }

    .btn-white:hover {
      background-color: #e1e1e1;
    }

    .link-like {
      border: none;
      font-size: 14px !important;
      padding: 0 !important;
    }

    .biometric-img-line {
      display: flex;
      justify-content: center;
      align-content: center;
      vertical-align: middle;

      img {
        width: 20px;
        margin-inline-end: 5px;
      }

      i {
        line-height: 31px;
        margin-inline-end: 2px;
      }
    }
  }

  .group-btn {
    margin-top: 37px !important;

    .btn {
      display: inline-block;
      vertical-align: middle;
      width: 95%;
      font-size: 17px;
      height: 40px;
      margin: auto;
    }

    .btn-white:hover {
      background-color: #e1e1e1;
    }

    .link-like {
      border: none;
      font-size: 14px !important;
      padding: 0 !important;
    }
  }
}

.register-link {
  margin-top: 20px !important;
}

.btn-link {
  text-align: center !important;
}

.modal-login {
  text-align: center;
  padding: 60px 30px 25px;
  font-family: 'OpenSansHebrew', sans-serif;

  .modal-title {
    color: #fff;

    .title {
      font-size: 30px;
    }

    .subtitle {
      font-size: 17px;
      // margin-top: -8px;
    }
  }

  .list-lang {
    margin-top: 22px;

    .item-lang {
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      position: relative;
      margin: 0 10px;
      text-transform: uppercase;

      label {
        display: block;
        width: 73px;
        height: 73px;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: 1px solid #fff;
        font-size: 18px;
        color: #ffffff;
        line-height: 71px;
        @include transition(all .4s ease);
      }

      input[type=radio] {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer;

        &:hover+label,
        &:checked+label {
          background-color: #d5c641;
          border-color: #d5c641;
          color: $text-color;
        }
      }
    }
  }

  .btn {
    font-family: 'OpenSansHebrew', sans-serif;
    font-size: 18px;
    display: inline-block;
    margin-top: 35px;
    min-width: 111px;
    width: auto;
    padding: 0 10px;

    &.active {
      background-color: red;
    }

    &:hover {
      background-color: #d5c641;
      border-color: #d5c641;
    }
  }
}


.modal-message {
  width: 582px;
  height: auto;
  text-align: center;
  padding-top: 37px;
  padding-bottom: 37px;

  img {
    max-width: 93px;
  }

  .title {
    font-size: 18px;
    line-height: 22px;
    color: #fdffff;
    max-width: 266px;
    margin: 30px auto 0;
  }

  .form-control {
    max-width: 240px;
    width: 100%;
    margin-top: 25px;
    letter-spacing: 5px;
    text-align: right;

    &[aria-invalid=true] {
      border-color: #ff6a68;
    }
  }

  .group-btn {
    margin-top: 30px;

    .btn {
      display: inline-block;
      vertical-align: middle;

      &.btn-border {
        border-color: #d4c54b;
        color: #d4c54b;
        margin-left: 12px;
      }
    }
  }

  .error {
    display: block;
    text-align: right;
    position: static;
    margin-right: 0;
    margin-bottom: -10px;
    font-size: 16px;
    max-width: 240px;
    margin: 0 auto;
    text-align: center;
  }
}

.grecaptcha-badge {
  visibility: collapse !important;
}

.captcha-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  white-space: normal;
  align-items: center;
  margin: 25px 0px;

  p {
    width: 100%;
    color: white;
    font-size: 12px;
    direction: ltr;
    text-align: center !important;

    a {
      color: white;
      text-decoration: underline;
    }
  }

  .login-consent {
    margin: 5px 0px;
  }
}

.slide-in {
  animation: fadeInAnimation ease 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.biometric-failure {
  display: flex;
  flex-direction: column;
  align-items: center;

  .title {
    margin: 20px;
  }
}

#homepage {
  display: none;
}
</style>