
      <template>
        <div draggable="false" class="grey-bgc  main-container relative" :dir="$store.getters.getDir">
            <img class="kite" src="../assets/mygmt_v2_images/kite-transfer.svg" alt="">
            <Header2  
                :forceCloser="true"
                :hide="['FAQ', 'LOCATIONS']"></Header2>
                 <!-- <Header2 v-if="(!isFocused() && isMobileDevice) || !isMobileDevice" @stepBack="handleBackClick" -->
                <!-- :forceCloser="isCalculatorRoute && currentStep == 0" :currentStep="currentStep + 1" -->
                <!-- :hide="['FAQ', 'LOCATIONS']"></Header2> -->
            <div class="transfer-container relative"
                :class="{ 'zero-padding': true }">
               
                <div class="transfer-container relative">
                  <div class="container flex flex-column justify-center align-center">
    <!-- <div>{{ selectedTransferOption }}</div> -->
    <div v-if="selectedTransferOption && selectedTransferOption !== undefined && selectedTransferOption !== 'undefined'" class="status-type sucsess flex flex-column justify-center align-center">
      <div><img :src="getSuccsessImg" alt=""></div>
      <h1 class="title black bold">{{ $ml.get('paymentStatuses.titles.' + selectedTransferOption.transactionClass || 'CASH_TRANSFER') }}</h1>
      <div class="description black">
        
        <!-- <div v-if="selectedTransferOption.transactionClass === 'BANK_TRANSFER'">
           <p>
            {{ $ml.get('paymentStatuses.messages.' + selectedTransferOption.transactionClass) }}
          </p>
        </div> -->

        <div v-if="selectedTransferOption && beneficiary">
            <!-- <p>You successfully transferred {{selectedTransferOption.totalReceiveAmount? selectedTransferOption.totalReceiveAmount +' '+selectedTransferOption.payoutCurrency : calculatedTotal + ' '+ selectedTransferOption.currency }} to {{beneficiary.firstName}} <br> in {{getCountryNameByIso(selectedTransferOption.destinationCountry) }}.</p>
            <p>
            In a moment you will receive the transfer confirmation via SMS and e-mail.
            </p> -->

            <p>
              {{ $ml.with('amount',selectedTransferOption.totalReceiveAmount? selectedTransferOption.totalReceiveAmount +' ' : calculatedTotal + ' ').with('currency',selectedTransferOption.totalReceiveAmount? selectedTransferOption.payoutCurrency : selectedTransferOption.currency ).with('beneficiary',beneficiary.firstName + ' ' + beneficiary.lastName).with('destination',getCountryName(selectedTransferOption.destinationCountry)).get('paymentStatuses.messages.'+ selectedTransferOption.transactionClass) }}
            </p>
        </div>
      </div>
      <button @click="$router.push(`/TransactionsHistory?transactionId=${selectedTransferOption.transactionId}`)" style="width: 60%;" class="btn btn-main">{{$ml.get('paymentStatuses.buttons.buttonAction')}}</button>
      <a class="pointer" @click.prevent="backToHomePage">{{$ml.get('paymentStatuses.buttons.buttonLink')}}</a>
      <!-- <div @click="backToHomePage" class="pointer"></div> -->
    </div>
      </div>
        </div>
         </div>
        </div>
      </template>
<script>
import _ from 'lodash';
import { EventBus } from '../event-bus';
import Header2 from '../components/Header2.vue';
import { getBeneficiary } from '../services/api';
import {getCountryNameByIso} from '../services/utils'


export default {
  components: {Header2},
  name: 'TransactionDetails',
  props: { 
    type: {
      type: Object,
      required: false,
    },
  },
  created() {
    EventBus.$emit('unload');   
    this.getTransactionDetails()  
    getBeneficiary(this.selectedBeneficiaryId).then((beneficiary) => {
      this.beneficiary = beneficiary
    })   

   
  },
  data() {
    return {
      selectedTransaction : null,
      beneficiary: null
    };
  },
  computed: {
    getSuccsessImg(){
      let imgName = this.selectedTransferOption && this.selectedTransferOption.transactionClass ? this.selectedTransferOption.transactionClass : 'CASH_TRANSFER'
      return require(`../assets/mygmt_v2_images/beneficiary/${imgName}.svg`)
    },
    selectedTransferOption() {
      return JSON.parse(localStorage.getItem('selectedTransferOption')) 
    }, 
    selectedBeneficiaryId() {
      return  JSON.parse(localStorage.getItem('selectedBeneficiary'))
    },
    calculatedTotal() {
    return this.selectedTransferOption.amount + 
           this.selectedTransferOption.commission - 
           (this.usedCashBack ? this.cashBackCredit : 0);
    },
    errorMessage() {
      if (this.$store.getters.getErrorMessage)
        return this.$store.getters.getErrorMessage;
      else {
        let msg = this.$ml.get(this.$store.getters.getErrorCode);
        return msg ? msg : this.$ml.get('API_EXCEPTION');
      }
    },

  },
  watch: {
  },
  mounted() {
    
  },
  beforeDestroy() {
  //  this.removeStatus()
  localStorage.removeItem('selectedTransferOption');
  localStorage.removeItem('selectedBeneficiary');
  localStorage.removeItem('customerFields');
  localStorage.removeItem('beneficiaryFields');
  localStorage.removeItem('responseValidation');
  localStorage.removeItem('analyticsEvent');
   },
  methods: {
    
    getCountryName(iso){
     return getCountryNameByIso(iso)
    },

    backToHomePage(){
      this.$router.push('/Home')
    },

    getTransactionDetails() {
     const id = this.$route.query.amount


     // bla bla get the transactions


     this.selectedTransaction = {
    "date": "11/02/2025 16:45",
    "amount": 157.1,
    "currency": "USD",
    "beneficiaryId": 13200,
    "otherSide": "bla  bli ",
    "receiveAmount": 0,
    "payoutCurrency": "CAD",
    "country": "Canada",
    "countryIso": "CAN",
    "correspondent": "GMT",
    "controller": "gmt",
    "customerCommission": 6.9,
    "discount": null,
    "totalSum": 164,
    "correspondentReference": "",
    "transactionStatus": "SWIFT_REQUESTED",
    "canCheckStatus": false,
    "canClone": true,
    "transactionClass": "BANK_TRANSFER",
    "gmtId": 19772,
    "beneficiaryBankName": "219-Alberta Treasury Branches",
    "beneficiaryBankBranch": "",
    "beneficiaryBankAccount": "6876786786782",
    "beneficiaryBankIban": "",
    "beneficiaryCashBank": "",
    "beneficiaryWalletBank": "",
    "beneficiaryWalletIdentifier": "",
    "beneficiaryCardNumber": "",
    "beneficiaryUpiIdentifier": "",
    "expired": 1,
    "agentCommission": 0,
    "settings": "העברה לחשבון בנק באמצעות EARTHPORT - בדיגיטל",
    "cashBackCredit": 59.65,
    "cashBackCurrency": "USD",
    "cashBackCollected": 0,
    "descCode": null,
    "destination": "Canada",
    "paymentDetails": [
        {
            "id": 6724,
            "creditCardType": null,
            "flightDate": null,
            "branchCode": null,
            "paymentType": {
                "enumType": "com.gmt.core.transaction.PaymentType",
                "name": "CASHBACK"
            },
            "hypTerminalCg": null,
            "gmtTransaction": {
                "id": 19772
            },
            "direction": {
                "enumType": "com.gmt.core.conversion.Direction",
                "name": "IN"
            },
            "accountName": null,
            "hypXField": null,
            "paymentProcessor": null,
            "yaadSarigTransID": null,
            "checkDate": null,
            "hypTerminalId": null,
            "referenceNumber": null,
            "numOfPayments": 1,
            "installments": null,
            "hypUid": null,
            "refundReferenceNumber": null,
            "accountNumber": null,
            "yaadSarigId": null,
            "clientCardNumber": null,
            "bankCode": null,
            "hypTerminalNo": null,
            "refundYaadSarigTransID": null,
            "masofType": null,
            "paidSum": 59.65,
            "currencyConversion": null,
            "yaadSarigMasof": null,
            "status": {
                "enumType": "com.gmt.core.transaction.PaymentStatus",
                "name": "PAID"
            },
            "checkNumber": null,
            "paidCurrency": "USD",
            "remainder": 0,
            "paidTo": null
        },
        {
            "id": 6725,
            "creditCardType": null,
            "flightDate": null,
            "branchCode": null,
            "paymentType": {
                "enumType": "com.gmt.core.transaction.PaymentType",
                "name": "WALLET"
            },
            "hypTerminalCg": null,
            "gmtTransaction": {
                "id": 19772
            },
            "direction": {
                "enumType": "com.gmt.core.conversion.Direction",
                "name": "IN"
            },
            "accountName": null,
            "hypXField": null,
            "paymentProcessor": null,
            "yaadSarigTransID": null,
            "checkDate": null,
            "hypTerminalId": null,
            "referenceNumber": null,
            "numOfPayments": 1,
            "installments": null,
            "hypUid": null,
            "refundReferenceNumber": null,
            "accountNumber": null,
            "yaadSarigId": null,
            "clientCardNumber": null,
            "bankCode": null,
            "hypTerminalNo": null,
            "refundYaadSarigTransID": null,
            "masofType": null,
            "paidSum": 381.76,
            "currencyConversion": {
                "id": 25946
            },
            "yaadSarigMasof": null,
            "status": {
                "enumType": "com.gmt.core.transaction.PaymentStatus",
                "name": "PAID"
            },
            "checkNumber": null,
            "paidCurrency": "ILS",
            "remainder": 0,
            "paidTo": null
        }
    ],
    "correspondentType": "GMT",
    "correspondentIdentifier": "GMT"
}
    }
   
  },

};
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

h1 {
  text-align: center;
}

.container {
  height: 100%;
  width: 100%;
  padding: 25px 45px;
}

.sucsess {
  img {
    margin-top: 60px;
  }
  .title {
    margin-top: -40px;
    text-align: center;
  }

  .description {
    text-align: center;
    margin-top: -50px;
  }
}

.transfer-container[data-v-a1450d20] {
        z-index: 2;
        width: 600px;
        margin: 0 auto;
        min-height: auto;
        overflow: clip;
        max-height: 433px;
        margin-top: 40px;
        padding: 0px 24px;
        border-radius: 20px;
        background-image: none; }

.btn-main {
  height: 60px;
  padding: 0 15px;
}



.description {
  p {
    margin: 30px 0px;
    text-align: center;
  }
}

.status-type {
  gap: 50px;
}

@media only screen and (min-width: $screen-tablet) {
  
  
}
</style>






<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.main-container {
  min-height: none;
  overflow: hidden;
  overflow-y: auto;
  background-image: none;
  // max-height: 100vh;
}

.kite {
  display: none;
}

.eclipse {
  margin: 0 8px
}

.transfer-info {
  margin-bottom: 12px;
  font-size: 14px;

  .flag {
      height: 18px;
      width: 18px;
      margin-right: 5px;
      margin-left: 0px;
  }
}

[dir="rtl"] .transfer-info .flag {
  margin-right: 0px;
  margin-left: 5px;


}


.mobile-margin {
  margin-right: 20px;
  margin-left: 20px;
}

.zero-padding {
  padding: 0px !important;
}

.transfer-container {
  min-height: 90vh;
  padding: 0px 20px;
  background: #FFF;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 100px;
  //box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  background-image: url('../assets/mygmt_v2_images/mygmt2-background-grey.svg');

  .upper {
      align-items: center;

      // margin-bottom: 10px;
      .back {
          font-size: 16px;
          margin-top: 25px;
          margin-bottom: 8px;
          color: var(--GMT--Blue, #0E1F81);
      }

      .money-title {
          font-size: 20px;
          font-weight: 700;
          color: #0E1F81;
          font-style: normal;
          line-height: normal;
          font-family: Assistant;
      }
  }

  .progress-bar {
      width: 100vw;
      height: 4px;
      margin-left: -20px;
      background: #F6F6F6;

      // margin-bottom: 26px;
      // position: relative;
      .test-grey {
          // position: absolute;
          // background: #F6F6F6;
      }
  }

}

.mygmt2-header-wrapper {
  background-color: #FFFF;
}

@media only screen and (min-width: $screen-tablet) {

  .upper {
      margin-bottom: 10px;
  }

  .mobile-margin {
      margin-left: 0px;
      margin-right: 0px;
  }

  .main-container {
      min-height: 100vh;
      background-repeat: no-repeat;
      background-size: auto auto, auto auto;
      background-repeat: no-repeat, no-repeat;
      background-position: left bottom, right top;
      background-image: url('../assets/mygmt_v2_images/mygmt2-backgground-white.svg'), url('../assets/mygmt_v2_images/mygmt-backgground-white-small.svg');
  }

  .kite {
      right: 0px;
      top: 200px;
      z-index: 0;
      display: block;
      position: absolute;
  }


  .transfer-container {
      z-index: 2;
      width: 600px;
      margin: 0 auto;
      min-height: 80vh;
      overflow: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      max-height: 80vh;
      margin-top: 40px;
      padding: 0px 24px;
      border-radius: 20px;
      background-image: none;
      box-shadow: 0px 3px 11.66px -4.66px rgba(51, 77, 194, 0.34);


      .upper {
          align-items: flex-start;

          .money-title {
              font-size: 24px;
          }
      }

      .progress-bar {
          width: 100%;
          margin-left: 0px;
      }
  }
}
</style>
