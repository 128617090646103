<template>
  <div class="grey-bgc main-container">
    <Header2 :forceArrow="true" class="header" v-if="isMobileDevice" />
    <img class="kite" src="../assets/mygmt_v2_images/kite-transfer.svg" alt="">
    <div v-if="!isCreatingBeneficiary" class="main-wrapper">
      <div class=" block ">
        <div class="body">
          <div class="wrap flex flex-column justify-center">
            <div v-if="isMobileDevice" class="flex flex-column justify-center">
              <h1 class="title-new w-full flex justify-center" v-text="$ml.with('VueJS').get('itemNav4')"></h1>
              <!-- <div class="form-search">
                <input type="text" class="form-control" v-bind:placeholder="$ml.with('VueJS').get('freeSearch')"
                  v-model="searchBeneficiary">
                <i class="icon icon-search"></i>
              </div> -->
              <div class="search-box-container w-100" ref="searchBox">
                <div class="round-box search-box">
                  <img src="../assets/mygmt_v2_images/Search.svg" />
                  <input v-model="searchBeneficiary" :placeholder="$ml.get('btnSearch')" type="text" ref="searchBoxInput" />
                  <img @click.stop="searchBeneficiary = ''; handleBlur" v-if="searchBeneficiary" class="pointer"
                    style="height: 11px; width: 11px;;" src="../assets/mygmt_v2_images/close-black.svg" alt="">
                </div>
              </div>
              <div @click="isCreatingBeneficiary = true" class="btn full-center btn-border w-100">
                {{ $ml.get('newBeneficiary')}}
              </div>
            </div>
            <div v-else>
              <div class="flex justify-between">
                <div class="flex">
                  <h1 class="title-new  flex justify-center black bold" v-text="$ml.with('VueJS').get('itemNav4')"></h1>
                  <div @click="isCreatingBeneficiary = true" class="btn full-center btn-border ">
                    {{ $ml.get('newBeneficiary')}}
                  </div>
                </div>
                <div class="search-box-container" ref="searchBox">
                  <div class="round-box search-box">
                    <img src="../assets/mygmt_v2_images/Search.svg" />
                    <input v-model="searchBeneficiary" :placeholder="$ml.get('btnSearch')" type="text" ref="searchBoxInput" />
                    <img @click.stop="searchBeneficiary = ''; handleBlur" v-if="searchBeneficiary" class="pointer"
                      style="height: 11px; width: 11px;;" src="../assets/mygmt_v2_images/close-black.svg" alt="">
                  </div>
                </div>

              </div>
            </div>

            <div class="list-beneficiaries w-100">
              <div class="item-beneficiary pointer" v-for="(beneficiary, index) in beneficiaries"
                @click="isMobileDevice ? openDrawer(beneficiary) : toggleCursorMenu($event, beneficiary)">
                <!-- <div>{{ beneficiary }}</div> -->
                <div class="item-row flex">
                  <div class="flex full-center justify-between beneficiary-container">
                    <div class="flex full-center">
                      <div class="circle pointer" @click=""><strong>{{ beneficiary.firstName[0].toUpperCase()
                          }}</strong></div>
                      <div class="flex flex-column">
                        <div class="name flex">{{ beneficiary.firstName | lowerCaseCapitalise }} {{ beneficiary.lastName
                          | lowerCaseCapitalise }}</div>
                        <div class="info">
                          <div class="flex full-center">
                            <img :src="imgSrc(beneficiary.countryIso3)" style="width: 16px;height: 16px;" alt="">
                            <div>
                              {{ country(beneficiary.countryIso3) }}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div> <img src="../assets/mygmt_v2_images/Right-Chevron.svg" alt=""></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-else>

      <div class="mygmt2-page-wrapper-container-desktop">
        <Header class="header" />
        <div class="title payment-balances-card-grid-box ">
          <div class="flex align-center">
            <h1>My Beneficiaries</h1>
            <div class="btn btn-border flex full-center">
              <div class="full-center">
                + Add New
              </div>
            </div>
          </div>
          <div class="round-square w-full">
            <div v-for="beneficiary in beneficiaries" class="line-beneficiary flex align-center justify-between">
              <div class="name flex w-100-px">{{ beneficiary.firstName | lowerCaseCapitalise }} {{ beneficiary.lastName
                | lowerCaseCapitalise }}</div>

              <div class="info w-100-px">
                <div class="flex full-center">
                  <img :src="imgSrc(beneficiary.countryIso3)" style="width: 16px;height: 16px;" alt="">
                  <div class="weak">
                    {{ country(beneficiary.countryIso3) }}
                  </div>
                </div>
              </div>

              <div class="w-100-px weak w-100-px">
                0525132768
              </div>

              <div class="w-100-px weak"> data</div>
              <div class="w-100-px weak flex flex-end"> <img class="pointer"
                  @click="toggleCursorMenu($event, beneficiary)" src="../assets/mygmt_v2_images/Dots.svg" alt=""
                  srcset=""></div>

            </div>
          </div>
        </div>

      </div>
    </div> -->
    <!--Modal beneficiary dialogue -->
    <!-- <div class="modal-mask modal-mask-message toast" @click="closeDialgue">
      <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
        <div class="modal-body body-dialogue">
          <div class="modal-header">
            <a class="a-close" @click="closeDialgue" :class="{ 'a-close-rtl': dir === 'rtl' }">&#10005;</a>
          </div>
          <div class="title">
            <span v-text="$ml.with('beneficiary', selectedBeneficiaryName).get('beneficiaryRemoveDialogue')"></span>
          </div>
          <div class="title" v-text="$ml.with('VueJS').get('beneficiaryRemoveDialogue2')"></div>
          <div class="form-group group-btn">
            <button class="btn btn-small btn-border" v-text="$ml.with('VueJS').get('btnReturn')"
              @click="selectBeneficiary()"></button>
            <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.with('VueJS').get('btnOk')"
              @click="removeBeneficiary()"></button>
          </div>
        </div>
      </div>
    </div> -->
    <div class="beneficiary-creation-wrapper" v-else>
      <Beneficiary @createdBeneficiary="createdBeneficiary" @update="createdBeneficiary" :action="'create'">
      </Beneficiary>
    </div>


    <Drawer ref="benefeciaryControlDrawer" :title="''" @closed="isConfirming = false"
      :drawerId="'benefeciaryControlDrawer'" class="drawer-wrapper-card flex flex-column "
      :class="{ 'custom-closer': isConfirming,'no-border': !hasBeneficiaryInfo}">
      <template v-if="!isConfirming" v-slot:heading>
        <div class="flex flex-column no-border">
          <div class="name flex">{{ selectedBeneficiary.firstName | lowerCaseCapitalise }} {{
            selectedBeneficiary.lastName |
            lowerCaseCapitalise }}</div>
          <div class="info ">
            <div class="flex full-center">
              <img :src="imgSrc(selectedBeneficiary.countryIso3)" style="width: 16px;height: 16px;" alt="">
              <div>
                {{ country(selectedBeneficiary.countryIso3) }}
              </div>
            </div>
          </div>
        </div>

      </template>
      <template v-slot:content>
        <div >
          <div v-if="!isConfirming" class="flex flex-column justify-between">

            <div class="sub-row ">
              <!-- {{ selectedBeneficiary }} -->
              <div
                v-if="selectedBeneficiary.maskedCardNumber || selectedBeneficiary.accountIdentifier || selectedBeneficiary.walletIdentifier || selectedBeneficiary.upiIdentifier || selectedBeneficiary.iban || selectedBeneficiary.phoneNumber">
                <div class="flex justify-between line" v-show="selectedBeneficiary.maskedCardNumber">
                  <div v-text="$ml.with('VueJS').get('cardNumber')"></div>
                  <div> {{ selectedBeneficiary.maskedCardNumber | formatCardNumber }} </div>
                </div>
                <div class="flex justify-between line" v-show="selectedBeneficiary.accountIdentifier">
                  <div v-text="$ml.with('VueJS').get('beneficiary.accountIdentifier')"></div>
                  <div> {{ selectedBeneficiary.accountIdentifier }} </div>
                </div>
                <div class="flex justify-between line" v-show="selectedBeneficiary.iban">
                  <div v-text="$ml.with('VueJS').get('beneficiary.iban')"></div>
                  <div> {{ selectedBeneficiary.iban }} </div>
                </div>
                <div class="flex justify-between line" v-show="selectedBeneficiary.walletIdentifier">
                  <div v-text="$ml.with('VueJS').get('beneficiary.walletIdentifier')"></div>
                  <div> {{ selectedBeneficiary.walletIdentifier }} </div>
                </div>
                <div class="flex justify-between line" v-if="selectedBeneficiary.upiIdentifier">
                  <div v-text="$ml.get(`beneficiary.upi_${selectedBeneficiary.countryIso3}`)"></div>
                  <div> {{ selectedBeneficiary.upiIdentifier }} </div>
                </div>
                <div class="flex justify-between line" v-show="selectedBeneficiary.phoneNumber">
                  <div v-text="$ml.with('VueJS').get('labelPhone')"></div>
                  <div> {{ selectedBeneficiary.phoneNumber }} </div>
                </div>
              </div>
            </div>
            <div class="flex flex-column justify-center align-center prevent-close" style="margin-bottom: -10px;">
              <button class="btn btn-wide full-center w-100" @click="selectBeneficiary()">{{ $ml.get('btnTransfer') }}</button>
              <div class="btn-red prevent-close" @click="isConfirming = true;"><img class="prevent-close" src="../assets/mygmt_v2_images/Garbage-red.svg" alt="" srcset=""> <div class="prevent-close"> {{$ml.get('deleteBeneficiary')}} </div> </div>
            </div>
          </div>

          <div v-else>
            <div class="flex flex-column align-center delete-confirmation-container">
              <img src="../assets/mygmt_v2_images/beneficiary/remove-beneficiary.svg" alt="">
              <h2>{{$ml.get('deleteBeneficiary')}}</h2>
              <p  v-html="$ml.with('beneficiary', `<span> ${selectedBeneficiary.firstName  } ${
            selectedBeneficiary.lastName  } </span>`).get('beneficiaryRemoveDialogue')">  </p>
            <p>{{ $ml.get('beneficiaryRemoveDialogue2') }}</p>
              <button @click="removeBeneficiary()" class="btn btn-main">{{$ml.get('btnDelete')}}</button>
              <div href="" @click="closeDrawer">{{ $ml.get('keepBeneficiary') }}</div>
            </div>
          </div>
        </div>
      </template>



    </Drawer>

    <DesktopMenu backgroundColor="rgba(111, 111, 111, 0.30)" width="400px" backgroundFilter="blur(7.5px)" :isSideMenuVisible="true"
      :showCloseButton="true" position="center" name="select-beneficiary">
      <div style="padding: 20px;" class="flex flex-column ">
        <div class="name flex">{{ selectedBeneficiary.firstName | lowerCaseCapitalise }} {{
          selectedBeneficiary.lastName |
          lowerCaseCapitalise }}</div>
        <div class="info info-menu">
          <div class="flex full-center">
            <img :src="imgSrc(selectedBeneficiary.countryIso3)" style="width: 16px;height: 16px;" alt="">
            <div>
              {{ country(selectedBeneficiary.countryIso3) }}
            </div>
          </div>
        </div>

        <div class="flex flex-column justify-between">

          <div class="sub-row ">
            <!-- {{ selectedBeneficiary }} -->
            <div
              v-if="selectedBeneficiary.maskedCardNumber || selectedBeneficiary.accountIdentifier || selectedBeneficiary.walletIdentifier || selectedBeneficiary.upiIdentifier || selectedBeneficiary.iban || selectedBeneficiary.phoneNumber">
              <div class="flex justify-between line" v-show="selectedBeneficiary.maskedCardNumber">
                <div v-text="$ml.with('VueJS').get('cardNumber')"></div>
                <div> {{ selectedBeneficiary.maskedCardNumber | formatCardNumber }} </div>
              </div>
              <div class="flex justify-between line" v-show="selectedBeneficiary.accountIdentifier">
                <div v-text="$ml.with('VueJS').get('beneficiary.accountIdentifier')"></div>
                <div> {{ selectedBeneficiary.accountIdentifier }} </div>
              </div>
              <div class="flex justify-between line" v-show="selectedBeneficiary.iban">
                <div v-text="$ml.with('VueJS').get('beneficiary.iban')"></div>
                <div> {{ selectedBeneficiary.iban }} </div>
              </div>
              <div class="flex justify-between line" v-show="selectedBeneficiary.walletIdentifier">
                <div v-text="$ml.with('VueJS').get('beneficiary.walletIdentifier')"></div>
                <div> {{ selectedBeneficiary.walletIdentifier }} </div>
              </div>
              <div class="flex justify-between line" v-if="selectedBeneficiary.upiIdentifier">
                <div v-text="$ml.get(`beneficiary.upi_${selectedBeneficiary.countryIso3}`)"></div>
                <div> {{ selectedBeneficiary.upiIdentifier }} </div>
              </div>
              <div class="flex justify-between line" v-show="selectedBeneficiary.phoneNumber">
                <div v-text="$ml.with('VueJS').get('labelPhone')"></div>
                <div> {{ selectedBeneficiary.phoneNumber }} </div>
              </div>
            </div>
          </div>
          <div class="flex flex-column justify-center align-center prevent-close" style="margin-bottom: -10px;">
              <button class="btn btn-wide full-center w-100" @click="selectBeneficiary()">{{ $ml.get('btnTransfer') }}</button>
              <div class="btn-red prevent-close" @click="toggleDeleteConfimationMenu(); toggleCursorMenu()""><img class="prevent-close" src="../assets/mygmt_v2_images/Garbage-red.svg" alt="" srcset=""> <div class="prevent-close"> {{ $ml.get('deleteBeneficiary')}} </div> </div>
            </div>
        </div>
      </div>

      <!-- <div class="menu-content">
        <div @click="selectBeneficiary()" class="flex pointer">
          <img src="../assets/mygmt_v2_images/general.svg" alt="" srcset="">
          <div>Send money</div>
        </div>
        <div @click="toggleDeleteConfimationMenu(); toggleCursorMenu()" class="flex pointer">
          <img src="../assets/mygmt_v2_images/Garbage-black.svg" alt="" srcset="">
          <div>Delete</div>
        </div>
      </div> -->
    </DesktopMenu>

    <DesktopMenu backgroundColor="rgba(111, 111, 111, 0.30)" backgroundFilter="blur(7.5px)" :showCloseButton="true"
      position="center" name="delete-beneficiary" :isSideMenuVisible="true">
      <div class="desktop-menu-delete-confirmation">
        <div class="flex flex-column align-center delete-confirmation-container h-100 justify-center">
          <img src="../assets/mygmt_v2_images/beneficiary/remove-beneficiary.svg" alt="">
          <h2>{{$ml.get('deleteBeneficiary')}}</h2>
          <!-- <div class="title">
            <span v-text="$ml.with('beneficiary', selectedBeneficiaryName).get('beneficiaryRemoveDialogue')"></span>
          </div>
          <div class="title" v-text="$ml.with('VueJS').get('beneficiaryRemoveDialogue2')"></div> -->
          <p  v-html="$ml.with('beneficiary', `<span> ${selectedBeneficiary.firstName  } ${
            selectedBeneficiary.lastName  } </span>`).get('beneficiaryRemoveDialogue')">  </p>
            <p>{{ $ml.get('beneficiaryRemoveDialogue2') }}</p>
          <button @click="removeBeneficiary(); toggleDeleteConfimationMenu()" class="btn btn-main">{{$ml.get('btnDelete')}}</button>
          <div href="" @click="toggleDeleteConfimationMenu()">{{ $ml.get('keepBeneficiary') }}</div>
        </div>
      </div>
    </DesktopMenu>
    <!--End Modal beneficiary dialogue-->
    <Footer v-if="!isMobileDevice" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Header from "../components/Header";
import { deleteBeneficiaries } from "../services/api";
import { getCountryNameByIso, modalHandler, isMobile } from "../services/utils"
import Footer from "@/components/Footer.vue";
import _ from 'lodash';
import Drawer from '../components/Drawer.vue';
import DesktopMenu from '../components/DesktopMenu.vue';
import { EventBus } from '../event-bus';
import Header2 from '../components/Header2.vue';
import Beneficiary from '../pages/Beneficiary.vue'


export default {
  name: 'Beneficiaries',
  components: { Header2, Footer, Drawer, DesktopMenu, Beneficiary },
  data() {
    return {
      searchBeneficiary: '',
      selectedBeneficiary: '',
      isMobileDevice: isMobile(),
      isConfirming: false,
      isCreatingBeneficiary: false
    }
  },
  created() {
    if (!this.beneficiaries.length)
      this.$store.dispatch('loadBeneficiary');
  },
  computed: {
    dir() {
      return this.$store.getters.getDir;
    },
    ...mapGetters({
      'beneficiaries': 'getListBeneficiary'
    }),
    createBeneficiaryRoute() {
      return { name: 'beneficiary', params: { action: 'create' } }
    },
    beneficiaries() {

      return Object.filter(this.$store.getters.getListBeneficiary, item => {
        return (item.firstName.toLowerCase().indexOf(this.searchBeneficiary.toLowerCase()) > -1) || (item.lastName.toLowerCase().indexOf(this.searchBeneficiary.toLowerCase()) > -1)
      });
    },
    selectedBeneficiaryName() {
      return this.selectedBeneficiary.firstName + ' ' + this.selectedBeneficiary.lastName
    },
    hasBeneficiaryInfo() {
      const beneficiary = this.selectedBeneficiary;
      return (
        beneficiary &&
        (beneficiary.maskedCardNumber ||
          beneficiary.accountIdentifier ||
          beneficiary.walletIdentifier ||
          beneficiary.upiIdentifier ||
          beneficiary.iban ||
          beneficiary.phoneNumber)
      );
    },
  },
  methods: {
    toggleCursorMenu(event, beneficiary) {
      if (beneficiary) {
        this.selectedBeneficiary = beneficiary;
      }
      EventBus.$emit('toggleMenu', event, 'select-beneficiary');
    },
    createdBeneficiary() {
      this.isCreatingBeneficiary = false
    },
    toggleDeleteConfimationMenu(event) {
      EventBus.$emit('toggleMenu', event, 'delete-beneficiary');
    },
    closeMenu() {
      EventBus.$emit('toggleMenu');
    },

    country(iso3) {
      return getCountryNameByIso(iso3);
    },
    imgSrc(country) {
      try {
        return require(`../assets/mygmt_v2_images/countries/${country}.svg`);
      } catch (error) {
      }
    },
    openDrawer(beneficiary) {
      this.selectedBeneficiary = beneficiary;
      console.log("🚀 ~ openDrawer ~ this.$refs.benefeciaryControlDrawer:", this.$refs.benefeciaryControlDrawer)
      // modalHandler('.modal-mask-message', 'addClassOpen');
      this.$refs.benefeciaryControlDrawer.openDrawer()

    },
    closeDrawer() {
      this.$refs.benefeciaryControlDrawer.closeDrawer()

      setTimeout(() => { this.isConfirming = false }, 1000);
    },
    closeDialgue() {
      modalHandler('.modal-mask-message', 'removeClassOpen')
    },
    showDetails(it, index) {
      let action = $('.item-beneficiary:eq(' + index + ')');
      if (action.hasClass('open')) {
        action.removeClass('open');
      } else {
        $('.item-beneficiary').removeClass('open');
        action.addClass('open');
      }
    },
    selectBeneficiary() {
      this.$store.commit('setCurrentBeneficiary', this.selectedBeneficiary.id);
      sessionStorage.setItem('currentBeneficiary', this.selectedBeneficiary.id); // for reload .
      this.$store.commit('setCurrentBeneficiaryDetails', this.selectedBeneficiary); // after reload is undefined
      this.$store.commit('setTransferStep1', {
        amount: null,
        currency: null,
        destinationCountry: this.selectedBeneficiary.countryIso3,
        cloneInfo: {}
      });
      this.$store.commit('setTransferDestinationCountry', this.selectedBeneficiary.countryIso3);

      this.$store.commit('setHasCard', (this.selectedBeneficiary.maskedCardNumber));
      this.$router.push('/transfernew');
    },
    removeBeneficiary() {
      deleteBeneficiaries(this.selectedBeneficiary.id, (response) => {
        this.closeDialgue();
        this.$store.dispatch('loadBeneficiary');
        this.selectedBeneficiary = '';
      });
      this.$refs.benefeciaryControlDrawer.closeDrawer()
    }
  },

  mounted() {
    document.body.setAttribute('dir', this.dir);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "../assets/styles/partials/mixins";

.kite {
  display: none;
}

.custom-closer {
  ::v-deep .go-back-wrapper {
    position: absolute;
    top: 5%;
    right: 5%;

  }

  ::v-deep .head-line {
    height: 0;
    padding: 0;
  }
}


.list-beneficiaries {
  margin-top: 26px;
  text-align: start;
  overflow-y: auto;
  height: calc(100vh - 333px) // background-color: red;

}

.desktop-menu-delete-confirmation {
  width: 500px;
  height: 400px;
}

.btn-main {
  margin-bottom: 15px;
}

.drawer-wrapper-card {
  min-height: 200px;
  gap: 20px;

  ::v-deep .heading {
    border-bottom: 1px #EBEBEB solid;
    padding-bottom: 10px
  }

}

.no-border {
  border: none !important;
}

.payment-balances-card-grid-box {
  height: 90vh;

  .name {
    color: #000;
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .weak {
    color: #7F818D;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  h1 {
    color: var(--Calm-Gray, #3F3D48);
    font-family: Assistant;
    font-size: 36px;
    font-weight: 700;
  }

  .btn {
    margin-top: 0px;
    width: 17%;
    height: 36px;
    line-height: -15px;
  }

  .btn-border {
    width: 150px;
  }
}

.round-square {
  background-color: white;
  min-height: 405px;
  border-radius: 20px;
  margin-top: 20px;
}

.line-beneficiary {
  //  height: 100px;
  border-bottom: 1.5px solid #F5F5F5;
  padding: 18px 24px;



}

.menu-content {
  display: flex;
  flex-direction: column;
  gap: 15px;

  div {
    cursor: pointer;
  }

  img {
    height: 20px;
    width: 20px;
    margin: 0px 20px 0px 0px;
  }

  color: #000;
  font-family: Assistant;
  font-size: 13px;
  font-weight: 400;

}

.info-menu {
  border-bottom: 1px #EBEBEB solid;
  padding-bottom: 10px;
  margin-bottom: 20px;
}




.lines-box-wrapper {
  margin-top: -40px !important;
}

.btn-border {
  border-color: #0E1F81;
  color: #0E1F81;
  margin-left: 14px;

}

.btn-red {
  color: #FF0000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-wide {
  width: 100%;
  margin-bottom: 24px;
}

.wrap {
  .btn-border {
    margin: 0px 0px 20px 0px !important;

  }
}

.btn {
  margin-top: 20px;
  width: 50%;
  height: 45px;

}

.btn:first-of-type {}

.line {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 6px;
}

.seperator {
  border-bottom: 10px #EBEBEB solid;
}

// .seperator {
//   height: 1px;
//   width: 100%;
//   background-color: #EBEBEB;
//   margin-bottom: 20px;
// }

.delete-confirmation-container {
  img {
    width: 100px;
    height: 100px;
  }

  h2 {
    color: #000;
    text-align: center;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-top: 12px;

    span {
      color: #000;
      font-size: 14px;
      font-weight: 700;
    }
  }

  button {
    width: 70%;
    margin-top: 30px;
  }

  a {
    margin-top: 20px;
  }
}

.main-wrapper {
  background: white;

}


.title-new {
  font-size: 20px;
  color: var(--GMT--Blue, #0E1F81);
  font-family: Assistant;
  font-weight: 400;
  margin-bottom: 20px;
}

.item-beneficiary {
  margin-bottom: 20px;
}

.circle {
  background: #0E1F81;
  border-radius: 35px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;

  strong {
    color: white;
    font-weight: 600;
    height: 25px;
    font-size: 18px;
  }
}

.beneficiary-container {
  padding: 30px 14px 30px 12px;
  border-radius: 20px;
  border-radius: 29px;
  border: 1px solid #EBEBEB;
  background: #FFF;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  width: 100%;
  height: 56px;


}

.name {
  color: var(--Main-Blue, #0E1F81);
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.info {
  color: #000;
  font-family: Assistant;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  justify-items: start;

  div {
    img {
      margin-right: 5px;

    }
  }
}


.search-box-container {
  // padding-top: 10px;
  // padding-left: 20px;
  // background: white;
  // padding-bottom: 1px;
  // padding-right: 20px;
  // border-bottom-left-radius: 40px;
  // border-bottom-right-radius: 40px;
  // filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));
  background-color: white;

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;
    background-color: white;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;
      background-color: white;
    }
  }
}

.header {
  // margin-top: 20px;
}

.round-box {
  padding: 12.5px 14px;
}

.block-beneficiaries {
  padding-top: 0;

  .content-top {
    height: 69px;
    padding: 10px 0;
  }

  .wrap {
    padding: 0 20px !important;

  }

  .form-search {
    max-width: 739px;
    margin: 0 auto;
    position: relative;

    .form-control {
      width: 100%;
      height: 48px;
      font-size: 20px;
      padding: 0 18px 0 40px;

      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        font-size: 20px;
        color: #808080;
      }

      &::-moz-placeholder {
        /* Firefox 19+ */
        font-size: 20px;
        color: #808080;
      }

      &:-ms-input-placeholder {
        /* IE 10+ */
        font-size: 20px;
        color: #808080;
      }

      &:-moz-placeholder {
        /* Firefox 18- */
        font-size: 20px;
        color: #808080;
      }
    }

    .icon-search {
      position: absolute;
      top: 17px;
      left: 23px;
      cursor: auto;
    }
  }

  .body {
    max-width: 739px;
    margin: 43px auto 0;

    .wrap {
      max-height: 85vh !important;
    }
  }



  .list-beneficiaries {
    margin-top: 26px;
    text-align: start;
    overflow-y: auto;
  }

  .item-beneficiary {
    width: 100%;
    height: 82px;
    overflow: hidden;
    border: 2px solid rgb(236, 236, 236);
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 12px 12px 10px;
    display: flex;
    line-height: 22px;
    font-size: 16px;
    color: #767676;
    position: relative;
    @include transition(all .4s ease);

    &:hover {
      background-color: #f2f2f2;
    }

    .icon-arrow-down-circle {
      width: 15px;
      height: 15px;
      background-size: cover;
    }

    &.open {
      height: auto;
      padding-bottom: 22px;
      overflow: visible;

      .icon-arrow-down-circle {
        background-image: url(../assets/images/icons/i-arrow-up-circle.png);
      }

      .wrap-row {
        .sub-row {
          margin-top: 2px;
          display: block;

        }
      }
    }



    .item-title {
      font-size: 20px;
      color: #092086;
    }

    &+.item-beneficiary {
      margin-top: 4px;
    }

    .caption-title {
      border: #092086;
      top: 6px;
      border-top: 0.5px solid #d1cfcf;
      padding-top: 4px;
      min-width: 220px;
    }

    .wrap-row {
      width: 95%;

      .sub-row {
        display: none;
        position: relative;
        top: 2px;
        color: #092086;
        min-height: 500px;

        button {
          min-width: 100px;
          height: 29px;
          padding: 0px 10px;
          line-height: 27px;
          margin: 10px 4px 0px;
          display: inline-block;
        }

        & .btn-delete {
          border-color: $mainColor;
          color: $mainColor;
          width: 90px;

          &:hover {
            background-color: $mainColor;
            border-color: $mainColor;
            color: #ffffff;
          }
        }
      }
    }
  }

  .icon-transfer {
    width: 38px;
    height: 38px;
    background-color: #092086;
    border-radius: 50%;
    position: relative;
    margin-left: 18px;
    margin-top: 5px;
    cursor: pointer;

    &:before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }

  .icon-remove {
    position: absolute;
    top: 10px;
    left: 12px;
    right: auto;
  }

  .centered-btn {
    display: block !important;
    margin: 20px auto 0 !important;
    width: 170px !important;
  }


}

.beneficiary-creation-wrapper {
  padding: 20px 20px 0px 20px;
  background-color: white;

  ::v-deep form {
    overflow: visible;
    margin: none !important;
    width: 100% !important;
  }

  ;

  ::v-deep .btn {
    margin: 0 0 20px 0 !important;
  }

  ;
  width: auto;
  margin: 0 auto;
  margin-top: 0px;
  border-radius: 0px;
  box-shadow:none;

  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 100px;
  //box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  background-image: url('../assets/mygmt_v2_images/mygmt2-background-grey.svg');
}

@media only screen and (min-width: $screen-tablet) {

  .item-beneficiary {
    width: 500px;
  }

  .beneficiary-creation-wrapper {
    padding: 20px 20px 0px 20px;
    background-color: white;
    margin-top: 70px;

    ::v-deep form {
      overflow: visible;
      margin: none !important;
      width: 100% !important;
    }

    ;

    ::v-deep .btn {
      margin: 0 0 20px 0 !important;
    }

    ;
    width: 415px;
    margin: 0 auto;
    margin-top: 75px;
    border-radius: 20px;
    box-shadow: 0px 3px 11.66px -4.66px rgba(51, 77, 194, 0.34);
    background-image: none;

  }


  .main-container {
    min-height: 108vh;
    margin-top: -70px;
    padding-top: 70px;
    background-repeat: no-repeat;
    background-size: auto auto, auto auto;
    background-repeat: no-repeat, no-repeat;
    background-position: left bottom, right top;
    background-image: url('../assets/mygmt_v2_images/mygmt2-backgground-white.svg'), url('../assets/mygmt_v2_images/mygmt-backgground-white-small.svg');
  }

  .kite {
    right: 0px;
    top: 200px;
    z-index: 0;
    display: block;
    position: absolute;
  }


  .beneficiary-creation-wrapper {
    ::v-deep .btn {
      margin-top: 100px;
    }

    padding: 20px 20px 0px 20px;

    ::v-deep form {
      overflow: visible;
      width: 50%;
      margin: 0 auto;

    }
  }

  .main-wrapper {
    padding-bottom: 0px !important;
    height: 100vh;
  }

  .block {
    padding: 0px !important;
    height: 100%;
  }

  .body {
    height: 100%;
  }

  .list-beneficiaries {
    // margin-top: 26px;
    // text-align: start;
    // overflow-y: auto;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    height: 100%;
  }

  .beneficiary-container {
    // padding: 30px 14px 30px 12px;
    // border-radius: 20px;
    // border-radius: 29px;
    // border: 1px solid #EBEBEB;
    // background: #FFF;
    // box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    // width: 500px;
    // height: 56px;


  }

  .title-new {
    font-size: 20px;
    color: black;
    font-family: Assistant;
    font-weight: 700;
    margin-bottom: 20px;


    color: var(--Calm-Gray, #3F3D48);
    font-family: Assistant;
    font-size: 36px;
    width: 340px;
  }







}
</style>
