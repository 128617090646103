<template>
  <div draggable="false" class="grey-bgc  main-container relative" :dir="$store.getters.getDir">
    <img class="kite" src="../assets/mygmt_v2_images/kite-transfer.svg" alt="">
    <Header2 :forceCloser="true" :hide="['FAQ', 'LOCATIONS']"></Header2>
    <div class="transfer-container relative" :class="{ 'zero-padding': true }">

      <div class="relative">
        <div class="container flex flex-column justify-center align-center">
          <div class="container flex flex-column justify-center align-center">

    
            <div  class="status-type flex flex-column justify-center align-center">
              <div>
                <img :src="getErrorImg" alt="">
              </div>
              <h1 class="title black bold">{{translatedObject.title ? translatedObject.title : $ml.get('errors.default.title')  }} </h1>
              <div class="description black">
                <p> {{ $ml.get(errorCode) ||  $ml.get('DEFAULT') }}</p>
              </div>
              <button v-if="translatedObject && translatedObject.buttonAction" @click="buttonAction" style="width: 60%;" class="btn btn-main">{{
                translatedObject.buttonAction }}</button>
              <div @click="buttonLink" v-if="translatedObject && translatedObject.buttonLink" class="pointer">{{ translatedObject.buttonLink }}</div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import _ from 'lodash';
import { EventBus } from '../event-bus';
import Header2 from '../components/Header2.vue';
import { isApp, isMobile , getCountryNameByIso} from '../services/utils';



export default {
  components: { Header2 },
  name: 'TransferStatus',
  props: {

  },
  data() {
    return {
      errorGroups: {},
      errorCode: 'default',
      currErrorGroup: 'default'
    };
  },
  created() {
    EventBus.$emit('unload');
    this.errorGroupMap = this.createErrorGroupMap();
    this.errorCode = this.$route.params.errorCode || this.$store.getters.getErrorCode || 'default';
    this.currErrorGroup = this.errorGroupMap[this.errorCode] || 'default';
    this.setTranslatedObj()
  },
  computed: {
    getErrorImg() {
    const imgMap = {
    'default': 'service_error',
    'service': 'service_error',
    'kyc': 'service_error',
    'registration': 'service_error',
    'login': 'secure_error',
    'cards': 'card_error',
  };

     const imgName = imgMap[this.currErrorGroup] || 'service_error';
      return require(`../assets/mygmt_v2_images/errors/${imgName}.svg`)
    },

    countryName(){
      console.log("🚀 ~ countryName ~ this.$route.query:", this.$route.query.countryName)
     return this.$route.query.countryName
    },

    selectedTransferOption() {
      return this.$store.getters.getSelectedTransferOption
    },

    calculatedTotal() {
      return this.selectedTransferOption.amount +
        this.selectedTransferOption.commission -
        (this.usedCashBack ? this.cashBackCredit : 0);
    },


  },
  watch: {
  },
  methods: {
    postError(route = '/Home') {
      this.$emit('removeStatus')
      this.$router.push(route)
    },
    backToHomePage() {
      this.$router.push('/Home')
    },
    removeStatus() {
      this.$store.commit('setTransferStatus', null)
    },
    setTranslatedObj(){
      try {
        console.log(1);
        
      this.translatedObject = this.$ml.get(`errors.${this.currErrorGroup}`)
      if (!this.translatedObject) throw ''
      console.log("🚀 ~ setTranslatedObj ~ this.translatedObject:", this.translatedObject)
      } catch (error) {
        console.log(2);
      console.log("🚀 ~ setTranslatedObj ~ error:", error)
      this.translatedObject = this.$ml.get(`errors.default`)
      }
    },

    buttonAction(){
   const actions = {
      'default': () => this.$router.push('/transfernew'),
      'service': () => EventBus.$emit('openContactUs'),
      'kyc': () => EventBus.$emit('openContactUs'),
      'registration': () => EventBus.$emit('openContactUs'),
      'login': () => this.$router.push('/login'),
      'cards': () => this.$router.push('/transfernew'),
   }
    const fnc = actions[this.currErrorGroup] || actions['default']
    fnc()
    },
     buttonLink(){
   const links = {
      'default': () => EventBus.$emit('openContactUs'),
      'service': () => this.$router.push('/Home'),
      'registration': () => EventBus.$emit('openContactUs'),
      'cards': () => EventBus.$emit('openContactUs'),

   }
    const fnc = links[this.currErrorGroup] || links['default']
    fnc()
    },

    createErrorGroupMap() {
      return {
        'DEFAULT': 'default',
        'MONEYGRAM_EXCEPTION': 'default',
        'MONEYGRAM_323': 'default',
        'API_EXCEPTION': 'default',
        'GRAILS_VALIDATION_ERROR': 'default',
        'CAPTCHA_NOT_VERIFIED': 'default',
        'BENEFICIARY_NOT_FOUND': 'default',
        'BENEFICIARY_NOT_FOUND_FOR_CUSTOMER': 'default',
        'TRANSACTION_NOT_FOUND_FOR_CUSTOMER': 'default',
        'DOCUMENT_NOT_FOUND': 'default',
        'DOCUMENT_NOT_FOUND_FOR_CUSTOMER': 'default',
        'CREDIT_CARD_SIGN_ERROR': 'default',
        'CREDIT_CARD_VERIFY_ERROR': 'default',
        'CREDIT_CARD_COMMIT_ERROR': 'default',
        'INVALID_SESSION_INFO': 'default',
        'BAD_REQUEST': 'default',
        'MAX_EXCEPTION': 'default',
        'SERVER_ERROR': 'default',

        'YAAD_SARIG_3': 'cards',
        'YAAD_SARIG_4': 'cards',
        'YAAD_SARIG_6': 'cards',
        'YAAD_SARIG_26': 'cards',
        'YAAD_SARIG_141': 'cards',
        'YAAD_SARIG_303': 'cards',
        'YAAD_SARIG_447': 'cards',
        'YAAD_SARIG_553': 'cards',
        'YAAD_SARIG_999': 'cards',
        'YAAD_SARIG_3000': 'cards',
        'YAAD_SARIG_3003': 'cards',
        'YAAD_SARIG_9101': 'cards',
        'YAAD_SARIG_GENERAL': 'cards',

        'MONEYGRAM_301': 'service',
        'MONEYGRAM_304': 'service',
        'MONEYGRAM_4055': 'service',
        'MONEYGRAM_7003': 'service',
        'CUSTOMER_BLOCKED': 'service',
        'CUSTOMER_MOBILE_NOT_MATCH': 'service',
        'WALLET_FRIEND_NOT_UNIQUE': 'service',
        'WALLET_FRIEND_ALREADY_ADDED': 'service',
        'WALLET_FRIEND_BLOCKED': 'service',
        'WALLET_FRIEND_NOT_REGISTERED': 'service',
        'INVALID_CARD': 'service',
        'INVALID_VERSION': 'service',
        'AMOUNT_LIMITED': 'service',
        'BENEFICIARY_INVALID': 'service',
        'CONTACT_DENIED': 'service',
        'CARD_DENIED': 'service',
        'CARD_WRONG_DETAILS': 'service',
        'CARD_REJECTED': 'service',
        'BLACKLIST': 'service',
        'BLACKLIST_CARD': 'service',
        'CORRESPONDENT_EXCEPTION': 'service',
        'TEMPORARY_ERROR': 'service',

        'CUSTOMER_DENIED': 'registration',
        'CUSTOMER_MULTIPLE': 'registration',

        'CUSTOMER_DAILY_LIMIT_EXCEEDED': 'kyc',
        'NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED': 'kyc',
        'NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED': 'kyc',
        'NEW_BENEFICIARY_LIMIT_EXCEEDED': 'kyc',
        'NEW_BENEFICIARY_AMOUNT_EXCEEDED': 'kyc',
        'BENEFICIARY_CARD_BLOCKED': 'kyc',

        'SESSION_EXPIRED': 'login',
        'CUSTOMER_ALREADY_REGISTERED': 'login',
        'CONTACT_GENERAL': 'login',
        'CUSTOMER_DENIED': 'login',
      };

    }
  }
}

  
</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

h1 {
  text-align: center;
}

.container {
  height: 100%;
  width: 100%;
  padding: 25px 45px;
}

.sucsess {
  img {
    margin-top: 60px;
  }

  .title {
    margin-top: -40px;
    text-align: center;
  }

  .description {
    text-align: center;
    margin-top: -50px;
  }
}

.transfer-container[data-v-a1450d20] {
  z-index: 2;
  width: 600px;
  margin: 0 auto;
  min-height: auto;
  overflow: clip;
  max-height: 433px;
  margin-top: 40px;
  padding: 0px 24px;
  border-radius: 20px;
  background-image: none;
}

.btn-main {
  height: 60px;
}



.description {
  p {
    margin: 30px 0px;
    text-align: center;
  }
}

.status-type {
  gap: 50px;
}

@media only screen and (min-width: $screen-tablet) {}
</style>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";

.main-container {
  min-height: none;
  overflow: hidden;
  overflow-y: auto;
  background-image: none;
  // max-height: 100vh;
}

.kite {
  display: none;
}

.eclipse {
  margin: 0 8px
}

.transfer-info {
  margin-bottom: 12px;
  font-size: 14px;

  .flag {
    height: 18px;
    width: 18px;
    margin-right: 5px;
    margin-left: 0px;
  }
}

[dir="rtl"] .transfer-info .flag {
  margin-right: 0px;
  margin-left: 5px;


}


.mobile-margin {
  margin-right: 20px;
  margin-left: 20px;
}

.zero-padding {
  padding: 0px !important;
}

.transfer-container {
  // min-height: 90vh;
  padding: 0px 20px;
  background: #FFF;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position-x: right;
  background-position-y: 100px;
  //box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  background-image: url('../assets/mygmt_v2_images/mygmt2-background-grey.svg');

  .upper {
    align-items: center;

    // margin-bottom: 10px;
    .back {
      font-size: 16px;
      margin-top: 25px;
      margin-bottom: 8px;
      color: var(--GMT--Blue, #0E1F81);
    }

    .money-title {
      font-size: 20px;
      font-weight: 700;
      color: #0E1F81;
      font-style: normal;
      line-height: normal;
      font-family: Assistant;
    }
  }

  .progress-bar {
    width: 100vw;
    height: 4px;
    margin-left: -20px;
    background: #F6F6F6;

    // margin-bottom: 26px;
    // position: relative;
    .test-grey {
      // position: absolute;
      // background: #F6F6F6;
    }
  }

}

.mygmt2-header-wrapper {
  background-color: #FFFF;
}

@media only screen and (min-width: $screen-tablet) {

  .upper {
    margin-bottom: 10px;
  }

  .mobile-margin {
    margin-left: 0px;
    margin-right: 0px;
  }

  .main-container {
    min-height: 100vh;
    background-repeat: no-repeat;
    background-size: auto auto, auto auto;
    background-repeat: no-repeat, no-repeat;
    background-position: left bottom, right top;
    background-image: url('../assets/mygmt_v2_images/mygmt2-backgground-white.svg'), url('../assets/mygmt_v2_images/mygmt-backgground-white-small.svg');
  }

  .kite {
    right: 0px;
    top: 200px;
    z-index: 0;
    display: block;
    position: absolute;
  }


  .transfer-container {
    z-index: 2;
    width: 600px;
    margin: 0 auto;
    // min-height: 80vh;
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    // max-height: 80vh;
    margin-top: 40px;
    padding: 0px 24px;
    border-radius: 20px;
    background-image: none;
    box-shadow: 0px 3px 11.66px -4.66px rgba(51, 77, 194, 0.34);


    .upper {
      align-items: flex-start;

      .money-title {
        font-size: 24px;
      }
    }

    .progress-bar {
      width: 100%;
      margin-left: 0px;
    }
  }
}
</style>
