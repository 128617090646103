export const th = {
  GMTbranches: '<a href=""https://gmtonline.co.il/en/locating-branches/"" style=""color:#d4c641;text-decoration: underline;"">ที่สาขาใดก็ได้ของ GMT</a>',
  accessibleBranch: 'สามารถเข้าถึงได้',
  myCard: 'บัตรของฉัน',
  myDateBirthday: '(วัน-เดือน-ปี)',
  accountNumber: 'หมายเลขบัญชีของผู้รับผลประโยชน์',
  activityTime: 'เวลาที่ทำกิจกรรม:',
  actualRate1: 'อัตราออนไลน์',
  and: 'และ ',
  appDownload: 'ดาวน์โหลดแอปของเรา',
  appDownloadBtn: 'ดาวน์โหลดโดยตรง \n ไปยังโทรศัพท์ของคุณ',
  actualRate2: 'ตอนนี้คือ',
  addAddress: 'เพิ่มที่อยู่',
  additionalPhone: 'หมายเลขโทรศัพท์อื่น',
  amount: 'จำนวนเงิน',
  amountIn: 'จำนวนเงินสกุล ILS',
  recipientAmount: 'ผู้รับจะได้รับ',
  amountReceived: 'จำนวนเงินที่จะได้รับ',
  amountTogether: 'จำนวนเงินรวม',
  anotherSurname: 'นามสกุลอื่น',
  answerQuestion: 'ในระหว่างนี้ คุณสามารถค้นหาคำตอบสำหรับคำถามของคุณได้',
  approvalTransaction: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินได้รับอนุมัติแล้ว อีกสักครู่ คุณจะได้รับ SMS ยืนยันการทำธุรกรรม',
  maxCardLoadTransaction: 'ยอดเยี่ยม! การเรียกเก็บเงินสำเร็จแล้ว, และ GMT card ของคุณได้ถูกเติมเงินเข้าเรียบร้อยแล้ว',
  approvedPolicies: 'ฉันได้อ่านและยอมรับใน T&C',
  arrivalBranch: 'ขึ้นเงินสดที่ร้านของ GMT',
  arrivalBranchMsg1: 'เราจะแจ้งหมายเลขคำสั่งซื้อสำหรับชำระเงิน หมายเลขนี้สามารถใช้งานได้ 24 ชั่วโมง',
  arrivalBranchMsg2: 'และการชำระเงินจะเป็นสกุล ILS ตามอัตราแลกเปลี่ยนในร้าน',
  bankName: 'ชื่อบัญชี',
  bank: 'ธนาคาร',
  bankAccount: 'บัญชีธนาคาร',
  bankCode: 'หมายเลขธนาคาร',
  canceled: 'ยกเลิกแล้ว',
  cardNumber: 'หมายเลขบัตร',
  cashioPageTtile: 'Foreign currency exchange services including delivery or airport pickup',
  depositCardNumber: 'หมายเลขบัตรเครดิตของผู้รับผลประโยชน์',
  beneficiary: {
    firstName: 'ชื่อจริง',
    beneficiary: 'ชื่อผู้รับ',
    countryIso3: '.',
    lastName: 'นามสกุล',
    phoneNumber: 'หมายเลขโทรศัพท์ของผู้รับผลประโยชน์',
    phoneNumberShort: 'หมายเลขโทรศัพท์',
    country: 'ปลายทาง',
    transferMethod: 'ประเภทการโอน',
    areaCode: 'หมายเลขพื้นที่',
    maskedCardNumber: 'หมายเลขบัตรเครดิตของผู้รับผลประโยชน์',
    maskedCardNumber_unionPay: 'หมายเลขบัตรเครดิตของผู้รับผลประโยชน์',
    address: 'ที่อยู่',
    city: 'เมือง',
    province: 'รัฐ/จังหวัด',
    zipCode: 'รหัสไปรษณีย์',
    bankName: 'ชื่อบัญชี',
    cashBankName: 'จุดรับเงิน',
    walletBankName: 'ชื่อบัญชีวอลเลท',
    swiftCode: 'รหัส SWIFT/BIC ของธนาคาร',
    bankCity: 'เมืองของธนาคาร',
    bankAddress: 'ที่อยู่ของธนาคาร',
    branch: 'สาขาธนาคาร',
    iban: 'IBAN',
    accountIdentifier: 'หมายเลขบัญชี',
    walletIdentifier: 'หมายเลขบัญชีวอลเลท',
    walletPayment: 'ชำระผ่านบัญชีธนาคาร',
    alipayWallet: 'หมายเลขบัญชี',
    abaRoutingNumber: 'Bank Routing/หมายเลข ABA‏',
    ifsc: 'IFSC',
    relationshipWithCustomer: 'ความสัมพันธ์ระหว่างคุณและผู้รับผลประโยชน์คือ?',
    identification: 'Identification Number',
    upi: 'UPI ID or number',
    upi_IND: 'UPI ID or number',
    details: {
      BANK_TRANSFER: 'Beneficiary’s Bank Details',
      CASH_TRANSFER: 'Cash Transfer Details',
      WALLET_DEPOSIT: 'Wallet deposit',
      WALLET_FROM_WALLET: 'Beneficiary Wallet Details',
      WALLET_TO_WALLET: 'Beneficiary Wallet Details',
      WALLET_TRANSFER: 'Beneficiary Wallet Details',
      CARD_DEPOSIT: 'Beneficiary’s Card details',
      UPI_BANK_TRANSFER: 'Beneficiary Bank Details',
    },
  },
  transfer: {
    deliveryOptions: {
      ALL: 'ทั้งหมด',
      CASH_TRANSFER: 'โอนแบบรับเงินสดปลายทาง',
      BANK_TRANSFER: 'โอนไปยังบัญชีธนาคาร',
      WALLET_DEPOSIT: 'ฝากเข้าวอลเลท',
      WALLET_FROM_WALLET: 'โอนไปยังบัญชีวอลเลท',
      WALLET_TO_WALLET: 'โอนไปยังบัญชีวอลเลท',
      WALLET_TRANSFER: 'โอนไปยังบัญชีวอลเลท',
      CARD_DEPOSIT: 'โอนเข้าบัตร',
      UPI_BANK_TRANSFER: 'โอนเข้าบัญชีธนาคาร UPI',
    },
    errors: {
      amountExceeds: '/',
      corrspondentIsLocked: '/',
    },
    deliveryOptionsInfo: {
      iSend: 'ฉันส่ง',
      iPay: 'ฉันจ่าย',
      theyRecieve: 'พวกเขาได้รับ',
    },
    titles: {
      chooseAmount: 'เลือกจำนวน',
      chooseBeneficiary: 'เลือกผู้รับประโยชน์',
      chooseDestination: 'เลือกปลายทาง',
      recentDestinations: 'ปลายทางล่าสุดของคุณ',
      allDestinations: 'ปลายทางทั้งหมด',
      popularDestinations: 'ปลายทางยอดนิยม',
      sendMoney: 'ส่งเงิน',
      nothingFound: 'ไม่พบข้อมูล',
      typedCorrectly: 'ตรวจสอบให้แน่ใจว่าคุณพิมพ์ชื่อประเทศถูกต้อง',
      transferSummary: '.',
    },
    buttons: {
      select: 'เลือก',
      addNewBeneficiary: 'เพิ่มผู้รับประโยชน์ใหม่',
      coupon: {
        iHaveACoupon: 'ฉันมีคูปอง',
        couponApplied: 'คูปอง "{couponNumber}" ถูกนำไปใช้แล้ว!',
      },
      back: 'กลับ',
    },
    paymentMethods: {
      titles: {
        payingWith: 'ชำระ {amount} {currency} ด้วย:',
        paying: 'ชำระ ',
        with: 'ด้วย',
      },
      subtitles: {
        walletBalance: 'ยอดเงินในกระเป๋าของคุณคือ',
        creditCard: 'เรารับบัตรวีซ่าและมาสเตอร์การ์ด',
        bankTransfer: 'โอนโดยตรงไปยังบัญชีธนาคาร',
      },
    },
    countrySearchPlaceholder: 'ค้นหาปลายทางหรือสกุลเงิน',
  },
  relationshipWithCustomer: {
    SELF: 'ตัวเอง',
    CHILD: 'ลูกของฉัน',
    PARENT: 'พ่อแม่ของฉัน',
    SPOUSE: 'หุ้นส่วนของฉัน',
    SIBLING: 'พี่น้องของฉัน',
    EX_SPOUSE: 'คู่สมรส',
  },
  moreInformation: 'รายละเอียดเพิ่มเติม',
  beneficiaryRemoveDialogue: 'คุณต้องการลบออกจาก {beneficiary} รายชื่อผู้รับผลประโยชน์ของคุณ?',
  beneficiaryRemoveDialogue2: 'การดำเนินการนี้จะไม่มีผลกระทบต่อการโอนเงินปัจจุบัน',
  billingSuccessful: 'ยอดเยี่ยม! เราได้ดำเนินการส่งการโอนเงินของคุณแล้ว',
  billingSuccessfulCardDeposit: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณไปยังบัตรที่ระบุได้รับอนุมัติแล้ว',
  billingSuccessfulBankTransfer: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณไปยังบัญชีที่ระบุได้รับอนุมัติแล้ว',
  billingSuccessfulBankTransferISR: 'เยี่ยม! เรียกเก็บค่าธรรมเนียมสำเร็จแล้ว และการโอนเงินของคุณไปยังบัญชีที่ระบุได้รับอนุมัติแล้ว',
  cardUnBlockModalMessage: {
    '1': 'หลังจากที่คุณกดปุ่ม เปิดใช้งาน แล้ว บัตรของคุณจะถูกเปิดใช้งานสำหรับการทำธุรกรรมใหม่ๆ และการถอนเงินสด',
    '2': 'กรุณาเปิดใช้งาน เมื่อคุณมีบัตรอยู่กับคุณเท่านั้น',
  },
  cardBlockModalMessage: {
    '1': 'หลังจากที่คุณกดปุ่ม "ปิดกั้น" แล้ว เราจะปิดกั้นบัตรของคุณชั่วคราว ไม่ให้ทำธุรกรรมใหม่ๆ หรือถอนเงินสดได้',
    '2': 'ยอดคงเหลือของบัตรของคุณจะถูกบันทึกไว้',
    '3': 'ในกรณีที่คุณเจอบัตรแล้ว คุณสามารถเปิดใช้งานใหม่ได้ที่นี่ หรือคุณจะขอให้เราปิดกั้นบัตรอย่างถาวร และออกบัตรใบใหม่ให้กับคุณก็ได้',
  },
  blocked: 'ถูกปิดกั้นไว้',
  blog: 'ปิดกั้น',
  bntClear: 'ยกเลิก',
  bntContinued: 'ขั้นตอนถัดไป',
  bntContinue: 'ถัดไป',
  bntImgDelete: 'อัปโหลดอีกครั้ง',
  bntSave: 'บันทึก',
  branchNumber: 'สาขา',
  checkStatus: 'ตรวจสอบสถานะ',
  status: 'ตรวจสอบสถานะ',
  btnAccept: 'ยอมรับ',
  btnCheckStatusCheck: 'พร้อมชำระ',
  btnCheckStatusNoCheck: 'ไม่สามารถชำระเงินได้',
  btnOk: 'อนุมัติ',
  btnDone: 'เสร็จแล้ว',
  btnDelete: 'ลบ',
  btnFinish: 'เสร็จแล้ว',
  btnClose: 'ปิด',
  new: 'ไหม่',
  newCustomer_section: 'ทางเลือกที่ดีที่สุดสำหรับการจัดสรรการเงินของคุณ',
  quick_guide: 'คู่มือฉบับย่อสำหรับคุณ',
  invite_friends: 'เชิญเพื่อนเข้าร่วมใช้งาน GMT',
  cash_back_desc: 'ของขวัญเงินคืน',
  send_money_home: 'ส่งเงินกลับบ้าน',
  send_money_worldwide: 'ส่งเงินไปต่างประเทศ',
  newCustomer_title: 'การโอนเงินไปต่างประเทศและบริการทางการเงินอย่างมืออาชีพ',
  quickSend: 'ส่งอีกครั้ง',
  quickActions: 'ทางลัด',
  quickSendActionsParagraph: 'นี่คือผู้รับผลประโยชน์ล่าสุดของคุณ คุณสามารถที่จะกดส่งอีกครั้งได้เลย',
  quickActionsAdd: 'เพิ่ม',
  payAnywhere: 'บัตรเติมเงิน',
  carousel_slide_one_text: 'รับบัตรเติมเงินเพื่อจัดสรรการเงินของคุณให้ดียิ่งขึ้น',
  carousel_slide_one_button: 'รายละเอียดเพิ่มเติม',
  seeAll: 'ดูทั้งหมด',
  cardBalance: 'ยอดคงเหลือในบัตร',
  getCashback: 'และรับ',
  cashBack_info: 'ของขวัญเงินคืน',
  justReminder: 'เตือนความจำ',
  home: 'หน้าหลัก',
  menu: 'เมนู',
  activity: 'กิจกรรม',
  cards: 'บัตร',
  greeting_morning: 'สวัสดีตอนเช้า',
  greeting_afternoon: 'สวัสดีตอนบ่าย',
  greeting_evening: 'สวัสดีตอนเย็น',
  greeting_night: 'ราตรีสวัสดิ์',
  paidBy: 'ชำระด้วย',
  incoming: 'คุณได้รับ',
  outgoing: 'คุณได้ชำระ',
  CONVERSION: 'เรทเงิน',
  PAYMENT: 'การชำระเงิน',
  CASH_BACK: 'ของขวัญเงินคืน',
  cardActions: 'ตัวเลือกของบัตร',
  actions: 'ตัวเลือกการใช้งาน',
  activateCard: 'เปิดใช้งานบัตรใหม่ของคุณ',
  activateCardAction: 'เปิดใช้งานบัตร',
  enterLastDigits: 'กรอกหมายเลข 4 ตัวท้ายของบัตรเพื่อเปิดการใช้งาน',
  searchTransactions: 'ค้นหาธุรกรรม',
  filterTransactions: 'กรองรายการโอน',
  filterTransactionsButton: 'กรอง',
  openWallet: 'เปิดบัญชีกระเป๋าเงินฟรี',
  pleaseSelect: 'เลือกได้มากกว่าหนึ่งรายการขึ้นไป',
  date: 'วันที่',
  from_date: 'จากวันที่',
  to_date: 'ถึงวันที่',
  choose_date: 'เลือกวันที่',
  showResults: 'แสดงผลลัพธ์',
  clearFilter: 'ล้างตัวกรอง',
  today: 'วันนี้',
  contact_us_paragraph: '"เรายินดีที่จะให้ความช่วยเหลือ, คุณสามารถติดต่อหาเราได้หลากหลายช่องทางที่นี่.',
  call_us: 'โทรหาเรา',
  contact_us_or: 'หรือ',
  lastTransactions: 'การทำธุรกรรมครั้งสุดท้ายของบัตร',
  transactionClasses: {
    BANK_TRANSFER: 'โอนเข้าธนาคาร',
    CASH_TRANSFER: 'โอนแบบรับเงินสดปลายทาง',
    CARD_DEPOSIT: 'โอนไปยังบัญชีบัตร',
    PAYMENT: 'การโอนเข้า',
    CONVERSION: 'การแปลงสกุลเงิน',
    CASH_BACK: 'เงินคืน',
    WALLET_DEPOSIT: 'ฝากเข้าบัญชี myGMT',
    WALLET_TRANSFER: 'โอนไปยังบัญชีวอลเลท',
    WALLET_TO_WALLET: 'โอนไปยังบัญชี GMT',
    WALLET_FROM_WALLET: 'การโอนเข้าบัญชี GMT',
    UPI_BANK_TRANSFER: 'การโอนไปยังธนาคาร UPI',
  },
  paymentTypes: {
    CASH: 'เงินสด',
    CHECK: 'ตรวจสอบ',
    BANK_TRANSFER: 'โอนเงินเข้าธนาคาร',
    CREDIT_CARD: 'บัตร',
    CREDITTING: 'บัตร',
    OUTER_CONVERSION: 'เงินสด',
    CASHBACK: 'ของขวัญเงินคืน',
    CUSTOMER_DEPOSIT: 'ยอดคงเหลือของบุคคลที่สาม',
    WALLET: 'วอลเลท',
    CHANGE: 'แลกเปลี่ยน',
    AIRPORT: 'สนามบิน',
  },
  CARD: 'บัตร',
  CASH: 'เงินสด',
  AIRPORT: 'สนามบิน',
  CHECK: 'ตรวจสอบ',
  CHANGE: 'แลกเปลี่ยน',
  CREDIT_CARD: 'บัตร',
  CREDITTING: 'บัตร',
  OUTER_CONVERSION: 'เงินสด',
  CASHBACK: 'ของขวัญเงินคืน',
  CUSTOMER_DEPOSIT: 'ยอดคงเหลือของบุคคลที่สาม',
  WALLET: 'วอลเลท',
  changeLang: 'Change Language',
  nothing_found: 'ไม่พบธุรกรรม',
  nothing_found_text: 'เมื่อคุณได้มีการทำกิจกรรมใดๆ จะขึ้นแสดงที่นี่',
  max_transaction_description: 'คำอธิบาย',
  creditCardReferenceNumber: 'ข้อมูลอ้างอิงของบริษัทบัตรเครดิต',
  checkNumber: 'ตรวจสอบหมายเลข',
  referenceNumber: 'อ้างอิง',
  flightDate: 'วันที่บิน',
  deposit_to_wallet: 'เติมเงินเข้าวอลเลท',
  btnFinishREgistration: 'เพื่อลงทะเบียนให้เสร็จ',
  btnUploadDocuments: 'เพิ่มเอกสาร',
  btnRegistration: 'สร้างบัญชีใหม่ >',
  btnReturn: 'ย้อนกลับ',
  btnSend: 'ส่ง',
  btnSignIn: 'ส่งรหัสผ่านให้ฉันทาง SMS',
  btnBiometricSignInFaceId: 'ล็อกอินด้วย Face ID',
  btnBiometricSignInTouchId: 'ล็อกอินด้วย Touch ID',
  btnToMyGmt: 'ไปยัง GMT',
  btnTransfer: 'ส่งเงิน',
  btnTransferFooter: 'ส่งเงิน',
  btnEnter: 'เข้าเว็บไซต์',
  btnSearch: 'ค้นหา',
  business: 'อาชีพ',
  biometricsHeader: 'การระบุตัวตนด้วยการสแกนใบหน้าหรือนิ้วมือ',
  biometricSectionApprove: 'ฉันได้เห็นด้วยกับ T&C',
  biometricSectionSignIn: 'ในการลงทะเบียนสำหรับการใช้งานด้วยการสแกนใบหน้าหรือนิ้วมือให้เสร็จสมบูรณ์นั้น ท่านต้องทำการลงชื่อเข้าใช้บัญชีของท่าน',
  biometricToggler: 'เข้าสู่ระบบด้วยการสแกนใบหน้าหรือนิ้วมือ ',
  biometric_modal_text: 'คุณต้องการทำการยกเลิกการเข้าสู่ระบบด้วยการสแกนหรือไม่?',
  biometric_legal_clause_1: 'การระบุตัวตนด้วยไบโอเมตริกซ์ (หรือการใช้ลายนิ้วมือ หรือ การจดจำใบหน้า) จะใช้แทนการป้อนรหัสผ่าน เมื่อคุณใส่รายละเอียดของบัญชีทั้งหมดที่เกี่ยวข้องกับการใช้งาน ที่คุณใช้ในการเข้าสู่ระบบ',
  biometric_legal_clause_2: 'บริษัทไม่ได้ทำการจัดเก็บการจดจำใบหน้าของผู้ใช้, การระบุตัวตนจะอาศัยการระบุตัวตนแบบไบโอเมตริกที่กำหนดไว้ในอุปกรณ์เคลื่อนที่ของผู้ใช้',
  biometric_legal_clause_3: 'การลงทะเบียนสำหรับใช้บริการ ขึ้นอยู่กับบุคคลที่ได้รับอนุญาตให้เข้าสู่เว็บไซต์/แอพ ว่าพวกเขาต้องเป็นผู้ที่ใช้อุปกรณ์ที่มีข้อมูลระบุตัวตนแบบไบโอเมตริกซ์เท่านั้น, หากว่าได้มีการอัปเดตการระบุตัวตนทางชีวมาตรของบุคคลหลายคนบนอุปกรณ์นั้นๆ ไม่ควรใช้เพื่อลงทะเบียนสำหรับบริการ',
  biometric_legal_clause_4: 'การขอข้อมูลและ/หรือการดำเนินการบนเว็บไซต์/แอพจะถือเป็นการกระทำที่ดำเนินการโดยผู้ถือสิทธิ์ รวมถึงการกระทำที่ดำเนินการโดยบุคคลที่สามที่ละเมิดข้อ 3 ข้างต้น',
  biometric_legal_clause_5: 'ในกรณีที่อุปกรณ์เคลื่อนที่สูญหายและ/หรือถูกขโมย ต้องยกเลิกการลงทะเบียนใช้บริการทันทีโดยติดต่อฝ่ายบริการลูกค้าทางโทรศัพท์',
  biometric_legal_clause_6: 'คุณสามารถยกเลิกการใช้บริการได้ตลอดเวลาทั้งในแอปพลิเคชันและโดยติดต่อฝ่ายบริการลูกค้า',
  biometric_login_failed: 'ขออภัย การระบุตัวตนของท่านล้มเหลว',
  biometric_public_key_expired: 'ขออภัยในความไม่สะดวก! เราปิดการใช้งานการเข้าสู่ระบบด้วยไบโอเมตริกซ์ของคุณ หรือ ระบบการสแกนใบหน้า หรือ ลายนิ้วมือเพื่อรักษาบัญชีของคุณให้ปลอดภัย กรุณาลงทะเบียนเพื่อเข้าสู่ระบบไบโอเมตริกซ์ไหม่อีกครั้ง ขอบคุณ!',
  biometric_unregister_modal_title: 'ปิดการใช้งานด้วยการสแกนใบหน้าหรือนิ้วมือ',
  calculatedBy: 'คำนวณเป็น {curr}',
  captionAgreement: 'ข้าพเจ้าได้อ่านและยอมรับใน',
  captionAgreementLink: 'T&C',
  captionCustomerService1: 'กรุณาติดต่อแผนกบริการลูกค้าของเรา เรายินดีช่วยเหลือคุณ',
  chatRepresentative: 'แชทกับเรา',
  qrCodeDownload: 'Download QR',
  scanExplanation: 'Scan this QR code find us on WeChat',
  cashBack: 'เครดิตเงินคืนของฉัน',
  cashBackReceive: 'รับของขวัญเงินคืน',
  cashBackLabel: 'CashBack',
  cashBackBalance1: 'เย้ คุณได้ของขวัญเงินคืนแล้ว!',
  cashBackBalance2: 'ยอดเงินของคุณคือ:',
  cashBackCredit: 'แลกเครดิตเงินคืน',
  cashBackModalMessage: 'เซอร์ไพรส์!',
  cashBackModalMessage2: 'คุณมี',
  cashBackModalMessage3: 'จากเรา',
  cashBackModalMessage4: 'คุณสามารถใช้ได้เลยและจ่ายน้อยลง',
  cashBackModalMessageConfirm: 'ดีมาก! ฉันต้องการใช้เลย',
  cashBackModalMessageCancel: 'ฉันจะเก็บสะสมต่อไป',
  cashBackPageTitlePlus: 'ดีมาก! คุณมี',
  cashBackPageTitleZero: 'รับเงินคืน',
  cashBackPageMessagePlus: 'ฉันจะรับเครดิตเงินคืนเพิ่มได้อย่างไร?',
  cashBackPageMessageZero: 'ทำอย่างไรถึงจะได้เครดิตเงินคืน?',
  cashBackPageMessage2: 'แค่ใช้บริการของ GMT เป็นประจำ แล้วรับเครดิตเงินคืน',
  cashBackPageMessage3: 'เมื่อคุณมียอดถึง',
  cashBackPageMessage4: '$/€ คุณจะสามารถเริ่มใช้ หรือเก็บสะสมต่อไปก็ได้',
  cashBackPageFotter: 'คุณได้รับเครดิตเงินคืนในสกุลเงินเดียวกับการโอนเงินของคุณ',
  cashBackPageFotter2: 'คุณสามารถใช้เครดิตเงินคืนของคุณใน GMTหรือที่สาขาใดก็ได้ของ GMT (เร็วๆ นี้)',
  cashBackPageFotter3: 'ถ้าคุณไม่ได้ใช้บริการของเรานานกว่า 6 เดือน ยอดเงินคืนของคุณจะถูกยกเลิก',
  cashBackPageFotter4: 'อ่าน T&C ฉบับเต็ม>',
  cashBackUseNowOrLater: 'คุณสามารถใช้งานได้ทันทีหรือเก็บไว้เพื่อทำธุรกรรมในอนาคตได้',
  youHaveCashBack: 'คุณมี {cashBack Info} พร้อมใช้งาน',
  tags: {
    popular: 'เป็นที่นิยม',
    recentlyUsed: 'ใช้ล่าสุด',
    unavailable: 'ไม่สามารถใช้งานได้',
    amountExceeded: 'จำนวนเงินไม่ถูกต้อง',
  },
  cardMargketing: {
    title: 'บัตรเติมเงินที่ได้รับความร่วมมือจาก',
    subtitle: 'เรายินดีที่จะนำเสนอวิธีที่สะดวกในการควบคุมค่าใช้จ่ายของคุณ โดยไม่ต้องใช้บัญชีธนาคาร',
    info1: {
      title: 'สิทธิประโยชน์ของบัตร:',
      con1: 'การซื้อในรัฐอิสราเอล ในต่างประเทศ และทางออนไลน์',
      con2: 'สิทธิประโยชน์ของบัตร:',
    },
    info2: {
      title: 'คุณสามารถ:',
      con1: 'ดูยอดคงเหลือของบัตรของคุณ',
      con2: 'ติดตามสถานะการซื้อ',
      con3: 'กู้คืนรหัส PIN ของคุณ',
      con4: 'ปิดกั้นบัตรที่สูญหายหรือถูกขโมย',
    },
    footer: {
      '1': 'คุณสามารถรับบัตรใบใหม่ได้ที่สาขาของ GMT',
      '2': 'สำหรับรายละเอียดและการจอง',
    },
    btn: 'แชทหาเราบน WhatsApp',
  },
  cardActionsModalTitle: 'การดำเนินการสำหรับบัตร',
  cardRoute: 'บัตรเติมเงิน',
  cardHystory: 'ธุรกรรมก่อนหน้านี้',
  cardBlock: 'ปิดกั้นบัตร',
  cardBlockBtn: 'ปิดกั้น',
  cardUnBlock: 'เปิดใช้งานบัตรที่ปิดกั้นไว้',
  cardUnBlockBtn: 'เปิดใช้งาน',
  cardBlockConfirm: {
    '1': 'เราได้ปิดกั้นบัตรให้กับคุณแล้ว คุณสามารถเปิดใช้งานใหม่ได้บนเว็บไซต์',
    '2': 'กรุณาติดต่อเรา หากคุณมีคำถามใดๆ ก็ตาม',
    '3': 'ติดต่อเรา',
  },
  walletOrder: {
    deposited: 'นำฝากเข้า',
    verification: 'นี่คือรหัสยืนยันของคุณ',
    getVerification: 'กดเพื่อรับรหัสของคุณ',
    showTo: 'แสดงรหัสให้กับเจ้าหน้าที่',
    expiry: 'รหัสจะหมดอายุภายใน',
    minutes: 'นาที',
    returnHere: 'คุณสามารถกลับไปที่ของคุณได้ตลอดเวลา',
    mainPage: 'หน้าหลัก',
    cancel: 'ต้องการยกเลิกการออร์เดอร์',
    hooray: 'เย้',
    close: 'ปิด',
    depositedToYourWallet: 'เราได้ทำการฝากเงิน',
    depositedToYourWallet1: 'ไปที่วอลเลทของคุณ',
    cancelModalTitle: 'คุณต้องการยกเลิกการออเดอร์นี้ใช่ไหม?',
    cancelModalText: 'คุณสามารถออเดอร์ไหม่ได้ทุกเมื่อ',
    goToActiveOrder: 'รหัสการนำฝากเงินของคุณ',
    depositCash: 'ฝากเงินสดไปยังบัญชีของคุณ',
    btnOk: 'อนุมัติ',
    btnReturn: 'ย้อนกลับ',
    amount: 'จำนวนเงิน',
    summaryOfDetails: 'สรุปรายละเอียดการโอนเงิน',
    transferType: 'ประเภทการโอน',
  },
  wallet: {
    menu: 'บัญชีของฉัน',
    balance: 'บัญชีของฉัน',
    payWith: 'ยืนยันและชำระด้วยบัญชี myGMT ',
    payment: {
      title: 'บัญชีของฉัน',
      body: 'หลังจากที่ท่านได้กดปุ่ม " สั่งจ่าย" เราจะเริ่มทำธุรกรรมให้กับท่าน และจะปรับยอดเงินคงเหลือในบัญชีของท่านตามนั้น.',
      footer: 'หลังจากจ่ายเงินแล้ว, จะมียอดเงินคงเหลือในบัญชีของท่านเป็นจำนวน {currency}{amount}',
      inActive: {
        footer: 'ขออภัย ยอดเงินคงเหลือในบัญชีของคุณไม่เพียงพอ คุณสามารถเปลี่ยนจำนวนเงินหรือฝากเงินเพิ่มเข้าในบัญชีวอลเลทของคุณได้',
      },
    },
    main: {
      title: 'สวัสดี {name},',
      card: {
        title: 'ยอดเงินคงเหลือในบัญชีของคุณ',
        transactionsLink: 'ดูธุรกรรมทั้งหมดของคุณ>',
      },
      depositToWallet: 'ฝากเข้าบัญชีของคุณด้วย',
      sendMoney: 'ส่งเงิน',
      walletOptions: 'ตัวเลือกบัญชี',
      depositActions: {
        cash: 'ฝากด้วยเงินสด',
        bank: 'ผ่านแบงค์ไปรษณีย์',
        card: 'ฝากเข้าผ่านบัตร',
      },
      sendActions: {
        sendHome: 'ส่งเงินกลับบ้าน',
        sendWorldWide: 'ส่งเงินได้ทั่วโลก',
        inIsrael: 'ส่งเงินภายในอิสราเอล',
      },
      walletActions: {
        loadPrePaid: 'GMT บัตรเติมเงิน',
        send: 'โอนให้เพื่อนที่ร่วมใช้GMT',
        invite: 'เชิญเพื่อนและจะได้รับเงินคืน',
      },
      doarInstruction: 'วิธีการโอนเงินจากแบงค์ไปรษณีย์',
    },
    invite: {
      title: 'แนะนำเพื่อนและคุณจะได้รับเงินของขวัญ cash back',
      body: 'คุณและเพื่อนของคุณสามารถใช้ประโยชน์จาก GMT ได้มากขึ้น\nส่งลิงค์การลงทะเบียนส่วนตัวของคุณให้เพื่อนของคุณ หลังจากทำธุรกรรมครั้งแรกใน GMT คุณทั้งคู่จะได้รับ {amount} เชคเกล cash back\n\nคุณสามารถแชร์ลิงค์การลงทะเบียนส่วนบุคคลของคุณโดยใช้ปุ่มด้านล่าง',
      shareActions: {
        copy: 'คัดลอกลิงค์ของคุณ',
        copied: 'คัดลอก',
        whatsapp: 'เชิญชวนผ่าน Whatsapp',
        wechat: 'เชิญชวนผ่าน wechat',
        line: 'เชิญชวนผ่าน LINE',
        general: 'แชร์',
      },
      inviteMessages: {
        firstLine: 'สวัสดี ร่วมเข้าใช้งาน GMT! ด้วยลิงก์ส่วนตัวของฉันด้านล่างนี้ กดเพื่อเริ่มการลงทะเบียนของคุณ',
        secondLine: 'ด้วย GMT แล้วเราทั้งคู่ก็จะได้รับเงินคืน!>',
      },
    },
    deposit: {
      cash: {
        title: 'ฝากเงินสดเข้าบัญชีของคุณ',
        body: 'คุณสามารถฝากเงินสดได้ที่สาขาของ GMT สาขาใดก็ได้ หลังจากนั้นไม่กี่วินาที บัญชี GMT ของคุณจะได้รับการอัปเดต และคุณสามารถใช้งานได้ทันที',
        locations: 'สาขาของ GMT>',
        walletOrderExplanation: {
          depositAnywhere: 'คุณสามารถฝากเงินสดเข้าวอลเลท ได้ที่GMTทุกสาขา',
          amount: 'ใส่จำนวนเงินที่คุณต้องการฝาก',
          verificationCode: 'รับรหัสยืนยันของคุณ',
          teller: 'นำเงินสดและรหัสที่ได้รับส่งให้กับเจ้าหน้าที่',
          finishTitle: 'เรียบร้อย',
          finishText: 'คุณจะได้รับการฝากเข้าบัญชี GMT ของคุณทันที',
        },
      },
      bank: {
        title: 'โอนจากบัญชีธนาคาร หรือแบงค์ HaDoar ของคุณไปยังบัญชี GMT ของคุณ',
        body: 'โอนเงินผ่านธนาคารไปยัง GMT และรับเงินเข้าบัญชี GMT ของคุณโดยตรง. นายจ้างของคุณสามารถโอนเงินจากบัญชีของพวกเขาไปยังบัญชีของคุณได้',
        transferDetails: 'รายละเอียดบัญชี:\nGMT tech innovation LTD\nBank HaPoalim 12\nสาขา 613\nบัญชี 545690',
        uploadDetails: 'อัปโหลดการยืนยันการโอนเงินของคุณที่นี่ อย่าลืมใส่ หมายเลขพาสปอร์ และ เบอร์โทรศัพท์ ของคุณ',
        uploadConfirmationBtn: 'ยืนยันการอัปโหลด',
        placeholder: 'เพิ่มการยืนยันการโอนเงิน',
        upload: {
          title: 'อัปโหลดการยืนยันการโอนเงินของคุณ',
          loader: 'เราได้อัพโหลดรูปภาพของคุณแล้ว',
          footer: 'อย่างลืมใส่ หมายเลขพาสปอร์ต และ เบอร์โทรศัพท์ของคุณ',
          success: 'ยอดเยี่ยม!! ทีมของเราได้ทำการดำเนินการและยอดเงินใน wallet ของคุณจะถูกอัพโหลดโดยเร็ว\nเราจะส่งการยืนยันทาง sms และ email ของคุณเช่นกัน',
        },
      },
    },
    friends: {
      title: 'โอนเงินให้เพื่อนที่ร่วมใช้ GMT',
      addFriend: 'เพิ่มเพื่อนไหม่',
      newFriend: 'ส่งไปยัง myGMT ของเพื่อน',
      contacts: 'เลือกจากรายการผู้ติดต่อของฉัน',
      addContactByPhone: 'โอนไปยังผู้รับที่ยังไม่ได้เพิ่มเป็นเพื่อน',
      searchByNameOrPhone: 'ค้นหาผ่านชื่อหรือหมายเลขโทรศัพท์',
      header: 'ด้วยคุณสมบัติในการโอนเงินที่ใช้งานง่ายของเรา คุณสามารถส่งเงินให้เพื่อนที่ร่วมใช้งาน GMT เหมือนกับคุณได้อย่างปลอดภัย \n วิธีการโอนที่ง่ายดายและรวดเร็ว!',
      mobilePhone: 'ใส่หมายเลขโทรศัพท์เพื่อนของคุณ',
      bulletsOne: 'ใส่หมายเลขโทรศัพท์ของเพื่อนของคุณ',
      bulletsTwo: 'กรอกจำนวนเงินที่คุณต้องการส่ง',
      bulletsThree: 'ยืนยันการทำรายการ',
      outro: 'เรียบร้อย! ยอดเงินจะถูกโอนเข้าบัญชีของเพื่อนคุณทันที \n เริ่มใช้งานได้แล้ววันนี้!',
      inviteBnt: 'เชิญชวนเพื่อนของคุณเข้าร่วม GMT',
      list: {
        title: 'รายชื่อเพื่อน',
      },
      addModal: {
        title: 'คุณต้องการที่จะเพิ่มหรือไม่',
        footer: 'ส่งไปที่ wallet ของเพื่อนของคุณ',
        saveBtn: 'บันทึก',
        closeBtn: 'ยกเลิก',
      },
      friendCard: {
        title: 'บัญชี',
        delete: 'ลบ',
        deleteDialogFirstLine: 'คุณต้องการลบ {beneficiary} ออกจากรายชื่อเพื่อนใน Wallet ของคุณหรือไม่?',
        deleteDialogSecondLine: 'การดำเนินการนี้จะไม่ส่งผลต่อการโอนที่มีอยู่',
        send: 'ส่งไปบัญชี GMT',
      },
    },
    transfer: {
      amount: {
        title: 'ส่งเงินไปยังบัญชี GMT',
      },
      send: {
        title: 'ส่ง {amount} ILS ไปยังบัญชี GMT',
      },
      summary: {
        title: 'ส่ง {amount} ILS ไปยังบัญชี GMT',
      },
      pay: {
        title: 'ส่ง {amount} ILS ไปยังบัญชี GMT',
      },
    },
    load: {
      amount: {
        title: 'ฝากเงินเข้าบัญชีของคุณโดยชำระเงินด้วยบัตรของคุณ',
      },
      send: {
        title: 'ฝากไปยังบัญชีของคุณ',
      },
      summary: {
        title: 'ฝากไปยังบัญชีของคุณ',
      },
      pay: {
        title: 'ฝากไปยังบัญชีของคุณ',
      },
    },
    card: {
      amount: {
        title: 'เติมเงินบัตร GMT ด้วยยอดเงินในบัญชีของคุณ',
      },
      send: {
        title: 'เติมเงินบัตร GMT ด้วยยอดเงินในบัญชีของคุณ',
      },
      summary: {
        title: 'เติมเงินเข้า GMT card',
      },
      pay: {
        title: 'เติมเงินเข้า GMT card',
      },
    },
  },
  loadCard: 'เติมเงินด้วย วอลเลท ของคุณ',
  pay: 'กดเพื่อยืนยันการทำธุรกรรม',
  cardUnBlockConfirm: 'บัตรของคุณได้ถูกเปิดใช้งานแล้ว',
  cardMerchantAddress: 'ที่อยู่',
  cardMerchantPhone: 'โทรศัพท์',
  cardPresented: 'ใช้บัตรรูด',
  changeAmount: 'ต้องการเปลี่ยนจำนวนเงินที่จะส่ง?',
  changePayoutAmount: 'ต้องการเปลี่ยนแปลงจำนวนเงินที่ผู้รับจะได้รับ?',
  changeDepositAmount: 'ต้องการเปลี่ยนแปลงจำนวนเงินที่ต้องการฝาก?',
  changeAmountLabel: 'จำนวนไหม่คือ {amount}',
  changeAmounBtn: 'คำนวณ',
  city: 'เมือง',
  commission: 'ค่าบริการ',
  confirmMarketingContent: 'ฉันต้องการรับเนื้อหาและข้อมูลล่าสุดทางการตลาด',
  confirmCreditNumber: 'ข้อสำคัญ! กรุณาตรวจสอบหมายเลขบัตรให้รอบคอบ เนื่องจากคุณไม่สามารถขอเงินคืนสำหรับเงินทุนที่ฝากเข้าบัตรในต่างประเทศไปแล้วได้',
  confirmBankAccount: 'ข้อสำคัญ! กรุณาตรวจสอบรายละเอียดของบัญชีอย่างถี่ถ้วน เนื่องจากคุณไม่สามารถขอเงินคืนสำหรับเงินทุนที่ฝากเข้าบัญชีในต่างประเทศไปแล้วได้',
  confirmBankAccount2: 'บัญชีของผู้รับผลประโยชน์จะได้รับเงินเข้าประมาณ 2-4 วันทำการ',
  contactBtnCaption: 'หน้าบริการ >',
  contactMenuCaption1: 'เทเลแกรม',
  contactMenuCaption2: 'Line',
  contactMenuCaption3: 'โทร',
  contactMenuCaption4: 'Whatsapp',
  country: 'ประเทศต้นทาง',
  countryBirth: 'ประเทศที่เกิด',
  countryDestination: 'ประเทศปลายทาง',
  countryFrom: 'จากประเทศ',
  correspondentReference: 'หมายเลขอ้างอิง',
  cookies: 'เว็บไซต์นี้ใช้ไฟล์คุ้กกี้ ถ้าคุณใช้งานเว็บไซต์นี้ต่อไป เราถือว่าคุณยินยอมให้เราใช้คุกกี้ หากคุณต้องการดูรายละเอียดเพิ่มเติม กรุณาดูที่ <a style=""text-decoration:underline;display: inline;"" href=""https://gmtonline.co.il/en/privacy/"" target=""_blank"">นโยบายความเป็นส่วนตัว </a>',
  clone: 'ส่งอีกครั้ง',
  payingReference: 'หมายเลขคำสั่งซื้อ',
  creditCard: 'บัตรเครดิต',
  crediting: 'เครดิต',
  creditCardOwner: 'ชื่อบนบัตร',
  creditCardSupport: 'เราทำงานกับ',
  creditCardCommissionMsg: 'บริษัทบัตรเครดิตของคุณอาจคิดค่าบริการสำหรับการแลกเปลี่ยนเงินตรา',
  creditCardSecurityMsg: 'เราไม่เก็บข้อมูลบัตรของคุณไว้ เราแค่ส่งข้อมูลให้กับบริษัทบัตรเครดิต เพื่อขออนุมัติเท่านั้น',
  cardDepositAccepted: 'บริการนี้สามารถใช้ได้สำหรับบัตร Visa และ Mastercard เท่านั้น',
  Contact: 'ติดต่อ',
  currency: 'สกุลเงิน',
  currencyDestination: 'สกุลเงินของผู้รับ',
  currencyILS: 'ILS',
  currencyUSD: 'USD',
  currencyEUR: 'EUR',
  currencyCardMsg: 'จำนวนเงินที่ได้รับท้ายที่สุดจะขึ้นกับชนิดและสกุลเงินของบัตรของผู้รับผลประโยชน์',
  currencyBankMsg: 'จำนวนเงินที่ได้รับท้ายที่สุดจะขึ้นกับชนิดและสกุลเงินของบัญชีของผู้รับผลประโยชน์',
  dateBirth: 'วันเกิด',
  daysWork: 'วันอาทิตย์ถึงวันพฤหัสบดี',
  daysWork2: 'วันอาทิตย์ถึงวันพฤหัสบดี 08:30-19:00 น. วันศุกร์ 08:30-14:00 น.',
  daysWork3: 'วันศุกร์',
  destination: 'ปลายทาง',
  discount: 'ส่วนลด',
  docsTitle: 'กรุณาสแกนเอกสารแสดงตัวตนที่ถูกต้องของคุณ',
  docsTitleUploaded: 'ขอบคุณ, การดำเนินการใกล้เสร็จสิ้นแล้ว!',
  documentsUploading1: 'เรากำลังบันทึกรูปภาพที่คุณได้ทำการเพิ่ม',
  documentsUploading2: 'จะใช้เวลาอีกสักครู่',
  documentsUploading3: 'จากนั้น ให้คุณกดปุ่มถัดไป',
  editUserProfile: 'รายละเอียดของฉัน',
  email: 'ที่อยู่อีเมล',
  errorCode: 'รหัสไม่ถูกต้อง กรุณาลองอีกครั้ง',
  estimated: 'โดยประมาณ',
  calculatedAbroad: 'จะถูกคำนวณในต่างประเทศ',
  expired: 'หมดอายุแล้ว',
  extraDetails: 'รายละเอียดเพิ่มเติม',
  exchangeRate: 'อัตราแลกเปลี่ยน',
  totalPayment: 'ยอดชำระเงิน',
  cashbackDiscount: 'ส่วนลดเงินคืน',
  byExchangeRate: 'อัตราแลกเปลี่ยน',
  destinationExchangeRate: 'อัตราแลกเปลี่ยนปลายทาง',
  pressPay: 'หลังจากคุณกดปุ่ม "ชำระเงิน" เราจะใช้ยอดเงินในบัญชีของคุณเพื่อทำธุรกรรม',
  fax: 'แฟ็กซ์',
  for: 'สำหรับ',
  finished: 'เสร็จแล้ว',
  foreignPassport: 'ฉันมีหนังสือเดินทางของต่างประเทศ',
  to: 'ไปที่',
  freeSearch: 'ค้นหา',
  friday: 'วันศุกร์',
  fullName: 'ชื่อเต็ม',
  fun: 'เราจะส่งข้อมูลอัปเดตในเรื่องสำคัญๆ เท่านั้นให้กับคุณ',
  foreignCurrencyHandlingFee: 'ค่าธรรมเนียมการจัดการสกุลเงินต่างประเทศ',
  gender: 'เพศ',
  here: 'ที่นี่',
  hours: 'หมายเหตุ: คุณต้องไปที่สาขาของ GMT เพื่อชำระเงินให้เสร็จ ภายใน 48 ชั่วโมงนับจากนี้',
  houseNumber: 'เลขที่บ้าน',
  welcome: 'ยินดีต้อนรับ',
  interest: 'รวมค่าธรรมเนียมแล้ว {inter}',
  interested: 'ในระหว่างนี้ คุณสามารถไปที่เว็บบล็อกของเรา',
  includesInterest: 'ต่อเดือน - รวมดอกเบี้ย {interest} ₪',
  includesInterest2: 'รวมดอกเบี้ย {interest} ₪',
  israel: 'อิสราเอล',
  israeliID: 'ฉันมีเลขประจำตัวประชาชนของอิสราเอล',
  israeliResident: 'ผู้มีถิ่นที่อยู่ในอิสราเอล',
  nonIsraeliResident: 'ผู้อยู่อาศัยที่เป็นชาวต่างชาติ',
  installments: 'การชำระเงิน',
  inProcess: 'อยู่ระหว่างการดำเนินการ',
  itemNavMain: 'My Wallet',
  itemNav0: 'เครดิตเงินคืนของฉัน',
  itemNav1: 'ส่งเงิน',
  itemNav10: 'สาขา',
  itemNav2: 'ประวัติของฉัน',
  itemNav3: 'เช็คเรทเงินและค่าธรรมเนียม',
  itemNav4: 'ผู้รับผลประโยชน์ของฉัน',
  itemNav5: 'สาขาของ GMT',
  itemNav6: 'คำถามที่พบบ่อย',
  itemNav7: 'ติดต่อเรา',
  itemNav8: 'ข้อกำหนดและเงื่อนไข',
  itemNav9: 'ออกจากระบบ',
  itemNav11: 'เกี่ยวกับ',
  itemNav12: 'บัตรของฉัน',
  itemNavUserProfile: 'รายละเอียดส่วนตัว และ การตั้งค่า',
  itemNav13: 'การสั่งซื้อและแลกเปลี่ยนเงินตรา',
  labelEmail: 'ที่อยู่อีเมล',
  labelMsg: 'คุณต้องการพูดเรื่องอะไร?',
  labelName: 'ชื่อจริงของฉัน',
  labelPhone: 'หมายเลขโทรศัพท์',
  lblDone: 'การโอนเงินนี้เป็นการโอนเงินเพื่อเป็นของขวัญหรือเงินสนับสนุนให้กับญาติสายตรง และไม่ได้ก่อให้เกิดรายได้กับผู้รับผลประโยชน์',
  lblDoneWechat: 'Note that for the first transaction, your recipient needs to approve the WeChat transaction. Tell them to click on the SMS link.',
  lblDoneWechat1: 'Please add 86 before your Chinese mobile number linked with WeChat, or 972 before your Israeli mobile number.',
  labelSurname: 'นามสกุลของฉัน',
  leaveMsg: 'คุณต้องการพูดเรื่องอะไร?',
  linkToLoginPage: 'ลงทะเบียนแล้วใช่ไหม? ลงชื่อเข้าใช้ >',
  listBranches: 'ค้นหา GMT',
  locatingBranches: 'กำลังค้นหาตำแหน่งที่ตั้งของสาขา',
  locationBtn: 'เปิด',
  locationBtnClose: 'ปิด',
  loginLabel1: 'หมายเลขประจำตัว/หนังสือเดินทาง',
  loginLabel2: 'หมายเลขโทรศัพท์มือถือ',
  logInConsent: 'เมื่อเข้าสู่ระบบ, ฉันได้ยอมรับ',
  mailingAddress: 'ที่อยู่',
  mailingRecipient: 'Raul Wallenberg 18C, Tel-Aviv, 6971915',
  marked: 'เปิดอยู่ในตอนนี้',
  maybe: 'ในระหว่างนี้ คุณสามารถไปที่เว็บบล็อกของเรา',
  messageCode: 'เราเพิ่งส่ง SMS ที่มีรหัสไปให้กับคุณ กรุณาป้อนรหัสดังกล่าวในช่องด้านล่างนี้',
  messageSend: 'เราจะส่งการยืนยันนี้ให้กับคุณทาง SMS และอีเมลในอีกสักครู่',
  messageNotSent: 'มีปัญหาในการรับรหัส OTP ของคุณใช่หรือไม่? โปรดตรวจสอบกล่องจดหมาย SMS และทำการเช็คที่ข้อความสแปมของคุณ. เรายินดีที่จะช่วยเหลือหากคุณต้องการรายละเอียดเพิ่มเติม',
  messageRegistration1: 'สวัสดี เรายินดีที่เห็นคุณกลับมา',
  messageRegistration2: 'เราต้องการให้คุณอัปโหลดบัตรประชาชน/หนังสือเดินทางของคุณ ยอมรับ T&C ของเรา เท่านี้ก็เสร็จแล้ว',
  messageUploadDocuments: 'ดูเหมือนเอกสารแสดงตัวตนของคุณจะใช้ไม่ได้อีกต่อไป กรุณาอัปโหลดเอกสารใบใหม่',
  middleName: 'ชื่อกลาง',
  mobilePhone: 'หมายเลขโทรศัพท์มือถือ',
  mobilePhoneNumber: 'หมายเลขโทรศัพท์อิสราเอล',
  monday: 'วันจันทร์',
  moneyApproved: 'การโอนเงินได้รับอนุมัติแล้ว',
  MoneyGram: 'MoneyGram',
  moreOptions: 'ตัวเลือกอื่นๆ',
  msgError: 'เฉพาะหมายเลขเท่านั้น',
  maxAccessionCommission: 'ค่าธรรมเนียมในการร่วมใช้บริการ',
  myId: 'บัตรประชาชนของฉัน',
  nameBeneficiary: 'ชื่อผู้รับผลประโยชน์',
  newBeneficiary: 'ผู้รับผลประโยชน์คนใหม่',
  version: 'v {num}',
  updateBeneficiary: 'รายละเอียดของผู้รับผลประโยชน์',
  nextLevel: 'ถัดไป',
  no: 'ไม่ใช่',
  noBeneficiaries: 'ยังไม่ได้กำหนดผู้รับผลประโยชน์คนใดไว้ในระบบ',
  noRecentActions: 'ยินดีต้อนรับสู่โซนส่วนตัวของคุณใน GMT',
  noRecentActions1: 'คุณสามารถดูประวัติทั้งหมดของคุณกับ GMT ได้ที่นี่',
  noRecentActions2: 'มาส่งเงินกันเลยเถอะ!',
  noCardRecentActions: 'ไม่พบธุรกรรม',
  notAvailable: 'ไม่สามารถใช้ได้',
  notRequired: 'ไม่บังคับ',
  numberPassport: 'หมายเลขหนังสือเดินทาง',
  numberOfTransaction: 'หมายเลขใบกำกับสินค้า',
  openNow: 'เปิด',
  paymentFrom: 'การชำระเงินจาก',
  payUsing: 'ฉันจะชำระด้วย',
  youllPay: 'คุณต้องชำระ',
  toPay: 'ยอดชำระ',
  payRate: 'เรทเงิน {rate}',
  paymentCredit: 'บัตรเครดิต',
  photoID: 'รูปหน้าพาสปอร์ตของท่าน',
  photoRequired: 'กรุณาเพิ่มภาพถ่าย',
  pinCode: 'ดูรหัส PIN',
  pinCodeModalMessage: 'คุณต้องการดูรหัส PIN ของบัตรที่ลงท้ายด้วยเลขต่อไปนี้ใช่ไหม {digits}',
  pinCodeModalMessage2: 'เราจะส่งข้อความไปที่โทรศัพท์มือถือของคุณ เพื่อให้คุณดูรหัส PIN',
  placeholderDigit: 'หมายเลขประจำตัว 9 หลัก',
  questionsAnswers: 'คำถามที่พบบ่อย',
  questionsAnswersSubtitle: 'ไม่พบคำตอบสำหรับคำถามของคุณ? กรุณาติดต่อเราด้วยวิธีที่ระบุไว้ในหน้าการติดต่อ',
  reasonTransfer: 'วัตถุประสงค์การโอน',
  recentActions: 'ประวัติของฉัน',
  recentActionsTab1: 'ผู้รับผลประโยชน์คนใหม่',
  recentActionsTab2: 'บริการแลกเปลี่ยนเงินตราต่างประเทศที่สนามบิน Ben Gurion',
  recentActionsTab3: 'กำลังเรียกเก็บเงินจากบัตร',
  recentActionsTab4: 'กำลังรับเงิน',
  recentActionsTab5: 'กำลังซื้อ Bitkoiin',
  recommended: 'รายการที่แนะนำ!',
  recentActionsBeneficiaries: 'ผู้รับผลประโยชน์ของฉัน',
  recentActionsTransfer: 'ส่งเงิน',
  r_step_address: 'ที่อยู่',
  r_step_doc: 'เอกสาร',
  r_step_name: 'ชื่อ',
  rate: 'เรทเงิน',
  registerSystem: 'การลงทะเบียน GMT',
  registerSystemAddDocuments: 'อัปโหลดเอกสารแสดงตัวตน',
  registerText1: 'คุณจะสามารถส่งเงินได้ในอีกสักครู่',
  registerText2: 'เตรียมบัตรประชาชน/หนังสือเดินทางของคุณไว้ เราจะขอให้คุณถ่ายภาพเอกสารในภายหลัง มาเริ่มกันเลยเถอะ',
  registerFooter: 'GMT ดำเนินงานโดยได้รับใบอนุญาตจากกระทรวงการคลัง',
  registerFooter2: 'ผู้ให้บริการด้านการเงินแบบขยาย - ใบอนุญาตเลขที่ 57479',
  registerFooter3: 'บริการของเรามีการเข้ารหัสลับและปลอดภัย และเราจะไม่เปิดเผยข้อมูลของคุณกับใครทั้งสิ้น',
  registrationSuccess: 'การลงทะเบียนสำเร็จแล้ว',
  registrationSuccess1: 'ขอบคุณ เราได้รับรายละเอียดของคุณแล้ว',
  registrationSuccess2: 'GMT ช่วยให้คุณสามารถส่งเงินไปต่างประเทศได้อย่างรวดเร็วและง่ายดาย',
  requestSuccessfully: 'เราได้รับคำขอของคุณแล้ว',
  requestSuccessfully1: 'หมายเลขคำสั่งซื้อของคุณคือ:',
  requestSuccessfully2: 'ฉันต้องทำอะไรต่อไป? คุณต้องชำระเงินสำหรับการโอนเงินนี้ภายใน 24 ชั่วโมงนับจากนี้',
  requestSuccessfully3: '<a href=""https://gmtonline.co.il/en/locating-branches/"" style=""color:#d4c641;text-decoration: underline;"">ที่สาขาใดก็ได้ของ GMT </a>',
  requestSuccessfully4: 'จากนั้น เงินดังกล่าวจะถูกส่งไปต่างประเทศ',
  riskyLimitMsg: 'หากต้องการส่งจำนวนเงินที่สูงขึ้น? ติดต่อซัพพอร์ตทีมของเรา.',
  russianSpeaker: 'บริการในภาษารัสเซีย',
  saturday: 'วันเสาร์',
  searchResult: 'พบ X สาขาใน XXX',
  selectBeneficiary: 'ผู้รับผลประโยชน์ของฉัน',
  selectCity: 'ค้นหาจากเมือง',
  selectCountry: 'ประเทศบ้านเกิด',
  selectProvince: 'เลือกจังหวัด',
  sendMarketingContentLbl: 'ส่งข้อเสนอพิเศษและข้อมูลล่าสุดให้กับฉัน',
  sentOn: 'วันที่',
  signedUp: 'คุณได้ลงทะเบียนแล้ว',
  soonPossible: 'เราจะติดต่อกลับไปหาคุณในชั่วโมงทำการของเรา',
  staging: {
    payWith: 'Cash at GMT location',
    body: "you'll press the \"\"place your order\"\" button, we will prepare the transfer for you. The transfer will be sent after you pay at one of GMT's locations.",
    order: 'Place Your Order',
  },
  streetName: 'ถนน',
  streetName_HouseNumber: 'เลขที่บ้านและชื่อถนน',
  subscribeDesc: 'พบกับข่าวสารล่าสุดทั้งหมดในโลกของ FinTech ได้ที่นี่',
  subscribeNewsletter: 'ลงทะเบียนเพื่อรับจดหมายข่าวขอเรา - คุ้มนะ!',
  subtitleCustomerService: 'กรุณาติดต่อแผนกบริการลูกค้าของเรา เรายินดีช่วยเหลือคุณ',
  summaryTransfer: 'สรุปรายละเอียดการโอนเงิน',
  sunday: 'วันอาทิตย์',
  surtitleFormLang: 'เลือกภาษาที่คุณต้องการ',
  smartBannerTitle: 'อัปเกรดเป็นแอป GMT',
  smartBannerText: 'ง่าย. เร็ว. ปลอดภัย. สำหรับคุณ.',
  smartBannerBtnText: 'รับ',
  system: 'ระบบ',
  correspondentSystem: 'สาขาของ',
  termsUse: 'T&C',
  imprtant: 'Important',
  termsTitle: 'กรุณาอ่านและยอมรับ T&C',
  tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
  surveyModal: {
    active: 'true',
    title: 'แบ่งปันความคิดเห็นของคุณ: ทำแบบสำรวจโดยไม่ระบุชื่ออย่างรวดเร็วของเรา',
    body: 'สวัสดี {name} เราอยากได้ยินความคิดเห็นจากคุณ! เพื่อช่วยให้เราได้ปรับปรุงบริการทางการเงินของเราโดยการตอบแบบสำรวจแบบสั้นๆ โดยไม่เปิดเผยตัวตน ความคิดเห็นของคุณมีความสำคัญกับเรามาก',
    confirm: 'เริ่มการสำรวจกันเลย',
    cancel: 'คราวหลัง',
    link: 'https://forms.gle/ZmtBA8s9HT7vVtvS8',
  },
  termsUpdate: {
    title: 'T&C และ นโยบายของความเป็นส่วนตัว',
    body: {
      first: 'สวัสดีคะ เราได้ทำการอัปเดตเอกสารทางกฎหมายของเรา',
      second: 'คุณสามารถเข้าอ่านได้ที่นี่>',
    },
  },
  thanks: 'ขอบคุณที่ติดต่อมาหาเรา',
  thursday: 'วันพฤหัสบดี',
  titleFormLang: 'ยินดีต้อนรับ',
  titleLoginPage: 'ลงชื่อเข้าใช้งานบัญชี GMT',
  toBankAccount: 'ไปที่บัญชี',
  toWalletAccount: 'ไปที่บัญชีวอลเลท',
  pickupPoint: 'จุดรับเงิน',
  toCreditCard: 'ไปที่บัตร',
  totalNIS: 'ยอดรวมเป็น NIS',
  totalTransfer: 'ยอดรวมที่ต้องชำระ',
  total: 'ยอดรวม',
  t_step_amount: 'จำนวน',
  t_step_transfer: 'โอน',
  t_step_summary: 'สรุป',
  t_step_pay: 'ชำระ',
  transferAmount: 'จำนวนเงิน',
  transactionAmount: 'จำนวนเงิน',
  transactionCharge: 'เรียงตาม',
  filterPaymentType: 'ประเภทการชำระเงิน',
  transactionType: 'ประเภทการโอน',
  transferFundsTo: 'ส่ง',
  transferOptions: 'ตัวเลือกการโอน',
  transferType: 'ชนิดการโอน',
  transferTypePick: 'กรุณาเลือกวิธีการโอนเงินของคุณ',
  transferTypePick2: 'เลือกประเภทการโอน',
  tuesday: 'วันอังคาร',
  waitingPayment: '- กำลังรอชำระเงิน',
  wednesday: 'วันพุธ',
  yourCardBalance: 'ยอดคงเหลือของบัตร',
  zipCode: 'รหัสไปรษณีย์',
  loginAgreementRequired: 'กรุณาอ่านและยอมรับข้อกำหนดในการใช้บริการ',
  customerCommission: 'ค่าบริการของคุณ',
  customerCommissionMaxTopup: 'ค่าธรรมเนียมในการเติมเงิน',
  customerEmailNotification: {
    title: 'กรุณาใส่่ที่อยู่ อีเมลย์ของท่าน',
    body: 'เราพบว่า เรายังไม่มีอีเมลย์ของท่าน\nเพื่อความสะดวก ควรใส่อีเมลย์ของท่านที่ช่องด้านล่างนี้ เพื่อที่เราจะได้ส่งสำเนาการทำธุรกรรมทุกอย่างไปที่อีเมลย์ของท่านด้วย.\nพวกเราจะไม่มีการส่งข้อมูลที่เป็นสแปมใดๆ ให้ท่าน.',
    cancel: 'ย้อนกลับ',
  },
  sumAmount: 'ยอดเงินรวม',
  terms: 'ข้อกำหนดและเงื่อนไข',
  error: 'ข้อผิดพลาด',
  generalErrorMsg: 'ขออภัย! เรากำลังปรับปรุงเว็บไซต์ เพื่อปรับปรุงบริการต่างๆ ของเรา เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  connectionError: 'ข้อผิดพลาดในการเชื่อมต่อ',
  locationLink: 'en/locating-branches/',
  faqLink: 'en/faq/',
  contactUsLink: 'en/contact-us/',
  protocolLink: 'en/protocol/',
  privacyLink: 'en/privacy',
  privacyPolicy: 'นโยบายความเป็นส่วนตัว',
  profileDelete: 'ลบบัญชีของคุณ',
  profileDeleteModalTitle: 'คุณต้องการที่จะทำการยกเลิกบัญชี GMT ของคุณหรือไม่',
  profileDeleteModalText: 'ทำได้โดยการที่คลิกปุ่มอนุมัติ,เราจะได้รับข้อความและติดต่อกลับโดยเร็วที่สุด',
  aboutLink: 'en/my-gmt/',
  paymentProcessing: 'โปรดรอซักครู่ ขณะนี้เรากำลังดำเนินการตามคำขอของคุณ',
  paymentType: 'ชนิดของการชำระเงิน',
  paymentTypeCredit: 'ชำระด้วยบัตรเครดิต',
  paymentTypeByCredit: 'ชำระด้วยบัตร',
  paymentTypeCash: 'เงินสด',
  uploadDocumentMsg1: 'เราต้องตรวจสอบเอกสารแสดงตัวตนของคุณ กรุณาถ่ายภาพเอกสารของคุณ',
  uploadDocumentMsg2: 'เรารักษาความเป็นส่วนตัวของคุณอย่างเคร่งครัด เว็บไซต์ของเรามีการเข้ารหัสลับและปลอดภัย และเราจะไม่เปิดเผยข้อมูลของคุณกับใครทั้งสิ้น',
  yes: 'ใช่',
  zipCodePlaceholder: 'ตัวเลข 7 หลัก',
  zipCodeRequiredFields: 'กรุณากรอกรายละเอียดที่อยู่ของคุณเพื่อให้เราสามารถค้นหารหัสไปรษณีย์ของคุณได้',
  zipCodeNotFound: 'เราค้นหารหัสไปรษณีย์ของที่อยู่นี้ไม่พบ กรุณาตรวจสอบอีกครั้ง หรือกรอกรหัสไปรษณีย์ 7 หลักของคุณ',
  CORRESPONDENT_BLOCKED: 'ขออภัย บริการนี้อยู่ระหว่างการบำรุงรักษา โปรดลองอีกครั้งในภายหลัง หากคุณต้องการความช่วยเหลือ เราพร้อมช่วยเหลือคุณ',
  CARD_DEPOSIT: 'โอนเข้าบัตรต่างประเทศ',
  CARD_DEPOSIT_MAX: 'เติมเงินเข้าบัตร',
  CARD_DEPOSIT_TO: 'โอนเข้าบัตรที่ {dest}',
  CASH_TRANSFER: 'รับเงินสดที่ต่างประเทศ',
  CASH_TRANSFER_INLAND: 'รับเงินสดปลายทาง',
  CASH_TRANSFER_ISR: 'รับเงินสดปลายทาง',
  CASH_TRANSFER_TO: 'รับเงินสดใน {dest}',
  BANK_TRANSFER: 'โอนไปยังบัญชีธนาคาร',
  UPI_BANK_TRANSFER: 'โอนเข้าบัญชีต่างประเทศ UPI',
  BANK_TRANSFER_ISR: 'ฝากไปยังบัญชี',
  BANK_TRANSFER_TO: 'ฝากเงินเข้าบัญชีใน {dest}',
  BANK_TRANSFER_PAYMENT: 'โอนเงินผ่านธนาคาร',
  WALLET_TRANSFER: 'ฝากเงินเข้า eWallet ในต่างประเทศ',
  WALLET_TRANSFER_TO: 'ฝากเงินเข้า eWallet ใน{dest}',
  WALLET_TO_WALLET: 'โอนไปยังบัญชี GMT',
  WALLET_FROM_WALLET: 'รับจากบัญชี GMT',
  WALLET_DEPOSIT: 'ฝากไปยังบัญชี GMT',
  NEW_CUSTOMER: 'สวัสดี การลงทะเบียนของคุณจะเสร็จสิ้นภายใน 24 ชั่วโมง จนกว่าจะถึงเวลานั้น ตัวเลือกการชำระเงินด้วยบัตรเครดิตจะยังไม่พร้อมใช้งาน เราจะแจ้งให้คุณทราบเมื่อพร้อมแล้ว',
  CUSTOMER_NOT_AUTHORIZED: 'ขออภัย! ตัวเลือกการชำระเงินบัตรเครดิตนี้ไม่สามารถใช้บริการได้ในโพรไฟล์ของคุณ เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  CUSTOMER_NOT_SIGNED: 'ขออภัย! คุณยังลงทะเบียนไม่เสร็จ ดังนั้น ตัวเลือกการชำระเงินบัตรเครดิตจึงไม่สามารถใช้บริการได้ในโพรไฟล์ของคุณ ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_LIMIT_UNREGISTERED: 'คุณสามารถส่งเงินในเดือนนี้ได้สูงสุด',
  CREDIT_LIMIT: 'คุณสามารถส่งเงินในเดือนนี้ได้สูงสุด',
  CREDIT_LIMIT_MONTHLY: 'คุณสามารถส่งเงินในเดือนนี้ได้สูงสุด {amount}',
  CREDIT_LIMIT_CREDIT_CARD: 'จำนวนเงินที่สามารถทำการโอนได้ต่อเดือน ด้วยบัตรเครดิต. คุณสามารถโอนได้อีก {amount}',
  CREDIT_LIMIT_WALLET: 'จำนวนเงินที่สามารถทำการโอนได้ต่อเดือน ด้วยกระเป๋าเงิน. คุณสามารถโอนได้อีก {amount}',
  CREDIT_LIMIT_RISKY: 'คุณสามารถส่งเงินไป {country} ได้สูงสุด',
  TRANSACTION_LIMIT: 'ขออภัย บริการนี้จำกัดจำนวนการโอนไม่เกิน {currency}{amount} ต่อรายการเท่านั้น โปรดอัปเดตจำนวนเงินและลองไหม่อีกครั้ง',
  CREDIT_CARD_ILS_AMOUNT: 'เรากำลังคำนวณยอดเงินในสกุล ILS การชำระเงินจะสามารถใช้งานได้ในทันที',
  CREDIT_NOT_ACTIVE: 'ขออภัย! ตัวเลือกการชำระเงินบัตรเครดิตไม่สามารถใช้บริการได้ในขณะนี้ เวลาให้บริการคือ 06:00-24:00 น.',
  CREDIT_BLOCKED: 'ขออภัย! ตัวเลือกการชำระเงินบัตรเครดิตไม่สามารถใช้บริการได้ในขณะนี้ เนื่องจากเรากำลังอัปเดตเว็บไซต์ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง เรายินดีช่วยเหลือ หากคุณมีคำถามใดๆ ก็ตาม',
  API_EXCEPTION: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  GRAILS_VALIDATION_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CAPTCHA_NOT_VERIFIED: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CUSTOMER_SESSION_NOT_FOUND: 'ข้อมูลประจำตัวไม่ถูกต้อง หรือคุณยังไม่ได้ลงทะเบียน GMT คุณสามารถลงทะเบียนได้ที่นี่ <a href=""https://mygmt.gmtonline.co.il/#/registration"" style=""color:#d4c641;text-decoration: underline;"">mygmt.gmtonline.co.il</a>',
  CUSTOMER_BLOCKED: 'ขออภัย! โพรไฟล์ของคุณถูกปิดแล้ว กรุณาติดต่อ<a href=""https://gmtonline.co.il/en/contact-us/"" style=""color:#d4c641;text-decoration: underline;"">แผนกบริการลูกค้า</a>ของเรา',
  SMS_WRONG: 'คุณป้อนรหัสไม่ถูกต้อง',
  SMS_EXPIRED: 'ขออภัย! รหัสนี้ไม่สามารถใช้ได้อีกต่อไป คุณสามารถขอรหัสใหม่ได้',
  REGISTRATION_TOKEN_NOT_FOUND: 'คุณป้อนรหัสไม่ถูกต้อง',
  REGISTRATION_TOKEN_EXPIRED: 'ขออภัย! รหัสนี้ไม่สามารถใช้ได้อีกต่อไป คุณสามารถขอรหัสใหม่ได้',
  SESSION_EXPIRED: 'เราคิดว่าคุณออกจากการใช้งานไปแล้ว ดังนั้นเราจึงออกจากระบบเพื่อรักษาบัญชีของคุณให้ปลอดภัย คุณสามารถเข้าสู่ระบบอีกครั้งเพื่อดำเนินการต่อ',
  CUSTOMER_MOBILE_NOT_MATCH: 'ขออภัย มีปัญหาบางอย่างเกี่ยวกับรายละเอียดที่คุณกรอกมา หากต้องการลงทะเบียนกับ GMT โปรดติดต่อฝ่ายบริการลูกค้าของเรา',
  WALLET_FRIEND_NOT_UNIQUE: 'ขออภัย เราไม่สามารถเพิ่มหมายเลขนี้ลงในรายชื่อเพื่อนของคุณได้ เรายินดีให้ความช่วยเหลือ โปรดกดที่ "ติดต่อเรา" เพื่อถามคำถามใดๆ',
  CUSTOMER_ALREADY_REGISTERED: 'สวัสดี! เราคิดว่าคุณได้ลงทะเบียนกับ GMT แล้ว คุณสามารถเข้าสู่ระบบได้ที่นี่ > <a href=""https://mygmt.gmtonline.co.il"" style=""color:#d4c641;text-decoration: underline;"">mygmt.gmtonline.co.il</a>',
  CUSTOMER_DENIED: 'สวัสดี เรายินดีที่เห็นคุณกลับมา คุณยังลงทะเบียนไม่เสร็จในครั้งที่แล้ว กรุณาติดต่อเรา เพื่อดำเนินการให้เสร็จ',
  BENEFICIARY_NOT_FOUND: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  TRANSACTION_NOT_FOUND_FOR_CUSTOMER: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  DOCUMENT_NOT_FOUND: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  DOCUMENT_NOT_FOUND_FOR_CUSTOMER: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_VALIDATION_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_CARD_SIGN_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_CARD_VERIFY_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CREDIT_CARD_COMMIT_ERROR: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  CUSTOMER_MULTIPLE: 'ขออภัย! หากคุณต้องการลงทะเบียน GMT กรุณาติดต่อ<a href=""https://gmtonline.co.il/en/contact-us/"" style=""color:#d4c641;text-decoration: underline;"">แผนกบริการลูกค้า</a>ของเรา',
  MONEYGRAM_EXCEPTION: 'ขออภัย! มีบางสิ่งผิดปกติ เราขอแนะนำให้คุณลองอีกครั้งในภายหลัง ถ้าคุณมีคำถามใดๆ เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_301: 'เกินจำนวนเงินสูงสุดที่สามารถโอนได้',
  MONEYGRAM_304: 'ขออภัย! บริการโอนเงินของเราปิดให้บริการอยู่ในขณะนี้ เราเปิดให้บริการในวันทำการ เวลา 8:00-21:00 น. และวันศุกร์ เวลา 8:00-15:00 น.',
  MONEYGRAM_323: 'ขออภัย! ชื่อของผู้รับผลประโยชน์ที่คุณป้อนยาวเกินไป คุณสามารถลองอีกครั้ง โดยใช้ชื่อที่สั้นลง เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_4055: 'ขออภัย! แต่ MoneyGram ได้ปฏิเสธการโอนเงินนี้เพื่อปกป้องคุณ และระบบไม่สามารถดำเนินการโอนเงินนี้ให้เสร็จได้ เรายินดีช่วยเหลือคุณ',
  MONEYGRAM_7003: 'ขออภัย! แต่คุณได้โอนเงินครบวงเงินสูงสุดของ MoneyGram แล้ว และระบบไม่สามารถดำเนินการโอนเงินนี้ให้เสร็จได้ เรายินดีช่วยเหลือคุณ',
  FILE_NOT_ALLOWED: 'เกิดปัญหากับไฟล์',
  INVALID_SESSION_INFO: 'ขออภัย! มีบางสิ่งผิดปกติ กรุณาลองอีกครั้ง สิ่งนี้สำคัญมากสำหรับเรา',
  KYC_EXCEPTION: 'เราต้องการรายละเอียดเพิ่มเติมอีกเล็กน้อยเพื่อดำเนินการธุรกรรมของคุณให้เสร็จสมบูรณ์ โปรดติดต่อฝ่ายบริการลูกค้าของเราเพื่อขอความช่วยเหลือ ขออภัยในความไม่สะดวกและขอขอบคุณสำหรับความร่วมมือของคุณ',
  WALLET_FRIEND_ALREADY_ADDED: 'สวัสดีคะ, หมายเลขโทรศัพท์นี้ได้อยู่ในรายชื่อเพื่อนของคุณแล้ว',
  WALLET_FRIEND_BLOCKED: 'ขออภัย,เราไม่สามารถเพิ่มเพื่อนด้วยหมายเลขโทรศัพท์นี้ ในรายการเพื่อนของคุณได้. รบกวนติดต่อเราสำหรับรายละเอียดเพิ่มเติม',
  WALLET_FRIEND_NOT_REGISTERED: 'ขออภัย หมายเลขโทรศัพท์มือถือ {mobileNumber} ยังไม่อยู่ใน GMT ใช้ลิงค์ลงทะเบียนส่วนตัวของคุณเพื่อเชิญเพื่อนของคุณและรับเงินคืน!',
  marketingMessage: 'GMT ช่วยให้คุณสามารส่งเงินให้กับครอบครัวของคุณในต่างประเทศ และรับบคะแนนการจ่ายเงินหลายพันคะแนนโดยใช้หมายเลขอ้างอิง สำหรับการฝากเงินเข้าบัตรเครดิตในต่างประเทศโดยตรง หรือการฝากเงินเข้าบัญชีธนาคารในต่างประเทศโดยตรง',
  marketingMessage2: 'ง่าย รวดเร็ว และปลอดภัย สำหรับคุณเท่านั้น',
  YAAD_SARIG_3: 'ขออภัย บริษัทบัตรเครดิตของคุณไม่อนุมัติการโอน เราขอแนะนำให้ติดต่อบริษัทบัตรของท่าน เพื่อแก้ไขปัญหาแล้วลองใหม่อีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_4: 'ขออภัย บริษัทบัตรเครดิตของคุณไม่อนุมัติการโอน หรือไม่อนุมัติรายละเอียดบัตรที่คุณป้อน เราขอแนะนำให้คุณลองอีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_6: 'ขออภัย มีข้อผิดพลาดเกิดขึ้นกับรายละเอียดบัตรที่คุณป้อน เราขอแนะนำให้ลองใหม่อีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_26: 'ขออภัย บัตรดังกล่าวไม่ตรงกับหมายเลขบัตรประชาชนของคุณ คุณสามารถลองใหม่อีกครั้งโดยใช้บัตรที่ลงทะเบียนในชื่อของคุณ หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_141: 'ขออภัย ในขณะนี้เรารับเฉพาะบัตร Visa และ MasterCard เท่านั้น หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  'מצטערים, אך הגעת לסכום החודשי המקסימלי ב-GMT': {
    ' נשמח אם תצרו קשר כדי להסיר מגבלה זו': {
      ' אנחנו כאן לעזור': {
        '\n\n': 'เราขออภัย แต่บัตรเครดิตของคุณไม่รองรับการตรวจสอบความปลอดภัยที่บริษัทบัตรกำหนด คุณสามารถติดต่อผู้ให้บริการบัตรของคุณเพื่อขอความช่วยเหลือได้ หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
      },
    },
  },
  YAAD_SARIG_447: 'ขออภัย หมายเลขบัตรที่คุณป้อนไม่ถูกต้อง ขอแนะนำให้ลองใหม่อีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_553: 'ขออภัย มีปัญหาเกิดขึ้นกับบริการจากบริษัทบัตรเครดิต เราขอแนะนำให้ติดต่อบริษัทบัตรเพื่อแก้ไขปัญหาแล้วลองใหม่อีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_999: 'ขออภัย มีบางอย่างผิดพลาด เราขอแนะนำให้ลองอีกครั้งในภายหลัง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_3000: 'ขออภัย บริษัทบัตรเครดิตไม่อนุมัติการโอนของท่าน เราขอแนะนำให้ติดต่อบริษัทบัตรเพื่อแก้ไขปัญหาแล้วลองใหม่อีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_3003: 'ขออภัย บริษัทบัตรเครดิตไม่อนุมัติการโอนของท่าน เราขอแนะนำให้ติดต่อบริษัทบัตรเพื่อแก้ไขปัญหาแล้วลองใหม่อีกครั้ง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_9101: 'เราขออภัย แต่บัตรเครดิตของคุณไม่รองรับการตรวจสอบความปลอดภัยที่บริษัทบัตรกำหนด คุณสามารถติดต่อผู้ให้บริการบัตรของคุณเพื่อขอความช่วยเหลือได้ หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  YAAD_SARIG_GENERAL: 'ขออภัย มีบางอย่างผิดพลาดกับการชำระเงิน เราขอแนะนำให้ลองใหม่อีกครั้งในภายหลัง หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  INVALID_CARD: 'ขออภัย แต่บัตรที่คุณพยายามใช้ส่งไม่ได้รับการรองรับ คุณสามารถลองใหม่อีกครั้งโดยใช้บัตรอื่น เรายินดีให้ความช่วยเหลือหากมีคำถามใดๆ',
  INVALID_VERSION: 'ขออภัยในความขัดข้อง โปรดรีเฟรชหน้าเพจเพื่อดูข้อมูลอัปเดตล่าสุดจาก GMT',
  AMOUNT_LIMITED: 'ขออภัย จำนวนเงินที่คุณพยายามส่งเกินขีดจำกัดของบัตรผู้รับเงิน คุณสามารถลองใหม่อีกครั้งด้วยจำนวนเงินที่น้อยลง เรายินดีให้ความช่วยเหลือหากมีคำถามใดๆ',
  CONTACT_GENERAL: 'ขออภัย เรากำลังปรับปรุงเว็บไซต์เพื่อปรับปรุงบริการของเรา ขอแนะนำให้ลองอีกครั้งในภายหลัง หากคุณมีคำถามใดๆ เรายินดีช่วยเหลือ',
  BENEFICIARY_INVALID: 'ขออภัย! รายละเอียดของผู้รับผลประโยชน์ของการโอนเงินนี้ไม่ถูกต้อง คุณสามารถแก้ไข แล้วลองอีกครั้ง เรายินดีช่วยเหลือคุณ',
  CONTACT_DENIED: 'ขออภัย! แต่เราต้องปฏิเสธการโอนเงินนี้เพื่อปกป้องคุณ และเราไม่สามารถดำเนินการโอนเงินนี้ให้เสร็จได้ เรายินดีช่วยเหลือคุณ',
  CARD_DENIED: 'ขออภัย! เราไม่สามารถโอนเงินเข้าบัตรนี้ได้ เรายินดีช่วยเหลือคุณ',
  CARD_WRONG_DETAILS: 'ขออภัย! รายละเอียดของการโอนเงินนี้ไม่ถูกต้อง คุณสามารถแก้ไข แล้วลองอีกครั้ง เรายินดีช่วยเหลือคุณ',
  REGISTER_INFO_DETAILS_REQUIRED: 'เราต้องการรายละเอียดและเอกสารแสดงตัวตนของคุณ เพื่อส่งเงินไปต่างประเทศ',
  REGISTER_INFO_SEARCH_ZIP: 'คุณสามารถค้นหารหัสไปรษณีย์ของคุณได้ โดยกดที่ไอคอนแว่นขยาย',
  CARD_REJECTED: 'ขออภัย! เราไม่สามารถส่งเงินให้กับผู้รับผลประโยชน์ของคุณได้ในขณะนี้ กรุณาติดต่อเรา เพื่อขอความช่วยเหลือ',
  BLACKLIST: 'ขออภัย! เราไม่สามารถส่งเงินให้กับผู้รับผลประโยชน์ของคุณได้ในขณะนี้ กรุณาติดต่อเรา เพื่อขอความช่วยเหลือ',
  BLACKLIST_CARD: 'ขออภัย! เราไม่สามารถส่งเงินให้กับผู้รับผลประโยชน์ของคุณได้ในขณะนี้ กรุณาติดต่อเรา เพื่อขอความช่วยเหลือ',
  COMPANY_IDENTIFICATION: 'ขออภัย ดูเหมือนว่าคุณพยายามลงทะเบียนด้วยหมายเลขธุรกิจ คุณสามารถลงทะเบียนด้วยหมายเลขประจำตัวของคุณ หากคุณมีคำถามใดๆ เราพร้อมช่วยเหลือคุณ',
  CUSTOMER_MONTHLY_LIMIT: 'ขออภัย คุณถึงขีดจำกัดการโอนรายเดือนกับ GMT แล้ว โปรดติดต่อเราเพื่อยกเลิกขีดจำกัดนี้ เราพร้อมให้ความช่วยเหลือ',
  CORRESPONDENT_EXCEPTION: 'ขออภัย เราพบข้อผิดพลาดขณะพยายามโอนเงินของคุณไปต่างประเทศ โปรดติดต่อเรา เราจะตรวจสอบและให้ความช่วยเหลือคุณด้วยความยินดี',
  TEMPORARY_ERROR: 'ขออภัย ระบบกำลังได้รับการอัปเดตในขณะนี้ ขอแนะนำให้ลองใหม่อีกครั้งในภายหลัง เรายินดีให้ความช่วยเหลือหากมีคำถามใดๆ',
  BAD_REQUEST: 'ขออภัย มีบางอย่างผิดพลาด เราขอแนะนำให้ลองใหม่อีกครั้งในภายหลัง เรายินดีให้ความช่วยเหลือหากมีคำถามใดๆ',
  MAX_EXCEPTION: 'ขออภัย มีบางอย่างผิดพลาด เราขอแนะนำให้ลองใหม่อีกครั้งในภายหลัง เรายินดีให้ความช่วยเหลือหากมีคำถามใดๆ',
  SERVER_ERROR: 'ขออภัย ระบบกำลังมีการอัปเดตอยู่ในขณะนี้ ขอแนะนำให้ลองใหม่อีกครั้งในภายหลัง เรายินดีให้ความช่วยเหลือหากมีคำถามใดๆ',
  CUSTOMER_DAILY_LIMIT_EXCEEDED: 'เพื่อปกป้องบัญชีของคุณ, เราจึงจำกัดจำนวนธุรกรรมการโอนผ่านบัตรต่อวัน. หากต้องการความช่วยเหลือ? โปรดติดต่อฝ่ายบริการลูกค้าของเรา.',
  NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: 'เพื่อปกป้องบัญชีของคุณ, เราจึงได้จำกัดจำนวนเงินในการทำธุรกรรมการชำระเงินด้วยบัตร. หากท่านต้องการความช่วยเหลือ? โปรดติดต่อฝ่ายบริการลูกค้าของเรา.',
  NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: 'เพื่อปกป้องบัญชีของคุณ, เราจึงจำกัดยอดรวมสำหรับการชำระเงินผ่านบัตร. หากต้องการความช่วยเหลือ? โปรดติดต่อฝ่ายบริการลูกค้าของเรา.',
  NEW_BENEFICIARY_LIMIT_EXCEEDED: 'เพื่อปกป้องบัญชีของคุณ, เราจึงจำกัดจำนวนการโอนไปยังบัตรใหม่ในต่างประเทศ. หากต้องการความช่วยเหลือ? โปรดติดต่อฝ่ายบริการลูกค้าของเรา.',
  NEW_BENEFICIARY_AMOUNT_EXCEEDED: 'เพื่อปกป้องบัญชีของคุณ, เราจึงจำกัดจำนวนการทำธุรกรรมไปยังบัตรใหม่ในต่างประเทศ. หากต้องการความช่วยเหลือ? โปรดติดต่อฝ่ายบริการลูกค้าของเรา.',
  BENEFICIARY_CARD_BLOCKED: 'This card number or the bank are not supported by the service, you can try to send to a different card',
  ERROR_NEGATIVE: 'ขออภัย คุณไม่สามารถทำธุรกรรมในจำนวนเงินที่ติดลบหรือน้อยเกินไป โปรดระบุยอดจำนวนเงินไหม่',
  E_DECIMAL: 'ขอเฉพาะตัวเลขที่ปัดเศษ',
  E_INVALID_zipCode: 'รหัสไปรษณีย์ไม่ถูกต้อง',
  E_LEMGTH: {
    '1': 'ตัวเลข',
    '2': 'หลักเท่านั้น',
  },
  E_MAX_VALUE: 'คุณป้อนค่าเกินขีดจำกัดสูงสุดแล้ว',
  E_MAX_CHAR: {
    '1': 'ความยาวสูงสุด',
    '2': 'อักขระ',
  },
  E_MIN_CHAR: {
    '1': 'ความยาวอย่างน้อย',
    '2': 'อักขระ',
  },
  E_MIN_passport: 'ต้องยาวเกิน 5 อักขระ',
  E_NUMERIC: 'ใช้แค่ตัวเลขเท่านั้น',
  E_REGEX: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
  E_REGEX_email: 'รูปแบบอีเมลไม่ถูกต้อง',
  E_REGEX_english: 'เฉพาะภาษาอังกฤษเท่านั้น',
  E_REGEX_mobileNumber: 'กรุณาป้อนหมายเลขโทรศัพท์มือถืออิสราเอลที่ถูกต้อง ด้วยตัวเลข 10 หลัก',
  E_REGEX_israeliId: 'ไม่ใช่เลขประจำตัวประชาชนของอิสราเอลที่ถูกต้อง',
  E_REGEX_passport: 'เฉพาะตัวเลขและตัวอักษรเท่านั้น',
  E_REGEX_birthDateAge: 'สำหรับผู้ที่มีอายุ 18-85 ปี',
  E_REGEX_zipCode: 'รหัสไปรษณีย์ต้องเป็นตัวเลข 7 หลัก',
  E_REQUIRED: 'ช่องที่ต้องระบุ',
  E_REQUIRED_amount: 'จำนวนเงินเท่าใด?',
  E_REQUIRED_currency: 'สกุลเงินอะไร?',
  E_REQUIRED_destinationCountry: 'ปลายทางคือที่ใด?',
  E_REQUIRED_agreement: 'กรุณาอ่านและยอมรับข้อกำหนดในการใช้บริการ',
  STATUS_NOT_FOUND: 'ไม่พบ',
  STATUS_CONFIRMED: 'Confirmed',
  STATUS_READY_FOR_PAYMENT: 'พร้อมชำระเงินแล้ว',
  STATUS_PAID: 'ชำระเงินแล้ว',
  STATUS_SENT_TO_BANK: 'ส่งไปที่ธนาคารแล้ว',
  STATUS_BEING_CANCEL: 'Being canceled',
  STATUS_READY_TO_CANCEL: 'Ready for Cancelation',
  STATUS_CANCELED: 'ยกเลิกแล้ว',
  STATUS_PREPARED: 'Prepared',
  STATUS_FAILED: 'Failed',
  STATUS_RETURNED: 'Returned',
  STATUS_ACCEPTED: 'Accepted',
  STATUS_STAGED: 'STAGED',
  STATUS_INCOMPLETE: 'incomplete',
  STATUS_IN_PROCESS: 'Being paid out',
  STATUS_SWIFT_TRANSMITTED: 'Transmitted',
  STATUS_SWIFT_APPROVED: 'Approved',
  STATUS_REJECTED: 'Rejected',
  STATUS_EXPIRED: 'Expired',
  STATUS_CLOSED: 'Closed',
  STATUS_SUBMITTED: 'Submitted',
  DESC_CASHBACK: 'เครดิตเงินคืน',
  DESC_CASHBACK_WELCOME: 'ยินดีต้อนรับ! รับเครดิตเงินคืน',
  DESC_CASHBACK_BIRTHDAY: 'สุขสันต์วันเกิด! รับเครดิตเงินคืน',
  DESC_CASHBACK_ONETIME: 'รับเครดิตเงินคืนเป็นของขวัญ',
  DESC_WALLET: 'ฝากเงินเข้ากระเป๋า',
  DESC_MAX_TOPUP: 'การเติมเงินในบัตร',
  DESC_MAX_PURCHASE: 'การซื้อ และการเติมเงินในบัตร',
  DESC_MAX_ORDER: 'การสั่งซื้อบัตรเติมเงิน',
  DESC_CONVERSION: 'การแปลงเงิน',
  DESC_019MOBILE: '019 mobile',
  DESC_REWIRE: 'Rewire',
  DESC_DEPOSIT_3MONTHS: 'แผนเงินฝากสำหรับ 3 เดือน',
  DESC_DEPOSIT_6MONTHS: 'แผนเงินฝากสำหรับ 6 เดือน',
  DESC_DEPOSIT_WITHDRAW: 'ถอนเงินออกจากบัญชีเงินฝาก',
  DESC_DEPOSIT_INTEREST_PAYMENT: 'สินเชื่อดอกเบี้ยสะสม',
  DEPOSIT_IN: 'โอนเข้าบัญชีเงินฝาก',
  DEPOSIT_OUT: 'การถอนเงินออกจากบัญชีเงินฝาก',
  DEPOSIT_INTEREST: 'สินเชื่อดอกเบี้ยสะสม',
  NAME_ALIPAY: 'ALIPAY',
  NAME_UNIONPAY: 'UNIONPAY',
  NAME_WECHATPAY: 'WECHATPAY',
  NAME_UPI: 'UPI',
  validation: {
    mock: '',
    beneficiary: {
      invalid: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
      maskedCardNumber: {
        invalidCountry: 'บัตรอาจไม่ตรงกับประเทศปลายทาง',
        tranglo: 'ขออภัย รายละเอียดของผู้รับผลประโยชน์ที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบ แก้ไข และลองอีกครั้ง',
      },
      accountIdentifier: {
        invalid: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
        tranglo: 'ขออภัย รายละเอียดของผู้รับผลประโยชน์ที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบ แก้ไข และลองอีกครั้ง',
      },
      walletIdentifier: {
        invalid: 'ป้อนรูปแบบในช่องนี้ไม่ถูกต้อง',
        tranglo: 'ขออภัย รายละเอียดของผู้รับผลประโยชน์ที่คุณป้อนไม่ถูกต้อง โปรดตรวจสอบ แก้ไข และลองอีกครั้ง',
      },
      phoneWallet: {
        unavailable: 'ขออภัย, บัญชีนี้ใช้งานไม่ได้และไม่สามารถรับเงินได้. โปรดตรวจสอบบัญชีก่อนลองอีกครั้ง.',
        mismatch: 'ขออภัย, ชื่อผู้รับผลประโยชน์และบัญชีไม่ตรงกัน. คุณสามารถแก้ไขรายละเอียดและลองอีกครั้ง.',
        invalidAccount: 'ขออภัย, บัญชีนี้ไม่ถูกต้อง. โปรดตรวจสอบบัญชีก่อนลองไหม่อีกครั้ง.',
        barred: 'ขออภัย, บัญชีนี้ถูกล็อค. โปรดตรวจสอบบัญชีก่อนลองไหม่อีกครั้ง.',
      },
      phoneNumber: {
        format: {
          passParam: 'use correct format: {format}',
        },
      },
    },
  },
  NEW_BENEFICIARY_EXCEEDED: 'เพื่อปกป้องบัญชีของคุณ, เราได้จำกัดการทำธุรกรรมไปยังผู้รับผลประโยชน์รายใหม่ของท่าน. หากต้องการความช่วยเหลือ? โปรดติดต่อฝ่ายบริการลูกค้าของเรา.',
  EMAIL_ALREADY_EXISTS: 'อีเมลนี้ได้ถูกใช้งานไปแล้ว กรุณาป้อนอีเมลอื่นเพื่อดำเนินการต่อ',
  EMAIL_ALREADY_EXISTS_UPDATE: 'ที่อยู่อีเมลที่คุณป้อนเชื่อมโยงกับบัญชีอื่นแล้วและไม่สามารถใช้งานซ้ำได้. คุณสามารถป้อนที่อยู่อีเมลอื่นๆหรือกดข้ามขั้นตอนนี้ไป.',
  paymentStatuses: {
    titles: {
      CASH_TRANSFER: 'เยี่ยมมาก! การโอนของคุณได้มีการดำเนินการแล้ว',
      BANK_TRANSFER: 'เยี่ยมมาก! การโอนของคุณได้มีการดำเนินการแล้ว',
      UPI_BANK_TRANSFER: 'เยี่ยมมาก! การโอนของคุณได้มีการดำเนินการแล้ว',
      CARD_DEPOSIT: 'เยี่ยมมาก! การโอนของคุณได้มีการดำเนินการแล้ว',
      WALLET_TRANSFER: 'เยี่ยมมาก! การโอนของคุณได้มีการดำเนินการแล้ว',
      WALLET_DEPOSIT: 'เยี่ยมมาก! การฝากเงินสำเร็จแล้ว',
      WALLET_TO_WALLET: 'เยี่ยมมาก! การโอนของคุณได้มีการดำเนินการแล้ว',
    },
    messages: {
      CASH_TRANSFER: 'คุณได้โอนเงินจำนวน {amount} {currency} ให้กับ {beneficiary} เพื่อรับเงินสดปลายทางที่ {destination} สำเร็จแล้ว การยืนยันการโอนเงินจะถูกส่งไปยังคุณในไม่ช้านี้ผ่าน SMS และอีเมล',
      BANK_TRANSFER: 'คุณได้โอนเงินจำนวน {amount} {currency}  ไปยังบัญชีของ {beneficiary}  ใน {destination} สำเร็จแล้ว เราจะส่งคำยืนยันการโอนเงินให้คุณทาง SMS และอีเมลในเร็วๆ นี้',
      UPI_BANK_TRANSFER: 'คุณได้โอนเงินจำนวน {amount} {currency}  ไปยังบัญชีธนาคาร {beneficiary} สำเร็จแล้วด้วย UPI ใน {destination} คุณจะได้รับคำยืนยันการโอนเงินผ่าน SMS และอีเมลในเร็วๆ นี้',
      CARD_DEPOSIT: 'คุณได้โอนเงินจำนวน {amount} {currency} ให้กับ {beneficiary} ไปยังบัตรใน {destination} สำเร็จแล้ว การยืนยันการโอนเงินจะถูกส่งถึงคุณในไม่ช้านี้ผ่านทาง SMS และอีเมล',
      WALLET_TRANSFER: 'คุณได้โอนเงินจำนวน {amount} {currency} ไปยัง {beneficiary} ไปยังวอลเลทใน {destination} สำเร็จแล้ว การยืนยันการโอนเงินจะถูกส่งถึงคุณในไม่ช้านี้ผ่านทาง SMS และอีเมล',
      WALLET_DEPOSIT: 'คุณได้ทำการฝากเงินจำนวน {amount} {currency} เข้าบัญชี GMT ของคุณเรียบร้อยแล้ว การยืนยันการฝากเงินจะถูกส่งไปยังคุณในไม่ช้านี้ผ่าน SMS และอีเมล',
      WALLET_TO_WALLET: 'คุณได้โอนเงินจำนวน {amount} {currency} ให้กับ {beneficiary} ไปยังบัญชี GMT ของพวกเขาเรียบร้อยแล้ว การยืนยันการโอนเงินจะถูกส่งถึงคุณในไม่ช้านี้ผ่านทาง SMS และอีเมล',
    },
    buttons: {
      buttonAction: 'ไปที่ประวัติการทำธุรกรรม',
      buttonLink: 'กลับไปยังหน้าหลัก',
    },
  },
  errors: {
    default: {
      title: 'มีบางอย่างผิดพลาด',
      buttonAction: 'ลองไหม่อีกครั้ง',
      buttonLink: 'ติดต่อเรา',
    },
    service: {
      title: 'มีบางอย่างผิดพลาด',
      buttonAction: 'ติดต่อเรา',
      buttonLink: 'กลับไปยังหน้าหลัก',
    },
    login: {
      title: 'ยินดีต้อนรับกลับมา',
      buttonAction: 'เข้าสู่ระบบอีกครั้ง',
    },
    registration: {
      title: 'ยินดีต้อนรับกลับมา',
      buttonAction: 'ติดต่อเรา',
    },
    card_error: {
      title: 'ปัญหาการชำระเงิน',
      buttonAction: 'ลองไหม่อีกครั้ง',
      buttonLink: 'ติดต่อเรา',
    },
    kyc: {
      title: 'ข้อมูลเพิ่มเติมที่ต้องการ',
      action: 'ติดต่อเรา',
    },
    nonefound: {
      title: 'ต้องการส่งเงินใช่หรือไม่? มาแก้ไขปัญหานี้กันเถอะ!',
      buttonAction: 'ติดต่อเรา',
      buttonLink: 'เลือกประเทศอื่น',
    },
  },
  DEFAULT: '',
  paymentPlan: 'เลือกแผนการชำระเงินของคุณ',
  by: 'ด้วย',
  NO_DELIVERY_OPTIONS: 'ดูเหมือนว่าขณะนี้เราไม่สามารถดำเนินการโอนไปยัง {{ countryName }} ได้.\nแต่ไม่ต้องกังวล เรายินดีช่วยเหลือ!\nทีมสนับสนุนของเราสามารถตรวจสอบตัวเลือกอื่นๆ หรือแจ้งให้คุณทราบเมื่อบริการพร้อมใช้งานอีกครั้ง.',
  transactionReason: {
    personalUse: 'สนับสนุน ญาติใกล้ชิด',
  },
  deleteBeneficiary: 'ลบผู้รับผลประโยชน์ท่านนี้ \n',
  keepBeneficiary: 'เก็บผู้รับผลประโยชน์ท่านนี้ไว้',
  minAmountToCurrencyError: 'ตัวเลือกการส่งไปยังฟิลิปปินส์เริ่มต้นที่ {minAmountInToCurrency}{currency}',
  maxAmountToCurrencyError: 'ตัวเลือกการส่งไปยังฟิลิปปินส์มีขีดจำกัดสูงสุดอยู่ที่ {maxAmountInToCurrency}{currency}',
  paymentRequestTitle: 'ประวัติการชำระเงิน',
  paymentRequest: {
    title: 'คำขอการชำระเงิน',
    yourAccount: 'บัญชีของคุณ',
    transferType: 'ประเภทการโอน',
    insufficientBalance: 'ยอดเงินคงเหลือไม่เพียงพอ',
    action: 'กลับไปที่รายการคำขอ',
    link: 'ปฏิเสธการชำระเงิน',
    validUntill: 'ใช้ได้ถึงวันที่',
    checkDetails: 'ตรวจสอบรายละเอียดก่อนยืนยันการชำระเงิน',
    action2: 'ยืนยันการชำระเงิน',
    rejectedInfo: 'การชำระเงินถูกปฏิเสธ',
    completedInfo: 'การชำระเงินเสร็จสมบูรณ์แล้ว',
    paymentRequestExplain: 'การโอนเงินจะถูกดำเนินการตามรายละเอียดที่ผู้เริ่มดำเนินการชำระเงิน. ระบบจะไม่ตรวจสอบความตรงกันระหว่างชื่อเจ้าของบัญชีกับหมายเลขบัญชี, ดังนั้นขอแนะนำให้ท่านทำการตรวจสอบความถูกต้องของรายละเอียดก่อนดำเนินการทุกครั้ง.\nเมื่อดำเนินการแล้ว, จะไม่สามารถยกเลิกการโอนเงินได้.\nข้อความนี้ไม่ถือเป็นการยืนยันหรืออนุมัติธุรกรรม.\nคำขอชำระเงินจะถูกดำเนินการทันทีโดยมีวันที่คิดมูลค่าเป็นวันนี้ และคาดว่าจะโอนเข้าบัญชีของผู้รับเงินตามวันนั้น.\nในบางกรณี ธุรกรรมอาจต้องได้รับการอนุมัติจากฝ่ายประมวลผลก่อนดำเนินการ ในกรณีดังกล่าว วันที่เดบิตและเครดิตอาจล่าช้ากว่าที่ระบุไว้ข้างต้น',
  },
  paymentRequests: {
    link: 'คำขอการชำระเงิน',
  },
  recentRequests: 'คำขอล่าสุด',
}



