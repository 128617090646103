export const cn = {
    GMTbranches: '<a href=""https://gmtonline.co.il/en/locating-branches/"" style=""color:#d4c641;text-decoration: underline;"">在任一GMT网点</a>',
    accessibleBranch: '开放的网点',
    myCard: 'GMT预付卡',
    myDateBirthday: '日/月/年',
    accountNumber: '收款人账户',
    activityTime: '活动时间',
    actualRate1: '线上',
    and: '和 ',
    appDownload: '下载我们的应用程序',
    appDownloadBtn: '直接下载 \n 到您的手机',
    actualRate2: '当前汇率是',
    addAddress: '添加地址',
    additionalPhone: '额外的电话号码',
    amount: '数额',
    amountIn: '谢克尔金额',
    recipientAmount: '收款人获得',
    amountReceived: '将收到的数额',
    amountTogether: '合计金额',
    anotherSurname: '额外的姓氏',
    answerQuestion: '与此同时，您可能可以找到问题的答案',
    approvalTransaction: '一切顺利！ 扣款成功，转账已批准。 在接下来的几分钟内，您将收到一条交易确认短信',
    maxCardLoadTransaction: '太棒了！充值成功，您的 GMT 卡已充值成功。',
    approvedPolicies: '我已阅读并同意相关条款',
    arrivalBranch: '在GMT网点的现金',
    arrivalBranchMsg1: '我们现在会给您一个付款用的订单号。该订单号的有效时间为24小时。',
    arrivalBranchMsg2: '并会按照线下门店汇率支付',
    bankName: '银行名称',
    bank: '银行',
    bankAccount: '银行账户',
    bankCode: '银行账号',
    canceled: '已取消',
    cardNumber: '卡号',
    cashioPageTtile: '外币兑换服务（包括快送或机场取钱）',
    depositCardNumber: '收款人信用卡卡号',
    beneficiary: {
      firstName: '名字的汉语拼音',
      beneficiary: '收款人',
      countryIso3: '.',
      lastName: '姓氏的汉语拼音',
      phoneNumber: '收款人电话号码',
      phoneNumberShort: '电话号码',
      country: '目的地',
      transferMethod: '汇款方式',
      areaCode: '地区代码',
      maskedCardNumber: '收款人信用卡卡号',
      maskedCardNumber_unionPay: '收款人银联卡卡号',
      address: '地址',
      city: '城市',
      province: '省/州',
      zipCode: '邮政编码',
      bankName: '银行名称',
      cashBankName: '提现点',
      walletBankName: '钱包名字',
      swiftCode: '银行识别码SWIFT/BIC',
      bankCity: '银行所在城市',
      bankAddress: '银行地址',
      branch: '银行支行',
      iban: 'IBAN国际银行账户号码',
      accountIdentifier: '账号',
      walletIdentifier: '钱包号码',
      walletPayment: '账号支付',
      alipayWallet: '支付宝账号',
      abaRoutingNumber: '银行识别代码/美国银行协会ABA识别代码',
      ifsc: 'IFSC',
      relationshipWithCustomer: '您与收款人的关系是？',
      identification: '收款人身份证号',
      upi: 'UPI ID 或号码',
      upi_IND: 'UPI ID 或号码',
      details: {
        BANK_TRANSFER: '收款人银行账户详情',
        CASH_TRANSFER: '现金汇款详情',
        WALLET_DEPOSIT: '充值钱包',
        WALLET_FROM_WALLET: '收款人钱包详情',
        WALLET_TO_WALLET: '收款人钱包详情',
        WALLET_TRANSFER: '收款人钱包详情',
        CARD_DEPOSIT: '收款人银行卡详情',
        UPI_BANK_TRANSFER: '收款人银行账户详情',
      },
    },
    transfer: {
      deliveryOptions: {
        ALL: '全部',
        CASH_TRANSFER: '现金汇款',
        BANK_TRANSFER: '银行账户汇款',
        WALLET_DEPOSIT: '钱包充值',
        WALLET_FROM_WALLET: '钱包汇款',
        WALLET_TO_WALLET: '钱包汇款',
        WALLET_TRANSFER: '钱包汇款',
        CARD_DEPOSIT: '银行卡汇款',
        UPI_BANK_TRANSFER: 'UPI银行账户汇款',
      },
      errors: {
        amountExceeds: '/',
        corrspondentIsLocked: '/',
      },
      deliveryOptionsInfo: {
        iSend: '我发送',
        iPay: '我支付',
        theyRecieve: '他们接收',
      },
      titles: {
        chooseAmount: '选择金额',
        chooseBeneficiary: '选择收款人',
        chooseDestination: '选择目的地',
        recentDestinations: '您最近的目的地',
        allDestinations: '所有目的地',
        popularDestinations: '热门目的地',
        sendMoney: '发送资金',
        nothingFound: '未找到',
        typedCorrectly: '请确保您正确输入了国家名称。',
        transferSummary: '.',
      },
      buttons: {
        select: '选择',
        addNewBeneficiary: '添加新收款人',
        coupon: {
          iHaveACoupon: '我有优惠券',
          couponApplied: '优惠券"{couponNumber}"已应用！',
        },
        back: '返回',
      },
      paymentMethods: {
        titles: {
          payingWith: '支付{amount} {currency} 通过:',
          paying: '支付',
          with: '通过',
        },
        subtitles: {
          walletBalance: '您的钱包余额是',
          creditCard: '我们支持VISA卡和万事达卡',
          bankTransfer: '直接转账到银行账户',
        },
      },
      countrySearchPlaceholder: '搜索目的地或货币',
    },
    relationshipWithCustomer: {
      SELF: '我本人',
      CHILD: '我的孩子',
      PARENT: '我的父母',
      SPOUSE: '我的配偶',
      SIBLING: '我的兄弟姐妹',
      EX_SPOUSE: '我的前任配偶',
    },
    moreInformation: '更多信息',
    beneficiaryRemoveDialogue: '确认需要从收款人列表中删除 {beneficiary} 吗？',
    beneficiaryRemoveDialogue2: '该操作将不会影响已存在的转账',
    billingSuccessful: '太棒了！ 您的款项已发送。',
    billingSuccessfulCardDeposit: '一切顺利！ 扣款成功，您的转账已被批准。',
    billingSuccessfulBankTransfer: '一切顺利！ 扣款成功，您的转账已被批准。',
    billingSuccessfulBankTransferISR: '一切顺利！ 扣款成功，您的转账已被批准。',
    cardUnBlockModalMessage: {
      '1': '按下“激活”按钮后，您的卡将被激活以进行新的支付交易和现金提取',
      '2': '请仅在您拥有该卡时才进行该操作',
    },
    cardBlockModalMessage: {
      '1': '在您按下“阻止”按钮后，我们将暂时阻止您的卡进行新的交易或提取现金。',
      '2': '您的余额已在卡上保存！',
      '3': '如果您找到了该卡，您可以在这里重新激活它，或者您可以要求我们永久将其冻结并为您补发一张新卡',
    },
    blocked: '受限',
    blog: '博客',
    bntClear: '取消',
    bntContinued: '下一步',
    bntContinue: '下一步',
    bntImgDelete: '重新上传',
    bntSave: '保存',
    branchNumber: '支行',
    checkStatus: '查看状态',
    status: '查看状态',
    btnAccept: '接受',
    btnCheckStatusCheck: '付款就绪',
    btnCheckStatusNoCheck: '无法付款',
    btnOk: '同意',
    btnDone: '确认',
    btnDelete: '删除',
    btnFinish: '结束',
    btnClose: '关闭',
    new: '新',
    newCustomer_section: '对金钱运用的完美选择',
    quick_guide: '快速指南',
    invite_friends: '邀请朋友加入GMT',
    cash_back_desc: '返现',
    send_money_home: '汇款回家',
    send_money_worldwide: '向世界各地汇款',
    newCustomer_title: '汇款和\先进的金融服务',
    quickSend: '快速汇款',
    quickActions: '快速行动',
    quickSendActionsParagraph: '您最近的收款人已存在并准备好再次发送',
    quickActionsAdd: '添加',
    payAnywhere: '预付卡',
    carousel_slide_one_text: '获取您的预付卡并更好地控制您的金钱',
    carousel_slide_one_button: '了解更多',
    seeAll: '查看全部',
    cardBalance: '卡片余额',
    getCashback: '获取',
    cashBack_info: '返现',
    justReminder: '温馨提示',
    home: '首页',
    menu: '菜单',
    activity: '活动',
    cards: '卡片',
    greeting_morning: '早上好',
    greeting_afternoon: '下午好',
    greeting_evening: '晚上好',
    greeting_night: '晚安',
    paidBy: '支付方式',
    incoming: '收款',
    outgoing: '汇款',
    CONVERSION: '汇率',
    PAYMENT: '款项',
    CASH_BACK: '返现',
    cardActions: '卡的操作',
    actions: '菜单',
    activateCard: '激活您的新卡',
    activateCardAction: '激活卡片',
    enterLastDigits: '输入卡号最后 4 位数字进行激活',
    searchTransactions: '搜寻款项',
    filterTransactions: '款项筛选',
    filterTransactionsButton: '筛选',
    openWallet: '免费开设钱包账户',
    pleaseSelect: '选择一个或多个',
    date: '日期',
    from_date: '从日期',
    to_date: '到日期',
    choose_date: '选择日期',
    showResults: '显示结果',
    clearFilter: '清除筛选',
    today: '今天',
    contact_us_paragraph: '"我们坚信良好的沟通，因此请随时通过此处列出的任何方式与我们联系。\n我们的营业时间：\n周日至周四：上午 08:30 - 下午 05:00，\n周五：上午 08:30 - 下午 02:00。""',
    call_us: '联系我们',
    contact_us_or: '或',
    lastTransactions: '卡最近交易记录',
    transactionClasses: {
      BANK_TRANSFER: '银行账户汇款',
      CASH_TRANSFER: '现金汇款',
      CARD_DEPOSIT: '银行卡汇款',
      PAYMENT: '收到款项',
      CONVERSION: '货币换算',
      CASH_BACK: '返现',
      WALLET_DEPOSIT: '存款到我的GMT账户',
      WALLET_TRANSFER: '钱包账户转账',
      WALLET_TO_WALLET: 'GMT账户转账',
      WALLET_FROM_WALLET: '收到GMT账户款项',
      UPI_BANK_TRANSFER: 'UPI银行账户转账',
    },
    paymentTypes: {
      CASH: '现金',
      CHECK: '支票',
      BANK_TRANSFER: '银行转账',
      CREDIT_CARD: '卡',
      CREDITTING: '卡',
      OUTER_CONVERSION: '现金',
      CASHBACK: '返现',
      CUSTOMER_DEPOSIT: '第三方结余',
      WALLET: '钱包账户',
      CHANGE: '零钱',
      AIRPORT: '机场',
    },
    CARD: '卡',
    CASH: '现金',
    AIRPORT: '机场',
    CHECK: '支票',
    CHANGE: '零钱',
    CREDIT_CARD: '卡',
    CREDITTING: '卡',
    OUTER_CONVERSION: '现金',
    CASHBACK: '返现',
    CUSTOMER_DEPOSIT: '第三方结余',
    WALLET: '钱包账户',
    changeLang: '更改语言',
    nothing_found: '没有找到任何交易',
    nothing_found_text: '只要您进行了一些交易，它就会显示在此处',
    max_transaction_description: '交易明细',
    creditCardReferenceNumber: '卡公司参考',
    checkNumber: '支票号码',
    referenceNumber: '参考号',
    flightDate: '航班日期',
    deposit_to_wallet: '充值钱包',
    btnFinishREgistration: '补全注册',
    btnUploadDocuments: '添加文档',
    btnRegistration: '创建账户',
    btnReturn: '返回',
    btnSend: '发送',
    btnSignIn: '通过短信发给我验证码',
    btnBiometricSignInFaceId: '面部识别登录',
    btnBiometricSignInTouchId: '指纹识别登录',
    btnToMyGmt: '到GMT',
    btnTransfer: '转账',
    btnTransferFooter: '转账',
    btnEnter: '让我们开始吧',
    btnSearch: '搜索',
    business: '职业',
    biometricsHeader: '生物识别',
    biometricSectionApprove: '我批准T＆C',
    biometricSectionSignIn: '要完成生物识别的注册，请登录帐户。',
    biometricToggler: '生物识别登录 ',
    biometric_modal_text: '您要取消快速生物识别登录吗？',
    biometric_legal_clause_1: '当您进入系统的用户名关联的所有帐户时，生物识别验证（使用指纹和/或面部识别）将代替输入密码。',
    biometric_legal_clause_2: '公司不储存用户的面部识别,该验证仅于用户移动设备上的生物识别定义。',
    biometric_legal_clause_3: '该服务的注册授权输入网站/应用程序的个人声明，他们应是具有生物识别设备的唯一用户。 如果在设备上更新了几个人的生物识别，则不应使用它来注册该服务。',
    biometric_legal_clause_4: '请求在网站/应用程序上执行的信息和/或执行操作将被视为授权给公司执行操作，包括违反第三方执行上面第3节的操作。',
    biometric_legal_clause_5: '如果丢失和/或被盗移动设备，则必须通过电话与客户服务联系立即取消该服务的注册。',
    biometric_legal_clause_6: '您可以在应用程序中或与客户服务联系随时断开服务。',
    biometric_login_failed: '抱歉验证失败',
    biometric_public_key_expired: '抱歉给您带来不便！ 我们禁用了您的生物识别登录，这是为了保障您的帐户安全。 请再次重新注册生物识别登录。 谢谢您！',
    biometric_unregister_modal_title: '关闭生物识别登录',
    calculatedBy: '换算成以{curr}计算',
    captionAgreement: '我已阅读并同意',
    captionAgreementLink: '条款',
    captionCustomerService1: '请联系我们的客服，我们将竭诚为您服务。',
    chatRepresentative: '和我们聊聊',
    qrCodeDownload: '下载二维码',
    scanExplanation: '请扫描此二维码即可在微信找到我们',
    cashBack: '我的返现',
    cashBackReceive: '得到返现',
    cashBackLabel: '返现',
    cashBackBalance1: '嘿，您有返现!',
    cashBackBalance2: '您的余额为:',
    cashBackCredit: '兑换返现礼金',
    cashBackModalMessage: '惊喜！',
    cashBackModalMessage2: '您有',
    cashBackModalMessage3: '来自我们的',
    cashBackModalMessage4: '您可以现在使用并立即省钱',
    cashBackModalMessageConfirm: '太好了！立刻使用',
    cashBackModalMessageCancel: '我将继续收集',
    cashBackPageTitlePlus: '真棒！您已收到',
    cashBackPageTitleZero: '返现',
    cashBackPageMessagePlus: '如何得到更多返现？',
    cashBackPageMessageZero: '如何获得返现？',
    cashBackPageMessage2: '阅读政策及条款全文',
    cashBackPageMessage3: '一旦您达到',
    cashBackPageMessage4: '美元/英镑 您可以开始使用或继续收存',
    cashBackPageFotter: '您将收到转账同等货币的返现',
    cashBackPageFotter2: '您可以在GMT或任意GMT网点（即将开放）使用您的返现',
    cashBackPageFotter3: '如果您超过六个月没有访问我们，您的返现礼金将被清空。',
    cashBackPageFotter4: '请阅读政策及条款全文',
    cashBackUseNowOrLater: '您现在可以使用它们，也可以继续保留以便在将来的交易中使用。',
    youHaveCashBack: '您有 {cashBackInfo} 返现待使用。',
    tags: {
      popular: '经常使用',
      recentlyUsed: '最近使用',
      unavailable: '不可用',
      amountExceeded: '无效金额',
    },
    cardMargketing: {
      title: '预付卡与 {max}  合作',
      subtitle: '我们很高兴为您提供无需银行账户即可掌控开支的便捷方式！',
      info1: {
        title: '卡片权益',
        con1: '在以色列、海外或线上购买',
        con2: '在以色列或海外的任意ATM取钱',
      },
      info2: {
        title: '您可以',
        con1: '查看您的卡片余额',
        con2: '追踪购买',
        con3: '恢复您的Pin密码',
        con4: '限制一张丢失或被偷窃的卡片',
      },
      footer: {
        '1': '新卡可在GMT网点获取',
        '2': '欲了解更多详情和订购卡片',
      },
      btn: '向我们发送Whatsapp消息',
    },
    cardActionsModalTitle: '卡片操作',
    cardRoute: '预付卡',
    cardHystory: '过往交易',
    cardBlock: '冻结银行卡',
    cardBlockBtn: '阻止',
    cardUnBlock: '激活已受限卡片',
    cardUnBlockBtn: '激活',
    cardBlockConfirm: {
      '1': '我们为您限制了该卡，您可在网站上重新激活。',
      '2': '如遇任何问题，请及时与我们联系。',
      '3': '联系我们',
    },
    walletOrder: {
      deposited: '存入',
      verification: '这是您的代码',
      getVerification: '获取您的代码',
      showTo: '出示给工作人员',
      expiry: '代码将在',
      minutes: '分钟后过期',
      returnHere: '您可以随时从返回这里',
      mainPage: '主页',
      cancel: '取消此订单',
      hooray: '万岁',
      close: '关闭',
      depositedToYourWallet: '我们将',
      depositedToYourWallet1: '充值到您的钱包',
      cancelModalTitle: '您想取消这个订单吗？',
      cancelModalText: '您总是可以重新操作一次',
      goToActiveOrder: '您的存款代码',
      depositCash: '存现金到您的钱包',
      btnOk: '批准',
      btnReturn: '返回',
      amount: '数额',
      summaryOfDetails: '转账详情摘要',
      transferType: '传输类型',
    },
    wallet: {
      menu: '我的账户',
      balance: '我的账户',
      payWith: '确认并使用我的GMT账户付款',
      payment: {
        title: '我的账户',
        body: '在您按下“下单”按钮后，我们将使用您的帐户信用额度为您进行交易，您的余额将相应更新。',
        footer: '付款后，您的余额将更新为 {currency}{amount}',
        inActive: {
          footer: '很抱歉，您的账户余额不足。您可以更改金额或向您的钱包账户存入更多资金',
        },
      },
      main: {
        title: '您好 {name}',
        card: {
          title: '您的账户余额',
          transactionsLink: '查看您的所有交易',
        },
        depositToWallet: '充值您的钱包通过',
        sendMoney: '汇钱',
        walletOptions: '账户选项',
        depositActions: {
          cash: '现金存款',
          bank: '银行转帐',
          card: '银行卡',
        },
        sendActions: {
          sendHome: '汇款回家',
          sendWorldWide: '向世界各地汇款',
          inIsrael: '以色列境内汇款',
        },
        walletActions: {
          loadPrePaid: 'GMT预付卡',
          send: '发送给GMT好友',
          invite: '邀请好友，返现',
        },
        doarInstruction: '如何从邮局汇款',
      },
      invite: {
        title: '邀请好友并获得现金返还',
        body: '您和您的朋友可以从 GMT 获得更多。\n“将您的个人注册链接发送给您的朋友。在 GMT 上进行第一次交易后，你们俩都将获得 {amount} ILS 现金返还！”\n\n您可以使用下面的按钮分享您的个人注册链接',
        shareActions: {
          copy: '复制到剪贴板',
          copied: '已复制',
          whatsapp: '通过 WhatsApp 邀请',
          wechat: '通过微信邀请',
          line: '通过LINE邀请',
          general: '分享',
        },
        inviteMessages: {
          firstLine: '嗨，加入 GMT！通过下面的我的个人链接开始注册',
          secondLine: '加入GMT，我们都可得返现>',
        },
      },
      deposit: {
        cash: {
          title: '给账户充值现金',
          body: '您可以在任何 GMT 网点存入现金。\几秒钟后，您的 GMT 帐户就会更新，您可以立即使用进行汇款。',
          locations: 'GMT网点地址>',
          walletOrderExplanation: {
            depositAnywhere: '您可以在GMT的任何网点存入现金。',
            amount: '输入您要存入的金额',
            verificationCode: '获取您的代码',
            teller: '带上资金并向工作人员出示代码。',
            finishTitle: '就是这样！',
            finishText: '您的钱即刻充值到您的GMT钱包',
          },
        },
        bank: {
          title: '从您的银行账户或者邮局账户汇钱到您的GMT账户',
          body: '通过银行转账至 GMT 并将其直接转入您的 GMT 账户。\您的雇主也可以从他们的账户将钱转入您的账户。',
          transferDetails: '“帐户详细资料:\nGMT TECH INNOVATION LTD\n银行 HaPoalim 12\n613 分行\n帐号 545690',
          uploadDetails: '“在此处上传您的转账确认信息，不要忘记提及 您的护照和手机号码”',
          uploadConfirmationBtn: '上传确认信息',
          placeholder: '添加银行转账确认',
          upload: {
            title: '上传您的转账确认',
            loader: '我们正在上传您添加的照片',
            footer: '不要忘记提及您的护照和手机号码',
            success: '太棒了！我们的工作人员会尽快处理，您​​的钱包余额将很快更新。\n我们还将通过短信和电子邮件向您发送确认信息。',
          },
        },
      },
      friends: {
        title: '汇款给GMT好友',
        addFriend: '添加新朋友',
        newFriend: '汇款到GMT钱包',
        contacts: '从我的联系人中选择',
        addContactByPhone: '转账到未储存的联系人',
        searchByNameOrPhone: '按姓名或号码搜索',
        header: '借助我们易于使用的转账功能，您可以安全地将资金发送给您的任何 GMT 朋友。\n 又快又简单！',
        mobilePhone: '手机号码',
        bulletsOne: '选择您朋友的手机电话。',
        bulletsTwo: '输入您要发送的金额。',
        bulletsThree: '确认交易。',
        outro: '就是这样！\n 您和您朋友的钱包余额将会立即更新。',
        inviteBnt: '邀请朋友加入 GMT',
        list: {
          title: '好友列表',
        },
        addModal: {
          title: '您要添加吗',
          footer: '给您钱包的朋友？',
          saveBtn: '保存',
          closeBtn: '取消',
        },
        friendCard: {
          title: '账户',
          delete: '删除',
          deleteDialogFirstLine: '您想从您的钱包好友列表中删除 {beneficiary} 吗？',
          deleteDialogSecondLine: '这不会影响现有的转账',
          send: '汇款到GMT账户',
        },
      },
      transfer: {
        amount: {
          title: '汇款到GMT账户',
        },
        send: {
          title: '汇 {amount} 谢客到GMT账户',
        },
        summary: {
          title: '汇 {amount} 谢客到GMT账户',
        },
        pay: {
          title: '汇 {amount} 谢客到GMT账户',
        },
      },
      load: {
        amount: {
          title: '充值您的账户&用您的银行卡支付',
        },
        send: {
          title: '充值您的账户',
        },
        summary: {
          title: '充值您的账户',
        },
        pay: {
          title: '充值您的账户',
        },
      },
      card: {
        amount: {
          title: '使用您的账户余额充值 GMT 卡',
        },
        send: {
          title: '使用您的账户余额充值 GMT 卡',
        },
        summary: {
          title: '充值GMT卡',
        },
        pay: {
          title: '充值GMT卡',
        },
      },
    },
    loadCard: '从钱包往卡充值',
    pay: '下单',
    cardUnBlockConfirm: '您的卡片现已激活',
    cardMerchantAddress: '地址',
    cardMerchantPhone: '电话',
    cardPresented: '现场刷卡',
    changeAmount: '想要修改转账金额吗?',
    changePayoutAmount: '想要修改收款金额吗?',
    changeDepositAmount: '想要修改存款金额吗?',
    changeAmountLabel: '{amount} 的新金额',
    changeAmounBtn: '计算',
    city: '城市',
    commission: '手续费',
    confirmMarketingContent: '我想接收营销内容和更新',
    confirmCreditNumber: '重要！ 请仔细检查账户明细，已存入海外账户的资金无法退还。',
    confirmBankAccount: '重要！ 请仔细检查账户明细，已存入海外账户的资金无法退还。',
    confirmBankAccount2: '收款人账户将在大约 2-4 个工作日内到账。',
    contactBtnCaption: '服务页面',
    contactMenuCaption1: '电报',
    contactMenuCaption2: '微信',
    contactMenuCaption3: '致电',
    contactMenuCaption4: 'Whatsapp',
    country: '国籍',
    countryBirth: '出生地所在国家',
    countryDestination: '目的地国',
    countryFrom: '从国家',
    correspondentReference: '交易参考号',
    cookies: '该网站使用cookie文件,如果您继续浏览该网站,代表您同意使用cookies,欲了解更多详情，请参阅<a style=""text-decoration:underline;display: inline;"" href=""https://gmtonline.co.il/en/privacy/"" target=""_blank"">隐私政策</a>',
    clone: '重新发送',
    payingReference: '订单号',
    creditCard: '信用卡',
    crediting: '收款',
    creditCardOwner: '卡上姓名',
    creditCardSupport: '我们合作',
    creditCardCommissionMsg: '您的信用卡公司可能会收取交易手续费',
    creditCardSecurityMsg: '我们不会保存您的信用卡详细信息，我们只会将其发送给信用卡公司进行审批',
    cardDepositAccepted: '该服务仅适用于 Visa / Mastercard万事达',
    Contact: '联系',
    currency: '货币',
    currencyDestination: '收件人货币/目的地金额',
    currencyILS: '以色列谢克尔',
    currencyUSD: '美元',
    currencyEUR: '欧元',
    currencyCardMsg: '最终收到的金额取决于收款人卡片的类型和币种',
    currencyBankMsg: '最终收到的金额取决于收款人账户的类型和币种',
    dateBirth: '出生日期',
    daysWork: '星期日至星期四',
    daysWork2: '星期日至星期四08:30-19:00, 星期五 08:30-14:00',
    daysWork3: '星期五',
    destination: '目的地',
    discount: '折扣',
    docsTitle: '请上传护照',
    docsTitleUploaded: '谢谢，我们即将完成!',
    documentsUploading1: '我们正在保存您添加的照片',
    documentsUploading2: '将需要片刻的时间',
    documentsUploading3: '随后点击下一步按钮',
    editUserProfile: '我的信息',
    email: '电子邮箱',
    errorCode: '无效码，请重试',
    estimated: '预估',
    calculatedAbroad: '将被在海外计算',
    expired: '过期',
    extraDetails: '更多细节',
    exchangeRate: '汇率',
    totalPayment: '总付款',
    cashbackDiscount: '返现福利',
    byExchangeRate: '汇率',
    destinationExchangeRate: '目的地汇率',
    pressPay: '在您按下“支付”按钮后，我们将使用您的账户额度进行交易',
    fax: '传真',
    for: '为',
    finished: '已完成',
    foreignPassport: '我持有外国护照',
    to: '到',
    freeSearch: '搜索',
    friday: '星期五',
    fullName: '全名',
    fun: '我们承诺只向您更新重要的信息。',
    foreignCurrencyHandlingFee: '外币手续费',
    gender: '性别',
    here: '这里',
    hours: '请注意！ 您必须在接下来的 48 小时内到达 GMT 分支网点才能完成付款',
    houseNumber: '房号',
    welcome: '欢迎',
    interest: '包含 {inter} 利息',
    interested: '同时，您可以访问我们的博客',
    includesInterest: '/月 - 包含 ₪{interest} 利息',
    includesInterest2: '包含 ₪{interest} 利息',
    israel: '以色列',
    israeliID: '我有以色列身份证',
    israeliResident: '以色列居民',
    nonIsraeliResident: '外国居民',
    installments: '付款次数',
    inProcess: '进行中',
    itemNavMain: 'My Wallet',
    itemNav0: '我的返现',
    itemNav1: '转钱',
    itemNav10: '网点',
    itemNav2: '我的历史',
    itemNav3: '汇率&费用',
    itemNav4: '我的收款人',
    itemNav5: 'GMT网点地址',
    itemNav6: '常见问题',
    itemNav7: '联系我们',
    itemNav8: '条款与条件',
    itemNav9: '退出登录',
    itemNav11: '关于',
    itemNav12: 'GMT预付卡',
    itemNavUserProfile: '个人详细信息和设置',
    itemNav13: '国外货币订单',
    labelEmail: '邮箱地址',
    labelMsg: '您想告诉我们什么吗？',
    labelName: '名字的汉语拼音',
    labelPhone: '电话号码',
    lblDone: '汇款是作为对一级亲属的礼物或生活支持，不构成受益人的收入。',
    lblDoneWechat: '请注意，对于第一笔交易，您的收款人需要批准微信交易。 请告知他们点击短信链接。',
    lblDoneWechat1: '请在您绑定了微信的中国手机号前加上86，或绑定的以色列手机号前加上972。',
    labelSurname: '姓氏的汉语拼音',
    leaveMsg: '您想告诉我们什么吗？',
    linkToLoginPage: '已注册？请登录',
    listBranches: '找到GMT',
    locatingBranches: '定位分支网点',
    locationBtn: '打开',
    locationBtnClose: '关闭',
    loginLabel1: '身份证/护照',
    loginLabel2: '电话号码',
    logInConsent: '通过登录，我同意',
    mailingAddress: '地址',
    mailingRecipient: '劳尔 瓦伦伯格 18C, 特拉维夫, 6971915',
    marked: '现在开放',
    maybe: '同时，您可以访问我们的博客',
    messageCode: '我们刚刚通过短信向您发送了一个验证码。请在下方输入验证码。',
    messageSend: '在接下来的几分钟内，我们还将通过短信和电子邮件向您发送此确认信息。',
    messageNotSent: '接收验证码时遇到问题？ 请检查您的短信垃圾匣,骚扰和屏蔽选项. 我们很乐意帮助解答任何问题。',
    messageRegistration1: '您好，欢迎回来！',
    messageRegistration2: '我们只需要您上传您的身份证或护照照片，同意我们的条款和条件，即一切就绪了',
    messageUploadDocuments: '您的身份证明文件似乎不再有效，请上传新的',
    middleName: '不用填',
    mobilePhone: '电话号码',
    mobilePhoneNumber: '以色列手机号码',
    monday: '星期一',
    moneyApproved: '转账已被批准！',
    MoneyGram: '速汇金',
    moreOptions: '更多选项',
    msgError: '仅使用数字',
    maxAccessionCommission: '参与手续费',
    myId: '我的身份证',
    nameBeneficiary: '收款人姓名',
    newBeneficiary: '新的收款人',
    version: 'v {num}',
    updateBeneficiary: '收款人信息',
    nextLevel: '下一步',
    no: '否',
    noBeneficiaries: '系统中未定义该收款人',
    noRecentActions: '欢迎来到您的GMT个人空间',
    noRecentActions1: '这里您可以看到您在GMT的所有历史记录',
    noRecentActions2: '现在请发起转账吧',
    noCardRecentActions: '未找到交易',
    notAvailable: '不可用',
    notRequired: '选填',
    numberPassport: '护照号',
    numberOfTransaction: '发票号',
    openNow: '开放',
    paymentFrom: '付款自',
    payUsing: '支付通过',
    youllPay: '您将支付',
    toPay: '支付',
    payRate: '汇率 {rate}',
    paymentCredit: '信用卡',
    photoID: '身份证、驾照或护照',
    photoRequired: '请添加一张照片',
    pinCode: '查看pin码',
    pinCodeModalMessage: '您想要查看以{digits}结尾的卡片的pin码吗',
    pinCodeModalMessage2: '我们向您的手机发送了一条短信以查看pin码',
    placeholderDigit: '九位数身份证号',
    questionsAnswers: '常见问题',
    questionsAnswersSubtitle: '找不到您的问题的答案？ 请通过联系页面上列出的任何方式与我们联系',
    reasonTransfer: '汇款目的',
    recentActions: '我的历史',
    recentActionsTab1: '新收款人',
    recentActionsTab2: '本古里安机场的外汇服务',
    recentActionsTab3: '收费',
    recentActionsTab4: '收钱',
    recentActionsTab5: '购买比特币',
    recommended: '推荐！',
    recentActionsBeneficiaries: '我的收款人',
    recentActionsTransfer: '汇款',
    r_step_address: '地址',
    r_step_doc: '文件',
    r_step_name: '名字',
    rate: '利率',
    registerSystem: '注册GMT',
    registerSystemAddDocuments: '请上传护照',
    registerText1: '只需片刻，您就可以汇款',
    registerText2: '找到你的身份证/护照，我们将会请你对其拍照，这就开始吧！',
    registerFooter: 'GMT 在财政部的许可下运营',
    registerFooter2: '扩展金融服务提供商 - 许可证编号 57479',
    registerFooter3: '我们的服务是加密和安全的，我们永远不会向第三方分享您的信息。',
    registrationSuccess: '注册成功！',
    registrationSuccess1: '感谢！我们已收到您的信息',
    registrationSuccess2: '通过GMT，您可以轻松快捷地向境外汇款',
    requestSuccessfully: '您的请求已成功收到',
    requestSuccessfully1: '您的订单号是：',
    requestSuccessfully2: '接下来做什么？ 现在您需要在 24 小时内支付这笔转账费用',
    requestSuccessfully3: '<a href=""https://gmtonline.co.il/en/locating-branches/"" style=""color:#d4c641;text-decoration: underline;"">在任一GMT支行网点</a>',
    requestSuccessfully4: '随后它将被发至海外',
    riskyLimitMsg: '需要汇款更高金额？ 联系我们的客服团队。',
    russianSpeaker: '俄语服务',
    saturday: '星期六',
    searchResult: '在XXX找到X网点',
    selectBeneficiary: '我的收款人',
    selectCity: '通过城市寻找',
    selectCountry: '国籍',
    selectProvince: '选择省份',
    sendMarketingContentLbl: '通知我特殊优惠与更新',
    sentOn: '日期',
    signedUp: '您已注册！',
    soonPossible: '我们将在上班时间回复您。',
    staging: {
      payWith: '在GMT网点支付现金',
      body: '当您按下“下单”按钮时，我们将为您准备转账。 您在GMT的任一网点付款后，转账将被发出。',
      order: '下单',
    },
    streetName: '街道',
    streetName_HouseNumber: '路名与门牌号',
    subscribeDesc: '金融科技界最新的新闻都在这里了',
    subscribeNewsletter: '注册接收我们的时事通讯——绝对值得！',
    subtitleCustomerService: '请联系我们的客服，我们将竭诚为您服务。',
    summaryTransfer: '转账详情概括',
    sunday: '星期天',
    surtitleFormLang: '选择适合您的语言',
    smartBannerTitle: '升级至GMT软件',
    smartBannerText: '简单。 快速。安全。 正是您需要的。',
    smartBannerBtnText: '安装',
    system: '系统',
    correspondentSystem: '的网点',
    termsUse: 'T&C',
    imprtant: 'Important',
    termsTitle: '请阅读并同意条款与条件',
    tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
    surveyModal: {
      active: 'true',
      title: '分享您的想法：参加我们的快速匿名问卷',
      body: '您好 {name}，我们希望收到您的意见！ 通过回答简短的匿名调查来帮助我们改善金融服务。 您的意见很重要。',
      confirm: '开始问卷调查',
      cancel: '稍后',
      link: 'https://forms.gle/LYSDw7oGrQPLNHvZ9',
    },
    termsUpdate: {
      title: '条款条件和隐私政策',
      body: {
        first: '您好，我们更新了我们的法律文件，',
        second: '您可以在这里阅读它们>',
      },
    },
    thanks: '感谢联系！',
    thursday: '星期四',
    titleFormLang: '欢迎',
    titleLoginPage: '登录 GMT 账户',
    toBankAccount: '到账户',
    toWalletAccount: '到钱包',
    pickupPoint: '取款点',
    toCreditCard: '到卡',
    totalNIS: '谢克尔总计',
    totalTransfer: '支付金额',
    total: '总计',
    t_step_amount: '金额',
    t_step_transfer: '支付',
    t_step_summary: '明细',
    t_step_pay: '转账',
    transferAmount: '金额',
    transactionAmount: '金额',
    transactionCharge: '排序依据',
    filterPaymentType: '付款类型',
    transactionType: '款项类型',
    transferFundsTo: '转',
    transferOptions: '查询',
    transferType: '转账类型',
    transferTypePick: '请选择您的转账方式',
    transferTypePick2: '选择汇款方式',
    tuesday: '星期二',
    waitingPayment: '等待付款',
    wednesday: '星期三',
    yourCardBalance: '卡余额',
    zipCode: '邮政编码',
    loginAgreementRequired: '请阅读并同意服务条款',
    customerCommission: '您的手续费',
    customerCommissionMaxTopup: '充手续费',
    customerEmailNotification: {
      title: '更新您的邮箱',
      body: '我们似乎没有您的电子邮件，您可以在下面的字段中更新它，以便我们通过电子邮件向您发送每笔交易的副本。我们绝不会发送垃圾邮件。',
      cancel: '跳过',
    },
    sumAmount: '合计金额',
    terms: '条款与条件',
    error: '错误',
    generalErrorMsg: '很抱歉，我们正在更新网站以改进我们的服务，建议稍后再试。如需帮助，请与我们联系。',
    connectionError: '连接错误',
    locationLink: 'zh-hant/branches/',
    faqLink: 'zh-hant/faq/',
    contactUsLink: 'en/contact-us/',
    protocolLink: 'en/protocol/',
    privacyLink: 'en/privacy',
    privacyPolicy: '隐私政策',
    profileDelete: '删除您的个人资料',
    profileDeleteModalTitle: '您要取消GMT个人资料吗？',
    profileDeleteModalText: '通过点击批准按钮,我们将收到一条消息，并尽快与您联系',
    aboutLink: 'ch/my-gmt/',
    paymentProcessing: '请稍候，我们正在处理您的请求。',
    paymentType: '付款类型',
    paymentTypeCredit: '信用卡支付',
    paymentTypeByCredit: '刷卡支付',
    paymentTypeCash: '现金',
    uploadDocumentMsg1: '我们需要认证您的身份信息，请拍张您身份证件的照片',
    uploadDocumentMsg2: '请放心，我们会认真对待您的隐私。 我们的网站已加密并安全，永远不会分享您的详细信息。',
    yes: '确认',
    zipCodePlaceholder: '七位数',
    zipCodeRequiredFields: '请填写您地址的详细信息，以便我们查找您的邮政编码',
    zipCodeNotFound: '我们找不到该地址的邮政编码，请再次检查或填写您的七位邮政编码',
    CORRESPONDENT_BLOCKED: '抱歉，此服务维护中。请稍后重试。如果您需要任何帮助，我们很乐意为您提供帮助。',
    CARD_DEPOSIT: '汇款给国外银行卡',
    CARD_DEPOSIT_MAX: '充值银行卡',
    CARD_DEPOSIT_TO: '汇款至银行卡通过{dest}',
    CASH_TRANSFER: '境外现金提取',
    CASH_TRANSFER_INLAND: '现金提取',
    CASH_TRANSFER_ISR: '现金提取',
    CASH_TRANSFER_TO: '现金提取通过{dest}',
    BANK_TRANSFER: '汇款至海外账户',
    UPI_BANK_TRANSFER: '汇款至海外账户通过UPI',
    BANK_TRANSFER_ISR: '充值到账户',
    BANK_TRANSFER_TO: '充值到账户通过{dest}',
    BANK_TRANSFER_PAYMENT: '银行转账',
    WALLET_TRANSFER: '向国外电子钱包存款',
    WALLET_TRANSFER_TO: '向电子钱包存款通过{dest}',
    WALLET_TO_WALLET: '汇款至GMT账户',
    WALLET_FROM_WALLET: '从GMT账户收款',
    WALLET_DEPOSIT: '充值到GMT账户',
    NEW_CUSTOMER: '您好，您的注册将在 24 小时内完成。在此之前，信用卡付款选项不可用。一旦准备就绪，我们将通知您。',
    CUSTOMER_NOT_AUTHORIZED: '很抱歉，您的个人资料中没有信用卡付款选项。如需帮助，请联系我们。',
    CUSTOMER_NOT_SIGNED: '很抱歉，您尚未完成注册，因此您的个人资料中还没有信用卡付款选项。如仍需帮助，请联系我们。',
    CREDIT_LIMIT_UNREGISTERED: '本月您可发送至多',
    CREDIT_LIMIT: '本月您可发送至多',
    CREDIT_LIMIT_MONTHLY: '本月您可发送至多 {amount}',
    CREDIT_LIMIT_CREDIT_CARD: '交易金额超过我们的信用卡每月限额。 您最多可以使用 {amount}',
    CREDIT_LIMIT_WALLET: '交易金额超过了我们的钱包每月限额。 您最多可以使用 {amount}',
    CREDIT_LIMIT_RISKY: '本月您可向{country}至多发',
    TRANSACTION_LIMIT: '抱歉，此项服务每笔交易限制为 {currency}{amount}。请更新金额并重试。',
    CREDIT_CARD_ILS_AMOUNT: '总和将以谢克尔计算，付款将立即可用',
    CREDIT_NOT_ACTIVE: '很抱歉，目前无法使用信用卡付款选项。 开放时间为 06:00-24:00。',
    CREDIT_BLOCKED: '很抱歉，由于我们正在更新网站，目前无法使用信用卡付款选项，建议稍后再试。 如仍需帮助，请与我们联系。',
    API_EXCEPTION: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    GRAILS_VALIDATION_ERROR: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    CAPTCHA_NOT_VERIFIED: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    CUSTOMER_SESSION_NOT_FOUND: '身份信息不正确或您尚未注册 GMT，您可以在这里注册 <a href=""https://mygmt.gmtonline.co.il/#/registration"" style=""color:#d4c641;text-decoration: underline;"">mygmt.gmtonline.co.il</a>',
    CUSTOMER_BLOCKED: '抱歉，您的个人资料已关闭，请联系我们',
    SMS_WRONG: '您输入的验证码有误',
    SMS_EXPIRED: '抱歉，验证码已失效，您可重新获取一个新验证码。',
    REGISTRATION_TOKEN_NOT_FOUND: '您输入的验证码有误',
    REGISTRATION_TOKEN_EXPIRED: '抱歉，该验证码不再有效，您可以重新获取',
    SESSION_EXPIRED: '我们认为您已离开，因此我们将您退出登录以保证您的帐户安全。您可以再次登录以继续。',
    CUSTOMER_MOBILE_NOT_MATCH: '很抱歉，您提供的详细信息有问题，要注册 GMT，请联系我们的客户服务',
    WALLET_FRIEND_NOT_UNIQUE: '抱歉，我们无法将此号码添加到您的朋友列表中。我们很乐意为您提供帮助，如有任何问题，请点击“联系我们”',
    CUSTOMER_ALREADY_REGISTERED: '您好！我们认为您已注册 GMT，您可以在此处登录 > <a href=""https://mygmt.gmtonline.co.il"" style=""color:#d4c641;text-decoration: underline;"">mygmt.gmtonline.co.il</a>',
    CUSTOMER_DENIED: '嗨，欢迎回来！您有上次未完成的注册，请联系我们',
    BENEFICIARY_NOT_FOUND: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    TRANSACTION_NOT_FOUND_FOR_CUSTOMER: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    DOCUMENT_NOT_FOUND: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    DOCUMENT_NOT_FOUND_FOR_CUSTOMER: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    MONEYGRAM_VALIDATION_ERROR: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    CREDIT_CARD_SIGN_ERROR: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    CREDIT_CARD_VERIFY_ERROR: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    CREDIT_CARD_COMMIT_ERROR: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    CUSTOMER_MULTIPLE: '抱歉，若需注册GMT，请访问我们<a href=""https://gmtonline.co.il/en/contact-us/"" style=""color:#d4c641;text-decoration: underline;"">Customer Service > </a>',
    MONEYGRAM_EXCEPTION: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    MONEYGRAM_301: '超过最大转账金额',
    MONEYGRAM_304: '很抱歉，我们的汇款服务目前已关闭。 我们将在工作日 8:00-21:00 和周五 8:00-15:00 之间回来',
    MONEYGRAM_323: '很抱歉，您输入的收款人姓名太长。 您可以使用较短的名称重试。 如仍需帮助，请联系我们。',
    MONEYGRAM_4055: '很抱歉，为了保护您，速汇金拒绝了此次转账，而且转账还没有完成。如需帮助，请联系我们。',
    MONEYGRAM_7003: '很抱歉，您已达到速汇金的限额，并且转账未完成。如需帮助，请联系我们。',
    FILE_NOT_ALLOWED: '文件出现问题',
    INVALID_SESSION_INFO: '十分抱歉，出了点问题，请稍后再试。如仍需帮助，请与我们联系。',
    KYC_EXCEPTION: '我们需要更多详细信息才能完成您的交易。请联系我们的客服寻求帮助。给您带来的不便深表歉意，感谢您的合作。',
    WALLET_FRIEND_ALREADY_ADDED: '您好，此号码已在您的好友列表中。',
    WALLET_FRIEND_BLOCKED: '抱歉，我们无法将此号码添加到您的好友列表中。 请与我们联系以获取更多信息',
    WALLET_FRIEND_NOT_REGISTERED: '抱歉，手机号码 {mobileNumber} 还不在 GMT 系统。使用您的个人注册链接邀请您的朋友并获得现金返还！',
    marketingMessage: '使用 GMT，您可以向境外的家人汇款，并在数千个支付点使用参考号取款，直接存入国外的信用卡，或直接存入国外的任何银行账户。',
    marketingMessage2: '简单。迅速。安全。为您定制',
    YAAD_SARIG_3: '很抱歉，您的信用卡公司未批准转账。我们建议您联系他们解决问题，然后重试。如果您有任何问题，我们随时为您提供帮助。',
    YAAD_SARIG_4: '很抱歉，您的信用卡公司未批准转账或您输入的信用卡详细信息。我们建议您重试。如果您有任何问题，我们将竭诚为您服务。',
    YAAD_SARIG_6: '很抱歉，您输入的银行卡详细信息有误。我们建议您重试。如果您有任何疑问，我们将竭诚为您服务。',
    YAAD_SARIG_26: '很抱歉，这张卡不属于您的身份证号码之下的卡。您可以使用以您名义注册的卡重试。如果您有任何问题，我们将竭诚为您服务。',
    YAAD_SARIG_141: '很抱歉，目前我们只接受 Visa 和 MasterCard。如果您有任何疑问，我们将竭诚为您服务。',
    'מצטערים, אך הגעת לסכום החודשי המקסימלי ב-GMT': {
      ' נשמח אם תצרו קשר כדי להסיר מגבלה זו': {
        ' אנחנו כאן לעזור': {
          '\n\n': '很抱歉，您的信用卡不支持信用卡公司要求的安全检查。您可以联系您的信用卡发卡机构寻求帮助。如果您有任何问题，我们将竭诚为您服务。',
        },
      },
    },
    YAAD_SARIG_447: '很抱歉，您输入的卡号不正确。我们建议您重试。如果您有任何疑问，我们将竭诚为您服务。',
    YAAD_SARIG_553: '很抱歉，信用卡公司出现了问题。我们建议您联系他们解决问题，然后重试。如果您有任何问题，我们随时为您提供帮助。',
    YAAD_SARIG_999: '很抱歉，出了点问题。我们建议您稍后再试。如果您有任何问题，我们随时为您提供帮助。',
    YAAD_SARIG_3000: '很抱歉，信用卡公司未批准转账。我们建议您联系他们解决问题，然后重试。如果您有任何问题，我们随时为您提供帮助。',
    YAAD_SARIG_3003: '很抱歉，信用卡公司未批准转账。我们建议您联系他们解决问题，然后重试。如果您有任何问题，我们随时为您提供帮助。',
    YAAD_SARIG_9101: '很抱歉，您的信用卡不支持信用卡公司要求的安全检查。您可以联系您的信用卡发卡机构寻求帮助。如果您有任何问题，我们将竭诚为您服务。',
    YAAD_SARIG_GENERAL: '很抱歉，付款出现问题。我们建议您稍后重试。如果您有任何疑问，我们将竭诚为您服务。',
    INVALID_CARD: '很抱歉，您尝试付款的卡不支持。您可以使用其他卡重试。我们很乐意为您解答任何问题。',
    INVALID_VERSION: '很抱歉打扰您。请刷新页面以查看 GMT 的最近更新。',
    AMOUNT_LIMITED: '很抱歉，您尝试发送的金额超出了收款人的卡限额。您可以尝试汇较少的金额重试。我们很乐意为您解答任何问题。',
    CONTACT_GENERAL: '很抱歉，我们正在更新网站以改善我们的服务。建议您稍后再试。如果您有任何问题，我们随时为您提供帮助。',
    BENEFICIARY_INVALID: '很抱歉，该转账收款人的信息有误，请修改后重试。如仍需帮助，请联系我们。',
    CONTACT_DENIED: '很抱歉，为了保护您的财产安全，我们不得不拒绝此转账，且转账未成功。 如仍需帮助，请与我们联系。',
    CARD_DENIED: '很抱歉，向该卡转账失败。如仍需帮助，请联系我们。',
    CARD_WRONG_DETAILS: '很抱歉，本次转账信息有误，请修改后重试。如仍需帮助，请与我们联系。',
    REGISTER_INFO_DETAILS_REQUIRED: '您好，我们需要您的身份信息和文档来向海外转钱',
    REGISTER_INFO_SEARCH_ZIP: '嗨，您可以通过点击放大镜搜索邮政编码',
    CARD_REJECTED: '很抱歉，现在无法向您的收款人转账，请联系我们获得帮助。',
    BLACKLIST: '很抱歉，现在无法向您的收款人转账，请联系我们获得帮助。',
    BLACKLIST_CARD: '很抱歉，现在无法向您的收款人转账，请联系我们获得帮助。',
    COMPANY_IDENTIFICATION: '抱歉，您似乎尝试使用企业号码进行注册。您可以使用您的身份证号码进行注册。如果您有任何疑问，我们将竭诚为您服务。',
    CUSTOMER_MONTHLY_LIMIT: '很抱歉，您已达到 GMT 的月度限制。请联系我们解除此限制。我们会尽力为您提供帮助。',
    CORRESPONDENT_EXCEPTION: '很抱歉，我们在尝试将您的转账汇至国外时遇到错误。请联系我们，我们将很乐意为您检查并提供帮助。',
    TEMPORARY_ERROR: '抱歉，系统目前正在更新。我们建议您稍后再试。我们很乐意为您解答任何问题。',
    BAD_REQUEST: '很抱歉，出了点问题。我们建议您稍后再试。我们很乐意为您解答任何问题。',
    MAX_EXCEPTION: '很抱歉，出了点问题。我们建议您稍后再试。我们很乐意为您解答任何问题。',
    SERVER_ERROR: '抱歉，系统目前正在更新。我们建议您稍后再试。我们很乐意为您解答任何问题。',
    CUSTOMER_DAILY_LIMIT_EXCEEDED: '为了保护您的帐户，我们限制了您每日信用卡交易次数。需要帮助？请联系客服。',
    NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: '为了保护您的帐户，我们限制了此信用卡付款交易次数。需要帮助？请联系客服。',
    NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: '为了保护您的帐户，我们限制了此信用卡付款的总金额。需要帮助？请联系客服。',
    NEW_BENEFICIARY_LIMIT_EXCEEDED: '为了保护您的账户，我们限制了您向新收款账户转账的次数。需要帮助？请联系客服。',
    NEW_BENEFICIARY_AMOUNT_EXCEEDED: '为了保护您的账户，我们限制了您向新收款账户转账的总金额。需要帮助？请联系客服。',
    BENEFICIARY_CARD_BLOCKED: '此卡号或银行不支持该服务，您可以尝试汇款到其他银行卡。',
    ERROR_NEGATIVE: '很抱歉您输入的金额有误无法以负金额进行交易，请更新请求的金额。',
    E_DECIMAL: '仅限整数',
    E_INVALID_zipCode: '邮政编码错误',
    E_LEMGTH: {
      '1': '""',
      '2': '仅数字',
    },
    E_MAX_VALUE: '您已超过最大值',
    E_MAX_CHAR: {
      '1': '""',
      '2': '最大字符数',
    },
    E_MIN_CHAR: {
      '1': '""',
      '2': '最少字符数',
    },
    E_MIN_passport: '必须多于5个字符',
    E_NUMERIC: '请仅使用数字',
    E_REGEX: '该字段格式无效',
    E_REGEX_email: '邮箱格式无效',
    E_REGEX_english: '请填拼音',
    E_REGEX_mobileNumber: '请输入10位数字有效的以色列手机号码',
    E_REGEX_israeliId: '非有效以色列身份证',
    E_REGEX_passport: '仅限字母与数字',
    E_REGEX_birthDateAge: '仅限18-85岁',
    E_REGEX_zipCode: '邮编应为7位数',
    E_REQUIRED: '必填项目',
    E_REQUIRED_amount: '金额是？',
    E_REQUIRED_currency: '货币是？',
    E_REQUIRED_destinationCountry: '目的地是？',
    E_REQUIRED_agreement: '请阅读并同意服务条款',
    STATUS_NOT_FOUND: '无法找到',
    STATUS_CONFIRMED: '已确认',
    STATUS_READY_FOR_PAYMENT: '付款就绪',
    STATUS_PAID: '已付',
    STATUS_SENT_TO_BANK: '汇给银行',
    STATUS_BEING_CANCEL: '已被取消',
    STATUS_READY_TO_CANCEL: '可取消',
    STATUS_CANCELED: '已取消',
    STATUS_PREPARED: '已准备',
    STATUS_FAILED: '已失败',
    STATUS_RETURNED: '已返还',
    STATUS_ACCEPTED: '已接受',
    STATUS_STAGED: '进行中',
    STATUS_INCOMPLETE: '未完成',
    STATUS_IN_PROCESS: '被支付',
    STATUS_SWIFT_TRANSMITTED: '已传送',
    STATUS_SWIFT_APPROVED: '已批准',
    STATUS_REJECTED: '已拒绝',
    STATUS_EXPIRED: '已过期',
    STATUS_CLOSED: '已关闭',
    STATUS_SUBMITTED: '已提交',
    DESC_CASHBACK: '返现',
    DESC_CASHBACK_WELCOME: '欢迎！返现',
    DESC_CASHBACK_BIRTHDAY: '生日快乐！返现',
    DESC_CASHBACK_ONETIME: '返现礼金',
    DESC_WALLET: '到电子钱包',
    DESC_MAX_TOPUP: '卡片充值',
    DESC_MAX_PURCHASE: '卡片购买与充值',
    DESC_MAX_ORDER: '预定卡片',
    DESC_CONVERSION: '对话',
    DESC_019MOBILE: '019移动',
    DESC_REWIRE: '重新接线',
    DESC_DEPOSIT_3MONTHS: '3个月存款计划',
    DESC_DEPOSIT_6MONTHS: '6个月存款计划',
    DESC_DEPOSIT_WITHDRAW: '提取存款资金',
    DESC_DEPOSIT_INTEREST_PAYMENT: '支付存款利息',
    DEPOSIT_IN: '转至存款账户',
    DEPOSIT_OUT: '提款存款账户',
    DEPOSIT_INTEREST: '支付存款利息',
    NAME_ALIPAY: '支付宝',
    NAME_UNIONPAY: '银联',
    NAME_WECHATPAY: '微信支付',
    NAME_UPI: 'UPI',
    validation: {
      mock: '',
      beneficiary: {
        invalid: '该字段格式无效',
        maskedCardNumber: {
          invalidCountry: '该卡可能与目的地国家/地区不匹配',
          tranglo: '抱歉，您输入的收款人信息有误。 请检查、修正并重试',
        },
        accountIdentifier: {
          invalid: '该字段格式无效',
          tranglo: '抱歉，您输入的收款人信息有误。 请检查、修正并重试',
        },
        walletIdentifier: {
          invalid: '该字段格式无效',
          tranglo: '抱歉，您输入的收款人信息有误。 请检查、修正并重试',
        },
        phoneWallet: {
          unavailable: '很遗憾，此帐户不可用且无法接收资金。 请在重汇款前检查帐户。',
          mismatch: '很遗憾，收款人姓名和帐号不匹配。 您可以修正详细信息，然后重试。',
          invalidAccount: '很遗憾，该帐户无效。 请在重汇款前检查帐户。',
          barred: '很遗憾，该帐户被锁。 请在重汇款前检查帐户。',
        },
        phoneNumber: {
          format: {
            passParam: '微信绑定国内电话前面加86,以色列电话绑定前面加972',
          },
        },
      },
    },
    NEW_BENEFICIARY_EXCEEDED: '为了保护您的帐户，我们限制了您向新的收款人转账。\n需要帮助？请联系客服。',
    EMAIL_ALREADY_EXISTS: '此电子邮箱已被使用。请输入不同的电子邮箱以继续。',
    EMAIL_ALREADY_EXISTS_UPDATE: '您输入的电子邮箱已被注册过，无法再使用该邮箱。您可以输入其他电子邮箱或暂时跳过此阶段。',
    paymentStatuses: {
      titles: {
        CASH_TRANSFER: '太棒了！您的款项已汇出',
        BANK_TRANSFER: '太棒了！您的款项已汇出',
        UPI_BANK_TRANSFER: '太棒了！您的款项已汇出',
        CARD_DEPOSIT: '太棒了！您的款项已汇出',
        WALLET_TRANSFER: '太棒了！您的款项已汇出',
        WALLET_DEPOSIT: '太棒了！您的款项已汇出',
        WALLET_TO_WALLET: '太棒了！您的款项已汇出',
      },
      messages: {
        CASH_TRANSFER: '您已成功向 {beneficiary} 汇款 {amount} {currency}，以便在 {destination} 领取现金。转账确认信息将很快通过短信和电子邮件发送给您。',
        BANK_TRANSFER: '您已成功将 {amount} {currency} 汇至 {destination} 的银行账户，汇给 {beneficiary}。转账确认信息将很快通过短信和电子邮件发送给您。',
        UPI_BANK_TRANSFER: '您已成功通过 UPI 将 {amount} {currency} 汇入 {destination} 的银行账户，汇给 {beneficiary}。转账确认信息将很快通过短信和电子邮件发送给您。',
        CARD_DEPOSIT: '您已成功将 {amount} {currency} 汇到 {destination} 的银行卡，汇给{beneficiary}。转账确认信息将很快通过短信和电子邮件发送给您。',
        WALLET_TRANSFER: '您已成功将 {amount} {currency} 汇到 {beneficiary} 的 {destination} 钱包。转账确认信息将很快通过短信和电子邮件发送给您。',
        WALLET_DEPOSIT: '您已成功将 {amount} {currency} 存入您的 GMT 帐户。存款确认信息将很快通过短信和电子邮件发送给您。',
        WALLET_TO_WALLET: '您已成功将 {amount} {currency} 汇入 {beneficiary} 的 GMT 账户。转账确认信息将很快通过短信和电子邮件发送给您。',
      },
      buttons: {
        buttonAction: '转至交易历史',
        buttonLink: '返回主页',
      },
    },
    errors: {
      default: {
        title: '出了点问题',
        buttonAction: '再试一次',
        buttonLink: '联系我们',
      },
      service: {
        title: '出现错误',
        buttonAction: '联系我们',
        buttonLink: '返回主页',
      },
      login: {
        title: '欢迎回来',
        buttonAction: '再次登录',
      },
      registration: {
        title: '欢迎回来',
        buttonAction: '联系我们',
      },
      card_error: {
        title: '付款出现问题',
        buttonAction: '再试一次',
        buttonLink: '联系我们',
      },
      kyc: {
        title: '需要额外的信息',
        action: '联系我们',
      },
      nonefound: {
        title: '需要汇款吗？让我们来解决这个问题！',
        buttonAction: '联系我们',
        buttonLink: '选择另一个国家',
      },
    },
    DEFAULT: '很抱歉，发生了意外错误。请稍后重试。如果问题仍然存在，我们的客服团队很乐意为您提供帮助。',
    paymentPlan: '选择您的支付方式',
    by: '通过',
    NO_DELIVERY_OPTIONS: '目前看来我们无法处理向 {{ countryName }} 的转账。\n但别担心——我们会帮助您！\n我们的客服团队可以查看其他选项或在服务再次可用时通知您。',
    transactionReason: {
      personalUse: '支持一位直系亲属',
    },
    deleteBeneficiary: '删除收款人',
    keepBeneficiary: '保留收款人',
    minAmountToCurrencyError: '此向菲律宾汇款选项的起价为 {minAmountInToCurrency}{currency}。',
    maxAmountToCurrencyError: '此向菲律宾汇款选项的最大限额为 {maxAmountInToCurrency}{currency}。',
    paymentRequestTitle: '交易请求历史',
    paymentRequest: {
      title: '交易请求',
      yourAccount: '您的账户',
      transferType: '汇款类型',
      insufficientBalance: '余额不足',
      action: '返回请求列表',
      link: '拒绝交易',
      validUntill: '有效期至',
      checkDetails: '确认支付前检查详细信息',
      action2: '批准交易',
      rejectedInfo: '交易请求被拒绝',
      completedInfo: '交易请求已完成',
      paymentRequestExplain: '资金转账将根据付款发起人提供的详细信息进行。系统不会验证账户持有人的姓名和账号是否匹配。因此，强烈建议在继续操作之前仔细检查相关信息。\n一旦执行，转账将无法取消。\n此消息不构成对交易的确认或批准。\n付款指令将立即执行，即为今天，预计不久后将到达收款人的账户。\n在某些情况下，交易可能需要处理方批准后才能执行。在这种情况下，借记和贷记日期可能会延迟到上述日期之后。',
    },
    paymentRequests: {
      link: '支付请求',
    },
    recentRequests: '近期请求',
  }