<template>
  <div class="mygmt2-page-wrapper" :class="{ 'h-100': isMobileDevice }">
    <div class="mygmt2-page-wrapper-container">
      <div class="mygmt2-page-wrapper-container-center">
        <div class="mygmt2-page-wrapper-container-desktop">
          <Header class="header-grid-box grid-fix" />
          <div class="payment-balances-card-grid-box ">
            <h1 class="title payment-balances-card-grid-box " v-if="!isMobileDevice">
              {{ $ml.get('paymentRequestTitle') }}</h1>
            <div class="transaction-list-container">
              <div :class="{ 'mygmt2-line between': !isMobileDevice }">
                <div class="cards-page-desktop-flex">
                  <div v-if="!isMobileDevice">
                    <div class="custom-select month-select">
                      <select class="form-control mygmt2-select mygmt2-month-select" id="selectedMonth"
                        v-model="selectedMonth" @change="monthChangeHandler">
                        <option v-for="(month) in this.months" :key="month.text" :value="month">
                          {{ month.textShort }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="transaction-list-filter-container">
                  <h6 v-if="isMobileDevice">{{ $ml.get('paymentRequestTitle') }}</h6>
                  <div class="transaction-list-carousel" v-if="isMobileDevice">
                    <div>
                      <section class="splide" aria-label="month picker" id="monthPicker">
                        <div class="splide__track">
                          <ul class="splide__list">
                            <li class="splide__slide slide-month" v-for="(month) in this.monthsReversed"
                              :key="month.text">
                              <span>{{ month.textShort }}</span>
                            </li>
                          </ul>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
              <div class="transaction-list-container">
                <div class="transaction-list-card-container">
                  <div class="transaction-list-card-header">
                    <h6>
                      <strong style="font-size: 14px;">{{ $ml.get('recentRequests') }}</strong>
                    </h6>
                  </div>

                  <div class="transaction-list-container">
                    <div v-for="paymentRequest of paymentRequests">
                      <div @click="openPaymentDetails(paymentRequest)" v-if="isMobileDevice" class="line ">
                        <div class="transaction-details-wrapper transaction-details-left">
                          <h6> {{ paymentRequest.creditorName }} </h6>
                          <span class="date">{{ formatDate(paymentRequest.date, 'DD/MM/YYYY') }}</span>
                        </div>
                        <div class="transaction-details-wrapper transaction-details-right">
                          <h6> {{ paymentRequest.amount | formatComma }} </h6>
                          <h6 class="amount">
                            <span :class="{ 'red': isRejected(paymentRequest) }">
                              {{ $ml.get('paymentRequest.status.' + paymentRequest.status) }}</span>
                          </h6>
                        </div>
                      </div>
                      <div :class="{ 'canceled-transaction': isRejected(paymentRequest) }"
                        @click="openPaymentDetails(paymentRequest)" v-else>
                        <div class="line mygmt2-transactions-desktop-line">
                          <h6>{{ paymentRequest.creditorName }}</h6>
                          <span>{{ formatDate(paymentRequest.date, 'DD/MM/YYYY') }}</span>
                          <span>{{ paymentRequest.creditorBankCode + '-' + paymentRequest.creditorBankBranch + '-' +
                            paymentRequest.creditorBankAccount }}</span>
                          <span class="">
                            <strong class="" :class="{ ' red': isRejected(paymentRequest) }">{{
                              $ml.get('paymentRequest.status.' + paymentRequest.status)
                              }}</strong>
                          </span>
                          <h6 class="last-inline">
                            {{ getCurrencySymbol('ILS') }} {{
                              paymentRequest.amount | formatComma }}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer v-if="!isMobileDevice" />
      </div>
    </div>
    <Drawer ref="paymentRequestDrawer" :title="''" @closed="isConfirming = false;" :drawerId="'paymentRequestDrawer'"
      class="drawer-wrapper-card flex flex-column justify-between custom-closer"
      :class="{ 'custom-closer': isConfirming }">
      <template v-slot:content>
        <div class="drawer-container " :class="{ 'small-drawer': isConfirming }">
          <div v-if="!isConfirming" class="flex flex-column prevent-close w-100 h-100">
            <h2 style="text-align: center;margin-bottom: 10px;">{{ $ml.get('')}}</h2>
            <div class="top-bottom-container flex flex-column justify-between h-100">
              <div class="top">
                <div class="request-info w-100">
                  <div class="request-title">
                    <div>
                      {{ selectedPaymentRequest.creditorName }}
                    </div>
                    <h1>
                      {{ selectedPaymentRequest.amount | formatComma }} ILS
                    </h1>
                  </div>
                  <div class="request-data w-100">
                    <div class="flex justify-between w-100">
                      <div>Time of the request</div>
                      <div> {{ formatDate(selectedPaymentRequest.date, 'HH:mm DD/MM/YYYY') }} </div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>{{ $ml.get('validUntill') }}</div>
                      <div>{{ formatDate(selectedPaymentRequest.expiryDate, 'HH:mm DD/MM/YYYY') }}</div>
                    </div>
                    <div v-if="isPending()" class="flex justify-between w-100">
                      <div> Your Balance</div>
                      <div :class="{ 'red': !isSufficient() }">{{ $store.getters.getWalletBalance('ILS') | formatDecimal
                      }}
                        ILS</div>
                    </div>
                    <div v-if="selectedPaymentRequest.customerAccount && selectedPaymentRequest.customerBankBranch"
                      class="flex justify-between w-100">
                      <div> {{ $ml.get('yourAccount') }}</div>
                      <div>{{ '69-' + selectedPaymentRequest.customerBankBranch + '-' +
                        selectedPaymentRequest.customerAccount }}</div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>To account</div>
                      <div>{{ selectedPaymentRequest.creditorBankCode + '-' + selectedPaymentRequest.creditorBankBranch
                        + '-' + selectedPaymentRequest.creditorBankAccount }}</div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>Transfer Type</div>
                      <div>{{ 'Immidiate' }}</div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>Status</div>
                      <div class="approved" :class="{
                        'canceled': isRejected()
                      }">{{ $ml.get('paymentRequest.status.' +
                        selectedPaymentRequest.status) }} </div>
                    </div>
                  </div>
                </div>
                <div v-if="isPending() && isSufficient()" class="explanation" v-html="$ml.get('paymentRequestExplain')"></div>
              </div>
              <div class="bottom flex justify-center bottom prevent-close">
                <div class="w-100 flex justify-center flex-column align-center" v-if="isSufficient() && isPending()">
                  <strong>Check details before confirming payment</strong>
                  <button @click="approvePayment" class="btn btn-main">Approve Payment</button>
                  <div class="flex">
                    <img src="../assets/mygmt_v2_images/Delete.svg" alt="" srcset="">
                    <div style="text-align: center;" @click="isConfirming = true"
                      class="w-100 red prevent-close pointer">Reject the Payment</div>
                  </div>
                </div>
                <div class="w-100 flex justify-center flex-column align-center"
                  v-else-if="!isSufficient() && isPending()">
                  <strong>Insufficient Balance</strong>
                  <button @click="closePaymentDetails" class="btn btn-main prevent-close"> Go back to requests list
                  </button>
                  <div @click="isConfirming = true" style="text-align: center;"
                    class="w-100 red prevent-close flex justify-center">
                    <div class="flex justify-center align-center prevent-close"><img
                        src="../assets/mygmt_v2_images/Delete.svg" alt="" srcset=""></div>
                    <div class="prevent-close">Reject the request</div>
                  </div>
                </div>
                <div class="w-100 flex justify-center flex-column align-center" v-else>
                  <strong>Payment Request Was {{ $ml.get('paymentRequest.status.' + selectedPaymentRequest.status) }}
                  </strong>
                  <button @click="closePaymentDetails" class="btn btn-main">Go back to requests list</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-column align-center delete-confirmation-container">
              <img src="../assets/mygmt_v2_images/Cloud.svg" alt="">
              <h2>Reject this Payment Request?</h2>
              <p>{{ selectedPaymentRequest.creditorName }}</p>
              <h1> {{ selectedPaymentRequest.amount | formatComma }} ILS</h1>
              <button @click.stop="rejectPayment" style="background-color: #FF525D;height: 45px;"
                class="w-100 btn btn-main red-bg">Yes, Reject this
                payment</button>
              <div href="" class="prevent-close" style="color: #0E1F81;;" @click="isConfirming = false">Go back</div>
            </div>
          </div>
        </div>
      </template>
    </Drawer>
    <DesktopMenu backgroundColor="rgba(111, 111, 111, 0.30)" backgroundFilter="blur(7.5px)" width="450px"
      :showCloseButton="true" position="center" name="payment-request-menu" :isSideMenuVisible="true">
      <template>
        <div class="drawer-container" :class="{ 'small-drawer': isConfirming }">
          <div v-if="!isConfirming" class="flex flex-column prevent-close w-100 h-100">
            <h2 style="text-align: center;margin-bottom: 10px;">Payment Request</h2>
            <div style="height: 95%;" class="top-bottom-container flex flex-column justify-between ">

              <div class="top">
                <div class="request-info w-100">
                  <div class="request-title">
                    <div>
                      {{ selectedPaymentRequest.creditorName }}
                    </div>
                    <h1>
                      {{ selectedPaymentRequest.amount | formatComma }} ILS
                    </h1>
                  </div>
                  <div class="request-data w-100">
                    <div class="flex justify-between w-100">
                      <div>Time of the request</div>
                      <div> {{ formatDate(selectedPaymentRequest.date, 'HH:mm DD/MM/YYYY') }} </div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>{{ $ml.get('validUntill') }}</div>
                      <div>{{ formatDate(selectedPaymentRequest.expiryDate, 'HH:mm DD/MM/YYYY') }}</div>
                    </div>
                    <div v-if="selectedPaymentRequest.status === 'PENDING'" class="flex justify-between w-100">
                      <div> Your Balance</div>
                      <div :class="{ 'red': !isSufficient() }">{{ $store.getters.getWalletBalance('ILS') | formatDecimal
                      }}
                        ILS</div>
                    </div>
                    <div v-if="selectedPaymentRequest.customerAccount && selectedPaymentRequest.customerBankBranch"
                      class="flex justify-between w-100">
                      <div> {{ $ml.get('yourAccount') }}</div>
                      <div>{{ '69-' + selectedPaymentRequest.customerBankBranch + '-' +
                        selectedPaymentRequest.customerAccount }}</div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>To account</div>
                      <div>{{ selectedPaymentRequest.creditorBankCode + '-' + selectedPaymentRequest.creditorBankBranch
                        + '-' + selectedPaymentRequest.creditorBankAccount }}</div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>Transfer Type</div>
                      <div>{{ 'Immidiate' }}</div>
                    </div>
                    <div class="flex justify-between w-100">
                      <div>Status</div>
                      <div class="approved" :class="{
                        'canceled': isRejected()
                      }">{{ $ml.get('paymentRequest.status.' +
                        selectedPaymentRequest.status) }} </div>
                    </div>
                  </div>
                </div>
                <div v-if="isPending()" class="explanation" v-html="$ml.get('paymentRequestExplain')" >
                </div>
              </div>
              <div class="bottom flex justify-center bottom prevent-close">
                <div class="w-100 flex justify-center flex-column align-center" v-if="isSufficient() && isPending()">
                  <strong>Check details before confirming payment</strong>
                  <button @click="approvePayment" class="btn btn-main">Approve Payment</button>
                  <div class="flex">
                    <img src="../assets/mygmt_v2_images/Delete.svg" alt="" srcset="">
                    <div style="text-align: center;" @click="isConfirming = true"
                      class="w-100 red prevent-close pointer">Reject the Payment</div>

                  </div>
                </div>

                <div class="w-100 flex justify-center flex-column align-center"
                  v-else-if="!isSufficient() && isPending()">
                  <strong>Insufficient Balance</strong>
                  <button @click="closePaymentDetails" class="btn btn-main prevent-close"> Go back to requests list
                  </button>
                  <div @click="isConfirming = true" style="text-align: center;"
                    class="w-100 red prevent-close flex justify-center pointer">
                    <div class="flex justify-center align-center prevent-close"><img
                        src="../assets/mygmt_v2_images/Delete.svg" alt="" srcset=""></div>
                    <div class="prevent-close">Reject the request</div>
                  </div>
                </div>

                <div class="w-100 flex justify-center flex-column align-center" v-else>
                  <strong>Payment Request Was {{ $ml.get('paymentRequest.status.' + selectedPaymentRequest.status) }}
                  </strong>
                  <button @click="closePaymentDetails" class="btn btn-main">Go back to requests list</button>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="flex flex-column align-center delete-confirmation-container">
              <img src="../assets/mygmt_v2_images/Cloud.svg" alt="">
              <h2>Reject this Payment Request?</h2>
              <p>{{ selectedPaymentRequest.creditorName }}</p>
              <h1>{{ selectedPaymentRequest.amount | formatComma }}ILS</h1>
              <button @click.stop="rejectPayment" style="background-color: #FF525D;height: 45px;"
                class="w-100 btn btn-main red-bg">Yes, Reject this
                payment</button>
              <div href="" class="prevent-close pointer" style="color: #0E1F81;;" @click="isConfirming = false">Go back
              </div>
            </div>
          </div>
        </div>
      </template>
    </DesktopMenu>

    <DesktopMenu class="payment-status-menu" backgroundColor="rgba(111, 111, 111, 0.30)" backgroundFilter="blur(7.5px)"
      :position="isMobileDevice ? 'fullscreen' : 'center'" :showCloseButton="true" name="payment-status-menu"
      :isSideMenuVisible="true" :width="isMobileDevice ? '' : '40%'" :height="isMobileDevice ? '' : '100%'">
      <template v-slot:default="{ dynamicProp }">
        <div v-if="dynamicProp === 'sucsess'"
          class="flex flex-column  align-center justify-between h-100 payment-status-menu">
          <div class="flex flex-column  align-center">
            <img src="../assets/mygmt_v2_images/BANK.svg" alt="" srcset="">
            <h1>The money successfully transferred!</h1>
            <div class="info-container flex flex-column justify-center align-center">
              <div class="title">{{ selectedPaymentRequest.creditorName }}</div>
              <div class="green"> {{ selectedPaymentRequest.amount | formatComma }} ILS </div>
            </div>
          </div>
          <div class="btn btn-main" @click="closeStatusMenu"> Back to homepage</div>
        </div>
        <div v-else class="flex flex-column  align-center justify-between h-100 payment-status-menu">
          <div class="flex flex-column  align-center">
            <img src="../assets/mygmt_v2_images/Error.svg" alt="" srcset="">
            <h1>Something went wrong</h1>
            <div style="color: black;text-align: center;line-height:50px;">We apologize, but we couldn’t complete the
              transaction. <br>
              Please contact us for further assistance.
            </div>
          </div>
          <div class="btn btn-main" @click="closeStatusMenu"> Back to homepage</div>
        </div>
      </template>
    </DesktopMenu>
  </div>
</template>
<script>

import Header from "../components/Header.vue";
import Footer from "@/components/Footer.vue";
import { getPreviousMonths, isApp, isMobile, getCurrencySymbol } from "@/services/utils";
import { getPaymentRequests, approvePaymentRequest, getPaymentRequestById, rejectPaymentRequest } from "@/services/api";
import Drawer from "@/components/Drawer.vue";
import DesktopMenu from "../components/DesktopMenu.vue";
import "flatpickr/dist/flatpickr.min.css";
import { EventBus } from "../event-bus.js";

export default {
  name: 'PaymentRequestsHistory',
  components: {
    Drawer,
    Header,
    Footer,
    DesktopMenu
  },
  data() {
    return {
      isMobileDevice: isMobile(),
      months: getPreviousMonths(),
      monthsReversed: getPreviousMonths().reverse(),
      isApp: isApp(),
      busy: false,
      selectedPaymentRequest: {},
      isConfirming: false,
      page: 0,
      paymentRequests: [],
      selectedMonth: {},
      selectedCard: {},
      max: false,
      monthPickerSplide: null,
      filterData: {
        fromDate: moment().startOf('month').format('DD/MM/YYYY'),
        toDate: moment().format('DD/MM/YYYY'),
      },
    }
  },
  computed: {
    getDir() {
      return this.$store.getters.getDir;
    },
    getSelectedCard() {
      return this.selectedCard
    },
    getLang() {
      return this.$store.getters.getLang;
    },
    getConditionsNum() {
      let count = 0;
      Object.entries(this.filterData).forEach((item) => {
        if (item[1].length > 0 && (item[0] !== 'fromDate') && (item[0] !== 'toDate')) {
          count++;
        }
      })
      return count;
    },
  },
  created() {
    const paramsPaymentRequest = JSON.parse(localStorage.getItem('paymentRequestData'))
    EventBus.$emit('load')
    window.scrollTo(0, 0);
    if (!this.maxFetch) {
      this.addListener();
    }
    this.loadTransactions(this.page).then((() => {
      if (paramsPaymentRequest) {
        const foundRequest = this.paymentRequests.find((request) => request.endToEndId === paramsPaymentRequest.id)
        if (foundRequest && this.isPending(foundRequest)) {
          this.openPaymentDetails(foundRequest)
          EventBus.$emit('unload')
          localStorage.removeItem('paymentRequestData')
        } else {
          getPaymentRequestById(paramsPaymentRequest.id).then((paymentRequest) => {
            if (paymentRequest && this.isPending(paymentRequest)) {
              this.openPaymentDetails(data)
            }
            EventBus.$emit('unload')
            localStorage.removeItem('paymentRequestData')
          })
        }
      } else {
        EventBus.$emit('unload')
        localStorage.removeItem('paymentRequestData')
      }
    }));

  },
  beforeDestroy() {
    this.removeListener();
  },
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    document.body.setAttribute('dir', this.getDir);
    let currentMonthIndex = 0;
    if (this.isMobileDevice) {
      currentMonthIndex = this.monthsReversed.length - 1;
      let splideOptions = {
        isNavigation: true,
        direction: this.getLang === 'il' ? 'rtl' : 'ltr',
        arrows: false,
        pagination: false,
        type: 'slide',
        focus: 'center',
        perPage: 4,
        trimSpace: false,
        start: currentMonthIndex,
        classes: {
          pagination: 'splide__pagination carousel-slide-pagination',
          page: 'splide__pagination__page carousel-slide-pagination-page',
        },
      };
      this.monthPickerSplide = new Splide('#monthPicker', splideOptions);
      this.monthPickerSplide.mount();
      let self = this;
      this.monthPickerSplide.on('active', function (e) {
        self.selectedMonth = self.monthsReversed[e.index]
        self.monthChangeHandler()
      });
    } else {
      this.selectedMonth = this.months[currentMonthIndex]
    }
  },
  methods: {
    getCurrencySymbol,
    isSufficient(paymentRequestParam) {
      const paymentRequest = paymentRequestParam || this.selectedPaymentRequest
      return paymentRequest.amount < this.$store.getters.getWalletBalance('ILS')
    },
    isPending(paymentRequestParam) {
      const paymentRequest = paymentRequestParam || this.selectedPaymentRequest
      return paymentRequest.status === 'PENDING'
    },
    isRejected(paymentRequestParam) {
      const paymentRequest = paymentRequestParam || this.selectedPaymentRequest
      return paymentRequest.status === 'REJECTED' || paymentRequest.status === 'CANCELED'
    },
    openPaymentDetails(paymentRequest) {
      this.selectedPaymentRequest = paymentRequest
      if (this.isMobileDevice) {
        this.openPaymentRequestDrawer()
      } else {
        this.togglePaymentRequestsMenu()
      }
    },
    formatDate(date, format) {
      return moment(date).format(format)
    },
    closePaymentDetails() {
      this.selectedPaymentRequest
      if (this.isMobileDevice) {
        this.$refs.paymentRequestDrawer.closeDrawer()
      } else {
        this.togglePaymentRequestsMenu()
      }
    },
    openStatusMenu(type) {
      EventBus.$emit('toggleMenu', null, 'payment-status-menu', type);
    },
    closeStatusMenu() {
      EventBus.$emit('toggleMenu', null, 'payment-status-menu')
    },
    openPaymentRequestDrawer() {
      this.$refs.paymentRequestDrawer.openDrawer()
    },
    togglePaymentRequestsMenu() {
      EventBus.$emit('toggleMenu', null, 'payment-request-menu');
    },
    async approvePayment() {
      this.isMobileDevice ? this.$refs.paymentRequestDrawer.closeDrawer() : EventBus.$emit('toggleMenu', null, 'payment-request-menu')
      EventBus.$emit('load')
      const res = await approvePaymentRequest(this.selectedPaymentRequest.endToEndId)
      if (res) {
        this.selectedPaymentRequest.status = 'COMPLETED'
      }
      EventBus.$emit('unload')
      this.openStatusMenu(res && !res.errorCode ? 'sucsess' : 'failed')
    },

    async rejectPayment() {
      const response = await rejectPaymentRequest(this.selectedPaymentRequest.endToEndId)
      if (response) {
        this.selectedPaymentRequest.status = 'REJECTED'
      }
      this.isMobileDevice ? this.$refs.paymentRequestDrawer.closeDrawer() : EventBus.$emit('toggleMenu', null, 'payment-request-menu')
      this.isConfirming = false
    },

    infiniteScroll() {
      if (this.max) {
        this.page = 0;
        return
      }
      if ($(window).scrollTop() + window.innerHeight >= document.body.scrollHeight - 1) {
        if (this.busy) return;
        this.busy = true;
        this.loadTransactions(++this.page, true)
      }
    },
    async monthChangeHandler() {
      let startDate = moment([this.selectedMonth.year, parseInt(this.selectedMonth.month) - 1, 1])
      let endDate = startDate.clone().endOf('month')
      this.page = 0
      this.filterData.fromDate = startDate.format('DD/MM/YYYY')
      this.filterData.toDate = endDate.format('DD/MM/YYYY')
      await this.loadTransactions();
    },
    async loadTransactions(page, scroll) {
      this.max = false
      let data = await getPaymentRequests({ 'page': this.page, fromDate: this.filterData.fromDate, toDate: this.filterData.toDate });
      if (data && page === 0) {
        this.$store.commit('setCachedPaymetRequests', data)
      }
      this.max = scroll && !data.length
      this.paymentRequests = scroll ? [...this.paymentRequests || [], ...data || []] : data
      if (this.maxFetch) {
        this.paymentRequests = _.take(this.paymentRequests, parseInt(this.maxFetch))
      }
      this.busy = false;
    },
    addListener() {
      window.addEventListener('scroll', this.infiniteScroll, true);
      window.addEventListener('touchmove', this.infiniteScroll, true);
    },
    removeListener() {
      window.removeEventListener('scroll', this.infiniteScroll, true);
      window.removeEventListener('touchmove', this.infiniteScroll, true);
      document.removeEventListener('click', this.handleClickOutside);
    },
  },
}

</script>

<style lang="scss" scoped>
@import "../assets/styles/partials/variables";
@import "./../../node_modules/select2/dist/css/select2.css";

.header-grid-box {
  padding-top: 25px;
  background-color: white;
}

.custom-closer {
  ::v-deep .go-back-wrapper {
    position: absolute;
    top: 15px;
    right: 5%;

  }

  ::v-deep .head-line {
    height: 0;
    padding: 0;
  }
}

::v-deep .close-button {
  top: 18px;
  right: 15px;
}

::v-deep .payment-status-menu .menu-content {
  background-image: url(../assets/mygmt_v2_images/mygmt2-background.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: 125px;
}

.red {
  color: #FF0B0B;
}

.drawer-container {
  height: 80vh;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;

  .request-info {
    border-radius: 20px;
    border: 1px solid #DADADA;
    box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    margin-bottom: 20px;
    display: inline-flex;
    height: 330px;
    padding: 25px 20px 15px 20px;
    width: 100%;
    flex-direction: column;
    align-items: center;

    .request-title {
      border-radius: 10px;
      border: 1px solid #DADADA;
      width: 100%;
      padding: 5px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-bottom: 25px;
    }

    .request-data {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
    }


  }

}

.small-drawer {
  height: 300px;
}


.bottom {
  // background-color: red; 

  strong {
    color: #000;
    font-family: Assistant;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 10px;
  }

  .btn {
    width: 100%;
    height: 45px;
  }

  .red-bg {
    background-color: #FF525D;
    ;
  }

  .red {
    color: #FF525D;
  }

  .blue {
    color: #0E1F81;
  }

  .btn-main {
    margin-bottom: 25px
  }
}

::v-deep .drawer-wrapper {
  padding: 45px 17px 35px 17px
}

.mygmt2-page-wrapper-container-center {
  // background-color: #F6F8F9;
  padding-bottom: 10px !important;
  height: 100%;
}

.payment-balances-card-grid-box {
  // background-color: #f6f8f9;
  border-top: 1px solid #e9e9e9;
  overflow-y: auto;
}

.mygmt2-page-wrapper-container {
  background-image: none;
  // background-color: #f6f8f9;
  height: 100%;

}

.canceled {
  background-color: #FFD8D8 !important;
  color: #FF0B0B !important;
  padding: 5px;
  border-radius: 27px;
}

.approved {
  background: #E3FFE6;
  color: #06A947;

  padding: 5px 10px;
  border-radius: 27px;
}


.payment-status-menu {

  padding: 20px 0px 20px 0px;

  h1 {
    color: #000;
    text-align: center;
    font-family: Assistant;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 107.692% */
    margin-top: 20px;
  }

  .info-container {
    padding: 10px 0px;
    border-radius: 10px;
    border: 1px solid #DADADA;
    width: 350px;
    margin-top: 20px
  }

  .title {
    color: #000;
    // font-family: Assistant;
    font-size: 16px;
    // font-style: normal;
    font-weight: 400;
    // line-height: normal;
  }

  .btn-main {
    width: 330px;
    height: 45px;
    line-height: 45px;
  }

  .green {
    color: #06A947;
    font-family: Assistant;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }


}

.top {
  max-height: 75%;
  overflow-y: auto;

  &::-webkit-scrollbar-button {
    background-image: url('');
    background-repeat: no-repeat;
    width: 5px;
    height: 0px
  }
}

.filter-drawer-wrapper {
  font-size: 14px;
  color: black;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 95%;

  h1 {
    font-size: 14px;
    font-weight: normal;
  }

  .custom-date-picker {
    width: 100%;

    .custom-date-picker-container {
      border: 1px solid #E4E8EB;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 48px;
      font-weight: 400;
      font-size: 14px;
      padding: 10px;

      .fa-calendar {
        margin-inline-start: 2px;
      }
    }
  }

  .direction-column {
    .direction-btn {
      color: black;
      margin-inline: 5px;
      font-size: 14px;
      font-weight: normal;
      box-shadow: 0 0 14px 0 #0000000F;
      width: 100%;
      padding: 0 2px;
      height: 36px;
    }

    .btn-primary {
      font-weight: bold;
      color: white;
    }
  }

  .delete-confirmation-container {
    height: 100%;

    h2 {
      margin-bottom: 15px;
    }

  }

  .btn-line {
    padding: 10px 0;

    .btn {
      width: 100%;
      height: 45px;
    }
  }

  .filter-container {
    width: 100%;
    margin: 10px 0;

    h1 {
      margin-bottom: 10px;
    }
  }

  #transaction-filter-errors {
    display: none;
    color: red;
    font-weight: normal;
  }
}

.explanation {
  padding: 0px 10px;
  // line-break: anywhere;
  max-height: 150px;
}

.delete-confirmation-container {
  height: 300px;
  gap: 10px;

  h2 {
    // margin-bottom: 15px;
  }

}

.mygmt2-tag {
  direction: ltr;
  display: flex;
  background-color: $mainColor;
  color: white;
  border-radius: 72px;
  padding: 8px;
  font-weight: bold;
  align-items: center;
  width: 210px;
  margin: auto;

  .go-back-wrapper {
    width: auto;
    color: white;
    margin-inline-start: 5px;
    margin-block-start: 1px;
  }
}

.btn-border {
  font-weight: normal;
}

.black-text {
  color: black !important;
}

.mygmt2-select {
  width: 100%;
  border: 1px solid #E4E8EB;
  border-radius: 5px;
  text-align: start;
  font-weight: normal;
}

.mygmt2-month-select {
  border: none;
  border-radius: 50px;
  width: 175px;
  height: 55px;
  padding: 0 15px;
  font-size: 16px;
  font-weight: 600;
}

.transaction-list-carousel {
  padding-top: 25px;
}

.flex-actions {
  display: flex;
  height: 100px;
  padding: 10px;
  background-color: white;
  margin: 10px 0;
  border-radius: 20px;
}

.cards-page-desktop-flex {
  display: block;
}

.transaction-list-filter-container {
  padding-top: 15px;

  h6 {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
  }

  .transaction-list-filter-wrapper {
    background-color: white;
    border-radius: 30px;
    width: 90%;
    margin: auto;
    padding: 5px;
    border: 1px solid #EBEBEB;
    height: 55px;
  }

  .transaction-list-flex {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px;

    img {
      padding-top: 4px;
    }

  }

  .btn-flex {
    width: 50px;
    height: 38px;
    padding: 0 10px;
    gap: 5px;
    justify-content: flex-end;
    background: linear-gradient(244.68deg, #137FE2 -2.13%, #2713A0 147.06%);

    span {
      font-size: 14px;
    }
  }

  .transaction-list-placeholder {
    border: none;
    color: black;
    font-weight: 400;
    font-size: 14px;
    opacity: 0.6;
    width: 120px;
  }
}

.title {
  color: black;
}

.flatpickr-calendar {
  left: auto !important;
}

.datepicker-mobile {
  left: auto !important;
}

:v-deep.flatpickr-calendar.open {
  // display: inline-block;
  // z-index: 99999;
  left: auto !important;
}

.transaction-list-card-container {
  // padding: 0 5%;
  border-radius: 20px;
  height: auto;
  background-color: white;
  margin-top: 10px;
  box-shadow: 0px -3px 18px 0px #D2D2D240;
  overflow-y: auto;
  overflow-x: clip;


  .transaction-list-card-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 15px 15px;
  }

  h6,
  h5,
  h4 {
    color: black;
    font-weight: 590;
    white-space: nowrap;
    overflow: hidden;
    font-size: 14px;
  }

  h4 {
    font-weight: 700;
  }

  h6 {
    font-size: 16px;
  }

  .red {
    color: #FF0B0B !important;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    opacity: 1 !important;
  }

  .green {
    color: rgb(57, 181, 74) !important;
    font-family: Assistant;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 600 !important;
    opacity: 1 !important;
  }


  .transaction-list-container {
    margin-top: 10px;

    .line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 15px;
      font-size: 14px;
      min-height: 40px;
      cursor: pointer;

      .transaction-details-wrapper {
        min-width: 100px;
      }

      .transaction-details-left {
        text-align: start;
      }

      .transaction-details-right {
        text-align: end;
      }

      span {
        color: black;
        opacity: .6;
        font-size: 12px;
      }

      .subtitle-span {
        opacity: 1;
      }
    }

    .mygmt2-transactions-desktop-line {
      // padding: 0 5px;
      border-radius: 8px;
      padding: 10px 15px;



      &:hover {
        background-color: #F0FAFF;
      }

      h6 {
        width: 200px;
      }

      span {
        width: 200px;
      }

      .last-inline {
        text-align: end;
      }
    }

    .canceled-box {
      display: flex;
      justify-content: space-between;
      align-items: end;
      white-space: nowrap;
    }

    .canceled-transaction {
      background: #FAFAFA;
      box-sizing: content-box;
      border-radius: 0;
      // margin-left: -20px;
      // margin-right: -15px;
      // padding-left: 25px;
      // padding-right: 19px;

      h6.last-inline {
        // padding-right: 10px;
        // padding-left: 9px;
      }

    }

    [dir="rtl"] .canceled-transaction {
      padding-right: 19px;

      h6.last-inline {
        padding-left: 8px;
      }
    }
  }


  .no-transactions-container {
    flex-direction: column;
    color: grey;
    padding: 16px;

    h6 {
      margin: 10px;
      font-size: 20px;
      font-weight: bold;
    }
  }


}


@media only screen and (min-width: $screen-tablet) {
  .header-grid-box {
    background-color: inherit;
  }

  .small-drawer {
    height: 300px;
  }

  .transaction-list-container {
    height: auto;
    min-height: 400px;
    max-height: 500px;


    .transaction-list-grid-box {
      height: 100%;
      background-color: white !important;
    }
  }

  .transaction-list-card-container {
    background-color: white;
    height: 100%;
    margin-top: 0;
    // padding: 15px 15px 15px 24px;
    box-shadow: none;
  }

  .mygmt2-header-wrapper {
    padding: 0;
  }

  .cards-page-desktop-flex {
    display: flex;
    margin-block: 10px;
  }

  .payment-balances-card-grid-box {
    grid-area: 2 / 1 / 13 / 14;
    overflow-y: visible;
    overflow-x: clip;
    // background-color: #f6f8f9;
    border-top: none;
    max-height: 90vh;
  }

  .transaction-list-grid-box {
    grid-area: 6 / 1 / 13 / 14;
  }


  .payment-status-menu {
    padding: 100px 50px 50px 50px;

  }

  .transaction-list-filter-container {
    padding-top: 0;

    .transaction-list-filter-wrapper {
      width: 600px;
      padding: 0 10px;
      margin: 0;
      height: 55px;
    }
  }
}
</style>