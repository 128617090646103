<template>
  <div class="">
      <div style="text-align: center;">
        <img style="width: 45px;" src="../../../../assets/mygmt_v2_images/Card.svg" alt="">
        <h4 class="black">{{ $ml.with('VueJS').get('paymentTypeCredit') }}</h4>
        <h1 class="black">{{ amountToPay }} ILS </h1>
        <div v-if="paymentData.installments && paymentData.numOfPayments > 1">
                    <label>₪{{ getInstallmentAmount }} * {{ paymentData.numOfPayments }}
                      {{ $ml.get('installments') }}</label>
                  </div>

      </div>
      <!-- <div class="form-group">
        <label for="nameOnCard">Name on card</label>
        <input
          id="nameOnCard"
          v-model="formData.nameOnCard"
          type="text"
          :class="['form-input', { 
            'has-error': errors.nameOnCard 
          }]"
          placeholder=""
          @input="formatnameOnCard"
          @blur="validateField('nameOnCard')"
        />
        <label for="creditCard">Credit Card Number</label>
        <input
          id="creditCard"
          v-model="formData.creditCard"
          type="text"
          :class="['form-input', { 
            'has-error': errors.creditCard 
          }]"
          placeholder="4111 1111 1111 1111"
          @input="formatCreditCard"
          @blur="validateField('creditCard')"
        />
        <span class="error-message">{{ errors.creditCard }}</span>
      </div> -->

      <!-- Card Details -->
      <!-- <div class="card-details">
        <div class="form-group">
          <label for="expiration">Expiration Date</label>
          <input
            id="expiration"
            v-model="formData.expiration"
            type="text"
            :class="['form-input', { 
              'has-error': errors.expiration 
            }]"
            placeholder="MM/YY"
            @input="formatExpiration"
            maxlength="5"
            @blur="validateField('expiration')"
          />
          <span class="error-message">{{ errors.expiration }}</span>
        </div>

        <div class="form-group">
          <label for="cvv">CVV</label>
          <input
            id="cvv"
            v-model="formData.cvv"
            type="text"
            :class="['form-input', { 
              'has-error': errors.cvv 
            }]"
            placeholder="123"
            maxlength="3"
            @blur="validateField('cvv')"
          />
          <span class="error-message">{{ errors.cvv }}</span>
        </div>
      </div> -->
      <div>
        <!-- <iframe height="900px" src="http://127.0.0.1:5500/gmt_1_eng.htm"></iframe> -->
        <iframe height="900px" :src="url"></iframe>
      </div>
  </div>
      </template>
<script>
import _ from 'lodash';


export default {
  components: {},
  name: 'TransferStatus',
  props: { type: {
      type: String,
      required: false,
      default: 'ERROR'
    },
    amountToPay: {
    type: Number,
    required: true
  }},
  created() {
    this.paymentData = JSON.parse(sessionStorage.getItem("cc_tempInfo"));
    console.log("🚀 ~ created ~ this.paymentData:", this.paymentData)
    
  },
  data() {
    return {


    };
  },
  computed: {
    url() {
      return this.paymentData.url;
    },
    getInstallmentAmount(){
     const foundMatchingInstallment = this.paymentData.installments.find(installmentData => installmentData.numOfPayments == this.paymentData.numOfPayments)
     return foundMatchingInstallment.installment
    },

  },
  watch: {
  },
  mounted() {
    
  },
  beforeDestroy() { },
  methods: {
    
  },

};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";
.tool-tip {
  color: black;
  background-color: #E3F3FF;
  padding: 10px;

  color: var(--Main-Blue, #0E1F81);
  font-feature-settings: 'liga' off, 'clig' off;
  font-family: Assistant;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  border-radius: 4px;

  img {
    margin-right: 5px;
  }
}

.title {
  color: #000;
  font-family: Assistant;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 10px;
}

.selected-value {
  font-weight: 600;
}


.search-box-container {
  padding-top: 10px;
  padding-left: 20px;
  background: white;
  padding-bottom: 1px;
  padding-right: 20px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  filter: drop-shadow(0px 9px 5px rgba(0, 0, 0, 0.05));

  .search-box {
    display: flex;
    align-items: center;
    cursor: text;
    padding-bottom: 17px;
    padding-top: 17px;

    img {
      width: 23px;
      height: 23px;
    }

    input {
      border: none;
      font-size: 16px;
      width: 100%;
      margin-left: 10px;
    }
  }
}


.menu-content {
  max-height: 220px;
  overflow-y: auto;
  padding-left: 0;
  padding-right: 0;
}



::v-deep .menu-content {
  padding: 0px 0px 0px 0px !important;
}


iframe {
  border: none;
  width: 100%;
}

.iframe-box {
  position: relative;
  margin-bottom: 10px;
}

.before-iframe-box {
  margin: 0px auto 12px;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 15px;
  margin-top: 10px;
}

.menu-item {
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 15px;

  &:hover {
    border-bottom: 1px solid #EEE;
    border-top: 1px solid #EEE;
    background: #F1F6F9;
  }
}

.form-container {
  max-width: 600px;
  /* margin: 0 auto; */
  padding-top: 20px;
}

.custom-select {
  color: black;
  display: flex;
  align-items: center;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
  cursor: pointer;
  position: relative;
}

.area-code {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;


}

.disabled {
  background-color: #cccccc61;
}

.seperator-container {
  border-top: 2px solid #ddd;
  border-bottom: 2px solid #ddd;
  padding: 5px 0px 5px 0px;
}

.seperator {
  border: 1px solid #ddd;
  width: 1px;
  height: 100%;

}

.phone-number {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left: none !important;

}

.payment-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.name-row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.name-field {
  flex: 1;
}

.phone-input-container {
  display: flex;
  /* gap: 10px; */
}

.area-code-select {
  width: 120px;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  background-color: white;
}

.phone-number-input {
  flex: 1;
}

.card-details {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

h4 {
font-weight: 400;
}

label {
  font-size: 14px;
  font-weight: 500;
  color: #333;
}

.form-input {
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.form-input:focus,
.area-code-select:focus,
.seperator-container:focus {
  outline: none;
  border-color: #2196f3;

}

.phone-input-container:focus-within .area-code-select,
.phone-input-container:focus-within .phone-number-input,
.phone-input-container:focus-within .seperator-container,
.phone-input-container:focus-within .seperator {
  border-color: #2196f3;
  /* background-color: #2196f3; */
}


.form-input.has-error,
.area-code-select.has-error,
.seperator-container.has-error {
  border-color: #ff5252;
}

.seperator.has-error {
  border-color: #ff5252;
}

.error-message {
  color: #ff5252;
  font-size: 12px;
  /* min-height: 16px; */
}

.submit-button {
  padding: 12px 24px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #1976d2;
}

.submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media only screen and (min-width: $screen-tablet) {
  
  
}
</style>
