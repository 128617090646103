<template>
  <div :dir="$store.getters.getDir">
    <div  v-if="!showCreditCard" class="payment-container">
    <div class="title black">
      <div class="paywith" v-html="$ml.with('amount', formatComma(amountToPay)  ).with('currency', 'ILS').get('transfer.paymentMethods.titles.payingWith')"></div>
    </div>
    <div class="payment-types">
      <div @click="walletBalance < amountToPay ? '' : showWalletPaymentApprovalModal()" class="payment-type flex justify-between pointer relative" :class="{ 'grey-unaviable': walletBalance < amountToPay}">
        <Tag v-if="walletBalance < amountToPay"  backgroundColor="#FFF0CB"
        textColor="#BF7E1D" :text="$ml.get('tags.unavailable')"></Tag>
        <div class="flex flex-column" >
          <div class="title flex"> <img src="../../../../assets/mygmt_v2_images/transfer_methods/WALLET_TRANSFER.svg"
              alt="" srcset=""> <span class="flex full-center">{{$ml.get('wallet.menu')}}</span></div>
          <div class="body">   {{ $ml.get('transfer.paymentMethods.subtitles.walletBalance') }}: <span> {{ walletBalance | formatComma }} ILS </span>
          </div>
        </div>
        <div class="flex full-center"> <img v-if="walletBalance > amountToPay" :class="{'rotate': isRtl}" src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
        
      </div>
      <div @click="isCreditCardAviable ? payByCreditCard(): ''" class="payment-type flex justify-between pointer relative" :class="{ 'grey-unaviable': !isCreditCardAviable}">
        <Tag v-if="!isCreditCardAviable"  backgroundColor="#FFF0CB"
        textColor="#BF7E1D" :text="$ml.get('tags.unavailable')"></Tag>
        <div class="flex flex-column">
          <div class="title flex"> <img  src="../../../../assets/mygmt_v2_images/transfer_methods/CARD_DEPOSIT.svg"
              alt="" srcset="">
               <span class="flex full-center">{{$ml.get('paymentTypes.CREDIT_CARD')}}</span></div>
          <div class="body"> {{$ml.get('transfer.paymentMethods.subtitles.creditCard') }}.</div>
          <button v-if="isCreditCardAviable && isIsraeli" class="btn btn-border" @click.stop="isCreditCardAviable ? openInstallments(): ''">
          {{$ml.get('installments')}}: {{ installments }} {{$ml.get('PAYMENT')}} 
          <img class="chevron" src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </button>
        <!-- <div v-if="installmentDetails ">₪{{installmentDetails.instalment | formatComma}} / month includes ₪{{ installmentDetails.interest }} fee</div> -->
        <div v-if="installments > 1" class="black" style="margin-top: 10px;"> <strong>{{$ml.get('youllPay')}}:</strong>  {{installment.totalAmount}} ILS ({{$ml.with('interest',(installment.totalAmount - installment.principal).toFixed(2)).get('includesInterest2') }}) </div>
        </div>
        <div class="flex full-center"> <img v-if="isCreditCardAviable" :class="{'rotate': isRtl}" src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
      </div>
      <!-- <div class="payment-type flex justify-between pointer">
        <div class="flex flex-column">
          <div class="title flex"> <img src="../../../../assets/mygmt_v2_images/transfer_methods/BANK_TRANSFER.svg"
              alt="" srcset=""> <span class="flex full-center">Bank transfer</span></div>
          <div class="body"> Transfer directly to a bank account</div>
        </div>
        <div class="flex full-center"> <img src="../../../../assets/mygmt_v2_images/Right-Chevron-blue.svg" alt="">
        </div>
      </div> -->
    </div>

    <!-- <modal ref="walletModal" class="modal-container2" :noCancelBtn="true" icon="fas fa-wallet" icon-style="color: white; font-size: 40px"
          :title="$ml.get('wallet.payWith')" :confirm="$ml.get('pay')" @action="payByWallet">
          <p style="font-size: 30px">{{ this.getWalletSymbol }}{{ this.amountToPayToPay | formatComma }}</p>
          <hr />

          <p>{{ $ml.get('wallet.payment.body') }} </p>
        </modal>  -->
    <Drawer ref="walletPaymentDrawer" :title="''" @closed="" :drawerId="'walletPaymentDrawer'"
      class="drawer-wrapper-card flex flex-column justify-between" :class="{ 'custom-closer': '' }">
      <template v-slot:heading>
      </template>
      <template v-slot:content>
        <div v-if="beneficiary" class="flex flex-column">
          <div class="modal-title bold">
            {{ $ml.get('beneficiary.walletPayment') }}
          </div>
          <div class="grey-box">
            <div class="flex align-center">
              <img src="../../../../assets/mygmt_v2_images/beneficiary/beneficiary_wallet.svg" alt="" srcset="">
              <div  class="flex flex-column">
                <div class="bold"> {{$ml.get('wallet.menu')}} </div>
                <div class="black"> {{ $ml.get('wallet.main.card.title') }}: {{ walletBalance | formatComma }} ILS</div>
              </div>
            </div>
          </div>

          <div class="grey-box" >
            <div class="flex align-center">
             
                <div class="circle pointer" style="text-align: center;" @click=""><strong>{{
                  beneficiary.firstName[0].toUpperCase()
                    }}</strong></div>
              
              <div class="flex  flex-column">
                <div class="blue bold">{{$ml.get('beneficiary.beneficiary')}}</div>
                <div class="black"> {{ beneficiary.firstName | lowerCaseCapitalise }} {{ beneficiary.lastName | lowerCaseCapitalise}}</div>
              </div>
            </div>
          </div>

          <div class="grey-box">
            <div class="flex justify-between">
              <div class="bold black"> {{$ml.get('totalPayment')}}:</div>
              <div class="bold black"> {{amountToPay | formatComma}} ILS  </div>
            </div>
          </div>

          <p  class="black">{{$ml.get('wallet.payment.body')}}.</p>

          <div class="flex justify-center">
            <button class="btn btn-main" @click="payByWallet">{{$ml.get('wallet.payWith')}}</button>
          </div>
        </div>
      </template>

    </Drawer>

    <DesktopMenu closerRight="true" backgroundColor="rgba(111, 111, 111, 0.30)" backgroundFilter="blur(7.5px)" :showCloseButton="true"
      position="center" width="534px" height="534px" name="walletPaymentMenu" :isSideMenuVisible="false">
      <div v-if="beneficiary" class="flex flex-column wallet-padding">
        <div class="modal-title bold">
          {{ $ml.get('beneficiary.walletPayment') }}
        </div>
        <div class="grey-box">
          <div class="flex align-center">
             <img src="../../../../assets/mygmt_v2_images/beneficiary/beneficiary_wallet.svg" alt="" srcset="">
            <div class="flex flex-column">
              <div> {{$ml.get('wallet.menu')}}</div>
              <div class="black"> {{ $ml.get('wallet.main.card.title') }}: {{ walletBalance | formatComma }} ILS</div>
            </div>
          </div>
        </div>

        <div class="grey-box">
          <div class="flex align-center">
            <div>
              <div class="circle pointer flex justify-center align-center" @click="">
                <strong style="margin: 0 auto;"
                  class="flex justify-center align-center">{{ beneficiary.firstName[0].toUpperCase()
                  }}</strong>
                  </div>
            </div>
            <div class="flex  flex-column">
              <div>{{$ml.get('beneficiary.beneficiary')}}</div>
              <div class="black"> {{ beneficiary.firstName | lowerCaseCapitalise }} {{ beneficiary.lastName | lowerCaseCapitalise}}, {{ selectedCountry[$store.getters.getLang] }}</div>
            </div>

          </div>
        </div>

        <div class="grey-box">
          <div class="flex justify-between">
            <div class="bold black"> {{$ml.get('totalPayment')}}:</div>
            <div class="bold black"> {{amountToPay | formatComma}} ILS  </div>
          </div>
        </div>

        <p  class="black">{{$ml.get('wallet.payment.body')}}.</p>
        <div  class="flex justify-center">
          <button class="btn btn-main" @click="payByWallet">{{$ml.get('wallet.payWith')}}</button>
        </div>
      </div>
    </DesktopMenu>


    <DesktopMenu class="installments-menu" backgroundColor="" backgroundFilter="" :showCloseButton="false"
      position="center" width="" height="" name="paymentInstallments" :isSideMenuVisible="false">
    <div class="menu-content-small" >
      <div v-if="installmentDetails && installmentDetails.length" name="installments" id="installments" style="width: 100%;" class="pointer" > 
          <div  v-for="installment in installmentDetails"  >
            <div @click="pickInstallmentAmount(installment)"  >
              <div class="w-100 installment-option">
                <h5>{{ installment.numOfPayments }} {{ $ml.get('installments') }}</h5>
                <div> ₪{{installment.installment}} <span v-if="installment.numOfPayments > 1"> / {{$ml.with('interest',(installment.totalAmount - installment.principal).toFixed(2)).get('includesInterest') }} </span> </div>
               </div>
            </div>
          </div>
      </div>
    </div>
     <!-- <div class="installment-details">
      <span v-if="installmentDetails">* ₪{{installmentDetails.instalment | formatComma}}</span>
    </div> -->
    </DesktopMenu>

    <Drawer ref="paymentInstallments" :title="''" @closed="" :drawerId="'paymentInstallments'"
      class="drawer-wrapper-card flex flex-column justify-between" :class="{ 'custom-closer': '' }">
      <template v-slot:heading>
        <h4 class="black" style="padding: 10px 5px;">{{$ml.get('paymentPlan')}}</h4> 
      </template>

      
      <template v-slot:content>
        <div >
      <div v-if="installmentDetails && installmentDetails.length" name="installments" id="installments" style="width: 100%;" class="pointer" > 
          <div  class="container"  v-for="installment in installmentDetails"  >
            <div @click="pickInstallmentAmount(installment)"  >
              <div class="w-100 installment-option">
                <h5>{{ installment.numOfPayments }} {{ $ml.get('installments') }}</h5>
                <div> ₪{{installment.installment}} <span v-if="installment.numOfPayments > 1"> / {{$ml.with('interest',(installment.totalAmount - installment.principal).toFixed(2)).get('includesInterest') }} </span> </div>
               </div>
            </div>
          </div>
      </div>
    </div>
      </template>

    </Drawer>
   
    </div>
    <div v-else>
      <CreditCardIFrame :amountToPay="amountToPay"></CreditCardIFrame>
    </div>
  </div>
 
</template>
<script>
import _ from 'lodash';
import { getCountryByIso, isApp, isMobile } from '../../../../services/utils';
import { getBeneficiary, commitTransaction, creditCardSign, paymentAmountInfo, sendValidation, getAllInstallmentsData } from "../../../../services/api";

import { formatComma } from '../../../../filters/AppFilters';
import Modal from '../../../../components/Modal.vue';
import Modal2 from '../../../../components/Modal2.vue';
import DesktopMenu from '../../../../components/DesktopMenu.vue';
import Drawer from '../../../../components/Drawer.vue';
import { EventBus } from '../../../../event-bus';
import CreditCardIFrame from '../components/CreditCardIFrame.vue';
import Tag from '../step2/Tag.vue';


export default {
  components: {},
  components: { Modal, Modal2, DesktopMenu, Drawer, CreditCardIFrame,Tag },
  created() {
    this.selectedCountry = getCountryByIso(this.$store.state.transfer.destinationCountry)
    getBeneficiary(this.$store.getters.getSelectedBeneficiary).then((beneficiary) => {
      this.beneficiary = beneficiary
    })

    getAllInstallmentsData({ principal: this.amountToPay }, response => {
    console.log("🚀 ~ created ~ response:", response)

          this.installmentDetails = response.splice(0,6)
    });
  },


  data() {
  return {
      name: 'PaymentMethodSelection',
      beneficiary: null,
      isMobileDevice: isMobile(),
      showCreditCard: false,
      installments: 1 ,
      installmentDetails: null,
      installment: null,
      // currency: 'ILS'
    };
  },
  methods: {
    showWalletPaymentApprovalModal() {
      // this.transmitSelectedPaymentType('my_wallet') 
      if (this.walletHasEnoughBalance) {
        this.isMobileDevice ? this.$refs.walletPaymentDrawer.openDrawer() : EventBus.$emit('toggleMenu', null, 'walletPaymentMenu');

      }
    },
    pickInstallmentAmount(installment){
      this.installments = installment.numOfPayments
      this.installment = installment
      this.isMobileDevice ? this.$refs.paymentInstallments.closeDrawer() : EventBus.$emit('toggleMenu', null, 'paymentInstallments');
    },
    doInstallments(val) {
      this.installments = val;
      // this.installmentDetails = null
      if (val > 1) {
     
      }
    },
    openInstallments(){
      this.isMobileDevice ? this.$refs.paymentInstallments.openDrawer() : EventBus.$emit('toggleMenu', null, 'paymentInstallments');
    },
    bla() {
      console.log('blaaa')
    },
    payByWallet() {
      this.paymentType = 'WALLET'
      this.isMobileDevice ? this.$refs.walletPaymentDrawer.openDrawer() : EventBus.$emit('toggleMenu', null, 'walletPaymentMenu');
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        let self = this;
        this.paying = true;
        EventBus.$emit('load');
        sendValidation({
          "beneficiaryId": this.$store.getters.getSelectedBeneficiary,
          "CORRESPONDENT_COMMISSION": selectedOption.correspondentCommission,
          "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
          "transaction": this.getTransactionData()
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            let transactionId = responseValidation.result.transactionId
            localStorage.setItem('selectedTransferOption', JSON.stringify({...this.$store.getters.getSelectedTransferOption,transactionId}));
            localStorage.setItem('selectedBeneficiary', JSON.stringify(this.$store.getters.getSelectedBeneficiary));

            // this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_6')
            commitTransaction({
              "transaction": {
                "id": responseValidation.result.transactionId,
                "sessionId": selectedOption.sessionId,
                "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
                "paymentType": 'WALLET',
              }
            }, response => {
              if (!response.errorCode) {
                this.paying = false;
                self.isSubmitted = false;
                if (response.result) {
                  this.referenceNumber = response.result;
                  // modalHandler('.modal-mask-transfer-3', 'show', '092086', 'light');
                  // localStorage.removeItem('selectedTransferOption');
                  // localStorage.removeItem('selectedBeneficiary');
                  localStorage.removeItem('responseValidation');
                  localStorage.removeItem('beneficiaryFields');
                localStorage.removeItem('customerFields');
                localStorage.removeItem('analyticsEvent');
                // this.$store.commit('setTransferStatus', {main: 'SUCCSESS', subType: 'CASH_TRANSFER'} );
                this.$router.push('TransactionDetails')
                  // this.$analyticsService.transmitSwitchCase('transaction', 'transaction_wallet')
                }
              } else {
             
                EventBus.$emit('unload');
                this.$router.push('/ErrorPage/'+responseValidation.errorCode)
                this.paying = false;
                // this.$store.commit('setShowModalError', true);
                // this.$store.commit('setTransferStatus', {main: 'ERROR', subType: 'CLOUD'} );

                // this.$store.commit('setErrorCode', responseValidation.errorCode);
                self.isSubmitted = false;
                // this.$analyticsService.transmitSwitchCase('transaction', 'transaction_error', { errorCode: responseValidation.errorCode })
              }
              
            EventBus.$emit('unload');

            });
          } else {
            EventBus.$emit('unload');
            this.$router.push('/ErrorPage/'+responseValidation.errorCode)
            this.paying = false;
            // this.$store.commit('setShowModalError', true);
            // this.$store.commit('setTransferStatus', {main: 'ERROR', subType: 'CLOUD'} );
            // this.$store.commit('setErrorCode', responseValidation.errorCode);

            self.isSubmitted = false;

          }
        });
        setTimeout(() => this.isSubmitted = false, 10000);
      }
    },
    payByCreditCard() {
      console.log('lalala');
      
      // this.transmitSelectedPaymentType('credit_card')
      this.paymentType = 'CREDIT_CARD'
      if (!this.isSubmitted) {
        this.isSubmitted = true;
        EventBus.$emit('load');

        // app.$Progress.start();
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        sendValidation({
          "beneficiaryId": this.$store.getters.getSelectedBeneficiary,
          "CORRESPONDENT_COMMISSION": selectedOption.correspondentCommission,
          "deliveryOptionCorrespondentReference": selectedOption.deliveryOptionCorrespondentReference,
          "transaction": this.getTransactionData(1)
        }, responseValidation => {
          if (!responseValidation.errorCode) {
            let transactionId = responseValidation.result.transactionId
            localStorage.setItem('mgparams', JSON.stringify(responseValidation.result));
            localStorage.setItem('selectedTransferOption', JSON.stringify({...this.$store.getters.getSelectedTransferOption,transactionId}));
            localStorage.setItem('selectedBeneficiary', JSON.stringify(this.$store.getters.getSelectedBeneficiary));
            localStorage.setItem('customerFields', sessionStorage.getItem('requiredFields')['CUSTOMER']);
            localStorage.setItem('beneficiaryFields', sessionStorage.getItem('requiredFields')['BENEFICIARY']);
            let self = this;
            this.$analyticsService.transmitSwitchCase('transaction', 'transaction_s_6')
            creditCardSign({
              "amountILS": this.amountToPay,
              "amount": this.amount,
              "currency": this.currency,
              "destinationCountry": selectedOption.destinationCountry,
              "numOfPayments": this.installments
            }, (response) => {
              if (!response.errorCode) {
                /* yaad page*/
                //    if(response.result.masofType=='MASOF_3DS')
                //         window.location = process.env.VUE_APP_3DS_URL + response.result.textResponse;
                //     else if(response.result.masofType=='MASOF_3DS_NEW')
                //         window.location = process.env.VUE_APP_3DS_NEW_URL + response.result.textResponse;
                //     else
                //         window.location = 'https://icom.yaad.net/p/?action=pay&'+response.result.textResponse;
                /* iframe page */
                sessionStorage.setItem('cc_tempInfo', JSON.stringify({
                  url: process.env.VUE_APP_YAAD_URL + response.result.textResponse,
                  p_amount: this.installmentDetails ? this.installmentDetails.totalAmount : this.amountToPay,
                  t_amount: selectedOption.amount,
                  t_currency: selectedOption.currency,
                  t_country: selectedOption.destinationCountry,
                  t_countryName: this.getCountryName,
                  t_titleAmount: this.getTitleAmount,
                  numOfPayments: this.installments,
                  installments: this.installmentDetails,
                }));
                localStorage.setItem('analyticsEvent', JSON.stringify({group:'transaction', eventId: 'transaction_credit_card'}) );
                // this.$router.push('credit_card_payment');
                this.showCreditCard = true
                // app.$Progress.finish();
                EventBus.$emit('unload');

              } else {
                // this.$store.commit('setShowModalError', true);
                // this.$store.commit('setErrorCode', responseValidation.errorCode);
                
                self.isSubmitted = false;
                // app.$Progress.finish();
                EventBus.$emit('unload');
                this.$analyticsService.transmitSwitchCase('transaction','transaction_error', {errorCode:  response.errorCode}) 
                this.$router.push('/ErrorPage/'+responseValidation.errorCode)
              }
            });
          } else {
            // this.$store.commit('setShowModalError', true);
            // this.$store.commit('setErrorCode', responseValidation.errorCode);
            
            EventBus.$emit('unload');
            self.isSubmitted = false;
            this.$router.push('/ErrorPage/'+responseValidation.errorCode)
            // app.$Progress.finish();
            if (responseValidation.errorCode === 'INVALID_SESSION_INFO') {
              this.$emit('onNextStep', 0);
            }
          }
        });
      }
    },
    getTransactionData(paymentType) {
      let selectedOption = this.$store.getters.getSelectedTransferOption;
      return {
        "amount": selectedOption.amount,
        "currency": selectedOption.currency,
        "commission": selectedOption.commission,
        "destinationCountry": selectedOption.destinationCountry,
        "sessionId": selectedOption.sessionId,
        "payoutCurrency": selectedOption.payoutCurrency,
        "transactionClass": selectedOption.transactionClass,
        "correspondentId": selectedOption.correspondentId,
        "paymentType": paymentType,
        "transferPurpose": "FAMILY_TRANSFER",
        "totalReceiveAmount": selectedOption.totalReceiveAmount || '',
        "transactionRate": this.$store.getters.getSelectedExchangeRate,
        "lookupAmount": selectedOption.lookupDto && selectedOption.lookupDto.lookupAmount,
        "lookupRate": selectedOption.lookupDto && selectedOption.lookupDto.rate,
        "lookupBaseRate": selectedOption.lookupDto && selectedOption.lookupDto.baseRate
      }
    },
    isLimitExceeded(paymentType) {
      return !this.getCreditAvailabilityMap.customerMonthlyLimitPaused && this.amountToPay > this.getLimitByPayment(paymentType)
    },
    getLimitByPayment(paymentType) {
      return (paymentType == 'MONTHLY')
        ? this.getCreditAvailabilityMap.limit
        : this.getCreditAvailabilityMap.paymentLimits[paymentType];
    },
    formatComma(val){
    return formatComma(val)
    }
  },
  computed: {
    // amountToPay() {
    //   return this.$store.getters.getSelectedTransferOption.amount + this.$store.getters.getSelectedTransferOption.commission - 
    //   (this.usedCashBack ? this.cashBackCredit : 0);;
    // },
    country() {
      try {
        const rd = JSON.parse(localStorage.getItem('registrationData'));
        console.log("🚀 ~ country ~ rd:", rd)
        return rd.country;
      } catch (error) {
        
      }
    },
    isIsraeli() {
      return this.country == 'ISR';
    },
    
    isCreditCardAviable(){
      console.log("🚀 ~ isCreditCardAviable ~ this.getCreditAvailabilityMap.available;:", this.getCreditAvailabilityMap.available)
      console.log("🚀 ~ isCreditCardAviable ~ !this.isCreditCardLimited:", !this.isCreditCardLimited)

      return !this.isCreditCardLimited && this.getCreditAvailabilityMap.available;
      
    },
    getCreditAvailabilityMap(){
      console.log("🚀 ~ getCreditAvailabilityMap ~  this.$store.getters.getCreditAvailabilityMap:",  this.$store.getters.getCreditAvailabilityMap)
      return this.$store.getters.getCreditAvailabilityMap;
    },
    isCreditCardLimited() {
      return this.isLimitExceeded('CREDIT_CARD')
    },


    getLimitMessage(paymentType) {
      if (this.creditAvailabilityMap.message != 'CREDIT_LIMIT') { // unavailable - other reasons
        return this.$ml.get(this.creditAvailabilityMap.message);
      }
      const max = Math.max(this.getLimitByPayment(paymentType), 0);
      return this.$ml.with('amount', this.getWalletSymbol + formatComma(max)).get('CREDIT_LIMIT_' + paymentType);
    },

    isRtl(){
      return this.$store.getters.getDir === 'rtl'
      },
    usedCashBack() {
      return this.$store.getters.getUseCashBack && this.cashBackCredit;
    },
    transactionAmount() {
      return this.$store.getters.getSelectedTransferOption.amount;
    },
    currency() {
     return this.$store.getters.getSelectedTransferOption.currency
    },
    getSymbol() {
      return this.$store.getters.getSelectedCurrencySymbol;
    },
    walletHasEnoughBalance() {
      return this.remainingWalletBalance >= 0;
    },
    walletBalance() {
      return (this.$store.getters.getWalletBalance('ILS'));
    },
    remainingWalletBalance() {
      return (this.$store.getters.getWalletBalance('ILS') - this.amountToPay).toFixed(2);
    },
    cashBackCredit() {
      return this.$store.getters.getCashBackCredit;
    },
    amountToPay(){
      return this.$store.getters.getPaymentAmountInfo.amountToPay
    },
    rate(){
      return this.$store.getters.getPaymentAmountInfo.rate
    }
  },
  watch: {
  },
  mounted() {
    // let lookupDto = this.$store.getters.getSelectedTransferOption.lookupDto;
    // console.log("🚀 ~ created ~ this.currency:",  this.$store.getters.getSelectedTransferOption.currency)
    // console.log("🚀 ~ created ~ this.currency:", this.$store.getters.getSelectedTransferOption.currency)
    // console.log("🚀 ~ created ~ this.currency:", this.currency)
    // paymentAmountInfo({
    //   "amount": this.$store.getters.getSelectedTransferOption.amount,
    //   "commission": this.$store.getters.getSelectedTransferOption.commission,
    //   "currency": this.$store.getters.getSelectedTransferOption.currency,
    //   "useCashBack": this.$store.getters.getUseCashBack,
    //   "cashBackCurrency": this.$store.getters.getCashBackCurrency,
    //   "transactionRate": this.$store.getters.getSelectedExchangeRate,
    //   "lookupAmount": lookupDto && lookupDto.lookupAmount,
    //   "lookupRate": lookupDto && lookupDto.rate,
    //   "lookupBaseRate": lookupDto && lookupDto.baseRate
    // }, (response) => {
    //   this.amountToPay = response.amountILS;
    //   this.rate = response.rate;
    //   this.loaded = true;
    // });
  },
  beforeDestroy() { },

};
</script>

<style lang="scss" scoped>
@import "../../../../assets/styles/partials/variables";

.modal-title {
  margin-bottom: 20px
}

.bold {
  font-weight: 700;
}

.rotate {
  transform: rotate(180deg);
}

.btn {
  // display: block;
  width: 80%;
  height: 45px;
  margin: 0 auto;
}

.drawer-wrapper-card {
  left: 0px;
  bottom: 0px;
  border-radius: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  width: 100vw;
  padding-bottom: 0px;
  min-height: 30%;
  // position: absolute;
  // inset-block-start: 500px;
}

::v-deep .go-back-wrapper {
  position: absolute;
  top: 3%;
  right: 5%;

}
::v-deep .tag {
  // right: 40px;
  right: auto;
  left: 100px;
  top: 13px;
  margin-left: 15px;

}
[dir='rtl'] ::v-deep .tag {
  right: 100px !important;
  left: auto !important;

}
::v-deep .tag-rtl {
  right: 120px;
  left: auto;
  top: 13px;
}

::v-deep .head-line {
  height: 0;
  padding: 0;
}

.btn-main {
  padding: 0 15px;
}



// ::v-deep .menu-content {
//   padding: 56px 66px !important;
// }


::v-deep .wallet-padding {
  padding: 36px 46px !important;
}

// ::v-deep .menu-content-small {
//   padding: 20px !important;
// }

::v-deep .installments-menu {
  ::v-deep .menu-content {
  // background-color: red;
 } 
}

#paymentInstallments {
  // background-color: red;
  ::v-deep .menu-content {
  padding: 10px 10px !important;
}
}
::v-deep .close-button {
    top: 15px;
    left: 20px;

}

[dir='rtl'] ::v-deep .close-button {
    top: 15px;
    right: -85%;

}

.grey-box {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  margin: 10px 0px;
  padding: 14px;

  & :first-child {
  }

  img {
    width: 36px;
    margin-right: 12px;
  }
}

.grey-box-rtl {
  & :first-child {
    margin-left: 12px;
  }
}

.circle {
  background: #0E1F81;
  border-radius: 35px;
  height: 36px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  // margin-right: 12px;

  strong {
    color: white;
    font-weight: 600;
    height: 25px;
    font-size: 18px;
  }
}

p {
  text-align: center;
    // padding: 0 25px;
    margin: 20px 0px; 
  }

.installment-option {
  h5 {
    color: #000;

font-feature-settings: 'liga' off, 'clig' off;
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 20px; /* 142.857% */
  }



  color: #000;
font-feature-settings: 'liga' off, 'clig' off;
font-family: Assistant;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 20px; /* 125% */

// height: 60px;
padding: 20px 0;
border-bottom: 1px solid #EEE;
:last-child {
  border-bottom: none;

}
}

.container:last-of-type .installment-option{
  border-bottom: none;
  padding-bottom: 15px;
}



.transfer-container {
  // styles here
  max-height: 90vh !important
}
.btn {
  margin: inherit;
}
.btn-border {
    border-color: #0E1F81;
    color: #0E1F81;
    height: 37px;
    margin-top: 10px;
    padding: 0 20px;
    
}

.modal-container2 {
  background-color: purple !important;

  .modal-mask {
    .modal-container {
      background-color: red !important;
    }
  }

}
.grey-unaviable {
  background: #F3F3F3 !important;
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13) !important;
  }



.payment-type {
  border-radius: 8px;
  border: 1px solid #EBEBEB;
  background: var(--Neutral-White, #FFF);
  box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
  padding: 15px;
  margin-top: 20px;
  &:hover {
      background-color: #F0FAFF;;
    }

  .title {
    color: var(--Main-Blue, #0E1F81);
    text-align: center;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;

    img {
      margin-right: 6px;
      width: 24px;
      height: 24px;
    }
  }

  .body {
    color: #000;
    font-family: Assistant;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      color: #000;
      font-family: Assistant;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

  }

  .chevron {
    transform: rotate(90deg);
    margin-left: 5px;
  }

}

[dir="rtl"] .payment-type .chevron {
  margin-left: 0px;
  margin-right: 5px;
}
[dir="rtl"] .payment-type .title img {
  margin-left: 6px;
  margin-right: 0px;
}

[dir="rtl"] .grey-box img {
    margin-left: 12px;
    margin-right: 0px;
}
[dir="rtl"] .grey-box .circle {
    margin-left: 12px;
    margin-right: 0px;
}
</style>
