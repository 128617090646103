<template>
  <div class="wrap">
    <div v-if="mode === this.$walletTransferType.FRIEND">
      <h1 v-text="$ml.with('name', name).with('amount', amount).get('wallet.transfer.summary.title')" class="title"/>
    </div>
    <div v-else-if="mode === this.$walletTransferType.CARD">
      <h1 v-text="$ml.get('wallet.card.summary.title')" class="title"/>
    </div>
    <div v-else-if="mode === this.$walletTransferType.WALLETORDER">
      <h1 v-text="$ml.get('walletOrder.depositCash')" class="title" />
    </div>
    <div v-else>
      <h1 v-text="$ml.get('wallet.load.summary.title')" class="title"/>
    </div>
    <h2 class="subtitle" v-text="mode === this.$walletTransferType.WALLETORDER ? $ml.get('summaryTransfer') : $ml.get('summaryTransfer')"></h2>
    <div class="summary-transfer">
      <div class="list-transfer">
        <div class="item-transfer">
          <ul class="captions">
            <li v-if="mode === this.$walletTransferType.FRIEND" v-text="$ml.get('nameBeneficiary')"></li>
            <li v-if="mode === this.$walletTransferType.FRIEND" v-text="$ml.get('mobilePhone')"></li>
            <li v-text="mode === this.$walletTransferType.WALLETORDER ? $ml.get('walletOrder.transferType') : $ml.get('transferType')"></li>
          </ul>
          <ul class="values">
            <li v-if="mode === this.$walletTransferType.FRIEND" id="name">{{ name }}</li>
            <li v-if="mode === this.$walletTransferType.FRIEND" id="phoneNumber">{{ phoneNumber }}</li>
            <li v-if="mode === this.$walletTransferType.CARD" v-text="$ml.get(transactionClass + '_MAX')"></li>
            <li v-else v-text="$ml.get(transactionClass)"></li>
          </ul>

        </div>
        <div class="item-transfer">
          <ul class="captions">
            <li v-text="$ml.get('amount')"></li>
            <li v-text="$ml.get('commission')"></li>
            <li v-text="$ml.get('totalTransfer')"></li>
          </ul>
          <ul class="values">
            <li>{{ amount | formatComma }} {{ currency }}</li>
            <li>
              {{ commission }} {{ currency }}
            </li>
            <li>{{ totalAmount | formatComma }} {{ payoutCurrency }}</li>
          </ul>
        </div>
      </div>
      <div class="group-btn">
        <button class="btn wide" v-text="mode === this.$walletTransferType.WALLETORDER ? $ml.get('walletOrder.getVerification') : $ml.get('btnOk')" @click="nextStep()"></button>
      </div>
    </div>
  </div>

</template>
<script>

import app from "@/main";
import { startOrder } from "../../../services/api"

export default {
  name: 'WalletSummary',
  props: {
    mode: {
      type: String,
      required: false
    },
    skip: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    name() {
      const beneficiary = JSON.parse(localStorage.getItem('currentWalletBeneficiary'))
      return beneficiary.firstName + ' ' + beneficiary.lastName
    },
    phoneNumber() {
      return JSON.parse(localStorage.getItem('currentWalletBeneficiary')).phoneNumber.replace('+972', '0')
    },
    amount() {
      return this.$store.getters.getSelectedTransferOption.amount;
    },
    commission() {
      return this.$store.getters.getSelectedTransferOption.commission;
    },
    totalAmount() {
      return this.amount + this.commission;
    },
    currency() {
      return this.$store.getters.getSelectedTransferOption.currency;
    },
    payoutCurrency() {
      return this.$store.getters.getSelectedTransferOption.payoutCurrency;
    },
    transactionClass() {
      return this.$store.getters.getSelectedTransferOption.transactionClass;
    }
  },
  created () {
    window.scrollTo(0, 0);
  },
  methods: {
    nextStep () {
      if (this.amount <= 0) {
        this.$store.commit('setShowModalError', true);
        this.$store.commit('setErrorCode', 'ERROR_NEGATIVE');
        return;
      }
      if (this.mode === this.$walletTransferType.WALLETORDER) {
        app.$Progress.start();
        let selectedOption = this.$store.getters.getSelectedTransferOption;
        startOrder({
          "currency": selectedOption.currency,
          "amount": this.$store.getters.getLookupAmount,
          "amountType": this.$store.getters.getAmountType,
          "orderType": 'WALLET'
        }, (res) => {
          if (res.result.created) {
            this.$router.push('/wallet/walletOrder') 
          app.$Progress.finish();
          this.$analyticsService.transmitSwitchCase('wallet_deposit','wallet_order_stage_3') 
          } else {
            this.$analyticsService.transmitSwitchCase('wallet_deposit','wallet_order_stage_3_error', {errorCode: responseValidation.errorCode}) 
          }
            
        });
      } else {
        app.$Progress.start();
        this.$emit('onNextStep')
        app.$Progress.finish();
        const eventGroup = this.mode === this.$walletTransferType.CARD ? 'card' : 'wallet'
        this.$analyticsService.transmitSwitchCase(eventGroup+'_deposit',eventGroup+'_deposit_stage_2', {
                    name: eventGroup+'_deposit_stage_2', data: {
                        page_title: eventGroup+" Deposit - Summary",
                        screen_name: eventGroup+" Deposit - Summary",
                        currency: this.currency,
                        amount: this.$store.getters.getLookupAmount,
                    }
                })   
      }
    },
    prevStep() {
      this.$emit('onPrevStep', 0, true)
    }
  }
}

</script>