export const he = {
  GMTbranches: '<a href=""https://gmtonline.co.il/branches/"" style=""color:#d4c641;text-decoration: underline;"">באחד מסניפי GMT</a>',
  accessibleBranch: 'נגיש',
  myCard: 'הכרטיס שלי',
  myDateBirthday: '(שנה-חודש-יום)',
  accountNumber: 'מספר חשבון המוטב',
  activityTime: 'שעות פעילות:',
  actualRate1: 'שער ה',
  and: 'ו',
  appDownload: 'הורידו את האפליקציה שלנו',
  appDownloadBtn: 'הורדה ישירה לטלפון',
  actualRate2: 'עכשיו באתר הוא',
  addAddress: 'הוסף כתובת',
  additionalPhone: 'מספר טלפון נוסף',
  amount: 'סכום',
  amountIn: 'סכום בש""ח',
  recipientAmount: 'סכום ביעד',
  amountReceived: 'סכום שיתקבל',
  amountTogether: 'סכום ביחד',
  anotherSurname: 'שם משפחה נוסף',
  answerQuestion: 'ובינתיים, אולי התשובה לשאלה שלך נמצאת',
  approvalTransaction: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף אושרה. בדקות הקרובות תישלח אליך הודעת SMS עם אישור העסקה',
  maxCardLoadTransaction: 'מעולה! הכרטיס שלך הוטען בהצלחה',
  approvedPolicies: 'קראתי ואישרתי את התקנון',
  arrivalBranch: 'מזומן בסניף GMT',
  arrivalBranchMsg1: 'אנו נספק לך עכשיו מספר הזמנה לצורך התשלום, ההזמנה תקפה ל 24 שעות',
  arrivalBranchMsg2: 'והתשלום יהיה בש"ח לפי השער בסניף',
  bankName: 'שם הבנק',
  bank: 'בנק',
  bankAccount: 'חשבון בנק',
  bankCode: 'מספר הבנק',
  canceled: 'עסקה מבוטלת',
  cardNumber: 'מספר כרטיס',
  cashioPageTtile: 'הזמנת מט״ח עד אליך במשלוח או איסוף מנתב״ג',
  depositCardNumber: 'מספר כרטיס האשראי של המוטב',
  beneficiary: {
    firstName: 'שם פרטי',
    beneficiary: 'מוטב',
    countryIso3: '.',
    lastName: 'שם משפחה',
    phoneNumber: 'טלפון המוטב',
    phoneNumberShort: 'מספר טלפון',
    country: 'יעד',
    transferMethod: 'סוג ההעברה',
    areaCode: 'אזור חיוג',
    maskedCardNumber: 'מספר כרטיס האשראי של המוטב',
    maskedCardNumber_unionPay: 'מספר כרטיס האשראי של המוטב',
    address: 'כתובת',
    city: 'עיר',
    province: 'מחוז/מדינה',
    zipCode: 'מיקוד',
    bankName: 'שם הבנק',
    cashBankName: 'נקודת איסוף מזומן',
    walletBankName: 'שם הארנק',
    swiftCode: 'קוד הבנק SWIFT/BIC',
    bankCity: 'עיר הבנק',
    bankAddress: 'כתובת הבנק',
    branch: 'מספר סניף',
    iban: 'IBAN',
    accountIdentifier: 'מספר חשבון',
    walletIdentifier: 'מספר ארנק',
    walletPayment: 'תשלום מחשבון',
    alipayWallet: 'חשבון AliPay',
    abaRoutingNumber: 'מספר הבנק ROUTING/ABA',
    ifsc: 'IFSC',
    relationshipWithCustomer: 'מה הקרבה ביניכם?',
    identification: 'מספר זהות',
    upi: 'מזהה UPI',
    upi_IND: 'מזהה UPI',
    details: {
      BANK_TRANSFER: 'פרטי חשבון הבנק של המוטב',
      CASH_TRANSFER: 'פרטי איסוף מזומן של המוטב',
      WALLET_DEPOSIT: 'פרטי הארנק של המוטב',
      WALLET_FROM_WALLET: 'פרטי הארנק של המוטב',
      WALLET_TO_WALLET: 'פרטי הארנק של המוטב',
      WALLET_TRANSFER: 'פרטי הארנק של המוטב',
      CARD_DEPOSIT: 'פרטי הכרטיס של המוטב',
      UPI_BANK_TRANSFER: 'פרטי חשבון ה UPI של המוטב',
    },
  },
  transfer: {
    deliveryOptions: {
      ALL: 'הכל',
      CASH_TRANSFER: 'העברה לאיסוף מזומן',
      BANK_TRANSFER: 'העברה לחשבון בנק',
      WALLET_DEPOSIT: 'הפקדה לחשבון ארנק',
      WALLET_FROM_WALLET: 'העברה לחשבון ארנק',
      WALLET_TO_WALLET: 'העברה לחשבון ארנק',
      WALLET_TRANSFER: 'העברה לחשבון ארנק',
      CARD_DEPOSIT: 'העברה לכרטיס',
      UPI_BANK_TRANSFER: 'העברה לחשבון בנק מסוג UPI',
    },
    errors: {
      amountExceeds: '/',
      corrspondentIsLocked: '/',
    },
    deliveryOptionsInfo: {
      iSend: 'אני שולח',
      iPay: 'אני משלם',
      theyRecieve: 'הם מקבלים',
    },
    titles: {
      chooseAmount: 'בחר סכום',
      chooseBeneficiary: 'בחר מוטב',
      chooseDestination: 'בחר יעד',
      recentDestinations: 'היעדים האחרונים שלך',
      allDestinations: 'כל היעדים',
      popularDestinations: 'יעדים פופולריים',
      sendMoney: 'שלח כסף',
      nothingFound: 'לא נמצא דבר',
      typedCorrectly: 'ודא שהקלדת את שם המדינה נכון.',
      transferSummary: '.',
    },
    buttons: {
      select: 'בחר',
      addNewBeneficiary: 'הוסף מוטב חדש',
      coupon: {
        iHaveACoupon: 'יש לי קופון',
        couponApplied: 'קופון "{couponNumber}" הופעל!',
      },
      back: 'חזור',
    },
    paymentMethods: {
      titles: {
        payingWith: 'תשלום של  {amount} {currency} ב:',
        paying: 'תשלום',
        with: 'באמצעות',
      },
      subtitles: {
        walletBalance: 'יתרת הארנק שלך היא',
        creditCard: 'אנו מקבלים כרטיסים מסוג ויזה ומאסטרקארד',
        bankTransfer: 'העברה ישירה לחשבון בנק',
      },
    },
    countrySearchPlaceholder: 'חפש יעד או מטבע',
  },
  relationshipWithCustomer: {
    SELF: 'לעצמי',
    CHILD: 'בן / בת שלי',
    PARENT: 'הורה שלי',
    SPOUSE: 'בן/בת הזוג שלי',
    SIBLING: 'אח/אחות שלי',
    EX_SPOUSE: 'בן/בת זוג לשעבר שלי',
  },
  moreInformation: 'מידע נוסף',
  beneficiaryRemoveDialogue: 'האם למחוק את {beneficiary} מרשימת המוטבים שלך?',
  beneficiaryRemoveDialogue2: 'המחיקה אינה משפיעה על העסקאות הקיימות',
  billingSuccessful: 'מעולה! ההעברה שלך נשלחה',
  billingSuccessfulCardDeposit: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף לכרטיס בחו""ל אושרה.',
  billingSuccessfulBankTransfer: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף לחשבון בחו"ל אושרה.',
  billingSuccessfulBankTransferISR: 'נהדר! החיוב בוצע בהצלחה והעברת הכסף לחשבון אושרה.',
  cardUnBlockModalMessage: {
    '1': 'בלחיצה על כפתור "הפעלה" הכרטיס שלך יפתח בחזרה לביצוע עסקאות ומשיכת מזומן',
    '2': 'יש לבצע פעולה זו רק אם הכרטיס ברשותך',
  },
  cardBlockModalMessage: {
    '1': 'בלחיצה על כפתור "חסימה" אנו נחסום זמנית את הכרטיס לביצוע עסקאות ומשיכת מזומן.',
    '2': 'היתרה של הכרטיס שמורה לך!',
    '3': 'במידה ומצאת את הכרטיס ניתן להפעילו מחדש או שנוכל לחסום אותו באופן קבוע ולהוציא לך כרטיס חדש',
  },
  blocked: 'חסום',
  blog: 'בלוג',
  bntClear: 'ביטול',
  bntContinued: 'לשלב הבא',
  bntContinue: 'המשך',
  bntImgDelete: 'צילום מחדש',
  bntSave: 'שמור',
  branchNumber: 'סניף',
  checkStatus: 'סטטוס',
  status: 'סטטוס',
  btnAccept: 'אישור',
  btnCheckStatusCheck: 'מוכנה לתשלום',
  btnCheckStatusNoCheck: 'לא זמין לתשלום',
  btnOk: 'אישור',
  btnDone: 'סיום',
  btnDelete: 'מחיקה',
  btnFinish: 'סיום',
  btnClose: 'סיום',
  new: 'חדש',
  newCustomer_section: 'הבחירה הנכונה לכסף שלך',
  quick_guide: 'מדריך קצר עבורך',
  invite_friends: 'להזמין חברים ל GMT',
  cash_back_desc: 'CashBack',
  send_money_home: 'העברת כסף הביתה',
  send_money_worldwide: 'העברת כסף לחו״ל',
  newCustomer_title: 'העברת כספים לחו"ל ושרותים פיננסיים מתקדמים',
  quickSend: 'שליחה מהירה',
  quickActions: 'פעולות מהירות',
  quickSendActionsParagraph: 'המוטבים האחרונים שלך כאן ומוכנים לשליחה מהירה שוב',
  quickActionsAdd: 'הוספה',
  payAnywhere: 'כרטיס ניטען מבית MAX',
  carousel_slide_one_text: 'הכרטיס הניטען שלנו יעזור לך לשלוט בכסף שלך אפילו טוב יותר',
  carousel_slide_one_button: 'למידע נוסף',
  seeAll: 'הכל',
  cardBalance: 'יתרת כרטיס',
  getCashback: 'ולקבל',
  cashBack_info: 'CashBack',
  justReminder: 'תזכורת',
  home: 'בית',
  menu: 'תפריט',
  activity: 'פעילות',
  cards: 'כרטיסים',
  greeting_morning: 'בוקר טוב',
  greeting_afternoon: 'צהריים טובים',
  greeting_evening: 'ערב טוב',
  greeting_night: 'לילה טוב',
  paidBy: 'שולם באמצעות:',
  incoming: 'קבלת',
  outgoing: 'שילמת',
  CONVERSION: 'המרת מט״ח',
  PAYMENT: 'תשלום',
  CASH_BACK: 'CashBack',
  cardActions: 'פעולות לכרטיס',
  actions: 'פעולות',
  activateCard: 'הפעלת כרטיס חדש',
  activateCardAction: 'הפעלת הכרטיס',
  enterLastDigits: 'בבקשה להקליד 4 ספרות אחרונות של הכרטיס שלך',
  searchTransactions: 'חיפוש עסקאות',
  filterTransactions: 'פילטור עסקאות',
  filterTransactionsButton: 'פילטר',
  openWallet: 'פתיחת חשבון ארנק בחינם',
  pleaseSelect: 'אפשר לבחור אחד או יותר',
  date: 'תאריך',
  from_date: 'מתאריך',
  to_date: 'עד תאריך',
  choose_date: 'בחירת תאריך',
  showResults: 'תצוגת תוצאות',
  clearFilter: 'חזרה לכל התוצאות',
  today: 'היום',
  contact_us_paragraph: 'אנחנו מאמינים בתקשורת טובה, אז הרגישו חופשי לפנות אלינו בכל הדרכים הרשומות.\nשעות הפעילות שלנו:\nא׳-ה 08:30-17:00, ו׳ 08:30-14:00',
  call_us: 'שיחה טלפונית',
  contact_us_or: 'או',
  lastTransactions: 'עסקאות אחרונות לכרטיס',
  transactionClasses: {
    BANK_TRANSFER: 'העברה לחשבון בנק',
    CASH_TRANSFER: 'העברה לאיסוף מזומן',
    CARD_DEPOSIT: 'העברה לחשבון כרטיס',
    PAYMENT: 'העברה נכנסת',
    CONVERSION: 'המרת מט״ח',
    CASH_BACK: 'קאשבק',
    WALLET_DEPOSIT: 'הפקדה לחשבון GMT',
    WALLET_TRANSFER: 'העברה לחשבון ארנק',
    WALLET_TO_WALLET: 'העברה לחשבון GMT',
    WALLET_FROM_WALLET: 'העברה נכנסת לחשבון GMT',
    UPI_BANK_TRANSFER: 'העברה לחשבון בנק באמצעות מזהה UPI',
  },
  paymentTypes: {
    CASH: 'מזומן',
    CHECK: 'שיק',
    BANK_TRANSFER: 'העברה בנקאית',
    CREDIT_CARD: 'כרטיס',
    CREDITTING: 'אשראי',
    OUTER_CONVERSION: 'מזומן',
    CASHBACK: 'קאשבק',
    CUSTOMER_DEPOSIT: 'יתרה מצד ג׳',
    WALLET: 'חשבון ארנק',
    CHANGE: 'עודף',
    AIRPORT: 'נתב״ג',
  },
  CARD: 'כרטיס',
  CASH: 'מזומן',
  AIRPORT: 'נתב״ג',
  CHECK: 'שיק',
  CHANGE: 'עודף',
  CREDIT_CARD: 'כרטיס',
  CREDITTING: 'אשראי',
  OUTER_CONVERSION: 'מזומן',
  CASHBACK: 'קאשבק',
  CUSTOMER_DEPOSIT: 'יתרה מצד ג׳',
  WALLET: 'חשבון ארנק',
  changeLang: 'שינוי שפה',
  nothing_found: 'לא נימצאו עסקאות',
  nothing_found_text: 'ברגע שיהיו לך פעולות חדשות, הן יופיעו כאן',
  max_transaction_description: 'תיאור העסקה',
  creditCardReferenceNumber: 'אסמכתה של חברת האשראי',
  checkNumber: 'מספר שיק',
  referenceNumber: 'אסמכתה',
  flightDate: 'תאריך טיסה',
  deposit_to_wallet: 'הפקדה לארנק',
  btnFinishREgistration: 'לסיום הרשמה',
  btnUploadDocuments: 'הוספת תעודה מזהה',
  btnRegistration: 'יצירת חשבון>',
  btnReturn: 'חזרה',
  btnSend: 'שליחה',
  btnSignIn: 'שלחו לי סיסמה ב SMS',
  btnBiometricSignInFaceId: 'כניסה עם זיהוי פנים',
  btnBiometricSignInTouchId: 'כניסה עם זיהוי ביומטרי',
  btnToMyGmt: 'ל GMT',
  btnTransfer: 'העברת כסף',
  btnTransferFooter: 'העברת כסף',
  btnEnter: 'כניסה',
  btnSearch: 'חיפוש',
  business: 'העיסוק שלי',
  biometricsHeader: 'הזדהות ביומטרית',
  biometricSectionApprove: 'אישרתי את התנאים',
  biometricSectionSignIn: 'לסיום ההרשמה לזיהוי הביומטרי, בבקשה להתחבר לחשבון',
  biometricToggler: 'הרשמה לזיהוי ביומטרי ',
  biometric_modal_text: 'האם ברצונך לבטל את ההתחברות הביומטרית לחשבונך?',
  biometric_legal_clause_1: 'הזיהוי הביומטרי (באמצעות טביעת אצבע ו/או זיהוי פנים) ישמש תחליף להקשת סיסמה בכניסה לכל החשבונות המשויכים למשתמש עמו נכנסת למערכת.',
  biometric_legal_clause_2: 'החברה אינה שומרת את זיהוי הפנים של המשתמש, ההזדהות מסתמכת על  הזיהוי הביומטרי המוגדר במכשיר הנייד של המשתמש.',
  biometric_legal_clause_3: 'ההרשמה לשירות הינה בכפוף להצהרת בעל ההרשאה לכניסה לאתר כי הינו המשתמש היחידי במכשיר בעל  הזיהוי הביומטרי. מקום בו מעודכנות במכשיר זיהוי ביומטרי של מספר אנשים אין להירשם לשירות.',
  biometric_legal_clause_4: "בקשת מידע ו/או ביצוע פעולות שיבוצעו באפליקציה או באתר יחשבו כפעולות שבוצעו ע\"\"י בעל ההרשאה, וזאת לרבות פעולות שבוצעו ע\"\"י צד ג' תוך הפרת סעיף 3 שלעיל.",
  biometric_legal_clause_5: 'במקרה של אובדן ו/או גניבה של מכשיר הנייד יש לבטל מיידית את הרישום לשירות באתר או בפניה טלפונית לשירות הלקוחות.',
  biometric_legal_clause_6: 'באפשרותך להתנתק מהשירות בכל עת הן באפליקציה והן באמצעות פניה לשירות הלקוחות.',
  biometric_login_failed: 'מצטערים, הזיהוי נכשל',
  biometric_public_key_expired: 'סליחה על ההפרעה, ביטלנו את האפשרות לכניסה ביומטרית באפליקציה כדי לשמור על חשבונך מאובטח ובטוח. אפשר להרשם ולהפעיל מחדש את הכניסה ביומטרית כעת, תודה!',
  biometric_unregister_modal_title: 'כיבוי זיהוי ביומטרי',
  calculatedBy: 'חישוב לפי {curr}',
  captionAgreement: 'קראתי ואישרתי את',
  captionAgreementLink: 'התקנון',
  captionCustomerService1: 'נשמח לעזור לך בשירות לקוחות',
  chatRepresentative: "צ'אט איתנו",
  qrCodeDownload: 'Download QR',
  scanExplanation: 'Scan this QR code find us on WeChat',
  cashBack: 'myCashBack',
  cashBackReceive: 'לקבל CashBack',
  cashBackLabel: 'הטבת קאשבק',
  cashBackBalance1: 'היי, יש לך קאשבק!',
  cashBackBalance2: 'היתרה שלך היא:',
  cashBackCredit: 'ניצול קאשבק',
  cashBackModalMessage: 'איזה כיף!',
  cashBackModalMessage2: 'יש לך',
  cashBackModalMessage3: 'מאיתנו',
  cashBackModalMessage4: 'אפשר להשתמש בהם עכשיו ולשלם פחות',
  cashBackModalMessageConfirm: 'מעולה! אני רוצה לממש כעת',
  cashBackModalMessageCancel: 'אני רוצה להמשיך לצבור',
  cashBackPageTitlePlus: 'יש! צברת כבר',
  cashBackPageTitleZero: 'קבלו כסף חזרה',
  cashBackPageMessagePlus: 'איך ממשיכים לצבור?',
  cashBackPageMessageZero: 'איך מרוויחים?',
  cashBackPageMessage2: 'פשוט שולחים כסף עם GMT ומקבלים כסף בחזרה.',
  cashBackPageMessage3: 'ברגע שהגעת ל',
  cashBackPageMessage4: 'דולר או יורו, ניתן יהיה להשתמש בכסף או להמשיך לצבור',
  cashBackPageFotter: '-הצבירה מתבצעת לפי מטבע העסקה, וניתנת לשימוש באותו מטבע',
  cashBackPageFotter2: '-ניתן להשתמש בכסף באתר או בסניפים (בקרוב)',
  cashBackPageFotter3: '- אם לא בקרת אותנו מעל לשישה חודשים , חשבון ה CashBack שלך יתרוקן',
  cashBackPageFotter4: 'לתקנון המלא>',
  cashBackUseNowOrLater: 'אפשר להשתמש בהם עכשיו או להמשיך לחסוך לעסקאות עתידיות.',
  youHaveCashBack: 'יש לך {cashBackInfo} החזר כספי מוכן לשימוש.',
  tags: {
    popular: 'פופולארי',
    recentlyUsed: 'השתמשת לאחרונה',
    unavailable: 'לא זמין כעת',
    amountExceeded: 'סכום לא תקין',
  },
  cardMargketing: {
    title: 'כרטיס אשראי נטען בשיתוף',
    subtitle: 'שמחים להציע לך את הדרך הנוחה לשליטה בהוצאות ללא צורך בחשבון בנק!',
    info1: {
      title: 'יתרונות הכרטיס:',
      con1: 'רכישות בארץ, בחו"ל ובאינטרנט',
      con2: 'משיכת כסף בארץ ובחו""ל מכספומט',
    },
    info2: {
      title: 'תוכלו תמיד:',
      con1: 'לברר יתרה של הכרטיס',
      con2: 'לעקוב אחר רכישות ועסקאות',
      con3: 'לשחזר קוד סודי',
      con4: 'לחסום כרטיס שאבד/ נגנב',
    },
    footer: {
      '1': 'ניתן להנפיק ולטעון את הכרטיס טעינה חוזרת בסניפי GMT',
      '2': 'לפרטים נוספים והזמנות',
    },
    btn: 'כתבו לנו בוואצאפ',
  },
  cardActionsModalTitle: 'פעולות לכרטיס',
  cardRoute: 'כרטיס נטען',
  cardHystory: 'עסקאות אחרונות',
  cardBlock: 'חסימת כרטיס',
  cardBlockBtn: 'חסימה',
  cardUnBlock: 'הפעלת כרטיס חסום',
  cardUnBlockBtn: 'הפעלה',
  cardBlockConfirm: {
    '1': 'חסמנו עבורך את הכרטיס, אך ניתן להפעילו מחדש שוב באתר.',
    '2': 'לכל שאלה אנו זמינים עבורך.',
    '3': 'ליצירת קשר',
  },
  walletOrder: {
    deposited: 'הפקדה של',
    verification: 'זהו קוד האימות שלך',
    getVerification: 'לקבלת קוד אימות',
    showTo: 'יש להציג את הקוד בסניף',
    expiry: 'הקוד יפקע בעוד',
    minutes: 'דקות',
    returnHere: 'ניתן תמיד לחזור לעמוד זה',
    mainPage: 'מהעמוד הראשי',
    cancel: 'ביטול ההזמנה הזו',
    hooray: 'היפ היפ הוריי',
    close: 'סגירה',
    depositedToYourWallet: 'הפקדנו',
    depositedToYourWallet1: 'לחשבון הארנק שלך',
    cancelModalTitle: 'האם ברצונך לבטל הזמנה זו?',
    cancelModalText: 'תמיד ניתן לעשות עוד אחת',
    goToActiveOrder: 'קוד ההפקדה שלך',
    depositCash: 'הפקדת מזומן לחשבון',
    btnOk: 'אישור',
    btnReturn: 'חזרה',
    amount: 'סכום',
    summaryOfDetails: 'סיכום פרטי הפקדה',
    transferType: 'סוג',
  },
  wallet: {
    menu: 'החשבון שלי',
    balance: 'החשבון שלי:',
    payWith: 'אישור ותשלום באמצעות חשבון GMT',
    payment: {
      title: 'החשבון שלי',
      body: 'לאחר לחיצה על כפתור "לתשלום" אנו נבצע את הפעולה עבורך ויתרת חשבונך תתעדכן בהתאם.',
      footer: 'לאחר התשלום, יתרת חשבונך תהיה {currency}{amount}.',
      inActive: {
        footer: 'מצטערים, יתרתך נמוכה מסכום העסקה, אפשר לעדכן את סכום  העסקה או לטעון לארנקך סכום נוסף',
      },
    },
    main: {
      title: 'היי {name},',
      card: {
        title: 'יתרת החשבון שלך',
        transactionsLink: 'צפייה בכל העסקאות שלך>',
      },
      depositToWallet: 'הפקדה לחשבון באמצעות',
      sendMoney: 'שליחת כסף',
      walletOptions: 'אפשרויות חשבון',
      depositActions: {
        cash: 'הפקדת\nמזומן',
        bank: 'העברה\nבנקאית',
        card: 'כרטיס\nאשראי',
      },
      sendActions: {
        sendHome: 'העברת כסף הביתה',
        sendWorldWide: '',
        inIsrael: 'העברת כסף בישראל',
      },
      walletActions: {
        loadPrePaid: 'הכרטיס הנטען של GMT',
        send: 'העברה לחברי GMT',
        invite: 'להזמין חברים ולקבל CashBack',
      },
      doarInstruction: 'איך מפקידים כסף מהדואר לחשבון Gmt',
    },
    invite: {
      title: 'להזמין חברים ולקבל CashBack',
      body: 'אפשר לקבל אפילו יותר מGMT.\nבעזרת הלינק האישי שלך, אפשר להזמין חברים וחברות חדשים לGMT.\n אחרי ההעברה הראשונה שלהם, שניכם תקבלו {amount} ש״ח CashBack\n\nניתן לשתף את הלינק האישי שלך בעזרת הכפתור למטה',
      shareActions: {
        copy: 'העתקה',
        copied: 'הועתק',
        whatsapp: 'הזמנה באמצעות WhatsApp',
        wechat: 'הזמנה באמצעות WeChat',
        line: 'הזמנה באמצעות Line',
        general: 'שיתוף',
      },
      inviteMessages: {
        firstLine: 'היי, הצטרפת כבר לGMT? עם הלינק האישי שלי המצורף למטה אפשר להרשם',
        secondLine: 'לGMT ושנינו נקבל CashBAck! >',
      },
    },
    deposit: {
      cash: {
        title: 'הפקדת מזומן לחשבון שלך',
        body: 'ניתן להפקיד מזומן בכל הנקודות של GMT.\nאחרי כמה שניות, הארנק שלך יתעדכן, וניתן יהיה להשתמש ביתרה החדשה שלך ב GMT',
        locations: 'סניפי GMT>',
        walletOrderExplanation: {
          depositAnywhere: 'ניתן להפקיד מזומן בכל סניפי GMT',
          amount: 'בעמוד הבא מקלידים את הסכום להפקדה',
          verificationCode: 'מקבלים קוד אימות',
          teller: 'מביאים את המזומן ומציגים את הקוד בסניף.',
          finishTitle: 'זהו!',
          finishText: 'יתרת חשבונך תזוכה במיידית',
        },
      },
      bank: {
        title: 'העברה מחשבון הבנק או הדואר לחשבון הGMT שלך',
        body: 'ניתן לטעון את החשבון שלך באמצעות העברה ישירה לחשבון חברת GMT. \nגם המעסיק שלך יכול או יכולה להעביר כסף לארנק ישירות מחשבון הבנק שלהם.',
        transferDetails: 'פרטי החשבון:\nג׳י.אם.טי טק אינוביישן בע״מ\nבנק הפועלים 12\nסניף 613\nחשבון 545690',
        uploadDetails: 'ניתן להעלות אישור העברה כאן,\nלא לשכוח לציין את מספר הפספורט והטלפון שלך',
        uploadConfirmationBtn: 'העלאת אישור',
        placeholder: 'הוספת אישור העברה בנקאית',
        upload: {
          title: 'העלאת אישור העברה בנקאית',
          loader: 'אנו מעלים את התמונה שהוספת',
          footer: 'לא לשכוח לציין את מספר הפספורט והטלפון שלך',
          success: 'נהדר! הצוות שלנו מטפל בזה ויתרת הארנק שלך תתעדכן בהקדם\nאנחנו נשלח לך אישור גם בהודעת טקסט ובמייל',
        },
      },
    },
    friends: {
      title: 'העברת כסף לחברי GMT',
      addFriend: 'הוספת מספר ארנק',
      newFriend: 'העברה לחשבון GMT',
      contacts: 'בחירה מתוך אנשי קשר',
      addContactByPhone: 'העברה למספר שלא באנשי הקשר',
      searchByNameOrPhone: 'חיפוש לפי שם או מספר',
      header: 'GMT מאפשר לך להעביר כספים לכל חברייך. \n השרות שלנו מהיר ופשוט!',
      mobilePhone: 'מספר נייד',
      bulletsOne: 'צריך לבחור איש קשר',
      bulletsTwo: 'להקליד את סכום ההעברה',
      bulletsThree: 'לאשר',
      outro: 'זהו! היתרות הארנק שלך ושל חברך יתעדכנו באופן מיידי. \n רוצה להעביר כסף בקלות עכשיו?',
      inviteBnt: 'הזמנת חברים ל GMT',
      list: {
        title: 'רשימת חברים',
      },
      addModal: {
        title: 'האם ברצונך להוסיף',
        footer: 'לרשימת החברים שלך?',
        saveBtn: 'שמירה',
        closeBtn: 'ביטול',
      },
      friendCard: {
        title: 'חשבון',
        delete: 'מחיקה',
        deleteDialogFirstLine: 'האם ברצונך למחוק את {beneficiary} מרשימת חברה הארנק שלך?',
        deleteDialogSecondLine: 'לפעולה זו לא תהיה השפעה על עסקאות קיימות',
        send: 'שליחה לחשבון GMT',
      },
    },
    transfer: {
      amount: {
        title: 'שליחת כסף לחשבון GMT ל\n {name}',
      },
      send: {
        title: 'שליחת {amount} ש״ח לחשבון GMT',
      },
      summary: {
        title: 'שליחת {amount} ש״ח לחשבון GMT',
      },
      pay: {
        title: 'שליחת {amount} ש״ח לחשבון GMT',
      },
    },
    load: {
      amount: {
        title: 'הפקדה לחשבון GMT ותשלום באשראי',
      },
      send: {
        title: 'הפקדה לחשבון שלך',
      },
      summary: {
        title: 'הפקדה לחשבון שלך',
      },
      pay: {
        title: 'הפקדה לחשבון שלך',
      },
    },
    card: {
      amount: {
        title: 'טעינת כרטיס ותשלום מחשבון GMT',
      },
      send: {
        title: 'טעינת כרטיס ותשלום מחשבונך',
      },
      summary: {
        title: 'טעינת כרטיס GMT',
      },
      pay: {
        title: 'טעינת כרטיס GMT',
      },
    },
  },
  loadCard: 'טעינה ותשלום מהחשבון',
  pay: 'לתשלום',
  cardUnBlockConfirm: 'הכרטיס שלך עכשיו פעיל',
  cardMerchantAddress: 'כתובת',
  cardMerchantPhone: 'טלפון',
  cardPresented: 'כרטיס הוצג',
  changeAmount: 'רוצה לשנות את הסכום?',
  changePayoutAmount: 'רוצה לשנות את הסכום ביעד?',
  changeDepositAmount: 'רוצה לשנות את סכום ההפקדה?',
  changeAmountLabel: 'סכום חדש ב {amount}',
  changeAmounBtn: 'חישוב',
  city: 'עיר',
  commission: 'עמלה',
  confirmMarketingContent: 'אני רוצה לקבל תכנים שיווקים ועדכונים',
  confirmCreditNumber: 'חשוב מאוד!  לבדוק את מספר הכרטיס. לא ניתן למשוך חזרה כספים שהועברו לכרטיס בחו""ל.',
  confirmBankAccount: 'חשוב מאוד! יש לבדוק את פרטי החשבון. לא ניתן למשוך חזרה כספים שהופקדו לחשבון בחו""ל.',
  confirmBankAccount2: 'חשבון המוטב יזוכה בדר""כ תוך 2-4 ימי עסקים.',
  contactBtnCaption: 'שירות לקוחות >',
  contactMenuCaption1: 'טלגרם',
  contactMenuCaption2: "מסנג'ר",
  contactMenuCaption3: 'להתקשר',
  contactMenuCaption4: 'ווצאפ',
  country: 'ארץ המוצא',
  countryBirth: 'ארץ הלידה',
  countryDestination: 'מדינת יעד',
  countryFrom: 'מדינת המוצא',
  correspondentReference: 'קוד',
  cookies: 'באתר זה נעשה שימוש בקבצי Cookies. המשך הגלישה באתר מהווה הסכמה לשימוש זה. למידע נוסף ניתן לעיין <a style=""text-decoration:underline;display: inline;"" href=""https://gmtonline.co.il/privacy/"" target=""_blank"">במדיניות הפרטיות. </a>',
  clone: 'העברה נוספת',
  payingReference: 'מספר הזמנה',
  creditCard: 'כרטיס אשראי',
  crediting: 'זיכוי',
  creditCardOwner: 'שם על הכרטיס',
  creditCardSupport: 'אנו תומכים ב',
  creditCardCommissionMsg: 'יתכן וחברת האשראי שלך תגבה עמלת המרה',
  creditCardSecurityMsg: 'אנו לא שומרים את פרטי הכרטיס שלך, הם רק מועברים לחברת האשראי לבדיקה ואימות',
  cardDepositAccepted: 'השרות נתמך רק בכרטיסים של חברות Visa / Mastercards',
  Contact: 'קונטקט',
  currency: 'מטבע',
  currencyDestination: 'מטבע ביעד',
  currencyILS: 'ש""ח',
  currencyUSD: 'דולר',
  currencyEUR: 'יורו',
  currencyCardMsg: 'הסכום הסופי שיתקבל תלוי בסוג ומטבע הכרטיס של המוטב',
  currencyBankMsg: 'הסכום הסופי שיתקבל תלוי בסוג ומטבע החשבון של המוטב',
  dateBirth: 'תאריך הלידה',
  daysWork: 'א-ה',
  daysWork2: "א'-ה'  08:30-19:00, ו׳ 08:30-14:00",
  daysWork3: 'ו׳',
  destination: 'יעד',
  discount: 'הנחה',
  docsTitle: 'בבקשה לצלם תעודה מזהה בתוקף',
  docsTitleUploaded: 'תודה, כמעט סיימנו!',
  documentsUploading1: 'אנו שומרים את התמונה שצרפת',
  documentsUploading2: 'זה יקח עוד כמה שניות',
  documentsUploading3: 'ואז אפשר להמשיך',
  editUserProfile: 'הפרטים שלי',
  email: 'כתובת מייל',
  errorCode: 'הקוד לא תקין, נסה שוב',
  estimated: 'משוער',
  calculatedAbroad: 'יחושב בחו""ל',
  expired: 'תוקף הסתיים',
  extraDetails: 'פרטים נוספים',
  exchangeRate: 'שער המרה',
  totalPayment: 'סה״כ לתשלום',
  cashbackDiscount: 'הנחת קאשבק',
  byExchangeRate: 'לפי שער המרה של',
  destinationExchangeRate: 'שער ההמרה ביעד',
  pressPay: '.',
  fax: 'פקס',
  for: 'עבור',
  finished: 'סיים',
  foreignPassport: 'יש לי דרכון זר',
  to: 'ל',
  freeSearch: 'חיפוש חופשי...',
  friday: 'ו׳',
  fullName: 'שם מלא',
  fun: 'איזה כיף. מבטיחים לעדכן רק במה שמעניין',
  foreignCurrencyHandlingFee: 'עמלת טיפול במט""ח',
  gender: 'מין',
  here: 'כאן',
  hours: 'לתשומת ליבך! יש לבצע תשלום עד 48 שעות',
  houseNumber: 'מספר בית',
  welcome: 'שלום',
  interest: 'כולל ריבית {inter}',
  interested: 'ובינתיים, אולי הבלוג שלנו מעניין אותך?',
  includesInterest: 'לחודש - כולל ריבית של ₪{interest} ',
  includesInterest2: 'כולל ריבית של ₪{interest}',
  israel: 'ישראל',
  israeliID: 'יש לי תעודת זהות ישראלית',
  israeliResident: 'תושבי ישראל',
  nonIsraeliResident: 'תושב זר',
  installments: 'תשלומים',
  inProcess: 'בתהליך אישור',
  itemNavMain: 'My Wallet',
  itemNav0: 'myCashBack',
  itemNav1: 'העברת כספים',
  itemNav10: 'סניפים',
  itemNav2: 'הפעולות שלי',
  itemNav3: 'שערים ועמלות',
  itemNav4: 'המוטבים שלי',
  itemNav5: 'סניפי GMT',
  itemNav6: 'שאלות ותשובות',
  itemNav7: 'צרו קשר',
  itemNav8: 'תקנון',
  itemNav9: 'יציאה',
  itemNav11: 'מידע נוסף',
  itemNav12: 'כרטיס נטען',
  itemNavUserProfile: 'הגדרות והפרטים שלי',
  itemNav13: 'הזמנת מט""ח',
  labelEmail: 'כתובת המייל',
  labelMsg: 'מה יש לי לומר?',
  labelName: 'שמי הפרטי באנגלית',
  labelPhone: 'מספר הטלפון',
  lblDone: 'העברה נשלחת כמתנה או תמיכה לקרוב משפחה מדרגה ראשונה, והיא אינה מהווה הכנסה אצל המוטב',
  lblDoneWechat: 'יש לשים לב, בהעברה הראשונה, מקבל הכספים יצטרך לאשר את העסקה ב-WeChat. חשוב לידע אותם על הדרישה זו.',
  lblDoneWechat1: 'Please add 86 before your Chinese mobile number linked with WeChat, or 972 before your Israeli mobile number.',
  labelSurname: 'שם משפחתי באנגלית',
  leaveMsg: 'מה יש לי לומר?',
  linkToLoginPage: 'נרשמת כבר? לעמוד כניסה>',
  listBranches: 'לרשימת הסניפים שלנו',
  locatingBranches: 'חיפוש סניפים',
  locationBtn: 'פתוח',
  locationBtnClose: 'סגור',
  loginLabel1: 'תעודת הזהות / הדרכון',
  loginLabel2: 'מספר הנייד',
  logInConsent: 'עם כניסתי לחשבון, אשרתי את ה',
  mailingAddress: 'כתובת למשלוח דואר',
  mailingRecipient: 'ראול ולנברג 18C תל אביב, 6971915',
  marked: 'מסומן',
  maybe: 'ובינתיים, אולי הבלוג שלנו מעניין אותך?',
  messageCode: 'שלחנו לך עכשיו הודעת טקסט עם קוד. יש להכניס את הקוד בשדה למטה',
  messageSend: 'בדקות הקרובות נשלח אליך גם הודעת SMS ומייל עם אישור העסקה',
  messageNotSent: 'אם לא קבלת את הססמה ב SMS כדאי לבדוק את תיבת ההודעות טקסט ואת תיבת הספאם שלך, נשמח לעזור בכל שאלה',
  messageRegistration1: 'היי, שמחים שחזרת!',
  messageRegistration2: 'נשאר לך רק להעלות תעודה מזהה, לאשר את התקנון וסיימנו',
  messageUploadDocuments: 'נראה שהתעודה המזהה שלך כבר לא בתוקף, אפשר להעלות תעודה חדשה בעמוד הבא',
  middleName: 'שם אמצעי',
  mobilePhone: 'מספר הנייד',
  mobilePhoneNumber: 'מספר נייד ישראלי',
  monday: 'ב׳',
  moneyApproved: '! העברת הכסף אושרה',
  MoneyGram: 'MoneyGram',
  moreOptions: 'עוד אפשרויות',
  msgError: 'יש להשתמש במספרים בלבד',
  maxAccessionCommission: 'עמלת הצטרפות',
  myId: 'תעודת הזהות שלי',
  nameBeneficiary: 'שם המוטב',
  newBeneficiary: 'מוטב חדש',
  version: 'v {num}',
  updateBeneficiary: 'פרטי מוטב',
  nextLevel: 'לשלב הבא',
  no: 'לא',
  noBeneficiaries: 'לא הוגדרו מוטבים במערכת',
  noRecentActions: 'ברוכים הבאים לאיזור האישי ב GMT',
  noRecentActions1: 'כאן תראו את ההיסטוריה שלכם עם GMT',
  noRecentActions2: 'בואו נתחיל!',
  noCardRecentActions: 'לא נמצאו עסקאות בכרטיס',
  notAvailable: 'לא זמין כעת',
  notRequired: 'שדה רשות',
  numberPassport: 'מספר הדרכון',
  numberOfTransaction: 'מספר קבלה',
  openNow: 'פתוח',
  paymentFrom: 'תשלום מ',
  payUsing: 'אני אשלם באמצעות:',
  youllPay: 'לתשלום',
  toPay: 'לתשלום',
  payRate: 'לפי שער {rate}',
  paymentCredit: 'כרטיס אשראי',
  photoID: 'תז, רישיון נהיגה ישראלי או דרכון',
  photoRequired: 'יש להעלות תמונה',
  pinCode: 'צפייה בקוד סודי',
  pinCodeModalMessage: 'רוצה לצפות בקוד הסודי של הכרטיס המסתיים ב {digits}',
  pinCodeModalMessage2: 'שלחנו לך כעת הודעת טקסט לטלפון הנייד עם לינק לצפייה בקוד הסודי',
  placeholderDigit: 'מספר הזהות בן 9 ספרות',
  questionsAnswers: 'שאלות ותשובות',
  questionsAnswersSubtitle: 'ריכזנו בשבילכם שאלות וכמובן תשובות הקשורות להעברות כספים ולשאר השרותים הפיננסים שלנו.לא מוצאים תשובה לשאלתכם? אתם כמובן מוזמנים לפנות אלינו בכל אחת מהדרכים המפורטות בעמוד',
  reasonTransfer: 'סיבת העברה',
  recentActions: 'הפעולות שלי',
  recentActionsTab1: 'מוטב חדש',
  recentActionsTab2: 'שירות הזמנת מט""ח לנתב""ג',
  recentActionsTab3: 'הטענת כרטיס',
  recentActionsTab4: 'קבלת כסף',
  recentActionsTab5: 'רכישת ביטקויין',
  recommended: 'אנו ממליצים!',
  recentActionsBeneficiaries: 'המוטבים שלי',
  recentActionsTransfer: 'העברת כספים',
  r_step_address: 'כתובת',
  r_step_doc: 'תעודה',
  r_step_name: 'שם ועיסוק',
  rate: 'שער',
  registerSystem: 'הרשמה ל GMT',
  registerSystemAddDocuments: 'העלאת תעודה מזהה',
  registerText1: 'בעוד רגע תוכלו לשלוח כסף בקלות ובמהירות',
  registerText2: 'הצטיידו בתעודה מזהה, אנו נבקש לצלם אותה, בואו נתחיל!',
  registerFooter: 'GMT פועלת תחת רישיון נותן שירותים פיננסיים מורחב',
  registerFooter2: 'של משרד האוצר - מספר רישוי 57479',
  registerFooter3: 'האתר שלנו מוצפן ומאובטח ואנו לעולם לא נשתף את הפרטים שלך עם אף אחד.',
  registrationSuccess: 'ההרשמה בוצעה בהצלחה!',
  registrationSuccess1: 'תודה! קיבלנו את כל הפרטים',
  registrationSuccess2: 'ב GMT ניתן לשלוח כסף לחו"ל בקלות ובמהירות',
  requestSuccessfully: 'בקשתך נקלטה בהצלחה',
  requestSuccessfully1: 'מספר ההזמנה:',
  requestSuccessfully2: 'מה עכשיו? יש לשלם עבור ההעברה תוך 24 שעות',
  requestSuccessfully3: '<a href=""https://gmtonline.co.il/branches/"" style=""color:#d4c641;text-decoration: underline;"">באחד מסניפי GMT</a>',
  requestSuccessfully4: 'ולאחר מכן היא תשלח לחו"ל',
  riskyLimitMsg: 'לסכומים גבוהים יותר, אפשר ליצור איתנו קשר.',
  russianSpeaker: 'שרות ברוסית',
  saturday: 'ש׳',
  searchResult: 'נמצאו X סניפים ב XXX',
  selectBeneficiary: 'המוטבים שלי',
  selectCity: 'חיפוש לפי עיר',
  selectCountry: 'ארץ מוצא',
  selectProvince: 'בחר מדינה',
  sendMarketingContentLbl: 'שלחו לי הצעות מיוחדות ועדכונים',
  sentOn: 'תאריך',
  signedUp: 'נרשמת!',
  soonPossible: 'נחזור בשעות הפעילות',
  staging: {
    payWith: 'מזומן בסניף GMT',
    body: 'לאחר לחיצה על כפתור ""הזמנה"", אנו נכין את ההעברה לחו"ל והיא תבוצע לאחר התשלום בסניף לבחירתך.',
    order: 'הזמנה',
  },
  streetName: 'רחוב',
  streetName_HouseNumber: 'שם רחוב ומספר בית',
  subscribeDesc: 'כל מה שחדש ומעניין בתחום הפינטק- ממש כאן בבלוג שלנו ובהרשמה לניוזלטר שלנו',
  subscribeNewsletter: 'הרשמה לניוזלטר הרשמה לניוזלטר - כדאי!',
  subtitleCustomerService: 'נשמח לעזור לך בשירות לקוחות',
  summaryTransfer: 'סיכום פרטי העברה:',
  sunday: 'א׳',
  surtitleFormLang: 'מה השפה המתאימה לך?',
  smartBannerTitle: 'שדרגו לאפליקציית GMT',
  smartBannerText: 'קל. מהיר. בטוח. בדיוק בשבילך',
  smartBannerBtnText: 'הורדה',
  system: 'באמצעות מערכת',
  correspondentSystem: 'בסניפי',
  termsUse: 'תקנון',
  imprtant: 'Important',
  termsTitle: 'יש לקרוא ולאשר את התקנון ומדיניות הפרטיות',
  tpinWarning: 'To ensure your transaction is successful, please verify that your beneficiary’s TPIN and mobile wallet number are registered under the same ID (NRC or Passport). For TPIN registration or verification, visit a Zambia Revenue Authority office or go to zra.org.zm.',
  surveyModal: {
    active: 'true',
    title: 'מה המחשבות שלך: הכנו עבורך סקר אנונימי קצר',
    body: 'היי {name}, נשמח לשמוע ממך ולקבל ממך עזרה כיצד נוכל לשפר את השרותים הפיננסים שלנו בעזרת מענה על סקר קצר ואנונימי. הדיעה שלך חשובה. אפשר להתחיל עכשיו.',
    confirm: 'להתחיל את הסקר עכשיו',
    cancel: 'אח״כ',
    link: 'https://forms.gle/dCH3X8vWdNRUx1y27',
  },
  termsUpdate: {
    title: 'תקנון ומדיניות הפרטיות שלנו',
    body: {
      first: 'שלום, לידיעתך עדכנו את התקנון ומדיניות הפרטיות שלנו.',
      second: 'ניתן לקרוא אותם כאן>',
    },
  },
  thanks: 'תודה שפנית אלינו!',
  thursday: 'ה׳',
  titleFormLang: 'שמחים שבאת',
  titleLoginPage: 'כניסה לחשבון',
  toBankAccount: 'לחשבון',
  toWalletAccount: 'לארנק',
  pickupPoint: 'נקודת איסוף',
  toCreditCard: 'לכרטיס',
  totalNIS: 'סה”כ לתשלום בשקלים',
  totalTransfer: 'סה״כ לתשלום',
  total: 'סה״כ',
  t_step_amount: 'סכום',
  t_step_transfer: 'שליחה',
  t_step_summary: 'סיכום',
  t_step_pay: 'תשלום',
  transferAmount: 'סכום העברה',
  transactionAmount: 'סכום העסקה',
  transactionCharge: 'מיון לפי',
  filterPaymentType: 'סוג התשלום',
  transactionType: 'סוג העסקאות',
  transferFundsTo: 'העברת',
  transferOptions: 'אפשרויות העברה',
  transferType: 'סוג העברה',
  transferTypePick: 'נא לבחור סוג העברה',
  transferTypePick2: 'בחירת סוג העברה',
  tuesday: 'ג׳',
  waitingPayment: 'הזמנה לתשלום:',
  wednesday: 'ד׳',
  yourCardBalance: 'יתרה בכרטיס',
  zipCode: 'מיקוד',
  loginAgreementRequired: 'יש לאשר את קריאת התקנון',
  customerCommission: 'עמלתך',
  customerCommissionMaxTopup: 'עמלת טעינה',
  customerEmailNotification: {
    title: 'עדכון כתובת המייל שלך',
    body: 'נראה שאין לנו את המייל שלך,\nכדאי בשבילך לעדכן אותו בשדה למטה כך נוכל לשלוח לך העתק של כל פעולה גם למייל\nאנחנו לעולם לא נשלח ספאם',
    cancel: 'חזרה',
  },
  sumAmount: 'סכום כולל',
  terms: 'תקנון',
  error: 'שגיאה',
  generalErrorMsg: 'מצטערים, יצאנו לשדרוג קצר כדי להשתפר, אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  connectionError: 'אנא נסו מאוחר יותר שגיאה',
  locationLink: 'branches/',
  faqLink: 'שאלות-ותשובות/',
  contactUsLink: 'צור-קשר/',
  protocolLink: 'protocol/',
  privacyLink: 'privacy/',
  privacyPolicy: 'מדיניות הפרטיות',
  profileDelete: 'מחיקת הפרופיל שלך',
  profileDeleteModalTitle: 'ברצונך למחוק את הפרופיל?',
  profileDeleteModalText: 'בלחיצה על כפתור האישור, \n אנו נקבל הודעה ונחזור אלייך בהקדם',
  aboutLink: 'my-gmt/',
  paymentProcessing: 'בבקשה להמתין עד שנסיים לטפל בבקשה שלך',
  paymentType: 'אופן תשלום',
  paymentTypeCredit: 'תשלום בכרטיס אשראי',
  paymentTypeByCredit: 'שולם באשראי',
  paymentTypeCash: 'מזומן',
  uploadDocumentMsg1: 'אנו נדרשים לאמת את הזהות שלך, לכן אנו מבקשים להעלות תעודה מזהה.',
  uploadDocumentMsg2: 'הפרטיות שלך חשובה לנו מאוד, האתר שלנו מוצפן ומאובטח ואנו לעולם לא נשתף את הפרטים שלך עם אף אחד.',
  yes: 'כן',
  zipCodePlaceholder: '7 ספרות',
  zipCodeRequiredFields: 'בבקשה למלא את פרטי הכתובת שלך, כדי שנוכל לחפש את המיקוד המתאים',
  zipCodeNotFound: 'לא מצאנו מיקוד לכתובת שהזנת, כדאי לבדוק שוב את הכתובת או למלא ידנית מיקוד בן 7 ספרות',
  CORRESPONDENT_BLOCKED: 'מצטערים, השרות אינו פעיל זמנית, אפשר לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CARD_DEPOSIT: 'העברה לכרטיס בחו""ל',
  CARD_DEPOSIT_MAX: 'טעינת כרטיס נטען',
  CARD_DEPOSIT_TO: 'העברה לכרטיס ב{dest}',
  CASH_TRANSFER: 'איסוף מזומן בחו""ל',
  CASH_TRANSFER_INLAND: 'איסוף מזומן',
  CASH_TRANSFER_ISR: 'איסוף מזומן',
  CASH_TRANSFER_TO: 'איסוף מזומן ב{dest}',
  BANK_TRANSFER: 'העברה לחשבון בנק בחו""ל',
  UPI_BANK_TRANSFER: 'העברה לחשבון בנק בחו״ל באמצעות מזהה UPI',
  BANK_TRANSFER_ISR: 'העברה לחשבון בנק',
  BANK_TRANSFER_TO: 'העברה לחשבון בנק ב{dest}',
  BANK_TRANSFER_PAYMENT: 'העברה לחשבון בנק',
  WALLET_TRANSFER: 'העברה לחשבון ארנק בחו""ל',
  WALLET_TRANSFER_TO: 'העברה לחשבון ארנק ב{dest}',
  WALLET_TO_WALLET: 'העברה לחשבון GMT',
  WALLET_FROM_WALLET: 'קבלת כסף מחשבון GMT',
  WALLET_DEPOSIT: 'הפקדה לחשבון GMT',
  NEW_CUSTOMER: 'היי, תהליך ההרשמה יושלם תוך 24 שעות. עד אז, אפשרות התשלום באשראי אינה זמינה. נעדכן אותך ברגע שזה יהיה מוכן.',
  CUSTOMER_NOT_AUTHORIZED: 'מצטערים, אפשרות התשלום באשראי סגורה בפרופיל שלך, נשמח לעזור בכל שאלה',
  CUSTOMER_NOT_SIGNED: 'מצטערים, לא סיימת את תהליך ההרשמה ולכן אפשרות התשלום באשראי עדיין סגורה בפרופיל שלך. נשמח לעזור בכל שאלה',
  CREDIT_LIMIT_UNREGISTERED: 'אפשר להעביר בחודש עד',
  CREDIT_LIMIT: 'אפשר להעביר החודש עד',
  CREDIT_LIMIT_MONTHLY: 'אפשר להעביר החודש עד {amount}',
  CREDIT_LIMIT_CREDIT_CARD: 'סכום העסקה עובר את המקסימום החודשי שלנו בכרטיס אשראי. נישאר לך לשימוש עד {amount}',
  CREDIT_LIMIT_WALLET: 'סכום העסקה עובר את המקסימום החודשי שלנו בארנק. נישאר לך לשימוש עד {amount}',
  CREDIT_LIMIT_RISKY: 'אפשר להעביר ל{country}‎ עד',
  TRANSACTION_LIMIT: 'מצטערים, שרות זה מוגבל עד לסכום של {currency}{amount} לפעולה אחת. אפשר לעדכן את הסכום ולנסות שוב',
  CREDIT_CARD_ILS_AMOUNT: 'התשלום באשראי יהיה זמין מייד',
  CREDIT_NOT_ACTIVE: 'מצטערים, אפשרות התשלום באשראי סגורה כעת, נחזור בשעות הפעילות בין 06:00-24:00.',
  CREDIT_BLOCKED: 'מצטערים, אפשרות התשלום באשראי סגורה כעת עקב שדרוג, אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  API_EXCEPTION: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  GRAILS_VALIDATION_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CAPTCHA_NOT_VERIFIED: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CUSTOMER_SESSION_NOT_FOUND: 'פרטי הזיהוי שגויים או שעוד לא נרשמת ל GMT, אפשר להרשם כאן> <a href=""https://mygmt.gmtonline.co.il/#/registration"" style=""color:#d4c641;text-decoration: underline;"">mygmt.gmtonline.co.il</a>',
  CUSTOMER_BLOCKED: 'מצטערים, הפרופיל שלך סגור, בבקשה לפנות > <a href=""https://gmtonline.co.il/צור-קשר/"" style=""color:#d4c641;text-decoration: underline;"">לשרות לקוחות </a>',
  SMS_WRONG: 'קוד האימות שהזנת שגוי',
  SMS_EXPIRED: 'מצטערים, קוד האימות כבר לא תקף, אפשר לשלוח שוב',
  REGISTRATION_TOKEN_NOT_FOUND: 'קוד האימות שהזנת שגוי',
  REGISTRATION_TOKEN_EXPIRED: 'מצטערים, קוד האימות כבר לא תקף, אפשר לשלוח שוב',
  SESSION_EXPIRED: 'חשבנו שעזבת, אז התנתקנו כדי לשמור על הבטיחות. ניתן להתחבר שוב ולהמשיך.',
  CUSTOMER_MOBILE_NOT_MATCH: 'מצטערים, ניתקלנו בבעיה בפרטים שהזנת, על מנת להרשם לGMT עליך לפנות לשרות לקוחות ',
  WALLET_FRIEND_NOT_UNIQUE: 'מצטערים, לא ניתן לצרף מספר זה לרשימת החברים שלך. נשמח לעזור בכל שאלה, ניתן לפנות אלינו באמצעות הכפתור ״צרו קשר״ למעלה',
  CUSTOMER_ALREADY_REGISTERED: 'היי, אנחנו חושבים שכבר נרשמת לGMT,  אפשר להכנס כאן> <a href=""https://mygmt.gmtonline.co.il"" style=""color:#d4c641;text-decoration: underline;"">mygmt.gmtonline.co.il</a>',
  CUSTOMER_DENIED: 'היי, שמחים שחזרת! לא סיימת את ההרשמה בפעם הקודמת, נשמח לעזור לך',
  BENEFICIARY_NOT_FOUND: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  BENEFICIARY_NOT_FOUND_FOR_CUSTOMER: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  TRANSACTION_NOT_FOUND_FOR_CUSTOMER: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  DOCUMENT_NOT_FOUND: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  DOCUMENT_NOT_FOUND_FOR_CUSTOMER: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  MONEYGRAM_VALIDATION_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CREDIT_CARD_SIGN_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CREDIT_CARD_VERIFY_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CREDIT_CARD_COMMIT_ERROR: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  CUSTOMER_MULTIPLE: 'מצטערים, על מנת להרשם לGMT עליך לפנות <a href=""https://gmtonline.co.il/צור-קשר/"" style=""color:#d4c641;text-decoration: underline;"">לשרות לקוחות </a>',
  MONEYGRAM_EXCEPTION: 'מצטערים, משהו השתבש. אנחנו ממליצים לנסות שוב מאוחר יותר, נשמח לעזור בכל שאלה.',
  MONEYGRAM_301: 'עברתם את הסכום המקסימלי להעברה',
  MONEYGRAM_304: 'מצטערים, שרות העברת הכספים שלנו סגור כעת, נחזור בשעות הפעילות בימי חול בין 8:00-21:00 ובימי שישי בין 8:00-15:00',
  MONEYGRAM_323: 'מצטערים, אך שם המוטב שציינת ארוך מדי, באפשרותך לשלוח שוב עם שם קצר יותר. נשמח לעזור בכל שאלה.',
  MONEYGRAM_4055: 'מצטערים, אך חברת מניגרם דחתה את העברה לחו"ל כדי להגן עליך. העסקה לא בוצעה. נשמח לעזור בכל שאלה.',
  MONEYGRAM_7003: 'מצטערים, אך הגעת למגבלה החודשית במערכת MoneyGram. העסקה לא בוצעה. נשמח לעזור בכל שאלה.',
  FILE_NOT_ALLOWED: 'קיימת בעיה בקובץ',
  INVALID_SESSION_INFO: 'מצטערים, משהו השתבש. נשמח אם תנסו שוב, זה חשוב לנו',
  KYC_EXCEPTION: 'כדי להשלים את הפעולה, אנו זקוקים למידע נוסף. יש ליצור קשר עם שירות הלקוחות לקבלת סיוע. תודה על שיתוף הפעולה.',
  WALLET_FRIEND_ALREADY_ADDED: 'היי, המספר שבקשת כבר קיים ברשימת החברים שלך',
  WALLET_FRIEND_BLOCKED: 'מצטערים, לא ניתן לצרף מספר זה לרשימת החברים שלך. נשמח לעזור בכל שאלה, ניתן לפנות אלינו באמצעות הכפתור ״צרו קשר״ למעלה',
  WALLET_FRIEND_NOT_REGISTERED: 'מצטערים המספר {mobileNumber} עוד לא קיים בGMT. כדאי להשתמש בלינק האישי שלך להזמין חברים חדשים ולקבל CashBack',
  marketingMessage: 'עם GMT ניתן לשלוח כסף לקרובי המשפחה בחו""ל ולאסוף אותו במאות אלפי נקודות התשלום בכל רחבי העולם עם קוד סודי, להפקיד כסף ישירות לכרטיס אשראי בחו""ל או לזכות חשבון בחו""ל.',
  marketingMessage2: 'קל. מהיר. בטוח. בדיוק בשבילך.',
  YAAD_SARIG_3: 'מצטערים, אך חברת האשראי לא אישרה את ההעברה. ממליצים לפנות אליה לטיפול בנושא ואז לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  YAAD_SARIG_4: 'מצטערים, אך חברת האשראי לא אישרה את ההעברה או את פרטי הכרטיס שהזנת. ממליצים לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך.\n\n',
  YAAD_SARIG_6: 'מצטערים, אך ישנה טעות בפרטי הכרטיס שהזנת. ממליצים לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  YAAD_SARIG_26: 'מצטערים, אך מספר תעודת הזהות לא משויך לכרטיס שהזנת. אפשר לנסות שוב עם כרטיס הרשום על שמך. יש שאלות? שירות הלקוחות כאן בשבילך.',
  YAAD_SARIG_141: 'מצטערים, אך כרגע ניתן להשתמש רק בכרטיסי ויזה ומאסטרקארד. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  'מצטערים, אך הגעת לסכום החודשי המקסימלי ב-GMT': {
    ' נשמח אם תצרו קשר כדי להסיר מגבלה זו': {
      ' אנחנו כאן לעזור': {
        '\n\n': 'מצטערים, אך כרטיס האשראי שלך לא תומך בבדיקת האבטחה הנדרשת על ידי חברת האשראי. אפשר לפנות לחברת האשראי לקבלת מידע נוסף. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
      },
    },
  },
  YAAD_SARIG_447: 'מצטערים, אך מספר הכרטיס שהזנת שגוי. ממליצים לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך\n',
  YAAD_SARIG_553: 'מצטערים, אך ישנה בעיה מול חברת האשראי. ממליצים לפנות אליה לטיפול בנושא ואז לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  YAAD_SARIG_999: 'מצטערים, משהו השתבש. ממליצים לנסות שוב מאוחר יותר. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  YAAD_SARIG_3000: 'מצטערים, אך חברת האשראי לא אישרה את ההעברה. ממליצים לפנות אליה לטיפול בבעיה ואז לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך.',
  YAAD_SARIG_3003: 'מצטערים, אך חברת האשראי לא אישרה את ההעברה. ממליצים לפנות אליה לטיפול בבעיה ואז לנסות שוב. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  YAAD_SARIG_9101: 'מצטערים, אך כרטיס האשראי שלך לא תומך בבדיקת האבטחה הנדרשת על ידי חברת האשראי. אפשר לפנות לחברת האשראי לקבלת מידע נוסף. יש שאלות? שירות הלקוחות כאן בשבילך.',
  YAAD_SARIG_GENERAL: 'מצטערים, משהו השתבש בתשלום. ממליצים לנסות שוב מאוחר יותר. יש שאלות? שירות הלקוחות כאן בשבילך.\n',
  INVALID_CARD: 'מצטערים, אך הכרטיס אליו ניסית להעביר אינו נתמך בשירות. ניתן לנסות שוב עם כרטיס אחר. נשמח לעזור בכל שאלה.\n\n',
  INVALID_VERSION: 'מצטערים על ההפרעה. יש לרפרש את העמוד כדי לצפות בעדכונים האחרונים מ-GMT.\n',
  AMOUNT_LIMITED: 'מצטערים, הסכום שניסית להעביר גדול מהמגבלה של כרטיס המוטב. ממליצים לנסות סכום נמוך יותר. נשמח לעזור בכל שאלה.\n',
  CONTACT_GENERAL: 'מצטערים, אנחנו בעדכון קצר כדי לשפר את השירותים שלנו. ממליצים לנסות שוב מאוחר יותר. נשמח לעזור בכל שאלה.',
  BENEFICIARY_INVALID: 'מצטערים, שם המוטב אינו תואם לפרטי הכרטיס בחו"ל. ממליצים לתקן ואז לשלוח שוב, נשמח לעזור בכל שאלה',
  CONTACT_DENIED: 'מצטערים, אך נאלצנו לדחות את העברה לחו"ל כדי להגן עליך. העסקה לא בוצעה. נשמח לעזור בכל שאלה.',
  CARD_DENIED: 'מצטערים אך לא ניתן לבצע העברה לכרטיס זה. נשמח לעזור בכל שאלה.',
  CARD_WRONG_DETAILS: 'מצטערים, פרטי ההעברה לחו"ל שניסית לבצע שגויים, ממליצים לתקן ולנסות שנית. נשמח לעזור בכל שאלה.',
  REGISTER_INFO_DETAILS_REQUIRED: 'היי, הפרטים והתעודה שלך נדרשים לצורך שליחת כסף לחו"ל',
  REGISTER_INFO_SEARCH_ZIP: 'היי, ניתן לחפש את המיקוד שלך ע"י לחיצה על הזכוכית מגדלת',
  CARD_REJECTED: 'מצטערים, אך לא ניתן לשלוח למוטב בחו"ל שציינת כעת. נשמח לעזור בשרות הלקוחות‎',
  BLACKLIST: 'מצטערים, אך לא ניתן לשלוח למוטב בחו"ל שציינת כעת. נשמח לעזור בשרות הלקוחות‎',
  BLACKLIST_CARD: 'מצטערים, אך לא ניתן לשלוח למוטב בחו"ל שציינת כעת. נשמח לעזור בשרות הלקוחות‎',
  COMPANY_IDENTIFICATION: 'מצטערים, נראה שניסית להרשם עם מספר של לקוח עסקי. ניתן להרשם עם מספר תעודת זהות. נשמח לעזור בכל שאלה.',
  CUSTOMER_MONTHLY_LIMIT: 'מצטערים, אך הגעת לסכום החודשי המקסימלי ב-GMT. נשמח לעזור כדי להסיר מגבלה זו. אנחנו כאן לעזור.\n',
  CORRESPONDENT_EXCEPTION: 'מצטערים, אך קיבלנו שגיאה בניסיון לשלוח את ההעברה שלך לחו"ל. ממליצים לפנות אלינו ונשמח לבדוק ולעזור.',
  TEMPORARY_ERROR: 'מצטערים, המערכת נמצאת כעת בשדרוג. ממליצים לנסות שוב מאוחר יותר. נשמח לעזור בכל שאלה.',
  BAD_REQUEST: 'מצטערים, משהו השתבש. ממליצים לנסות שוב מאוחר יותר. נשמח לעזור בכל שאלה.\n',
  MAX_EXCEPTION: 'מצטערים, משהו השתבש. ממליצים לנסות שוב מאוחר יותר. נשמח לעזור בכל שאלה.\n',
  SERVER_ERROR: 'מצטערים, המערכת נמצאת כעת בשדרוג. ממליצים לנסות שוב מאוחר יותר. נשמח לעזור בכל שאלה.',
  CUSTOMER_DAILY_LIMIT_EXCEEDED: 'על מנת להגן על חשבונך, אנו מגבילים את מספר העסקאות היומיות בכרטיס אשראי. יש שאלות? שירות הלקוחות כאן בשבילך.',
  NEW_CUSTOMER_SESSION_LIMIT_EXCEEDED: 'על מנת להגן על חשבונך, אנו מגבילים את מספר העסקאות בכרטיס אשראי. יש שאלות? שירות הלקוחות כאן בשבילך.',
  NEW_CUSTOMER_SESSION_SUM_LIMIT_EXCEEDED: 'על מנת להגן על חשבונך, אנו מגבילים את הסכום הכולל של תשלומים בכרטיס אשראי. יש שאלות? שירות הלקוחות כאן בשבילך.',
  NEW_BENEFICIARY_LIMIT_EXCEEDED: 'על מנת להגן על חשבונך, אנו מגבילים את מספר ההעברות לכרטיסים חדשים בחו"ל. יש שאלות? שירות הלקוחות כאן בשבילך.',
  NEW_BENEFICIARY_AMOUNT_EXCEEDED: 'על מנת להגן על חשבונך, אנו מגבילים את הסכום הכולל של העברות לכרטיסים חדשים בחו"ל. יש שאלות? שירות הלקוחות כאן בשבילך.',
  BENEFICIARY_CARD_BLOCKED: 'מספר הכרטיס או הבנק אינם נתמכים כרגע בשרות, ניתן לשלוח לכרטיס אחר',
  ERROR_NEGATIVE: 'מצטערים, לא ניתן לבצע פעולה בסכום שלילי, בבקשה לעדכן את הסכום המבוקש.',
  E_DECIMAL: 'רק מספר עגול בבקשה',
  E_INVALID_zipCode: 'המיקוד לא תקין',
  E_LEMGTH: {
    '1': '""',
    '2': 'ספרות בלבד',
  },
  E_MAX_VALUE: 'חרגת מהסכום המקסימלי',
  E_MAX_CHAR: {
    '1': 'מקסימום',
    '2': 'תווים',
  },
  E_MIN_CHAR: {
    '1': 'מינימום',
    '2': 'תווים',
  },
  E_MIN_passport: 'מספר הפספורט צריך להכיל יותר מ 5 תווים',
  E_NUMERIC: 'רק מספרים בבקשה',
  E_REGEX: 'פורמט השדה אינו תקין',
  E_REGEX_email: 'נראה שנפלה לך טעות במייל',
  E_REGEX_english: 'רק באנגלית בבקשה',
  E_REGEX_mobileNumber: 'בבקשה להכניס מספר טלפון ישראלי בן 10 ספרות.',
  E_REGEX_israeliId: 'תעודת זהות לא תקינה',
  E_REGEX_passport: 'אותיות ומספרים בלבד בבקשה',
  E_REGEX_birthDateAge: 'ההרשמה לגילאי 18-85 בלבד',
  E_REGEX_zipCode: 'יש לכתוב מיקוד בעל 7 ספרות',
  E_REQUIRED: 'שדה חובה',
  E_REQUIRED_amount: 'מהו הסכום?',
  E_REQUIRED_currency: 'מהו המטבע?',
  E_REQUIRED_destinationCountry: 'מהו היעד?',
  E_REQUIRED_agreement: 'יש לאשר את קריאת התקנון',
  STATUS_NOT_FOUND: 'לא נמצא',
  STATUS_CONFIRMED: 'מאושר',
  STATUS_READY_FOR_PAYMENT: 'מוכן לתשלום',
  STATUS_PAID: 'שולם',
  STATUS_SENT_TO_BANK: 'הועבר לבנק',
  STATUS_BEING_CANCEL: 'בתהליך ביטול',
  STATUS_READY_TO_CANCEL: 'מוכן לביטול',
  STATUS_CANCELED: 'בוטל',
  STATUS_PREPARED: 'טיוטה',
  STATUS_FAILED: 'נכשל',
  STATUS_RETURNED: 'הוחזר',
  STATUS_ACCEPTED: 'התקבל',
  STATUS_STAGED: 'STAGED',
  STATUS_INCOMPLETE: 'ביצוע חלקי',
  STATUS_IN_PROCESS: 'בתהליך תשלום',
  STATUS_SWIFT_TRANSMITTED: 'שודר לבנק',
  STATUS_SWIFT_APPROVED: 'אושר לבנק',
  STATUS_REJECTED: 'נדחה',
  STATUS_EXPIRED: 'לא בתוקף',
  STATUS_CLOSED: 'סגור',
  STATUS_SUBMITTED: 'נשלח',
  DESC_CASHBACK: 'זיכוי לחשבון קאשבק',
  DESC_CASHBACK_WELCOME: 'שמחים שהצטרפת CashBack',
  DESC_CASHBACK_BIRTHDAY: 'מזל טוב CashBack',
  DESC_CASHBACK_ONETIME: 'מתנת CashBack',
  DESC_WALLET: 'הפקדה לארנק',
  DESC_MAX_TOPUP: 'טעינת כרטיס נטען',
  DESC_MAX_PURCHASE: 'רכישה וטעינת כרטיס ניטען',
  DESC_MAX_ORDER: 'הזמנת כרטיס נטען',
  DESC_CONVERSION: 'המרה',
  DESC_019MOBILE: '019 mobile',
  DESC_REWIRE: 'Rewire',
  DESC_DEPOSIT_3MONTHS: 'פקדון ל 3 חודשים',
  DESC_DEPOSIT_6MONTHS: 'פקדון ל 6 חודשים',
  DESC_DEPOSIT_WITHDRAW: 'משיכה מפקדון',
  DESC_DEPOSIT_INTEREST_PAYMENT: 'תשלום ריבית מפקדון',
  DEPOSIT_IN: 'הפקדה לחשבון פקדון',
  DEPOSIT_OUT: 'משיכה מחשבון פקדון',
  DEPOSIT_INTEREST: 'תשלום ריבית מפקדון',
  NAME_ALIPAY: 'ALIPAY',
  NAME_UNIONPAY: 'UNIONPAY',
  NAME_WECHATPAY: 'WECHATPAY',
  NAME_UPI: 'UPI',
  validation: {
    mock: '',
    beneficiary: {
      invalid: 'ערך לא חוקי',
      maskedCardNumber: {
        invalidCountry: 'הכרטיס כנראה אינו תואם למדינת היעד',
        tranglo: 'מצטערים, פרטי המוטב כפי שהקלדת שגויים, אפשר לעדכן ולנסות שוב',
      },
      accountIdentifier: {
        invalid: 'ערך לא חוקי',
        tranglo: 'מצטערים, פרטי המוטב כפי שהקלדת שגויים, אפשר לעדכן ולנסות שוב',
      },
      walletIdentifier: {
        invalid: 'ערך לא חוקי',
        tranglo: 'מצטערים, פרטי המוטב כפי שהקלדת שגויים, אפשר לעדכן ולנסות שוב',
      },
      phoneWallet: {
        unavailable: 'לצערנו, החשבון הזה אינו זמין ולא יכול לקבל כספים. בבקשה לבדוק את החשבון ולנסות שנית',
        mismatch: 'לצערנו, פרטי החשבון ושם החשבון אינם תואמים. אפשר לתקן ולנסות שוב',
        invalidAccount: 'לצערנו, החשבון אינו תקין. בבקשה לבדוק לפני ניסיון נוסף',
        barred: 'לצערנו, החשבון חסום. בבקשה לבדוק לפני ניסיון נוסף',
      },
      phoneNumber: {
        format: {
          passParam: 'הפורמט הנכון: {format}',
        },
      },
    },
  },
  NEW_BENEFICIARY_EXCEEDED: 'על מנת להגן על חשבונך, אנו מגבילים העברות למוטבים חדשים. יש שאלות? שירות הלקוחות כאן בשבילך',
  EMAIL_ALREADY_EXISTS: 'האימייל הזה כבר בשימוש. בבקשה להזין אימייל אחר כדי להמשיך',
  EMAIL_ALREADY_EXISTS_UPDATE: 'כתובת המייל שהזנת כבר מקושרת לחשבון אחר ואינה ניתנת לשימוש. ניתן להזין כתובת אחרת או לדלג עליה בשלב זה.',
  paymentStatuses: {
    titles: {
      CASH_TRANSFER: 'מעולה! ההעברה שלך נשלחה',
      BANK_TRANSFER: 'מעולה! ההעברה שלך נשלחה',
      UPI_BANK_TRANSFER: 'מעולה! ההעברה שלך נשלחה',
      CARD_DEPOSIT: 'מעולה! ההעברה שלך נשלחה',
      WALLET_TRANSFER: 'מעולה! ההעברה שלך נשלחה',
      WALLET_DEPOSIT: 'מעולה! ההפקדה בוצעה בהצלחה',
      WALLET_TO_WALLET: 'מעולה! ההעברה שלך נשלחה',
    },
    messages: {
      CASH_TRANSFER: 'העברת בהצלחה {amount} {currency} ל {beneficiary} לאיסוף מזומן ב{destination}. אישור יישלח אליך גם בSMS ובאימייל.',
      BANK_TRANSFER: 'העברת בהצלחה {amount} {currency} ל {beneficiary} לחשבון בנק ב{destination}. אישור יישלח אליך גם בSMS ובאימייל.',
      UPI_BANK_TRANSFER: 'העברת בהצלחה {amount} {currency} ל {beneficiary} לחשבון בנק ב{destination}. אישור יישלח אליך גם בSMS ובאימייל.',
      CARD_DEPOSIT: 'העברת בהצלחה {amount} {currency} ל {beneficiary} לכרטיס ב{destination}. אישור יישלח אליך גם בSMS ובאימייל.',
      WALLET_TRANSFER: 'העברת בהצלחה {amount} {currency} ל {beneficiary} לארנק ב{destination}. אישור יישלח אליך גם בSMS ובאימייל.',
      WALLET_DEPOSIT: 'הפקדת בהצלחה {amount} {currency} לחשבונך ב GMT. אישור יישלח אליך גם בSMS ובאימייל.',
      WALLET_TO_WALLET: 'העברת בהצלחה {amount} {currency} ל {beneficiary} לחשבונם ב GMT. אישור יישלח אליך גם בSMS ובאימייל.',
    },
    buttons: {
      buttonAction: 'להיסטוריית העסקאות',
      buttonLink: 'לדף הבית',
    },
  },
  errors: {
    default: {
      title: 'אירעה שגיאה',
      buttonAction: 'ניסיון נוסף',
      buttonLink: 'יצירת קשר ',
    },
    service: {
      title: 'סליחה, משהו השתבש',
      buttonAction: 'יצירת קשר ',
      buttonLink: 'לדף הבית',
    },
    login: {
      title: 'חזרת!',
      buttonAction: 'להתחברות מחדש',
    },
    registration: {
      title: 'חזרת!',
      buttonAction: 'יצירת קשר ',
    },
    card_error: {
      title: 'הייתה בעיה בתשלום',
      buttonAction: 'ניסיון נוסף',
      buttonLink: 'יצירת קשר ',
    },
    kyc: {
      title: 'נדרש מידע נוסף',
      action: 'יצירת קשר ',
    },
    nonefound: {
      title: 'יש בעיה לשלוח כסף? נפתור את זה!',
      buttonAction: 'יצירת קשר ',
      buttonLink: 'בחירת מדינה אחרת',
    },
  },
  DEFAULT: 'אנו מצטערים, אך אירעה שגיאה שלא צפינו. מומלץ לנסות שוב מאוחר יותר. אם הבעיה נמשכת, נשמח לסייע לך',
  paymentPlan: 'בחירת מסלול התשלום שמתאים לך',
  by: 'באמצעות',
  NO_DELIVERY_OPTIONS: 'נראה שהעברת כספים ל-{{ countryName }} אינה זמינה כרגע.\nאבל אל דאגה – אנחנו כאן כדי לעזור!\nצוות השרות שלנו יכול לבדוק אפשרויות חלופיות או להודיע לכם כאשר השירות יהיה זמין שוב.\n',
  transactionReason: {
    personalUse: 'תמיכה משפתית',
  },
  deleteBeneficiary: 'מחיקה המוטב',
  keepBeneficiary: 'שמירת המוטב',
  minAmountToCurrencyError: 'אפשרות שליחה זו לפיליפינים מתחילה מ-{minAmountInToCurrency}{currency}.',
  maxAmountToCurrencyError: 'לאפשרות שליחה זו לפיליפינים יש מגבלת שליחה מקסימלית של {maxAmountInToCurrency}{currency}.',
  paymentRequestTitle: 'בקשות תשלום אחרונות',
  paymentRequest: {
    title: 'בקשת תשלום',
    yourAccount: 'החשבון שלך',
    transferType: 'סוג העברה',
    insufficientBalance: 'יתרה לא מספיקה',
    action: 'חזרה לרשימת הבקשות',
    link: 'דחיית הבקשה',
    validUntill: 'בתוקף עד',
    checkDetails: '',
    action2: 'אישור תשלום',
    rejectedInfo: 'בקשת התשלום נדחתה',
    completedInfo: 'בקשת התשלום הושלמה',
    paymentRequestExplain: 'העברת הכספים תתבצע לפי הפרטים שסופקו על ידי יוזם התשלום.\nהמערכת אינה בודקת התאמה בין שם בעל החשבון לבין מספר החשבון, ולכן מומלץ לוודא את נכונות הפרטים לפני ביצוע הפעולה.\nלא ניתן לבטל את ההעברה לאחר שבוצעה.\nהודעה זו אינה מהווה אישור או התחייבות לביצוע הפעולה.\nהוראת התשלום מבוצעת באופן מיידי, עם ערך תאריך של היום, וצפויה להירשם בחשבון המוטב בהתאם.\nבמקרים מסוימים, הפעולה תועבר לגורם מטפל לאישור. במקרים אלה, ייתכן שתאריכי החיוב והזיכוי יידחו מהתאריכים שצוינו לעיל.\n',
  },
  paymentRequests: {
    link: 'בקשות תשלום',
  },
  recentRequests: 'בקשות אחרונות',
}