<template>
  <div>
    <Header></Header>
    <div class="main-wrapper">
      <MainNav></MainNav>
      <Wellcome></Wellcome>
      <div class="content block block-card-main wallet-main">
        <div class="card">
          <p class="card-header" v-text="$ml.get('wallet.main.card.title')"/>
          <div class="card-balance">
            <p class="currency-symbol" v-formatTitleAmount="[balanceAmount, balanceCurrency]"/>
          </div>
          <div v-if="cashBackBalances.length > 0" class="card-cash-back">
            <i class="fas fa-gift"/>
            <span v-for="(balance, index) in cashBackBalances">
              <span v-format-title-amount="[balance.amount, balance.symbol]"/>
              <span v-if="cashBackBalances.length > 1 && index < cashBackBalances.length - 1">,&nbsp;</span>
            </span>
          </div>
          <a @click="goToRecentActions" class="pointer">{{ $ml.get('wallet.main.card.transactionsLink') }}</a>
        </div>
        <!--        <h1 class="title sub-title">{{ $ml.get('wallet.main.sendMoney') }}</h1>-->
        
        <div class="row lottie-and-link" v-show="this.$store.getters.isOrderActive" @click="goToWalletOrder()">
          <lottie-player id="walking-gif-elm" autoplay loop mode="normal" :class="{ mirror: this.$store.getters.getDir === 'rtl'}"></lottie-player>
          <a class="sub-title">
            {{ $ml.get('walletOrder.goToActiveOrder') }}
          </a>
        </div>
        <div class="row" v-if="!this.isIsraeli && !this.$store.getters.isOrderActive">
          <a @click="openDoarModal" class="sub-title">
            <i class="icon-action fa fa-youtube-play"></i> {{ $ml.get('wallet.main.doarInstruction') }}
          </a>
        </div>
        <div class="actions">
          <action @action="goToSendHomePage">{{
            $ml.get('wallet.main.sendActions.sendHome') + (this.countries.find(element => element.iso === countryIso3).emoji || '')
          }}
          </action>
          <action v-text="$ml.get('wallet.main.sendActions.inIsrael')" @action="goToSendInIsrael"/>
        </div>
        <h1 class="title sub-title">{{ $ml.get('wallet.main.walletOptions') }}</h1>
        <div class="actions">
          <action @action="goToWalletFriendsPage"><i style="padding-inline-end: 5px" class="fas fa-users"/>{{ $ml.get('wallet.main.walletActions.send') }}</action>
          <action @action="goToCardPage"><i style="padding-inline-end: 5px" class="fa fa-cc-visa"/>{{ $ml.get('wallet.main.walletActions.loadPrePaid') }}</action>
          <action @action="goToInvitationPage"><i style="padding-inline-end: 5px" class="fas fa-gift"/>{{ $ml.get('wallet.main.walletActions.invite') }}</action>
        </div>
        <h1 class="title sub-title">{{ $ml.get('wallet.main.depositToWallet') }}</h1>
        <div class="row">
          <action :title="$ml.get('wallet.main.depositActions.cash')" icon="fa fa-money" :newNotification="true" @action="goToCashDepositPage"/>
          <action :title="$ml.get('wallet.main.depositActions.bank')" icon="fa fa-university bank" @action="goToBankDepositPage"/>
          <action :title="$ml.get('wallet.main.depositActions.card')" icon="fa fa-credit-card" @action="goToTransferPage"/>
        </div>
        <div class="modal-mask modal-mask-message toast" @click="closeDialgue">
            <div class="modal-container container-dialogue modal-message" id="modal-message" @click.stop>
                <div class="modal-body body-dialogue">
                    <div class="modal-header">
                        <a class="a-close" @click="closeDialgue" :class="{ 'a-close-rtl': getDir === 'rtl' }">&#10005;</a>
                    </div>
                    <div class="title-you-tube" v-text="$ml.get('wallet.main.doarInstruction')"></div>
                      <div class="row row-youtube">
                        <iframe width="560" height="315" :src="getUrl" showinfo=0 frameborder="0" class="iframe-y" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen ></iframe>
                      </div>
                    <div class="form-group group-btn">
                        <button type="submit" class="btn btn-small btn-yellow" v-text="$ml.get('btnReturn')" @click="closeDialgue()"></button>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import Header from "../../components/Header";
import MainNav from "../../components/MainNav";
import Action from "@/components/Action";
import {EventBus} from '../../event-bus.js';
import Wellcome from '../../components/Wellcome.vue';
import {loadLottieJson, modalHandler} from "../../services/utils";

export default {
  name: 'WalletMain',
  components: {Action, MainNav, Header, Wellcome},
  data() {
    return {
      countries: [],
      resetIframeSrc: true,
    }
  },
  computed: {
    isIsraeli(){
      const rd = JSON.parse(localStorage.getItem('registrationData'));
      return rd && rd.country == 'ISR';
    },
    getDir() {
      return this.$store.getters.getDir;
    },
    balance() {
      return this.$store.getters.getWalletBalances.find(balance => balance.currency === 'ILS');
    },
    balanceAmount() {
      return this.balance ? this.balance.amount : ''
    },
    balanceCurrency() {
      return this.balance ? this.balance.symbol : '₪'
    },
    cashBackBalances() {
      return (this.$store.getters.getCashBackBalances || []).filter(element => element.amount > 0)
    },
    countryIso3() {
      return localStorage.getItem('country');
    },
    getUrl(){
      const langUrl = {
        il: 'lU4NnpKP2HM',
        ru: 'i5V2_uwSgxM',
        th: 've4pWm4Jz_k',
        cn: '57t7XQtVNZM',
        en: 'lU4NnpKP2HM',
      }      
      // const langUrl = {
      //   il: 'lBr32bsia4o',
      //   ru: 'TIH-Jax2KKU',
      //   th: 'OC472qeVw4k',
      //   cn: 'b6lPKihg5k0',
      //   en: 'lBr32bsia4o',
      // }
      return 'https://www.youtube.com/embed/' + langUrl[this.$store.state.locale.lang] + (this.resetIframeSrc ? '?autoplay=0' : '?autoplay=0&')
    }
  },
  created() {
    window.scrollTo(0, 0);
    this.countries = require('../../data/countries');
  },
  mounted () {
    document.body.setAttribute('dir', this.getDir);
    loadLottieJson("walking-gif-elm", "walking")
    if(localStorage.getItem('redirectToWalletOrder')) {
        localStorage.removeItem('redirectToWalletOrder')
        this.goToWalletOrder()
    }
  },
  methods: {
    goToRecentActions() {
      this.$router.push('/recent_actions')
    },
    openDoarModal() {
      modalHandler('.modal-mask-message', 'addClassOpen')
    },
    goToCashDepositPage () {
      if (this.$store.getters.isOrderActive) {
        this.goToWalletOrder()
      } else {
        this.$router.push('/wallet/deposit/cash')
      }
    },
    goToBankDepositPage() {
      this.$router.push('/wallet/deposit/bank')
    },
    goToTransferPage() {
      this.$router.push('wallet/transfer')
    },
    goToSendHomePage() {
      // EventBus.$emit('clearTransferState');
      this.$router.push('/transfer')
    },
    goToSendInIsrael() {
      this.$store.commit('setTransferStep1', {
        amount: null,
        currency: 'ILS',
        destinationCountry: 'ISR'
      });
      this.$router.push('/transfer')
    },
    goToWalletReceivePage() {
      this.$router.push('/wallet/receive')
    },
    goToWalletFriendsPage () {
      this.$router.push('/wallet/friends')
    },
    goToWalletOrder () {
      this.$router.push('/wallet/walletOrder')
    },
    goToInvitationPage() {
      this.$router.push('/wallet/invite')
    },
    goToCardPage() {
      this.$router.push('/card_main')
    },
    closeDialgue(){
      modalHandler('.modal-mask-message', 'removeClassOpen')
      setTimeout(() => {
          this.resetIframeSrc = false;
          setTimeout(() => { this.resetIframeSrc = true;}, 600)
        }, 600)
    },
  },
}

</script>

<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";
@import "../../assets/styles/partials/mixins";

.block.title {
  font-size: 20px;
}

.block {
  .title {
    margin-left: 20px;
  }

  .card {
    margin: auto;
    margin-top: 1%;
    margin-bottom: 2%;
    text-align: center;
    line-height: 1.7;
    box-shadow: 1px 1px 3px 0.1px #969696;
    background-color: $mainColor;
    padding: 4px 10px;
    color: $bgColor;
    width: 75%;

    a {
      color: $secondaryColor;
    }

    .card-header {
      font-size: 18px;
      padding: 5px 0px 0px 0px;
    }

    .card-balance {
      display: flex;
      justify-content: center;
      font-size: 28px;
      font-weight: 600;

      .currency-symbol {
        font-size: 40px;
      }
    }

    .card-cash-back {
      display: flex;
      justify-content: center;
      font-size: 18px;
      text-align: center;
      margin-bottom: 6px;
      margin-top: -12px;

      i {
        line-height: 1.7;
        margin-right: 2%;
        margin-left: 2%;
      }
    }
  }

  .title {
    font-size: 20px;
  }

  .title.sub-title {
    font-size: 20px;
    margin-bottom: 1%;
  }

  a.sub-title{
    cursor: pointer;
    .fa-youtube-play{
      color:red;
    }
  }

  .title-you-tube{
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color:#ffffff
  }

  .container-dialogue{
    opacity: 1;
  }

  .row {
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    height: auto;

    .action-card {
      margin: 0.5%;
      padding: 20px 0;
      cursor: pointer;
      width: 130px;
    }
  }

  .actions {
    margin-bottom: 2%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    .action-card {
      width: 415px;
      margin: 0.3%;
      padding: 8px;
      cursor: pointer;
      font-weight: 600;
      font-size: 20px;
    }
  }

  .actions .fa-gift {
    color: #00cc00;
  }

    .lottie-and-link {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom:10px;
      #walking-gif-elm {
        margin-inline-end: 10px;
        width: 4.5%;
      }
    }
}

@include max-screen($screen-phones) {
  .block .title {
    font-size: 20px;
    text-align: right;
    margin-right: 20px;
  }
  .block {
    .card {
      line-height: 1.5;
      margin-top: 3%;
      margin-bottom: 5%;
      width: 90%;
    }

    .title.sub-title {
      font-size: 20px;
    }

    .row {
      margin: 0 auto;
      margin-top: 2%;
      margin-bottom: 5%;
      display: flex;
      justify-content: center;
      flex-direction: row;
      height: auto;
      width: 90%;
      .action-card {
        margin: 1%;
        width: 120px;
      }
      .iframe-y{
        width: 100%;
      }
    }
    .row-youtube{
      width: 96%;
    }

    .actions {
      margin-top: 2%;
      margin-bottom: 5%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      .action-card {
        width: 88%;
        margin: 1%;
        padding: 14px;
      }
    }
    .lottie-and-link {
      #walking-gif-elm {
        width: 20%
      }
    }
    
  }
}
</style>