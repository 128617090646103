<template>
    <div draggable="false" class="grey-bgc  main-container relative" :dir="$store.getters.getDir">
        <img class="kite" src="../../assets/mygmt_v2_images/kite-transfer.svg" alt="">
        <Header2 v-if="(!isFocused() && isMobileDevice) || !isMobileDevice" @stepBack="handleBackClick"
            :forceCloser="isCalculatorRoute && currentStep == 0" :currentStep="currentStep + 1"
            :hide="['FAQ', 'LOCATIONS']"></Header2>
        <div class="transfer-container relative" :class="{ 'zero-padding': currentStep === 0 && isMobileDevice }">
            <div class="upper flex flex-column">
                <div @click="handleBackClick" class="pointer back" v-if="!isMobileDevice">
                    < {{ $ml.get('transfer.buttons.back') }} </div>
                        <div class="money-title">
                            {{ isCalculatorRoute ? $ml.get('transfer.titles.chooseDestination') :
                                $ml.get('wallet.main.sendMoney') }}
                        </div>
                </div>

                <div class="flex transfer-info" v-if="currentStep && currentStep < 3"
                    :class="{ 'justify-center': isMobileDevice }">
                    <div class="flex full-center">
                        <img class="flag" :src="getCountryFlagByIso()" alt="" srcset="">
                        <div>{{ selectedCountry }}</div>
                    </div>
                    <div class="flex" v-if="selectedTransferOption">
                        <div class="flex">
                            <div class="flex full-center eclipse"> <img src="../../assets/mygmt_v2_images/Ellipse.svg"
                                    alt="" srcset=""></div> <span>{{ selectedTransferOption.amount +
                                        selectedTransferOption.commission | formatComma }} {{ selectedTransferOption.currency
                                }}</span>
                            <div class="flex full-center eclipse"> <img src="../../assets/mygmt_v2_images/Ellipse.svg"
                                    alt="" srcset=""></div>
                        </div>
                        <div> {{ $ml.get('transfer.deliveryOptions.' + selectedTransferOption.transactionClass) }}</div>
                    </div>
                </div>
                <div style="margin-bottom: 10px;" v-else></div>
                <ProgressBar style="margin-bottom: 10px;"
                    v-if="currentStep > 0 && ((!isFocused() && isMobileDevice) || !isMobileDevice)"
                    :trackColor="'#F6F6F6'" :fillColor="'#D5C840'" :ballColor="'#D5C840'" :progress="calculatedProgress"
                    class="progress-bar" />
                <component :isCalculatorRoute="isCalculatorRoute" ref="genericComponent" :previousStep="previousStep"
                    :is="steps[currentStep]" @changeStep="changeStep($event)" @changeStatus="changeStatus($event)">
                </component>

            </div>
        </div>
</template>
<script>
import CountrySelect from './steps/step1/CountrySelect.vue'
import Lookup from '../../components/Lookup.vue';
import MethodAndAmountSelection from './steps/step2/MethodAndAmountSelection.vue'
import Header2 from "@/components/Header2";
import Footer from "@/components/Footer.vue";
import ProgressBar from './steps/components/ProgressBar.vue'
import { isMobile, getCountryNameByIso } from "@/services/utils";
import BeneficiarySelection from './steps/step3/BeneficiarySelection.vue';
import Summary from './steps/step4/Summary.vue';
import PaymentMethodSelection from './steps/step5/PaymentMethodSelection.vue';
import Payment from './steps/step6/Payment.vue';
import { EventBus } from '../../event-bus';
import TransferStatus from './steps/components/TransferStatus.vue';




export default {
    components: { CountrySelect, MethodAndAmountSelection, Header2, Footer, ProgressBar, Lookup, BeneficiarySelection, Summary, PaymentMethodSelection, Payment, TransferStatus },
    data() {
        return {
            isMobileDevice: isMobile(),
            steps: ['CountrySelect', 'MethodAndAmountSelection', 'BeneficiarySelection', 'Summary', 'PaymentMethodSelection', 'Payment'],
            currentStep: 0,
            progress: 0,
            previousStep: '',
            showStatus: false,
            statusType: ''
        }
    },
    created() {
        const country = this.$route.query.destinationCountry || this.$store.state.transfer.destinationCountry
        if (country) {
            this.$store.commit('setTransferDestinationCountry', country);
        }
        const langQuery = this.$route.query.lang ? this.$route.query.lang.toLowerCase() : ''
        const langState = this.$store.getters.getLang
        if ((!langState && langQuery) || (langState && langQuery && langQuery !== langState)) {
            this.$store.commit('changeLang', { 'lang': langQuery, 'dir': '' });
            this.$store.commit('setLangTitle', langQuery);
        }
        let step = 0
        if (!this.isCalculatorRoute) {
            this.$store.dispatch('loadBeneficiary');
        }
        if (country) this.changeStep(1)

    },
    mounted() {
        document.body.setAttribute('dir', this.dir);
    },
    methods: {
        changeStep(idx) {
            this.previousStep = this.steps[this.currentStep]
            this.currentStep = idx
        },
        changeStatus(status) {
            this.statusType = status
            this.showStatus = true
        },

        isFocused() {
            if (this.$refs && this.$refs.genericComponent && this.$refs.genericComponent.isFocused) {
                return true
            } else return false
        },
        handleBackClick() {
            const component = this.$refs.genericComponent
            if (component && component.name === 'MethodAndAmountSelection' && component.isCalculating) {
                component.amount = component.previousState.amount
                component.selectedCurrency = component.previousState.currency
                component.isCalculating = false
            } else if (component && component.name === 'BeneficiarySelection' && component.isCreatingBeneficiary) {
                if (component.$children[0].creationStep > 1) {
                    component.$children[0].creationStep--
                } else if (component && component.$children[0].creationStep == 1) {
                    component.isCreatingBeneficiary = false
                }

            } else if (this.$store.state.transfer.cloneInfo && component.name === 'Summary') {
                this.currentStep = this.currentStep - 2
            } else if (component.name === 'PaymentMethodSelection' && component.showCreditCard) {
                component.showCreditCard = false
                component.isSubmitted = false
            } else {
                this.currentStep > 0 ? this.currentStep-- : this.$router.go(-1);
            }
        },
        getCountryFlagByIso() {
            try {
                return require(`../../assets/mygmt_v2_images/countries/${this.$store.state.transfer.destinationCountry}.svg`);

            } catch (error) {

            }

        }
    },
    computed: {
        dir() {
            return this.$store.getters.getDir;
        },
        getTransferStatus() {
            return this.$store.getters.getTransferStatus
        },

        isLoggedIn() {
            return this.$store.getters.getLoggedIn && !this.$store.getters.getNotSigned;
        },

        isCalculatorRoute() {
            const calculatorRoute = 'calculator-unregistered';
            return this.$route.path.includes(calculatorRoute);
        },

        selectedCountry() {
            return getCountryNameByIso(this.$store.state.transfer.destinationCountry)
        },
        selectedTransferOption() {
            if (this.$store && this.$store.getters) {
                return this.$store.getters.getSelectedTransferOption

            }
        },
        calculatedProgress() {
            return (this.currentStep / (this.steps.length - 1)) * 90;
        }
    },
    watch: {
        currentStep(newValue) {
            if (newValue === 0) {
                this.$store.commit('setTransferStep1Response', []);
                this.$store.commit('setTransferStep1', {});
                this.$store.commit('setCurrentBeneficiary', null);
                sessionStorage.setItem('currentBeneficiary', null);
            }
            if (newValue > 1 && !this.isLoggedIn) {
                this.$router.push('/my-gmt-welcome')
            }
        }
    },
    beforeDestroy() {
        if (this.isLoggedIn) {
            EventBus.$emit('clearTransferState');
            sessionStorage.setItem('currentBeneficiary', null);
            this.$store.commit('setCurrentBeneficiary', null);
            this.$store.commit('setTransferStep1', {})
            this.$store.commit('setDestinationCountry', null)
            this.$store.commit('setTransferStatus', null)
            this.$store.commit('setLookupAmount', null);
            this.$store.commit('setLookupCurrency', null);
            EventBus.$emit('updateWalletBalances')

        }
        EventBus.$emit('unload');
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/styles/partials/variables";

.main-container {
    min-height: none;
    overflow: hidden;
    overflow-y: auto;
    background-image: none;
}

.kite {
    display: none;
}

.eclipse {
    margin: 0 8px
}

.transfer-info {
    margin-bottom: 12px;
    font-size: 14px;

    .flag {
        height: 18px;
        width: 18px;
        margin-right: 5px;
        margin-left: 0px;
    }
}

[dir="rtl"] .transfer-info .flag {
    margin-right: 0px;
    margin-left: 5px;


}


.mobile-margin {
    margin-right: 20px;
    margin-left: 20px;
}

.zero-padding {
    padding: 0px !important;
}

.transfer-container {
    min-height: 90vh;
    padding: 0px 20px;
    background: #FFF;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: 100px;
    //box-shadow: 0px 4px 24px 0px rgba(177, 177, 177, 0.13);
    background-image: url('../../assets/mygmt_v2_images/mygmt2-background-grey.svg');

    .upper {
        align-items: center;

        // margin-bottom: 10px;
        .back {
            font-size: 16px;
            margin-top: 25px;
            margin-bottom: 8px;
            color: var(--GMT--Blue, #0E1F81);
        }

        .money-title {
            font-size: 20px;
            font-weight: 700;
            color: #0E1F81;
            font-style: normal;
            line-height: normal;
            font-family: Assistant;
        }
    }

    .progress-bar {
        width: 100vw;
        height: 4px;
        margin-left: -20px;
        background: #F6F6F6;

        // margin-bottom: 26px;
        // position: relative;
        .test-grey {
            // position: absolute;
            // background: #F6F6F6;
        }
    }

}

.mygmt2-header-wrapper {
    background-color: #FFFF;
}

@media only screen and (min-width: $screen-tablet) {

    .upper {
        margin-bottom: 10px;
    }

    .mobile-margin {
        margin-left: 0px;
        margin-right: 0px;
    }

    .main-container {
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: auto auto, auto auto;
        background-repeat: no-repeat, no-repeat;
        background-position: left bottom, right top;
        background-image: url('../../assets/mygmt_v2_images/mygmt2-backgground-white.svg'), url('../../assets/mygmt_v2_images/mygmt-backgground-white-small.svg');
    }

    .kite {
        right: 0px;
        top: 200px;
        z-index: 0;
        display: block;
        position: absolute;
    }


    .transfer-container {
        z-index: 2;
        width: 600px;
        margin: 0 auto;
        min-height: 80vh;
        overflow: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        max-height: 80vh;
        margin-top: 40px;
        padding: 0px 24px;
        border-radius: 20px;
        background-image: none;
        box-shadow: 0px 3px 11.66px -4.66px rgba(51, 77, 194, 0.34);


        .upper {
            align-items: flex-start;

            .money-title {
                font-size: 24px;
            }
        }

        .progress-bar {
            width: 100%;
            margin-left: 0px;
        }
    }
}
</style>